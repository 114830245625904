import React from 'react'

const BrandList = () => {
    return (
        <>
            <div className='header-home__search p-x-16'>
                <form className="form">
                    <div className="form-group">
                        <div className="input-icon">
                            <input type="text" className="bannerInput-search" placeholder="Search for brands"/>
                            <span className="bannerSearch-icon">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.0262 12.8473L17.5953 16.4157L16.4162 17.5948L12.8478 14.0257C11.5201 15.09 9.86866 15.6689 8.16699 15.6665C4.02699 15.6665 0.666992 12.3065 0.666992 8.1665C0.666992 4.0265 4.02699 0.666504 8.16699 0.666504C12.307 0.666504 15.667 4.0265 15.667 8.1665C15.6694 9.86817 15.0905 11.5196 14.0262 12.8473ZM12.3545 12.229C13.4121 11.1414 14.0027 9.68353 14.0003 8.1665C14.0003 4.94317 11.3895 2.33317 8.16699 2.33317C4.94366 2.33317 2.33366 4.94317 2.33366 8.1665C2.33366 11.389 4.94366 13.9998 8.16699 13.9998C9.68402 14.0022 11.1419 13.4116 12.2295 12.354L12.3545 12.229Z" fill="#999999"/>
                                </svg>                                                        
                            </span>
                        </div>                                            
                    </div>
                </form>
            </div>
            <div className='brands-list__wrap'>
                <div className='brands-list__flex'>
                    <div className='brands-list__head'>
                        <span>A</span>
                    </div>
                    <div className='brands-list__content'>
                        <div className='brands-list__item'>Amazfit</div>
                        <div className='brands-list__item'>Amazon</div>
                        <div className='brands-list__item'>Aero</div>
                    </div>
                </div>
                <div className='brands-list__flex'>
                    <div className='brands-list__head'>
                        <span>B</span>
                    </div>
                    <div className='brands-list__content'>
                        <div className='brands-list__item'>BoAT</div>
                    </div>
                </div>
                <div className='brands-list__flex'>
                    <div className='brands-list__head'>
                        <span>c</span>
                    </div>
                    <div className='brands-list__content'>
                        <div className='brands-list__item'>Crompton</div>
                    </div>
                </div>
                <div className='brands-list__flex'>
                    <div className='brands-list__head'>
                        <span>D</span>
                    </div>
                    <div className='brands-list__content'>
                        <div className='brands-list__item'>Dyson</div>
                    </div>
                </div>
                <div className='brands-list__flex'>
                    <div className='brands-list__head'>
                        <span>f</span>
                    </div>
                    <div className='brands-list__content'>
                        <div className='brands-list__item'>Fingers</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BrandList