import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

const UpdateDetails = ({ ...props }) => {
    const [show, setShow] = useState(false);
    const [contactNumber, setContactNumber] = useState(true);
 
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div role='button' className='floating-link location-change text-dark' onClick={handleShow} aria-hidden="true">Change</div>

            <Offcanvas show={show} onHide={handleClose} {...props} className='notify-me__offCanvas-'>
                <Offcanvas.Header closeButton>
                    {contactNumber ?
                        <Offcanvas.Title className='cart-heading'>Update your mobile number</Offcanvas.Title> :
                        <Offcanvas.Title className='cart-heading'>Enter OTP</Offcanvas.Title>
                    }
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='check-EMI__offers' style={{ display: contactNumber ? "block" : "none" }}>
                        <div className='eligible__section'>
                            <p className='eligible-info__section'>Please enter the number you want to change to in your profile.</p>
                            <div className='check-EMI__input'>
                                <div className="check-input-box mb-5">
                                    <input className='input-emi' placeholder='Mobile Number' />
                                    <span className="unit">+91 <span className='unit-bar'>|</span></span>
                                </div>
                                <div className='flexible'></div>
                                <button type='button' className='btn btn-disabled w-100' onClick={() => setContactNumber(false)}>Continue</button>
                            </div>
                        </div>
                    </div>
                    <div className='check-EMI__offers' style={{ display: contactNumber ? "none" : "block" }}>
                        <div className='eligible__section'>
                            <p className=' Login-info text-center mt-1'>Please enter OTP sent to 78*****389. <a href="/" className='Linkpolicy'>Change</a> </p>
                            <div className='check-EMI__input align-items-center'>
                                <div className='cart-address__wrap '>
                                    <form action="" className='otp_set'>
                                        < input type='number' className='form-control otp_input'/>
                                        < input type='number' className='form-control otp_input'/>
                                        < input type='number' className='form-control otp_input'/>
                                        < input type='number' className='form-control otp_input '/>
                                    </form>
                                </div>
                                <div className="resendotp text-center">
                                    <a href="/" className='Resend_OtpLink'>Resend OTP</a>
                                </div>  
                                <div className='flexible'></div>
                                <button type='button' className='btn btn-dark-custom w-100'>Verify</button>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default UpdateDetails