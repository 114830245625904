import React from 'react'

const CartSteps = () => {
    return (
        <>
            <ul className='form-steps'>
                <li className='steps valid'>
                    <span>Cart</span>
                </li>
                <li className='steps'>
                    <span>Address</span>
                </li>
                <li className='steps'>
                    <span>Payment</span>
                </li>
            </ul>
        </>
    )
}

export default CartSteps