import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SimilarProductList from './SimilarProductList';
import notifyMe from '../../Images/product/notifyMe.png';

const NotifyMeBtn = ({ ...props }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <button type='button' onClick={handleShow} className='btn btn-block btn-dark-outline btn-notify-me'> 
                <span>Notify Me</span>
            </button>
            <Offcanvas show={show} onHide={handleClose} {...props} className='notify-me__offCanvas'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <div className='notify-me__wrap'>
                            <div className='notify-me__media'>
                                <img src={notifyMe} className='img img-fluid' alt='' />
                            </div>
                            <div className='notify-me__content'>
                                <h6 className='notify-me__head'>Samsung Galaxy Z Fold2 5G (Awesome Purpl...</h6>
                                <p className='notify-me__text'>is Out of Stock</p>
                            </div>
                        </div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <div className='cart-horizontal-line mt-0'></div>
                <Offcanvas.Body>
                    <div className='similar-product__wrap mb-5'>
                        <h4 className='nfm-heading mb-1'>Similar Products</h4>
                        <div className='similar-product__items'>
                            <SimilarProductList />
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default NotifyMeBtn
