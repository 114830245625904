import React, { useState } from 'react';
// import {useDispatch, useSelector} from 'react-redux';
// import { getProducts } from '../../store/actions/productAction';
import ProductGallery from '../../Components/Product/ProductGallery';
import ProductColors from '../../Components/Product/ProductColors';
import ProductModels from '../../Components/Product/ProductModels';
import LoggedAddress from '../../Components/Address/LoggedAddress';
import WhyButFromSangeetha from '../../Components/Product/WhyButFromSangeetha';
import ProductBox from '../../Components/Product/ProductBox';
import ProductHighlights from '../../Components/Product/ProductHighlights';
import ProductRecommend from '../../Components/Product/ProductRecommend';
import ProductAccessories from '../../Components/Product/ProductAccessories';
import ProductRelatedCategory from '../../Components/Product/ProductRelatedCategory';
import ProductKeyFeatures from '../../Components/Product/ProductKeyFeatures';
import ProductWishlist from '../../Components/Product/ProductWishlist';
import ProductFooter from '../../Components/Footer/ProductFooter';
import 'react-multi-carousel/lib/styles.css';

//Out Of Stock
import OutOfStockBtn from '../../Components/Product/OutOfStockBtn';
import VideoThumbnail from '../../Images/product/VideoThumbnail.png';
import PlayButton from '../../Images/product/PlayButton.png';
// const data = {
//     "product_id": 2,
//     "type":"mobile"
// };

const ProductOutOfStock = () => {
    // const dispatch = useDispatch();
    // const productDetailsData = useSelector(state => state.productDetails);
    // const {productDetailsLoading, productDetails, error} = productDetailsData;
    // const [errorMessage, setErrorMessage] = useState('');
    // const [showErrMsg, setShowErrMsg] = useState(false);
    const [ user ] = useState(false);
    const [ wishlistActive, setWishlistState ] = useState(false);
    const [isWishlist, setIsWishlist] = useState(false); 

    const handleToggle = () => {
        setWishlistState(!wishlistActive);
    };
    
    // useEffect(() => {
    //     dispatch(getProducts(data)) 
    // }, [dispatch])

    // useEffect(() => {
    //     if (error !== '') {
    //         setErrorMessage(error);
    //         setShowErrMsg(true);
    //     }
    // }, [error]);
    
    const handleClose = () => setIsWishlist(false);
    const handleChangeWishlist = () => {
        setIsWishlist((prevWishlist) => {
            return !prevWishlist;
        });
        setIsWishlist(true);
    };

    return (
        <>
            <div className='product-details-wrapper'>
                <div className='product-details-container product-bg'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='product-gallery-section'>
                                <div className='product-Out-of-stock'>Out of stock</div>
                                <div className='product-info'>
                                    <div className='brand-name'>Samsung</div>
                                    <h2 className='product-name'>Galaxy Z Fold2 5G</h2>
                                    <div className="price-offer">
                                        <div className="new-price">₹43,659</div>
                                        <div className="old-price">₹45,659</div>
                                    </div>
                                    <div className='price-info-text'>(Price Includes GST)</div>
                                </div>
                                <div className='mar-spacer'></div>
                                <div className='product-gallery'>
                                    <ProductGallery />                                    
                                </div>
                                {/* Wishlist and Share Icon */}
                                <div className='product-btns'>
                                    {['top'].map((placement, idx) => (
                                        <ProductWishlist 
                                            isWishlist={isWishlist} 
                                            handleChangeWishlist={handleChangeWishlist}
                                            handleClose={handleClose}
                                            key={idx} 
                                            placement={placement} 
                                            name={placement}
                                        />
                                    ))}
                                        
                                    <button type='button' className='product-share-btn' onClick={() => handleChangeWishlist()}>
                                        <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.04982 11.5157L5.64316 9.6577C5.31615 9.9823 4.90037 10.2028 4.44822 10.2915C3.99607 10.3801 3.5278 10.3329 3.10244 10.1558C2.67708 9.97866 2.31368 9.67958 2.05806 9.29624C1.80243 8.9129 1.66602 8.46246 1.66602 8.0017C1.66602 7.54095 1.80243 7.0905 2.05806 6.70716C2.31368 6.32382 2.67708 6.02474 3.10244 5.84764C3.5278 5.67053 3.99607 5.62331 4.44822 5.71195C4.90037 5.80059 5.31615 6.0211 5.64316 6.3457L9.04982 4.4877C8.93299 3.93953 9.01737 3.36761 9.28755 2.87654C9.55773 2.38547 9.99565 2.00806 10.5212 1.81332C11.0468 1.61859 11.6249 1.61955 12.1498 1.81603C12.6748 2.0125 13.1114 2.39136 13.38 2.88333C13.6485 3.37529 13.731 3.94749 13.6123 4.49527C13.4937 5.04306 13.1818 5.52983 12.7338 5.86657C12.2857 6.20331 11.7314 6.36752 11.1722 6.32917C10.6131 6.29081 10.0864 6.05246 9.68849 5.6577L6.28182 7.5157C6.34978 7.83614 6.34978 8.16727 6.28182 8.4877L9.68849 10.3457C10.0864 9.95094 10.6131 9.71259 11.1722 9.67424C11.7314 9.63588 12.2857 9.80009 12.7338 10.1368C13.1818 10.4736 13.4937 10.9603 13.6123 11.5081C13.731 12.0559 13.6485 12.6281 13.38 13.1201C13.1114 13.612 12.6748 13.9909 12.1498 14.1874C11.6249 14.3839 11.0468 14.3848 10.5212 14.1901C9.99565 13.9953 9.55773 13.6179 9.28755 13.1269C9.01737 12.6358 8.93299 12.0639 9.04982 11.5157Z" fill="#121415"/>
                                        </svg>
                                    </button>
                                </div>
                                {/* Key Features */}
                                <div className='show-features'>
                                    {['bottom'].map((placement, idx) => (
                                        <ProductKeyFeatures key={idx} placement={placement} name={placement} backdrop={true} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mar-spacer h-3'></div>
                <div className='product-specifications'>
                    {/* Colors */}
                    <div className='product-colors'>
                        <h4 className='specification-heading'>Color</h4>
                        <ProductColors />
                    </div>
                    <div className='border-divider'></div>
                    {/* Models */}
                    <div className='product-models'>
                        <h4 className='specification-heading'>Model</h4>
                        <ProductModels />
                    </div>
                    <div className='border-divider'></div>
                    {/* PIN Code */}
                    <div className='product-deliverTo mt-1'>
                        <h4 className='specification-heading'>Deliver To</h4>
                        <div className='deliver-location'>
                            <div className='location-pin'>
                                <span className='d-flex'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.2427 11.5746L8 15.8173L3.75734 11.5746C2.91823 10.7355 2.34679 9.66643 2.11529 8.50255C1.88378 7.33866 2.0026 6.13227 2.45673 5.03591C2.91086 3.93956 3.6799 3.00249 4.66659 2.34321C5.65328 1.68392 6.81332 1.33203 8 1.33203C9.18669 1.33203 10.3467 1.68392 11.3334 2.34321C12.3201 3.00249 13.0891 3.93956 13.5433 5.03591C13.9974 6.13227 14.1162 7.33866 13.8847 8.50255C13.6532 9.66643 13.0818 10.7355 12.2427 11.5746ZM8 8.66531C8.35362 8.66531 8.69276 8.52484 8.94281 8.27479C9.19286 8.02474 9.33334 7.6856 9.33334 7.33198C9.33334 6.97836 9.19286 6.63922 8.94281 6.38917C8.69276 6.13912 8.35362 5.99865 8 5.99865C7.64638 5.99865 7.30724 6.13912 7.05719 6.38917C6.80715 6.63922 6.66667 6.97836 6.66667 7.33198C6.66667 7.6856 6.80715 8.02474 7.05719 8.27479C7.30724 8.52484 7.64638 8.66531 8 8.66531Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span>462026</span>
                            </div>
                            {['bottom'].map((placement, idx) => (
                                <LoggedAddress key={idx} placement={placement} name={placement} user={user} />
                            ))}
                        </div>
                        {/* <p className='mb-0 deliver-info'>Get this product by <span className='deliver-time'>Tomorrow</span>. <span className='deliver-type'>FREE</span></p> */}
                        <p className='deliver-info_OutOfStock'>The product is currently unavailable for delivery</p>
                    </div>
                    <div className='mar-spacer h-3'></div>
                    {/* Buttons */}
                    <div className='cart-btns'>
                        <OutOfStockBtn />
                    </div>
                    <div className='mar-spacer h-3'></div>
                    {/* Removed Policies */}
                </div>
                {/* Removed Bank Offers */}
                {/* Removed Payment Offers */}
                {/* Removed Buy Together */}
                {/* What's in the box */}
                <div className='product-inbox specification-head px-0'>
                    <h4 className='specification-heading p-x-16'>What’s In The Box?</h4>
                    <h6 className='inbox-info p-x-16'>Samsung Galaxy Fold, Type C Cable, Pin</h6>
                    <ProductBox />
                </div>

                {/* Product Highlights */}
                <div className='product-specifications'>
                    <div className='mar-spacer'></div>
                    <div className='product-highlights'> 
                        <ProductHighlights />
                    </div>
                    <div className='mar-spacer'></div>
                </div>

                {/* Why Buy From Sangeetha */}
                <div className='product-inbox specification-head px-0'>
                    <div className='mar-spacer h-1'></div>
                    <h4 className='specification-heading p-x-16'>Why Buy From Sangeetha?</h4>
                    <div className='mar-spacer h-1'></div>
                    <WhyButFromSangeetha />
                    <div className='mar-spacer h-1'></div>
                </div>

                {/* Watch Review Video */}
                <div className='product-specifications'>
                    <div className='mar-spacer h-3'></div>
                    <div className='specification-head'>
                        <h4 className='specification-heading'>Watch Galaxy Fold’s Expert Review</h4>
                        <div className='product-review-wrap'>
                            <div className='review-section'>
                                <img src={VideoThumbnail} className='img img-fluid w-100' alt='' />
                                <div className='review-play-btn'>
                                    <img src={PlayButton} className='img img-fluid' alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mar-spacer h-3'></div>
                </div>

                {/* free demo */}
                <div className='product-free-demo'>
                    <h4 className='specification-heading'>Get a free demo at <br/>your home</h4>
                    <div className='product-offer-wrapper'>
                        <button type='button' className='btn btn-dark-outline'>Book an appointment 
                            <span className='ml-2'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.724 5.33333L3.986 11.0713L4.92867 12.014L10.6667 6.276V11.3333H12V4H4.66667V5.33333H9.724Z" fill="#121415"/>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <div className='bg-img-vector'>
                        <svg width="107" height="51" viewBox="0 0 107 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 8L39 43L74 8L109 43L144 8" stroke="white" strokeOpacity="0.2" strokeWidth="10"/>
                        </svg>
                        <svg width="107" height="51" viewBox="0 0 107 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 8L39 43L74 8L109 43L144 8" stroke="white" strokeOpacity="0.2" strokeWidth="10"/>
                        </svg>
                    </div>
                </div>
                <div className='product-specifications specification-head px-0'>
                    <div className='mar-spacer h-3'></div>

                    {/* Sangeetha Recommends */}
                    <h4 className='specification-heading p-x-16'>Sangeetha Recommends</h4>
                    <div className=''>
                        <ProductRecommend deviceType="mobile" wishlistActive={wishlistActive} handleToggle={handleToggle} />
                    </div>
                    <div className='mar-spacer h-3'></div>

                    {/* Accessories for you */}
                    <h4 className='specification-heading p-x-16'>Accessories for you</h4>
                    <div className=''>
                        <ProductAccessories wishlistActive={wishlistActive} handleToggle={handleToggle} />
                    </div>
                    <div className='mar-spacer h-3'></div>

                    {/* Related Categories */}
                    <h4 className='specification-heading p-x-16'>Related Categories</h4>
                    <div className='category-carousel ml--16'>
                        <ProductRelatedCategory />
                    </div>
                    <div className='mar-spacer h-3'></div>
                </div>
            </div>
            <ProductFooter />
        </>
    )
}

export default ProductOutOfStock
