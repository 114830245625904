import React,{ useState, useRef } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
//import SlotContainer from './SlotContainer';
import MyCouponDetails from '../Account/MyCouponDetails';

import wheelFrame from "../../Images/playEarn/wheelFrame.svg";
import item11 from "../../Images/playEarn/1.svg";
import item22 from "../../Images/playEarn/2.svg";
import item33 from "../../Images/playEarn/3.svg";
import item4 from "../../Images/playEarn/4.svg";
import item5 from "../../Images/playEarn/5.svg";
import item6 from "../../Images/playEarn/6.svg";
import item7 from "../../Images/playEarn/7.svg";
import item8 from "../../Images/playEarn/8.svg";

import sangeethaIcon from '../../Images/playEarn/sangeethaIcon.svg';
import Banner1 from '../../Images/coupon/banner1.png'
import coupon2 from '../../Images/coupon/coupon2.png';
import coupon2_1 from '../../Images/coupon/coupon2_1.png';
import { Link } from '@reach/router';

const PlayEarn = ({...props}) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const closeButton = () => setShow(false);
    const [item1, setItem1] = useState(item11);
    const [item2, setItem2] = useState(item22);
    const [item3, setItem3] = useState(item33);
    /* eslint-enable no-unused-vars */
    const [rolling, setRolling] = useState(false);
    let slotRef = [useRef(null), useRef(null), useRef(null)];
    const items = [item5, item4, item33, item22, item6, item7, item8, item5, item4, item33, item22, item6, item7, item8, item5, item4, item33, item22, item6, item7, item8];   

    const roll = () => {
        setRolling(true);
        setTimeout(() => {
            setRolling(false);
        }, 700);

        slotRef.forEach((slot, i) => {
            const selected = triggerSlotRotation(slot.current);
            if (i + 1 === 1) setItem1(selected);
            else if (i + 1 === 2) setItem2(selected);
            else setItem3(selected);
        });
    };

    const triggerSlotRotation = (ref) => {
        function setTop(top) {
            ref.style.top = `${top}px`;
        }
        let options = ref.children;
        let randomOption = Math.floor(Math.random() * items.length);
        let choosenOption = options[randomOption];
        setTop(-choosenOption.offsetTop + 2);
        return items[randomOption];
    };
    return (
        <>
            <button type='button' onClick={handleShow} className='btn btn-dark-custom space-padding bg-white text-dark px-2 py-1 mb-2'>Play now</button>
            <Offcanvas show={show} onHide={handleClose} {...props} className="play-n-earn">
                <Offcanvas.Body>
                    <div role="button" onClick={closeButton} aria-hidden="true" className='modal-close-btn'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z" fill="white"/>
                        </svg>
                    </div>
                    <div className='play-earn__wrapper'  style={{ display: item1 === item2 && item1 === item3 ? "none" : "flex" }}>
                        <div className='sangeetha-icon'>
                            <img src={sangeethaIcon} className='img-fluid w-100' alt='' />
                        </div>
                        <div className='earn__wrapper'>
                            <h2 className='play-earn__head'>Jackpot</h2>
                            <p className='play-earn__info'>Win Coupons, Cashbacks and bumper prize worth upto ₹60,000 with just one tap!</p>
                        </div>
                        {/* <SlotContainer /> */}
                        <div className='play-earn__container w-100 SlotMachine-'>
                            <div
                                className="position-relative"
                                style={{
                                    backgroundImage: `url(${wheelFrame})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "top center",
                                    backgroundSize: "contain",
                                    width: '290px',
                                    height: '177px',
                                    margin: '0 auto'
                                }}
                            >	
                                <div className="d-flex slot__wrapper">
                                    <div className="slot">
                                        <section className="slot-section">
                                            <div className="slot-container slot-container_1" ref={slotRef[0]}>
                                                {items.map((item, i) => (
                                                    <div key={i}>
                                                        <img src={item} className='img-fluid' alt="" />
                                                    </div>
                                                ))}
                                            </div>
                                        </section>
                                    </div>
                                    <div className="slot">
                                        <section className="slot-section">
                                            <div className="slot-container slot-container_2" ref={slotRef[1]}>
                                                {items.map((item, i) => (
                                                    <div key={i}>
                                                        <img src={item} className='img-fluid' alt="" />
                                                    </div>
                                                ))}
                                            </div>
                                        </section>
                                    </div>
                                    <div className="slot">
                                        <section className="slot-section">
                                            <div className="slot-container slot-container_3" ref={slotRef[2]}>
                                                {items.map((item, i) => (
                                                    <div key={i}>
                                                        <img src={item} className='img-fluid' alt="" />
                                                    </div>
                                                ))}
                                            </div>
                                        </section>
                                    </div>
                                    <div className="bg-gradien" />
                                </div>
                            </div>
                        </div>
                        <button
                            type="button"
                            className={`btn btn-dark-outline w-100 roll ${!rolling ? "rolling" : ""}`}
                            onClick={!rolling ? roll : null }
                            disabled={rolling}
                        >
                            {rolling ? "Spinning..." : "Spin Now"}
                        </button>
                    </div>
                    <div className='play-earn__wrapper success-playSlot' style={{ display: item1 === item2 && item1 === item3 ? "flex" : "none" }}>
                        <div className='sangeetha-icon'>
                            <img src={sangeethaIcon} className='img-fluid w-100' alt='' />
                        </div>
                        <div className='earn__wrapper'>
                            <h2 className='play-earn__head'>Congratulations</h2>
                            <p className='play-earn__info'>You have earned a special coupon</p>
                            <div className='mar-spacer mb-3' />
                            <div className='coupon-won'>
                                <div className='coupon-card__wrapper green-theme'>
                                    <img src={Banner1} className='img-fluid w-100 coupon-img' alt='' />
                                    <div className='coupon-card__bg'>
                                        <div className='coupon-card__content'>
                                            <h5>Get ₹1000 off </h5>
                                            <p>on your next purchase</p>
                                            <hr className='hr-coupon' />
                                            <div className='coupon-card__info'>VALIDITY: 31st March ‘21</div>
                                            {['bottom'].map((placement, idx) => (
                                                <MyCouponDetails key={idx} placement={placement} name={placement} />
                                            ))} 
                                        </div>
                                        <div className='coupon-card__media'>
                                            <img src={coupon2} className='img img-fluid' alt='' />
                                            <img src={coupon2_1} className='img img-fluid coupon-glitters' alt='' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className='play-earn__info text-center mt-4'>Find this in ‘My Coupons’ and use it before it expires!</p>
                        </div>
                        <Link to='/' type="button" className='btn btn-dark-outline w-100'>Go to home</Link>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default PlayEarn