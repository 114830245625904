import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';

import LoginOTP from '../Components/Login/LoginOTP';
import loginlogo1 from '../Images/CategoryDetails/Login_logo/logintop.png';
import loginlogo2 from '../Images/CategoryDetails/Login_logo/logindown.png';


const LoginScreen = ({ loginShow, handleCloseLogin }) => {
    return (
        <>
            <Offcanvas show={loginShow} onHide={handleCloseLogin} className="address-offcanvas offcanvas-bottom">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='address-loggedIn-section'>
                        <div className="Loginlogo">
                            <img src={loginlogo1} alt="" className='first_logo' />
                            <img src={loginlogo2} alt="" className='first_logo1'/>
                        </div>
                        <h5 className='cart-heading offcanvas-title h5 text-center mt-4'>Welcome to Sangeetha Mobiles</h5>
                        <p className=' Login-info text-center mt-1'>Please enter your mobile number</p>
                        <div className='loggedIn__wrap login-gsx'>
                            <div className='loggedIn__lists'>
                                <div className='input-group-address'>
                                    <div className='cart-address__wrap'>
                                        <input  type='number' className='form-control form-control-login' placeholder='Mobile Number'  maxLength="10"/>
                                        <span className='absolute-login-input'>+91</span> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="loginfooter">
                            <div className="footer-links mb-0 login-footerlink"><span className='fontcolor-light'>By continuing,</span>&nbsp;<span className='fontcolor-light'>I agree to the &nbsp;<a href="/" className='Linkpolicy'>Privacy Policy</a>  </span></div>
                            <div className="footer-links login-footerlink"> &nbsp;<span className='fontcolor-light'>and &nbsp;<a href="/" className='Linkpolicy'>Terms & Conditions</a> </span></div>
                        </div>
                        <div className="continuelogin_btn">
                            {['bottom'].map((placement, idx) => (
                                <LoginOTP key={idx} placement={placement} name={placement} backdrop={true} />
                            ))}
                        </div>                           
                    </div>                      
                </Offcanvas.Body>
            </Offcanvas>
        </>
	
    )
}

export default LoginScreen