import { Link } from '@reach/router';
import React from 'react';
import Carousel from "react-multi-carousel";
import Category1 from '../../Images/Home/catg/catg-1.png';
import Category2 from '../../Images/Home/catg/catg-2.png';
import Category3 from '../../Images/Home/catg/catg-3.png';
import Category4 from '../../Images/Home/catg/catg-4.png';
import Category5 from '../../Images/Home/catg/catg-5.png';

const CategoryCarousel = () => {
    const ProductCategory = [
        {   
            _id: 1,
            Image: Category1,
            Name: 'Phone',
            Offer: 'Upto 15% Off',
        }, {   
            _id: 2,
            Image: Category2,
            Name: 'Laptops',
            Offer: '',
        }, {   
            _id: 3,
            Image: Category3,
            Name: 'Earphones',
            Offer: '',
        }, {   
            _id: 4,
            Image: Category4,
            Name: 'Watches',
            Offer: '',
        }, {   
            _id: 5,
            Image: Category5,
            Name: 'Laptops',
            Offer: 'Upto 10% Off',
        }
    ];

    const responsive = {
        tablet: {
            breakpoint: { max: 768, min: 567 },
            items: 7,
            partialVisibilityGutter: 0
        },
        mobile: {
            breakpoint: { max: 567, min: 0 },
            items: 4,
            partialVisibilityGutter: 0
        },
        smMobile: {
            breakpoint: { max: 360, min: 0 },
            items: 4,
            partialVisibilityGutter: 0
        }
    };
    return (
        <>
            <Carousel                
                partialVisible={true}
                arrows={false}
                itemClass="image-item"
                responsive={responsive}
            >   
                {ProductCategory.map(ProductCategory => {
                    return (
                        <div className='d-flex flex-column align-items-center' key={ProductCategory._id}>
                            <div className='product-carousel-wrapper__category border-0'>
                                <div className='carousel-media-wrapper__category'>
                                    <img
                                        draggable={true}
                                        src={ProductCategory.Image}
                                        alt={ProductCategory.productName}
                                        className='img'
                                    />
                                </div>
                            </div>
                            <div className='carousel-content-wrapper__category d-flex flex-column align-items-center text-center'>
                                <Link to='/Category' className='carousel-content-wrapper__title'>{ProductCategory.Name}</Link>
                                <span className='carousel-content-wrapper__label'>{ProductCategory.Offer}</span>
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </>
    )
}

export default CategoryCarousel