import React, { useState, useEffect } from 'react';

const InputSearch = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [ stickyTab, setStickyTab] = useState(0);

    const handleScroll = (e) => {
        setStickyTab(e.target.scrollTop > 31);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);      
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleFocus = (e) => {
        e.target.classList.add("focus");
    };
    const handleBlur = (e) => {
        e.target.classList.remove("focus");
    };

    const SearchedQuery = [
        {
            _id: 1,
            Title: 'The Royal Orchard Bengaluru',
            AddressText: 'Major district road 40, Manikaran road, Bengaluru, Ka..',
            distance: '30 m'
        }, {
            _id: 2,
            Title: 'Hotel Royal Orchard Bengaluru',
            AddressText: 'Major district road 40, Manikaran road, Bengaluru, Ka..',
            distance: '50 m'
        }, {
            _id: 3,
            Title: 'The Royal Orchard Home Stay',
            AddressText: 'Major district road 40, Manikaran road, Bengaluru, Ka..',
            distance: '86 m'
        }
    ];

    const results = !searchTerm
        ? SearchedQuery
        : SearchedQuery.filter(person =>
            person.Title.toLowerCase().includes(searchTerm.toLocaleLowerCase())
        );
    return (
        <>
            <div className='cart-address__head'>
                <div 
                    className={stickyTab ? "input-group-location tab-sticky-top" : "input-group-location"}
                >
                    <div className='position-relative'>
                        <input
                            type='text'
                            className="form-control form-control-cart"
                            placeholder="Search for drop location"
                            value={searchTerm}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onChange={handleChange}     
                        />
                        <span className='form-control-search'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.0259 13.8475L18.5951 17.4158L17.4159 18.595L13.8476 15.0258C12.5199 16.0901 10.8684 16.669 9.16675 16.6666C5.02675 16.6666 1.66675 13.3066 1.66675 9.16663C1.66675 5.02663 5.02675 1.66663 9.16675 1.66663C13.3067 1.66663 16.6667 5.02663 16.6667 9.16663C16.6692 10.8683 16.0903 12.5197 15.0259 13.8475ZM13.3542 13.2291C14.4118 12.1415 15.0025 10.6837 15.0001 9.16663C15.0001 5.94329 12.3892 3.33329 9.16675 3.33329C5.94341 3.33329 3.33341 5.94329 3.33341 9.16663C3.33341 12.3891 5.94341 15 9.16675 15C10.6838 15.0023 12.1417 14.4117 13.2292 13.3541L13.3542 13.2291Z" fill="#121415"/>
                            </svg>
                        </span> 
                        {searchTerm ? 
                            (<span className='resetSearch' onClick={() => setSearchTerm(() => "")} aria-hidden="true">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.99996 18.3333C5.39746 18.3333 1.66663 14.6025 1.66663 9.99996C1.66663 5.39746 5.39746 1.66663 9.99996 1.66663C14.6025 1.66663 18.3333 5.39746 18.3333 9.99996C18.3333 14.6025 14.6025 18.3333 9.99996 18.3333ZM9.99996 16.6666C11.7681 16.6666 13.4638 15.9642 14.714 14.714C15.9642 13.4638 16.6666 11.7681 16.6666 9.99996C16.6666 8.23185 15.9642 6.53616 14.714 5.28591C13.4638 4.03567 11.7681 3.33329 9.99996 3.33329C8.23185 3.33329 6.53616 4.03567 5.28591 5.28591C4.03567 6.53616 3.33329 8.23185 3.33329 9.99996C3.33329 11.7681 4.03567 13.4638 5.28591 14.714C6.53616 15.9642 8.23185 16.6666 9.99996 16.6666ZM9.99996 8.82163L12.3566 6.46413L13.5358 7.64329L11.1783 9.99996L13.5358 12.3566L12.3566 13.5358L9.99996 11.1783L7.64329 13.5358L6.46413 12.3566L8.82163 9.99996L6.46413 7.64329L7.64329 6.46413L9.99996 8.82163Z" fill="#121415"/>
                                </svg>
                            </span>) : ''
                        }  
                    </div>                     
                </div>
                {searchTerm.length > 1 ?
                    (<div className='searchedList-Address'>
                        {results.map(item => (
                            <div className='searchedLists' key={item._id}>
                                <div className='searched-distance'>
                                    <span>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 13.9333L11.3 10.6333C11.9526 9.98066 12.397 9.14914 12.577 8.2439C12.7571 7.33867 12.6646 6.40039 12.3114 5.54769C11.9582 4.695 11.36 3.96619 10.5926 3.45343C9.82519 2.94068 8.92296 2.66699 8 2.66699C7.07704 2.66699 6.17481 2.94068 5.40739 3.45343C4.63997 3.96619 4.04183 4.695 3.68861 5.54769C3.33539 6.40039 3.24294 7.33867 3.42297 8.2439C3.603 9.14914 4.04741 9.98066 4.7 10.6333L8 13.9333ZM8 15.8187L3.75734 11.576C2.91823 10.7369 2.34679 9.66777 2.11529 8.50389C1.88378 7.34 2.0026 6.13361 2.45673 5.03726C2.91086 3.9409 3.6799 3.00384 4.66659 2.34455C5.65328 1.68527 6.81332 1.33337 8 1.33337C9.18669 1.33337 10.3467 1.68527 11.3334 2.34455C12.3201 3.00384 13.0891 3.9409 13.5433 5.03726C13.9974 6.13361 14.1162 7.34 13.8847 8.50389C13.6532 9.66777 13.0818 10.7369 12.2427 11.576L8 15.8187ZM8 8.66665C8.35362 8.66665 8.69276 8.52618 8.94281 8.27613C9.19286 8.02608 9.33334 7.68694 9.33334 7.33332C9.33334 6.9797 9.19286 6.64056 8.94281 6.39051C8.69276 6.14046 8.35362 5.99999 8 5.99999C7.64638 5.99999 7.30724 6.14046 7.05719 6.39051C6.80715 6.64056 6.66667 6.9797 6.66667 7.33332C6.66667 7.68694 6.80715 8.02608 7.05719 8.27613C7.30724 8.52618 7.64638 8.66665 8 8.66665ZM8 9.99999C7.29276 9.99999 6.61448 9.71904 6.11438 9.21894C5.61429 8.71884 5.33334 8.04057 5.33334 7.33332C5.33334 6.62608 5.61429 5.9478 6.11438 5.4477C6.61448 4.94761 7.29276 4.66666 8 4.66666C8.70725 4.66666 9.38552 4.94761 9.88562 5.4477C10.3857 5.9478 10.6667 6.62608 10.6667 7.33332C10.6667 8.04057 10.3857 8.71884 9.88562 9.21894C9.38552 9.71904 8.70725 9.99999 8 9.99999Z" fill="#98A0AE"/>
                                        </svg>
                                    </span>
                                    <span>{item.distance}</span>
                                </div>
                                <div className='searched-addr'>
                                    <div className='searched-heading'>{item.Title}</div>
                                    <div className='searched-text'>{item.AddressText}</div>
                                </div>
                            </div>
                        ))}
                    </div>) : ''            
                }      
            </div>
        </>
    )
}

export default InputSearch