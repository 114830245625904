import React from 'react';
import Tab from 'react-bootstrap/Tab';
// import Form from 'react-bootstrap/Form';
import { Nav } from 'react-bootstrap';
import ColorFilter from './ColorFilter';
import PriceFilter from './PriceFilter';
import BrandFilter from './BrandFilter';
import Discount from './Discount';
import LunchYearFilter from './LunchYearFilter';
import OfferFilter from './OfferFilter';
import ScreenSize from './ScreenSize';
import ScreenSizeGuide from './ScreenSizeGuide';
import Connectivity from './Connectivity';
import  '../../Styles/filteroffcanvas.css';

const ProductFiltersList = () => {
    return (
        <>
            <Tab.Container id="left-tabs-example" defaultActiveKey="price">
                <div className='d-flex'>
                    <div className='col-4'>
                        <div className='overflow-y'>
                            <Nav variant="pills" className="flex-column filter__nav">
                                <Nav.Item>
                                    <Nav.Link eventKey="price">Price <span>2</span></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="brands">Brands <span>3</span></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="discount">Discount</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="color">Color <span>2</span></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="offer">Offers <span>1</span></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="launchyear">Launch Year <span>2</span></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="mobileType">Mobile Type</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="screenSize">Screen Size</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="connectivity">Connectivity</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="OS">Operating System</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="ram">RAM</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="NCore">Number of Core</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="InternalM">Internal Memory</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="BatteryCap">Battery Capacity</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Camera">Camera</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Processor">Processor</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <div className='mar-spacer mar-spacer h-3 mb-1' />
                        </div>
                    </div>
                    <div className='col'>
                        <Tab.Content>
                            <Tab.Pane eventKey="price">
                                <div className='filter-tab__wrap'>
                                    <div className='filter-tab__section'>
                                        <div className='filter-tab__heading'>
                                            <h4>Price</h4>
                                        </div>
                                        <PriceFilter/>
                                        
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="brands">
                                <div className='filter-tab__wrap'>
                                    <div className='input__wrapper'>
                                        <input type='text' className='form-control form-control-cart' placeholder='Search by Color'/>
                                        <span>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.0155 8.3085L11.157 10.4495L10.4495 11.157L8.3085 9.0155C7.51187 9.65411 6.521 10.0015 5.5 10C3.016 10 1 7.984 1 5.5C1 3.016 3.016 1 5.5 1C7.984 1 10 3.016 10 5.5C10.0015 6.521 9.65411 7.51187 9.0155 8.3085ZM8.0125 7.9375C8.64706 7.28494 9.00143 6.41021 9 5.5C9 3.566 7.4335 2 5.5 2C3.566 2 2 3.566 2 5.5C2 7.4335 3.566 9 5.5 9C6.41021 9.00143 7.28494 8.64706 7.9375 8.0125L8.0125 7.9375Z" fill="#98A0AE"/>
                                            </svg>
                                        </span>
                                    </div>
                                    <div className='filter-tab__section'>
                                        <div className='filter-tab__heading'>
                                            <h4>POPULAR BRANDS</h4>
                                        </div>
                                        <BrandFilter/>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="discount">
                                <div className='filter-tab__wrap'>
                                    <Discount/>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="color">
                                <div className='filter-tab__wrap'>
                                    <div className='input__wrapper'>
                                        <input type='text' className='form-control form-control-cart' placeholder='Search by Color'/>
                                        <span>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.0155 8.3085L11.157 10.4495L10.4495 11.157L8.3085 9.0155C7.51187 9.65411 6.521 10.0015 5.5 10C3.016 10 1 7.984 1 5.5C1 3.016 3.016 1 5.5 1C7.984 1 10 3.016 10 5.5C10.0015 6.521 9.65411 7.51187 9.0155 8.3085ZM8.0125 7.9375C8.64706 7.28494 9.00143 6.41021 9 5.5C9 3.566 7.4335 2 5.5 2C3.566 2 2 3.566 2 5.5C2 7.4335 3.566 9 5.5 9C6.41021 9.00143 7.28494 8.64706 7.9375 8.0125L8.0125 7.9375Z" fill="#98A0AE"/>
                                            </svg>
                                        </span>
                                    </div>
                                    <div className='filter-tab__section'>
                                        <div className='filter-tab__heading'>
                                            <h4>Popular Choice</h4>
                                        </div>
                                        <ColorFilter />
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="launchyear">
                                <div className='filter-tab__wrap'>
                                    <div className='filter-tab__heading'>
                                        <h4>LAUNCH YEAR</h4>
                                    </div>
                                    <LunchYearFilter />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="offer">
                                <div className='filter-tab__wrap'>
                                    <div className='filter-tab__heading'>
                                        <h4>OFFERS</h4>
                                    </div>
                                    <OfferFilter />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="screenSize">
                                <div className='filter-tab__wrap'>
                                    <div className='filter-tab__heading'>
                                        <h4>POPULAR</h4>
                                    </div>
                                    <ScreenSize />
                                </div>
                                <div className='filter-space-margin'></div>
                                <ScreenSizeGuide/>

                            </Tab.Pane>
                            <Tab.Pane eventKey="connectivity">
                                <div className='filter-tab__wrap'>
                                    <div className='filter-tab__heading'>
                                        <h4>TYPE</h4>
                                    </div>
                                    <Connectivity />
                                </div>
                                <div className='filter-space-margin'></div>
                                <ScreenSizeGuide/>

                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </div>
            </Tab.Container>
        </>
    )
}

export default ProductFiltersList