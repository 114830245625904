import React,{useState} from 'react';
// import { Offcanvas } from 'bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';


const CheckeleigiblityModel = ({...props}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <button type='button' className='btn btn-dark-custom space-padding w-100' onClick={handleShow}>Check Eligibility</button>

            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Enter Pincode</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='input-group-address'>
                        <div className='cart-address__wrap'>
                            <input type='number' className='form-control form-control-cart' placeholder='' />
                            <button type='button' className='btn btn-dark-button absolute-cart-input btn-space'>Check</button> 
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        
        </>
    )
}

export default CheckeleigiblityModel