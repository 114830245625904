import React from 'react';
import CategoryCarousel from '../Components/Home/CategoryCarousel';
import CategoryListAccordion from '../Components/Home/CategoryListAccordion';

const Categories = () => {
    return (
        <>
            <div className='all-category__wrap brands-wrapper exclusive-section'>
                <div className='all-category__head top-head__wrap'>
                    <h1 className='brand-heading'>Popular Categories</h1>
                </div>
                <div className='all-category__content ml--16'>
                    <CategoryCarousel />
                </div>
            </div>
            <div className='exclusive-section'>
                <div className='all-category__head top-head__wrap'>
                    <h1 className='brand-heading'>All Categories</h1>
                </div>
                <div className='p-x-16'>
                    <CategoryListAccordion />
                </div>
            </div>
        </>
    )
}

export default Categories