import React from 'react';
import CartSimilarProductOffer from './CartSimilarProductOffer';


const CartBetterOffer = () => {
    return (
        <>
            <div className='Replace-cart__vas'>
                <div className='shopping-vas__info'>
                    <div className='Replace-vas__media'>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.26624 1.40039L13.8656 2.34372L14.8082 8.94373L8.68024 15.0717C8.55522 15.1967 8.38568 15.2669 8.20891 15.2669C8.03213 15.2669 7.86259 15.1967 7.73757 15.0717L1.13757 8.47172C1.01259 8.34671 0.942383 8.17717 0.942383 8.00039C0.942383 7.82362 1.01259 7.65408 1.13757 7.52906L7.26624 1.40039ZM9.15157 7.05772C9.27541 7.18152 9.42242 7.27971 9.5842 7.34669C9.74598 7.41366 9.91938 7.44812 10.0945 7.44809C10.2696 7.44806 10.443 7.41354 10.6047 7.34651C10.7665 7.27947 10.9134 7.18123 11.0372 7.05739C11.161 6.93356 11.2592 6.78655 11.3262 6.62476C11.3932 6.46298 11.4276 6.28959 11.4276 6.11449C11.4276 5.93939 11.3931 5.76601 11.326 5.60425C11.259 5.44249 11.1607 5.29552 11.0369 5.17172C10.9131 5.04793 10.7661 4.94974 10.6043 4.88276C10.4425 4.81578 10.2691 4.78133 10.094 4.78136C9.74037 4.78142 9.40125 4.92196 9.15124 5.17206C8.90123 5.42216 8.76081 5.76133 8.76087 6.11496C8.76094 6.46859 8.90147 6.80771 9.15157 7.05772Z" fill="#121415"/></svg>   
                        </span>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='shopping-vas__head'>2 phones with better offers available </div>
                        <div className='Cart-vas__label'>Save upto ₹9,999 more</div>

                    </div>
                </div>
                <div className='shopping-vas__close'>
                    
                    {['bottom'].map((placement, idx) => (
                        <CartSimilarProductOffer key={idx} placement={placement} name={placement} />
                    ))}
                </div>
            </div> 

        </>
    )
}

export default CartBetterOffer