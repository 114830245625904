import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TabContent from 'react-bootstrap/TabContent';
import { OffersList, CardlessEMIData } from '../../Data';

const PaymentOfferSList = ({ ...props }) => {
    const [key, setKey] = useState('1');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const closeButton = () => setShow(false)
    return (
        <>
            <button type='button' className='text-dark btn m-0 p-0 btn-link  d-flex align-items-center' onClick={handleShow}>
				View All Offers
                <span className='ml-1'>
                    <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.3332 0L0.158203 1.175L3.97487 5L0.158203 8.825L1.3332 10L6.3332 5L1.3332 0Z" fill="#121415"/>
                    </svg>
                </span>	
            </button>

            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header className='pb-0'>
                    <Offcanvas.Title className='w-100'>
                        <div className='coupon__head'>
                            <h5 className='cart-heading d-flex align-items-start justify-content-between'>
                                <span>Offers</span>
                                <button type='button' className='text-dark-link btn m-0 p-0 text-uppercase' onClick={closeButton}>
									Skip
                                </button>
                            </h5>
                        </div> 
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='pt-0'>
                    <div className='product-specifications-section'>
                        <div className="product-details__tabs">
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                className="d-flex justify-content-center flex-row"
                            >
                                <Tab eventKey="1" title="Bank Offers">
                                    <TabContent>
                                        <div className='bank-offer-wrapper'>
                                            <div className='product-payment-offers'>  
                                                {OffersList.map((OffersItems, idx) => {                      
                                                    return(
                                                        <div to='/BankOffersDetails' className='payment-offer-options' key={idx}>
                                                            <div className='payment-offer-content'>
                                                                <div className='payment-offer-icon'>
                                                                    <span>
                                                                        <img src={OffersItems.Image} className='img im-fluid' alt='' />
                                                                    </span>
                                                                </div>
                                                                <div className='payment-offer-info'>
                                                                    <p className='payment-offer-details'>{OffersItems.Text}</p>
                                                                </div>
                                                            </div>
                                                            <div className='highlight-body__link ml-2'>Apply</div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </TabContent>
                                </Tab>
                                <Tab eventKey="2" title="Cardless Offers">
                                    <TabContent>
                                        <div className='bank-offer-wrapper'>
                                            <div className='product-payment-offers'>  
                                                {CardlessEMIData.map((Cardless) => {                   
                                                    return(
                                                        <div to='/BankOffersDetails' className='payment-offer-options' key={Cardless._id}>
                                                            <div className='payment-offer-content emi__content'>
                                                                <div className='payment-offer-icon'>
                                                                    <span>
                                                                        <img src={Cardless.Img} className='img img-fluid' alt='' /> 
                                                                    </span>
                                                                </div>
                                                                <div className='payment-offer-info'>
                                                                    <div className='d-flex flex-column'>
                                                                        <h6>{Cardless.Title}</h6>
                                                                        <p>{Cardless.EMIInfo}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='highlight-body__link ml-2'>Apply</div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </TabContent >
                                </Tab>
                            </Tabs>  
                        </div> 
                    </div> 
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default PaymentOfferSList