import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import superman from '../../Images/product/superman.png';
import { OffersList } from '../../Data';

const CheckEligibility = ({...props }) => {
    const [show, setShow] = useState(false);
    const [isEligible, setIsEligible] = useState(true)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <button type='button' className='btn btn-dark-theme w-100' onClick={handleShow}>Login</button>
    
            <Offcanvas show={show} onHide={handleClose} {...props} className='notify-me__offCanvas-height'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Cardless EMI Offers</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {isEligible ? 
                        <div className='product-payment-offers bank-offer-wrapper'>
                            {OffersList.slice(0, 2).map((Items, idx) => {                      
                                return(
                                    <div to='/BankOffersDetails' className='payment-offer-options' key={idx}>
                                        <div className='payment-offer-content'>
                                            <div className='payment-offer-icon'>
                                                <span>
                                                    <img src={Items.Image} className='img im-fluid' alt='' />
                                                </span>
                                            </div>
                                            <div className='payment-offer-info'>
                                                <p className='payment-offer-details'>{Items.Text}</p>
                                            </div>
                                        </div>
                                        <div className='highlight-body__link ml-2'>Apply</div>
                                    </div>
                                );
                            })}
                        </div>  :
                        <div className='eligible__section EMI__offers'>
                            <div className='EMI__offers-media'>
                                <img src={superman} className='img img-fluid' alt='' />
                            </div>
                            <div className='EMI__offers-text'>
                                <h6>Uh Oh!</h6>
                                <p>We couldn’t fetch suitable options for this mobile number</p>
                            </div>
                            <button type='button' className='btn btn-dark-theme' onClick={() => setIsEligible(false)}>Visit Finance Center</button>
                        </div>
                    }
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default CheckEligibility