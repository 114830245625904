import React, { useState,useEffect } from "react";

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TabContent from 'react-bootstrap/TabContent';
import EarphoneHighlightTab from '../../Product/CategoryDetails/EarphoneHighlightTab';
import EarphoneManufaturer from '../../Product/CategoryDetails/EarphoneManufaturer';
import EarPhoneSpecificationFullTab from "../../Product/CategoryDetails/EarPhoneSpecificationFullTab";
// import StickyTab from './StickyTab';


const EarphoneSpecificationTab = () => {
    const [key, setKey] = useState('profile');
    const [ stickyTab, setStickyTab] = useState(0);

    const handleScroll = (e) => {
        setStickyTab(e.target.scrollTop > 280);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);      
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    return (
        <>              
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                // className={isSticky ? "d-flex justify-content-center" : "navbar-sticky sticky"}
                // className={scroll ? "d-flex justify-content-center tab-sticky-top" : "d-flex justify-content-center"}
                // style={{color: scroll}}
                className={stickyTab ? "d-flex justify-content-center tab-sticky-top" : "d-flex justify-content-center"}

            >
                <Tab eventKey="home" title="Highlights">
                    <TabContent>
                        <EarphoneHighlightTab />
                    </TabContent>
                </Tab>
                <Tab eventKey="profile" title="Specifications">
                    <TabContent>
                        <EarPhoneSpecificationFullTab />
                    </TabContent >
                </Tab>
                <Tab eventKey="contact" title="Manufacturer">
                    <TabContent>
                        <EarphoneManufaturer />
                    </TabContent>
                </Tab>
            </Tabs>
        </>
    )
}

export default EarphoneSpecificationTab