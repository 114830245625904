import React from 'react'

const TvModels = () => {
    return (
        <>
            <div className='product-models-box'>
                <div className='product-modelsFrame active'>
                    <span>65</span>
                </div>
                <div className='product-modelsFrame'>
                    <span>50</span>
                </div>
                <div className='product-modelsFrame'>
                    <span>55</span>
                </div>
                <div className='product-modelsFrame'>
                    <span>58</span>
                </div>
                <div className='product-modelsFrame'>
                    <span>48</span>
                </div>
            </div>
        </>
    )
}

export default TvModels;
