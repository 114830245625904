import React,{useState} from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

import shoppingCart2 from '../../Images/CategoryDetails/Cart/OnePlus-9rt.png'
import samsung from '../../Images/CategoryDetails/Cart/Samsung-Z.png'
import iphonehead from '../../Images/CategoryDetails/Cart/iphone_head.png'

const CartSimilarProductOffer = ({...props}) => {
    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <button  className='cart-view' onClick={handleShow}>view All</button>
            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header closeButton className='similarproduct-Mainhead'>
                    <Offcanvas.Title>
                        <div className="similarproducthead">
                            <div className="specific-wrapper__list">
                                <div className="specific-wrapper__media">
                                    <img src={iphonehead} className="img img-fluid" alt=""/>
                                </div>
                                <div className="specific-wrapper__content">
                                    <h4>Apple IPhone 13 Pro</h4>
                                    <p>Pink, 256GB + 8GB</p>
                                    
                                </div>
                            </div>
                        </div>


                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="replaceitems-model-offer">
                        <h3>
                        Similar Products with better offers
                        </h3>
                    </div>
                    <div className='product-wrapper'>
                        <div className='product-list px-0 border-0'>                    
                            <div className='offer-label-tag'>Save ₹8,000 More</div>               
                            <div className='shopping-cart__list p-0'>
                                <div className='shopping-cart___media'>
                                    <img src={shoppingCart2} className='img img-fluid' alt='' />
                                </div>
                                <div className='shopping-cart__info replace-model_info'>
                                    <h4>OnePlus 9RT 5G</h4>
                                    <p className='product-muted'>
                                        <span>Nano Silver</span>,
                                        <span>128GB </span> + 
                                        <span> 8GB</span>
                                    </p>                                   
                                    <div className='product-price addToCart-offcanvas'>
                                        <div className='new-price'>₹42,000</div>
                                        <div className='old-price'>₹45,559</div>
                                    </div>
                                    <div className="deliverTo__info mt-2 replace-offer">
                                        <span>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 4H10L11.5 6.028V9H10.4825C10.4231 9.41715 10.2151 9.79884 9.89684 10.075C9.57857 10.3511 9.17136 10.5031 8.75 10.5031C8.32864 10.5031 7.92143 10.3511 7.60316 10.075C7.28489 9.79884 7.07694 9.41715 7.0175 9H4.4825C4.42306 9.41715 4.21511 9.79884 3.89684 10.075C3.57857 10.3511 3.17136 10.5031 2.75 10.5031C2.32864 10.5031 1.92143 10.3511 1.60316 10.075C1.28489 9.79884 1.07694 9.41715 1.0175 9H0.5V3C0.5 2.86739 0.552678 2.74021 0.646447 2.64645C0.740215 2.55268 0.867392 2.5 1 2.5H8C8.13261 2.5 8.25979 2.55268 8.35355 2.64645C8.44732 2.74021 8.5 2.86739 8.5 3V4ZM8.5 5V6.5H10.5V6.3575L9.496 5H8.5Z" fill="#121415"/></svg>
                                        </span>
                                        <span className="info__similarProd ml-1">Delivery by &nbsp;</span>
                                        <span className="label__similarProd">2 hr</span>
                                        <span className="deliver-type text-uppercase">Free</span>
                                        
                                    </div>
                                    <div className="deliverTo__info mt-2 replace-offer">
                                        <span>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.5 1.5H6.086L1 6.586L5.414 11L10.5 5.914V1.5ZM8.75 2.5C9.164 2.5 9.5 2.836 9.5 3.25C9.5 3.664 9.164 4 8.75 4C8.336 4 8 3.664 8 3.25C8 2.836 8.336 2.5 8.75 2.5Z" fill="#121415"/></svg>
                                        </span>
                                        <span className="info__similarProd ml-1">10% Off on Axis Bank Credit Cards and Debit Cards &nbsp;</span>                                       
                                        
                                    </div>
                                </div>
                            </div>
                    
                        </div>
                        <div className="replace-productsbtn">
                            <button className='replacebtn-oferr-dark'>
                                <span className='svg-cart'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.46706 9.86706V3.46706H2.40039V2.40039H4.00039C4.14184 2.40039 4.27749 2.45658 4.37751 2.5566C4.47753 2.65662 4.53372 2.79228 4.53372 2.93372V9.33372H11.1673L12.234 5.06706H5.60039V4.00039H12.9177C12.9988 4.00039 13.0788 4.01888 13.1517 4.05444C13.2245 4.09 13.2883 4.1417 13.3382 4.20561C13.388 4.26952 13.4227 4.34396 13.4395 4.42328C13.4563 4.50259 13.4548 4.58468 13.4351 4.66332L12.1017 9.99666C12.0728 10.112 12.0062 10.2144 11.9125 10.2875C11.8188 10.3607 11.7033 10.4004 11.5844 10.4004H4.00039C3.85894 10.4004 3.72329 10.3442 3.62327 10.2442C3.52325 10.1442 3.46706 10.0085 3.46706 9.86706ZM4.53372 13.6004C4.25083 13.6004 3.97952 13.488 3.77948 13.288C3.57944 13.0879 3.46706 12.8166 3.46706 12.5337C3.46706 12.2508 3.57944 11.9795 3.77948 11.7795C3.97952 11.5794 4.25083 11.4671 4.53372 11.4671C4.81662 11.4671 5.08793 11.5794 5.28797 11.7795C5.48801 11.9795 5.60039 12.2508 5.60039 12.5337C5.60039 12.8166 5.48801 13.0879 5.28797 13.288C5.08793 13.488 4.81662 13.6004 4.53372 13.6004ZM10.9337 13.6004C10.6508 13.6004 10.3795 13.488 10.1795 13.288C9.97944 13.0879 9.86706 12.8166 9.86706 12.5337C9.86706 12.2508 9.97944 11.9795 10.1795 11.7795C10.3795 11.5794 10.6508 11.4671 10.9337 11.4671C11.2166 11.4671 11.4879 11.5794 11.688 11.7795C11.888 11.9795 12.0004 12.2508 12.0004 12.5337C12.0004 12.8166 11.888 13.0879 11.688 13.288C11.4879 13.488 11.2166 13.6004 10.9337 13.6004Z" fill="white"/></svg>
                                </span>
                                <span>Replace in cart</span>
                            </button>
                        </div>
                        <div className='replace-devider'></div>



                
                    </div>

                    <div className='replace-devider-24'></div>
                    <div className='product-wrapper'>
                        <div className='product-list px-0'>                    
                            <div className='offer-label-tag'>Save ₹2,000 More</div>               
                            <div className='shopping-cart__list p-0'>
                                <div className='shopping-cart___media'>
                                    <img src={samsung} className='img img-fluid' alt='' />
                                </div>
                                <div className='shopping-cart__info replace-model_info'>
                                    <h4>Samsung Galaxy Z Fold2 5G</h4>
                                    <p className='product-muted'>
                                        <span>Nano Silver</span>,
                                        <span>128GB </span> + 
                                        <span> 8GB</span>
                                    </p>                                   
                                    <div className='product-price addToCart-offcanvas'>
                                        <div className='new-price'>₹42,000</div>
                                        <div className='old-price'>₹45,559</div>
                                    </div>
                                    <div className="deliverTo__info mt-2 replace-offer">
                                        <span>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 4H10L11.5 6.028V9H10.4825C10.4231 9.41715 10.2151 9.79884 9.89684 10.075C9.57857 10.3511 9.17136 10.5031 8.75 10.5031C8.32864 10.5031 7.92143 10.3511 7.60316 10.075C7.28489 9.79884 7.07694 9.41715 7.0175 9H4.4825C4.42306 9.41715 4.21511 9.79884 3.89684 10.075C3.57857 10.3511 3.17136 10.5031 2.75 10.5031C2.32864 10.5031 1.92143 10.3511 1.60316 10.075C1.28489 9.79884 1.07694 9.41715 1.0175 9H0.5V3C0.5 2.86739 0.552678 2.74021 0.646447 2.64645C0.740215 2.55268 0.867392 2.5 1 2.5H8C8.13261 2.5 8.25979 2.55268 8.35355 2.64645C8.44732 2.74021 8.5 2.86739 8.5 3V4ZM8.5 5V6.5H10.5V6.3575L9.496 5H8.5Z" fill="#121415"/></svg>
                                        </span>
                                        <span className="info__similarProd ml-1">Delivery by &nbsp;</span>
                                        <span className="label__similarProd">2 hr</span>
                                        <span className="deliver-type text-uppercase">Free</span>
                                        
                                    </div>
                                    <div className="deliverTo__info mt-2 replace-offer">
                                        <span>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.5 1.5H6.086L1 6.586L5.414 11L10.5 5.914V1.5ZM8.75 2.5C9.164 2.5 9.5 2.836 9.5 3.25C9.5 3.664 9.164 4 8.75 4C8.336 4 8 3.664 8 3.25C8 2.836 8.336 2.5 8.75 2.5Z" fill="#121415"/></svg>
                                        </span>
                                        <span className="info__similarProd ml-1">10% Off on Axis Bank Credit Cards and Debit Cards &nbsp;</span>                                       
                                        
                                    </div>
                                </div>
                            </div>
                    
                        </div>
                        <div className="replace-productsbtn">
                            <button className='replacebtn-oferr-dark'>
                                <span className='svg-cart'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.46706 9.86706V3.46706H2.40039V2.40039H4.00039C4.14184 2.40039 4.27749 2.45658 4.37751 2.5566C4.47753 2.65662 4.53372 2.79228 4.53372 2.93372V9.33372H11.1673L12.234 5.06706H5.60039V4.00039H12.9177C12.9988 4.00039 13.0788 4.01888 13.1517 4.05444C13.2245 4.09 13.2883 4.1417 13.3382 4.20561C13.388 4.26952 13.4227 4.34396 13.4395 4.42328C13.4563 4.50259 13.4548 4.58468 13.4351 4.66332L12.1017 9.99666C12.0728 10.112 12.0062 10.2144 11.9125 10.2875C11.8188 10.3607 11.7033 10.4004 11.5844 10.4004H4.00039C3.85894 10.4004 3.72329 10.3442 3.62327 10.2442C3.52325 10.1442 3.46706 10.0085 3.46706 9.86706ZM4.53372 13.6004C4.25083 13.6004 3.97952 13.488 3.77948 13.288C3.57944 13.0879 3.46706 12.8166 3.46706 12.5337C3.46706 12.2508 3.57944 11.9795 3.77948 11.7795C3.97952 11.5794 4.25083 11.4671 4.53372 11.4671C4.81662 11.4671 5.08793 11.5794 5.28797 11.7795C5.48801 11.9795 5.60039 12.2508 5.60039 12.5337C5.60039 12.8166 5.48801 13.0879 5.28797 13.288C5.08793 13.488 4.81662 13.6004 4.53372 13.6004ZM10.9337 13.6004C10.6508 13.6004 10.3795 13.488 10.1795 13.288C9.97944 13.0879 9.86706 12.8166 9.86706 12.5337C9.86706 12.2508 9.97944 11.9795 10.1795 11.7795C10.3795 11.5794 10.6508 11.4671 10.9337 11.4671C11.2166 11.4671 11.4879 11.5794 11.688 11.7795C11.888 11.9795 12.0004 12.2508 12.0004 12.5337C12.0004 12.8166 11.888 13.0879 11.688 13.288C11.4879 13.488 11.2166 13.6004 10.9337 13.6004Z" fill="white"/></svg>
                                </span>
                                <span>Replace in cart</span>
                            </button>
                        </div>


                        
                
                    </div>




        
                    
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default CartSimilarProductOffer