import React from 'react'
import specific from '../../../../Images/CategoryDetails/Tvspecificationmain.png'
import SmartTvSpecificationTab from '../../CategoryDetails/Television_details/SmartTvSpecificationTab';

const SmartTvSpecification = () => {
    return (
        <>
            <div className='product-specifications-section'>
                <div className='product-details__specific'>
                    <div className='specific-wrapper__list'>
                        <div className='specific-wrapper__media'>
                            <img src={specific} className='img img-fluid' alt='' />
                        </div>
                        <div className='specific-wrapper__content'>
                            <h4>Samsung Crystal 4K Pro</h4>
                            <p>163 cm (65 inch), Ultra HD (4K) LED Smart TV with Voice Search</p>
                            <div className="price-offer justify-content-start">
                                <div className="new-price">₹89,990</div>
                                <div className="old-price">₹1,25,990</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='product-details__tabs'>
                    <SmartTvSpecificationTab />
                </div>
            </div>
        </>
    )
}

export default SmartTvSpecification;