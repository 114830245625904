import React from 'react';
import Form from 'react-bootstrap/Form'


const BrandFilter = () => {
    return (
        <>
            <div className='filter-tab__lists'>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='Mi' />
                        <label className='filter_price' htmlFor="Mi">Mi</label>
                    </div>
                    <span className='filter-tab__count'>20</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='Mi' />
                        <label className='filter_price' htmlFor="Mi">Apple</label>
                    </div>
                    <span className='filter-tab__count'>14</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='Mi' />                    
                        <label className='filter_price' htmlFor="Mi">Oppo</label>
                    </div>
                    <span className='filter-tab__count'>14</span>
                </div>
                <div className='filter-tab__list'>  
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='Mi' />
                        <label className='filter_price' htmlFor="Mi">Samsung</label>                
                    </div>
                    <span className='filter-tab__count'>12</span>
                </div>
                <div className='filter-tab__heading'>
                    <h4>ALL BRANDS</h4>
                </div>
                <div className='filter-tab__lists'>
                    <div className='filter-tab__list'>
                        <div className='filter-tab__flex'>
                            <Form.Check className='filter__checkbox' aria-label="option 1" id='Mi' />
                      
                            <label className='filter_price' htmlFor="Mi">Acer</label>                
                        </div>
                        <span className='filter-tab__count'>10</span>
                    </div>
                    <div className='filter-tab__list'>
                        <div className='filter-tab__flex'>
                            <Form.Check className='filter__checkbox' aria-label="option 1" id='Mi' />
                            <label className='filter_price' htmlFor="Mi">Asus</label>                
                        </div>
                        <span className='filter-tab__count'>12</span>
                    </div>
                    <div className='filter-tab__list'>
                        <div className='filter-tab__flex'>
                            <Form.Check className='filter__checkbox' aria-label="option 1" id='Mi' />
                    
                            <label className='filter_price' htmlFor="Mi">Aqua</label>                
                        </div>
                        <span className='filter-tab__count'>12</span>
                    </div>
                    <div className='filter-tab__list'>
                        <div className='filter-tab__flex'>
                            <Form.Check className='filter__checkbox' aria-label="option 1" id='Mi' />
                       
                            <label className='filter_price' htmlFor="Mi">Binatone</label>
                        </div>
                        <span className='filter-tab__count'>4</span>
                    </div>
                    <div className='filter-tab__list'>
                        <div className='filter-tab__flex'>
                            <Form.Check className='filter__checkbox' aria-label="option 1" id='Mi' />
                     
                            <label className='filter_price' htmlFor="Mi">Blackbear</label>
                        </div>
                        <span className='filter-tab__count'>8</span>
                    </div>
                    <div className='filter-tab__list'>
                        <div className='filter-tab__flex'>
                            <Form.Check className='filter__checkbox' aria-label="option 1" id='Mi' />
                     
                            <label className='filter_price' htmlFor="Mi">BlackBerry</label>
                        </div>
                        <span className='filter-tab__count'>20</span>
                    </div>
                    <div className='filter-tab__list'>
                        <div className='filter-tab__flex'>
                            <Form.Check className='filter__checkbox' aria-label="option 1" id='Mi' />
                    
                            <label className='filter_price' htmlFor="Mi">BLU</label>
                        </div>
                        <span className='filter-tab__count'>20</span>
                    </div>
                    <div className='filter-tab__list'>
                        <div className='filter-tab__flex'>
                            <Form.Check className='filter__checkbox' aria-label="option 1" id='Mi' />
                      
                            <label className='filter_price' htmlFor="Mi">Clouts</label>
                        </div>
                        <span className='filter-tab__count'>20</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BrandFilter