import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import RemoveCartItems from './RemoveCartItems';

import saved1 from '../../Images/product/saved1.png';
import saved2 from '../../Images/product/saved2.png';

const CartSavedItems = () => {
    const [activeId, setActiveId] = useState();

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    return (
        <>
            <Accordion defaultActiveKey={activeId} className='saved-for-later__wrap'>
                <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('0')} className='remove-padding'>Saved For Later <span>(2)</span></Accordion.Header>
                    <Accordion.Body>
                        <div className='saved-for-later'>
                            <div className='shopping-cart___wrap'>
                                <div className='shopping-cart__list'>
                                    <div className='shopping-cart___media'>
                                        <img src={saved1} className='img img-fluid' alt='' />
                                    </div>
                                    <div className='shopping-cart__info'>
                                        <h4>Xiaomi Redmi Note 10 Pro</h4>
                                        <p>
                                            <span>Purple</span>,
                                            <span>256GB</span> + 
                                            <span> 8GB</span>
                                        </p>
                                        <div className='price-offer addToCart-offcanvas'>
                                            <div className='new-price'>₹19,999</div>
                                            <div className='old-price'>₹24,559</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mar-spacer h-1_2' />
                                <div className='shopping-cart__btn'>
                                    {['bottom'].map((placement, idx) => (
                                        <RemoveCartItems key={idx} placement={placement} name={placement} />
                                    ))} 
                                    <button type='button' className='btn btn-save-for-later btn-cart-h'>
                                        <span>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.53333 2.40002H12C12.1414 2.40002 12.2771 2.45621 12.3771 2.55623C12.4771 2.65625 12.5333 2.79191 12.5333 2.93336V13.143C12.5334 13.1906 12.5207 13.2375 12.4965 13.2786C12.4723 13.3197 12.4375 13.3535 12.3958 13.3766C12.3541 13.3997 12.3069 13.4112 12.2593 13.4099C12.2116 13.4085 12.1652 13.3945 12.1248 13.3691L8.26667 10.9494L4.40853 13.3686C4.3682 13.3939 4.32181 13.408 4.27419 13.4093C4.22657 13.4107 4.17946 13.3992 4.13776 13.3762C4.09606 13.3532 4.06128 13.3194 4.03706 13.2784C4.01283 13.2374 4.00004 13.1906 4 13.143V2.93336C4 2.79191 4.05619 2.65625 4.15621 2.55623C4.25623 2.45621 4.39188 2.40002 4.53333 2.40002ZM11.4667 3.46669H5.06667V11.6971L8.26667 9.69122L11.4667 11.6971V3.46669Z" fill="#121415"/>
                                            </svg>                                      
                                        </span>
                                        <span className='ml-1'>Save For Later</span>
                                    </button>
                                </div>
                                <div className='mar-spacer h-3' />
                            </div>

                            <div className='shopping-cart___wrap'>
                                <div className='shopping-cart__list'>
                                    <div className='shopping-cart___media'>
                                        <img src={saved2} className='img img-fluid' alt='' />
                                    </div>
                                    <div className='shopping-cart__info'>
                                        <h4>Xiaomi Redmi Note 10 Pro</h4>
                                        <p>
                                            <span>Purple</span>,
                                            <span>256GB</span> + 
                                            <span> 8GB</span>
                                        </p>
                                        <div className='price-offer addToCart-offcanvas'>
                                            <div className='new-price'>
                                                <span>Unavailable</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mar-spacer h-1_2' />
                                <div className='shopping-cart__btn'>
                                    {['bottom'].map((placement, idx) => (
                                        <RemoveCartItems key={idx} placement={placement} name={placement} />
                                    ))} 
                                    <button type='button' className='btn btn-save-for-later btn-cart-h'>
                                        <span>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.53333 2.40002H12C12.1414 2.40002 12.2771 2.45621 12.3771 2.55623C12.4771 2.65625 12.5333 2.79191 12.5333 2.93336V13.143C12.5334 13.1906 12.5207 13.2375 12.4965 13.2786C12.4723 13.3197 12.4375 13.3535 12.3958 13.3766C12.3541 13.3997 12.3069 13.4112 12.2593 13.4099C12.2116 13.4085 12.1652 13.3945 12.1248 13.3691L8.26667 10.9494L4.40853 13.3686C4.3682 13.3939 4.32181 13.408 4.27419 13.4093C4.22657 13.4107 4.17946 13.3992 4.13776 13.3762C4.09606 13.3532 4.06128 13.3194 4.03706 13.2784C4.01283 13.2374 4.00004 13.1906 4 13.143V2.93336C4 2.79191 4.05619 2.65625 4.15621 2.55623C4.25623 2.45621 4.39188 2.40002 4.53333 2.40002ZM11.4667 3.46669H5.06667V11.6971L8.26667 9.69122L11.4667 11.6971V3.46669Z" fill="#121415"/>
                                            </svg>                                      
                                        </span>
                                        <span className='ml-1'>Save For Later</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default CartSavedItems