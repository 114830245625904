import React from 'react'
import ReplaceCartItem from './ReplaceCartItem';


const CartReplace = () => {
    return (
        <>
            <div className='Replace-cart__vas'>
                <div className='shopping-vas__info'>
                    <div className='Replace-vas__media'>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.3337 5.33398H13.3337L15.3337 8.03798V12.0007H13.977C13.8977 12.5568 13.6205 13.0658 13.1961 13.4339C12.7718 13.8021 12.2288 14.0048 11.667 14.0048C11.1052 14.0048 10.5622 13.8021 10.1379 13.4339C9.71351 13.0658 9.43624 12.5568 9.35699 12.0007H5.97699C5.89774 12.5568 5.62047 13.0658 5.19611 13.4339C4.77175 13.8021 4.2288 14.0048 3.66699 14.0048C3.10518 14.0048 2.56223 13.8021 2.13787 13.4339C1.71352 13.0658 1.43625 12.5568 1.35699 12.0007H0.666992V4.00065C0.666992 3.82384 0.73723 3.65427 0.862254 3.52925C0.987279 3.40422 1.15685 3.33398 1.33366 3.33398H10.667C10.8438 3.33398 11.0134 3.40422 11.1384 3.52925C11.2634 3.65427 11.3337 3.82384 11.3337 4.00065V5.33398ZM11.3337 6.66732V8.66732H14.0003V8.47732L12.6617 6.66732H11.3337Z" fill="#121415"/></svg>

                               
                        </span>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='shopping-vas__head'>1 variant available quicker </div>
                        <div className='Cart-vas__label'>Get delivery in 2 hrs to 560103</div>

                    </div>
                </div>
                <div className='shopping-vas__close'>
                    
                    {['bottom'].map((placement, idx) => (
                        <ReplaceCartItem key={idx} placement={placement} name={placement} />
                    ))}
                </div>
            </div> 
            
        </>
    )
}

export default CartReplace;