import { Link } from '@reach/router';
import React, { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import notifyMe from '../../Images/product/notifyMe.png';
import { FaCheckCircle } from 'react-icons/fa';
 
const AddToCart = ({ ...props }) => {
    const [show, setShow] = useState(false);
    const [ stickyTab, setStickyTab] = useState(0);

    const handleScroll = (e) => {
        setStickyTab(e.target.scrollTop > 400);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);      
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className={`cart__sticky ${stickyTab ? 'add-to-cart-sticky' : ''}`}>
                <div className='cart-exchange__offer'>Yay! Saving ₹9,800 on Exchange </div>
                <button type='button' className='btn btn-block btn-dark-custom w-100 d-flex align-items-center justify-content-between' onClick={handleShow}>
                    <div className='sticky-cart-wrap'>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.33333 12.3333V4.33333H3V3H5C5.17681 3 5.34638 3.07024 5.4714 3.19526C5.59643 3.32029 5.66667 3.48986 5.66667 3.66667V11.6667H13.9587L15.292 6.33333H7V5H16.1467C16.248 5 16.348 5.02311 16.4391 5.06756C16.5301 5.11201 16.6099 5.17663 16.6722 5.25652C16.7346 5.33641 16.7779 5.42947 16.7989 5.52861C16.8198 5.62775 16.818 5.73037 16.7933 5.82867L15.1267 12.4953C15.0906 12.6395 15.0073 12.7675 14.8901 12.8589C14.773 12.9503 14.6286 13 14.48 13H5C4.82319 13 4.65362 12.9298 4.5286 12.8047C4.40357 12.6797 4.33333 12.5101 4.33333 12.3333ZM5.66667 17C5.31304 17 4.97391 16.8595 4.72386 16.6095C4.47381 16.3594 4.33333 16.0203 4.33333 15.6667C4.33333 15.313 4.47381 14.9739 4.72386 14.7239C4.97391 14.4738 5.31304 14.3333 5.66667 14.3333C6.02029 14.3333 6.35943 14.4738 6.60948 14.7239C6.85952 14.9739 7 15.313 7 15.6667C7 16.0203 6.85952 16.3594 6.60948 16.6095C6.35943 16.8595 6.02029 17 5.66667 17ZM13.6667 17C13.313 17 12.9739 16.8595 12.7239 16.6095C12.4738 16.3594 12.3333 16.0203 12.3333 15.6667C12.3333 15.313 12.4738 14.9739 12.7239 14.7239C12.9739 14.4738 13.313 14.3333 13.6667 14.3333C14.0203 14.3333 14.3594 14.4738 14.6095 14.7239C14.8595 14.9739 15 15.313 15 15.6667C15 16.0203 14.8595 16.3594 14.6095 16.6095C14.3594 16.8595 14.0203 17 13.6667 17Z" fill="white"/>
                        </svg>
                        <span className='ml-1'>Add To Cart</span>
                    </div>
                    <div className='sticky-cart-price'>₹43,659</div>
                </button>
            </div>

            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas addToCart-offcanvas">
                <Offcanvas.Header closeButton className='align-items-start'>
                    <Offcanvas.Title className='cart-heading'>
                        <div className='notify-me__wrap'>
                            <div className='notify-me__media'>
                                <img src={notifyMe} className='img img-fluid' alt='' />
                            </div>
                            <div className='notify-me__content'>
                                <h6 className='notify-me__head'>1 Item(s) added to your cart</h6>
                                <p className='notify-me__text'>Samsung Galaxy Z Fold2 5G, Awesome White</p>
                                <div className="price-offer">
                                    <div className="new-price">₹43,659</div>
                                    <div className="old-price">₹45,659</div>
                                </div>
                            </div>
                        </div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <div className='add-to-cart__wrap'>
                    <Link to='/' className='btn btn-cart-outline w-100'>Continue Shopping</Link>
                    <Link to='/Cart' className='btn btn-cart ml-2 w-100'>View Cart (1)</Link>
                </div>
                <div className='cart-horizontal-line mt-0'></div>
                <Offcanvas.Body>
                    <div className='addToCart__wrap'>
                        <h5>Protect Your Device</h5>
                        <div className='addToCart__section'>
                            <div className='addToCart__list'>
                                <div className='product-label-tag addToCart-label-tag'>Most Popular</div>
                                <div className='addToCart__head'>Money and Damage Protection (MAD) </div>
                                <ul className='addToCart__li'>
                                    <li><span><FaCheckCircle /></span> Free repairs - just walk in at any Sangeetha store!</li>
                                    <li><span><FaCheckCircle /></span> Replacement of damaged SKU at 50% cost</li>
                                    <li><span><FaCheckCircle /></span> Full refund in case of no claims</li>
                                </ul>
                                <Link to='/' className='addToCart__link'>Know More</Link>
                                <div className='cart-horizontal-line m-0'></div>
                                <div className='addToCart-footer'>
                                    <div className="addToCart-price">₹999</div>
                                    <button type='button' className='btn btn-add-device'>Add</button>
                                </div>
                            </div>
                            <div className='addToCart__list'>
                                <div className='addToCart__head'>Price Protection Plan</div>
                                <ul className='addToCart__li'>
                                    <li><span><FaCheckCircle /></span> Bought a phone today and price dropped after? We refund you the difference.</li>
                                    <li><span><FaCheckCircle /></span> No questions asked redeem in store or online</li>
                                </ul>
                                <Link to='/' className='addToCart__link'>Know More</Link>
                                <div className='cart-horizontal-line m-0'></div>
                                <div className='addToCart-footer'>
                                    <div className="addToCart-price">₹599</div>
                                    <button type='button' className='btn btn-add-device'>Add</button>
                                </div>
                            </div>
                            <div className='addToCart__list'>
                                <div className='addToCart__head'>Assured Buyback</div>
                                <ul className='addToCart__li'>
                                    <li><span><FaCheckCircle /></span> Get assured money back on your next puchase at Sangeetha</li>
                                </ul>
                                <Link to='/' className='addToCart__link'>Know More</Link>
                                <div className='cart-horizontal-line m-0'></div>
                                <div className='addToCart-footer'>
                                    <div className="addToCart-price">₹599</div>
                                    <button type='button' className='btn btn-add-device'>Add</button>
                                </div>
                            </div>
                            <div className="flexible"></div>
                            <Link to='/Cart' className='btn btn-block btn-dark-button w-100 mb-5'>View Cart (1)</Link>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default AddToCart