import React from 'react';
import '../../../Styles/productspecifications.css';

const SmartwatchSpecificationFullTab = () => {

    return (
        <>
            <div className='specifications-tab' >
                <div className='specifications-tab_box'>
                    <div className='specifications-tab_heading'> <h6>Key Features </h6></div>
                    <ul className="specification_listingdata">
                        <li className='specifications-tab_para'> GPS and altimeter </li>
                        <li className='specifications-tab_para'> Swimproof</li>
                        <li className='specifications-tab_para'>Voice based Siri</li>
                        <li className='specifications-tab_para'>3 Activity Rings Tracking - Move, Exercise, Stand</li>
                        <li className='specifications-tab_para'>Smart Coaching   </li>
                        <li className='specifications-tab_para'>Activity Sharing and Achievements  </li>
                        <li className='specifications-tab_para'>Heart Rate monitor</li>
                        <li className='specifications-tab_para'>Breathe App</li>
                        <li className='specifications-tab_para'> Notifications</li>
                        <li className='specifications-tab_para'>Touchscreen</li>
                        <li className='specifications-tab_para'>Notifier, Fitness and Outdoor</li>
                        <li className='specifications-tab_para'>Battery Runtime : Upto 18 hours</li>
                    </ul>
                </div>
                <hr className='specification_hr_para'/>
                <div className='specifications-tab_heading'> <h6>General </h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>What’s in the Box </th>
                            <td>Smartwatch, Magnetic Charging Cable (1m), Band, Quick Start Guide</td>
                        </tr>
                        <tr>
                            <th>Model Number</th>
                            <td>MTF32HN/A</td>
                        </tr>
                        <tr>
                            <th>Model Name </th>
                            <td>Watch series 3 GPS</td>
                        </tr>
                        <tr>
                            <th>Dial Color</th>
                            <td>Space Grey</td>
                        </tr>
                        <tr>
                            <th>Strap Color  </th>
                            <td>Black </td>
                        </tr>
                        <tr>
                            <th>Strap Material </th>
                            <td>Silicone</td>
                        </tr>
                        <tr>
                            <th>Size </th>
                            <td>Regular  </td>
                        </tr>
                        <tr>
                            <th>Touchscreen</th>
                            <td>Yes</td>

                        </tr>
                        <tr>
                            <th>Water Resistant</th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Water Resistant Depth</th>
                            <td>50m</td>
                        </tr>
                        <tr>
                            <th>Usage</th>
                            <td>Notifier, Fitness & Outdoor</td>
                        </tr>
                        <tr>
                            <th> Dial Material </th>
                            <td>Aluminium Case</td>
                        </tr>

                        <tr>
                            <th>Ideal for </th>
                            <td>Women and Men</td>
                        </tr>
   

                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Product Details </h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Sensor </th>
                            <td>Biometric Altimeter, Heart Rate Sensor, Accelerometer, Gyroscope, Ambient Light Sensor, Built-in GPS and GLONASS</td>
                        </tr>
                        <tr>
                            <th>Compatible Device</th>
                            <td>iPhone 6s or later with iOS 14 or later</td>
                        </tr>
                        <tr>
                            <th>Notification</th>
                            <td>Messages, Calendar, Meet</td>
                        </tr>
                        <tr>
                            <th>Battery Type</th>
                            <td>Lithium Ion</td>
                        </tr>
                        <tr>
                            <th>Charge Time </th>
                            <td>90 mins</td>
                        </tr>
                        <tr>
                            <th>Battery Life</th>
                            <td>Upto 18 hours</td>
                        </tr>
                        <tr>
                            <th>Rechargeable Battery</th>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Platform and Storage Features </h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Operating System  </th>
                            <td>watchOS 4</td>
                        </tr>
                        <tr>
                            <th>Compatible Operating System</th>
                            <td>iOS 10 and Above</td>
                        </tr>
                        <tr>
                            <th>Number of Cores </th>
                            <td>Dual Core</td>
                        </tr>
                        <tr>
                            <th>Processor Name  </th>
                            <td>S3</td>
                        </tr>
                        <tr>
                            <th>Internal Memory  </th>
                            <td>8 GB </td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Connectivity Features </h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Call function  </th>
                            <td>No</td>
                        </tr>
                        <tr>
                            <th>Bluetooth  </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Wi-Fi</th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th> GPS</th>
                            <td>  Yes</td>
                        
                        </tr>
                        <tr>
                            <th> Bluetooth Version</th>
                            <td>v4.2</td>
                        </tr>
                        <tr>
                            <th>
                            Other Connectivity Features
                            </th>
                            <td>
                            Wi-Fi (802.11b/g/n 2.4 GHz)
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Camera and Display Features</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Display Resolution  </th>
                            <td>312 x 390 pixels</td>
                        </tr>
                        <tr>
                            <th>Display Size </th>
                            <td>42 mm</td>
                        </tr>
                        <tr>
                            <th>Display Type</th>
                            <td>OLED Retina Display</td>
                        </tr>
                        <tr>
                            <th>Scratch Resistant  </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Other Display Features </th>
                            <td>Ion-X (Strengthened Glass), Support Force Touch </td>
                        </tr>
                       

                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Fitness Watch and Functions </h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Calorie Count  </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Step Count  </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Heart Rate Monitor</th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Altimeter </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Other Fitness Features </th>
                            <td>Built in Fitness Tracker : Steps Count, Distance and Calories Burned </td>
                        </tr>
                        <tr>
                            <th>Date and Time Display</th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Calendar </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Alarm Clock </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Number of Buttons</th>
                            <td>2</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Warranty</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Warranty Summary </th>
                            <td>1 year Warranty </td>
                        </tr>
                        <tr>
                            <th>Warranty Service Type </th>
                            <td>Carry in  </td>
                        </tr>
                        <tr>
                            <th>Covered in Warranty</th>
                            <td>Manufacturing Defects</td>
                        </tr>
                        <tr>
                            <th>Not Covered in Warranty </th>
                            <td>Physical Damage</td>
                        </tr>
                       
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default SmartwatchSpecificationFullTab;