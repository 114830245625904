import React, { useState } from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Carousel from "react-multi-carousel";
import CustomDot from './CustomDot';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ProductDetail from '../../Images/product/product-detail.png';
import productDetail2 from '../../Images/product/product-detail2.png';

const ImageLightbox = ({ Data, ...props }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const GalleryImage = [
        productDetail2,
        ProductDetail,
        ProductDetail,
        ProductDetail
    ]
    return (
        <>
            <img src={Data} className='img img-fluid w-100' alt='' onClick={handleShow} aria-hidden="true" />

            <Offcanvas show={show} onHide={handleClose} {...props} className='light-box__offCanvas' style={{height: '100%'}}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>&nbsp;</Offcanvas.Title> 
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Carousel
                        partialVisible={false}
                        additionalTransfrom={0}
                        arrows={true}
                        autoPlaySpeed={3000}
                        centerMode={false}
                        className=""
                        containerClass="carouser-container Light-box-container"
                        customDot={<CustomDot />}
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={{
                            desktop: {
                                breakpoint: {
                                    max: 3000,
                                    min: 1024
                                },
                                items: 1
                            },
                            mobile: {
                                breakpoint: {
                                    max: 464,
                                    min: 0
                                },
                                items: 1
                            },
                            tablet: {
                                breakpoint: {
                                    max: 1024,
                                    min: 464
                                },
                                items: 1
                            }
                        }}
                        showDots
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                    >
                        {GalleryImage.map((Data, index) => (
                            <div className='Light-box easeOut' key={index}>
                                <TransformWrapper
                                    minScale={1}
                                    centerZoomedOut={true}
                                    centerOnInit={true}
                                    
                                >
                                    <TransformComponent>
                                        <img src={Data} className='img img-fluid w-100' alt='' aria-hidden="true" />
                                    </TransformComponent>
                                </TransformWrapper>
                            </div>
                        ))}
                    </Carousel>
                </Offcanvas.Body>		
            </Offcanvas>
        </>
    )
}

export default ImageLightbox