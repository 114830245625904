import React from 'react';
import '../../../Styles/productspecifications.css';

const EarPhoneSpecificationFullTab = () => {

    return (
        <>
            <div className='specifications-tab' >
                <div className='specifications-tab_box'>
                    <div className='specifications-tab_heading'> <h6>Key Features </h6></div>
                    <ul className="specification_listingdata">
                        <li className='specifications-tab_para'>With Mic : Yes </li>
                        <li className='specifications-tab_para'>Bluetooth Version : 5 </li>
                        <li className='specifications-tab_para'>Wireless Range : 10m</li>
                        <li className='specifications-tab_para'>Charging time : 2 hours</li>
                        <li className='specifications-tab_para'>Instant Voice Assistant  </li>
                        <li className='specifications-tab_para'>Type-C Charging Port | Upto 15 hours of total playtime   </li>
                        <li className='specifications-tab_para'>IWP Technology ( Earbuds power on automatically as one opens the case)</li>
                    </ul>
                </div>
                <hr className='specification_hr_para'/>
                <div className='specifications-tab_heading'> <h6>General </h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Model Name </th>
                            <td>Airdopes 441</td>
                        </tr>
                        <tr>
                            <th>Color</th>
                            <td>Awesome Violet</td>
                        </tr>
                        <tr>
                            <th>Headphones Type</th>
                            <td>True Wireless</td>
                        </tr>
                        <tr>
                            <th>Inline Remote</th>
                            <td>No</td>
                        </tr>
                        <tr>
                            <th>Sales Package </th>
                            <td>Airdopes 441, Charging Case, Type-C Charging, User Manual, Warranty Card </td>
                        </tr>
                        <tr>
                            <th>Connectivity</th>
                            <td>Bluetooth</td>
                        </tr>
                        <tr>
                            <th>Headphone Design </th>
                            <td>Earbud </td>
                        </tr>
   

                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Product Details </h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Sweat Proof </th>
                            <td>No</td>
                        </tr>
                        <tr>
                            <th>Foldable/ Collapsible </th>
                            <td>No</td>
                        </tr>
                        <tr>
                            <th>Deep Bass</th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Designed For</th>
                            <td>Apple, Android, Windows</td>
                        </tr>
                        <tr>
                            <th>Other Features </th>
                            <td>Upto 12 hours playback with a charging case (Up-to 4x Charge), Type-C Charging Port, Insta wake N’ Pair  </td>
                        </tr>
                        <tr>
                            <th>Headphone Driver</th>
                            <td>13mm</td>
                        </tr>
                        <tr>
                            <th>With Microphone</th>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Connectivity Features </h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Bluetooth Version </th>
                            <td>5</td>
                        </tr>
                        <tr>
                            <th>Bluetooth Range</th>
                            <td>10 m</td>
                        </tr>
                        <tr>
                            <th>Charging Time </th>
                            <td>2 hours</td>
                        </tr>
                        <tr>
                            <th>Play Time </th>
                            <td>15 hours</td>
                        </tr>
                        <tr>
                            <th>Standby Time  </th>
                            <td>100 hours </td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Warranty </h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Domestic Warranty  </th>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <th>Warranty Summary  </th>
                            <td>1 year warranty</td>
                        </tr>
                        <tr>
                            <th>Warranty Service Type</th>
                            <td>Reach out to us at info@imaginemarketingindia.com/ +912249461882 Alternatively you can activate your warranty by giving us a missed call on 9223032222 </td>
                        </tr>
                        <tr>
                            <th> Covered in Warranty</th>
                            <td>  Manufacturing Defects</td>
                        
                        </tr>
                        <tr>
                            <th> Not Covered in Warranty </th>
                            <td>Physical Damages</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Camera Features</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Primary Camera available  </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Primary Camera </th>
                            <td>12MP + 12MP + 12MP</td>
                        </tr>
                        <tr>
                            <th>Primary Camera Features</th>
                            <td>Triple Rear Camera with Director’s View, Auto Framing, Single Take & Night Mode, OIS, Ultra Wide, Depth & Macro</td>
                        </tr>
                        <tr>
                            <th>Secondary Camera Available </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Secondary Camera </th>
                            <td>10MP Front Camera </td>
                        </tr>
                        <tr>
                            <th>Secondary Camera Features</th>
                            <td>10MP (f/2.2) Front Camera on Cover Screen + 4MP (f/1.8) Under Display Camera on Main Screen, Features : Optical Image Stabilisation</td>
                        </tr>
                        <tr>
                            <th>Flash </th>
                            <td>Rear Flash</td>
                        </tr>
                        <tr>
                            <th>HD Recording </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Full HD Recording </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Video Recording  </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Image Editor </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Dual Camera Lens </th>
                            <td>Primary Camera</td>
                        </tr>

                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Call Features</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Hands Free  </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Video Call Support </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Call Divert</th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Phone Book </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Call Timer </th>
                            <td>Yes </td>
                        </tr>
                        <tr>
                            <th>Speaker Phone</th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Call Records </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Logs </th>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Connectivity Features</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Network Type </th>
                            <td>5G, 4G, 3G, 2G </td>
                        </tr>
                        <tr>
                            <th>Supported Networks </th>
                            <td>5G, 4G LTE, WCDMA, GSM </td>
                        </tr>
                        <tr>
                            <th>3G</th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>GPRS </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Pre-Installed Browser </th>
                            <td>Google Chrome | Samsung S-Browser 14.0 </td>
                        </tr>
                        <tr>
                            <th>Micro USB Port</th>
                            <td>No</td>
                        </tr>
                        <tr>
                            <th>Mini USB Port </th>
                            <td>No</td>
                        </tr>
                        <tr>
                            <th>Bluetooth Support </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Bluetooth Version </th>
                            <td>v5.0</td>
                        </tr>
                        <tr>
                            <th>Wi-Fi  </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Wi-Fi Hotspot </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Mini HDMI Port </th>
                            <td>No</td>
                        </tr>
                        <tr>
                            <th>TV Out </th>
                            <td>No</td>
                        </tr>
                        <tr>
                            <th>Infrared </th>
                            <td>No</td>
                        </tr>
                        <tr>
                            <th>Wi-Fi Hotspot </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>USB Connectivity </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Map Support </th>
                            <td>Google Maps</td>
                        </tr>
                        <tr>
                            <th>Google Maps </th>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Other Details</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Smartphone  </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>SIM Size </th>
                            <td>Nano Sim & E-Sim</td>
                        </tr>
                        <tr>
                            <th>Social Networking Phone</th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Instant Message </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>MMS </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Keypad</th>
                            <td>No</td>
                        </tr>
                        <tr>
                            <th>Voice Input </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Graphics PPI </th>
                            <td>374 PPI</td>
                        </tr>
                        <tr>
                            <th>Predictive Text</th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Sensors  </th>
                            <td>Accelerometer, Barometer, Fingerprint Sensor, Gyro Senso, Geomagnetic Sensor, Hall Sensor, Light Sensor, Proximity Sensor </td>
                        </tr>
                        <tr>
                            <th>Series </th>
                            <td>Z Series</td>
                        </tr>
                        <tr>
                            <th>Browser </th>
                            <td>Google Chrome</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Battery & Power Features </h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Battery Capacity  </th>
                            <td>4400 mAh</td>
                        </tr>
                        <tr>
                            <th>Dual Battery </th>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Dimensions</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Heights  </th>
                            <td>158.2 mm</td>
                        </tr>
                        <tr>
                            <th>Weight </th>
                            <td>271 g</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Warranty</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Warranty Summary  </th>
                            <td>1 Year Manufacturer Warranty for Device and 6 months Manufacturer Warranty for In-Box Accessories</td>
                        </tr>
                        <tr>
                            <th>Domestic Warranty  </th>
                            <td>1 year</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default EarPhoneSpecificationFullTab;