import React, { useState } from 'react';
//import Form from 'react-bootstrap/Form';

import Smarttv from '../../../../Images/CategoryDetails/TVtogether1.png';
import earbud from '../../../../Images/CategoryDetails/Tvtgether2.png';
import wristband from '../../../../Images/CategoryDetails/wristbandtogether.png';

const SmartTvTogether= () => {
    const [isChecked, setIsChecked] = useState(true);

    const handleOnChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <>
            <div className='buy-together__wrap'>
                <div className='buy-together__card'>
                    <div className="buy-together__checkBox">
                        {/* <Form.Check 
                            aria-label="option 1" 
                            checked={isChecked} 
                            onChange={handleOnChange}
                        /> */}
                        <input 
                            className="form-check-input" 
                            type="checkbox" 
                            value="" 
                            checked={isChecked} 
                            onChange={handleOnChange}
                            id="1" />
                        <div className='buy-together__media'>
                            <img src={Smarttv} className='img img-fluid' alt='' />
                        </div>
                        <div className='buy-together__details'>
                            <h6 className='buy-together__heading'>SAMSUNG Crystal 4K Pr..</h6>
                            <div className="price-offer">
                                <div className="new-price">₹41,900</div>
                                <div className="old-price">₹45,990</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='buy-together__card'>
                    <div className="form-check- buy-together__checkBox">
                        <input 
                            className="form-check-input" 
                            type="checkbox" 
                            value="" 
                            checked={isChecked} 
                            onChange={handleOnChange}
                            id="2" />
                    </div>
                    <div className='buy-together__media'>
                        <img src={earbud} className='img img-fluid' alt='' />
                    </div>
                    <div className='buy-together__details'>
                        <h6 className='buy-together__heading'>ZEBRONICS ZEB-Juke Bar 9400..</h6>
                        <div className="price-offer">
                            <div className="new-price">₹89,990</div>
                            <div className="old-price">₹1,25,900</div>
                        </div>
                    </div>
                </div>
                <div className='buy-together__card'>
                    <div className="form-check- buy-together__checkBox">
                        <input 
                            className="form-check-input" 
                            type="checkbox" 
                            value="" 
                            checked={isChecked} 
                            onChange={handleOnChange}
                            id="3" />
                    </div>
                    <div className='buy-together__media' htmlFor="apple">
                        <img src={wristband} className='img img-fluid' alt='' />
                    </div>
                    <div className='buy-together__details'>
                        <h6 className='buy-together__heading'>AGEIS Metal Strap Wrist..</h6>
                        <div className="price-offer">
                            <div className="new-price">₹15,999</div>
                            <div className="old-price">₹37,999</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='buy-together__savings'>
                <p className='mb-0'>Saving additional ₹3,000 on 3 items</p>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
                <div className="price-offer justify-content-start mt-3">
                    <div className="new-price">₹50,398</div>
                    <div className="old-price">₹57,788</div>
                </div>
                <div className='d-flex'>
                    <button type='button' className='btn btn-block btn-dark-custom mb-2'>Buy Now</button>
                </div>
            </div>
        </>
    )
}

export default SmartTvTogether;
