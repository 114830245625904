import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import CardPayment from './CardPayment';
import UPIPayment from './UPIPayment';
import NetBanking from './NetBanking';
import CardlessEmiPayment from './CardlessEmiPayment';
import WalletsPayment from './WalletsPayment';
import CODPayment from './CODPayment';

import upiLogo from '../../Images/product/emi/upiLogo.png';

const PaymentAccordion = () => {
    const [ istoggle, setToggle ] = useState(false);
    const [activeId, setActiveId] = useState('5');

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }
    return (
        <>
            <Accordion className='cart-payment__accordion' defaultActiveKey={activeId}>
                <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('0')}>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.9987 2H13.9987C14.1755 2 14.3451 2.07024 14.4701 2.19526C14.5951 2.32029 14.6654 2.48986 14.6654 2.66667V13.3333C14.6654 13.5101 14.5951 13.6797 14.4701 13.8047C14.3451 13.9298 14.1755 14 13.9987 14H1.9987C1.82189 14 1.65232 13.9298 1.52729 13.8047C1.40227 13.6797 1.33203 13.5101 1.33203 13.3333V2.66667C1.33203 2.48986 1.40227 2.32029 1.52729 2.19526C1.65232 2.07024 1.82189 2 1.9987 2ZM13.332 7.33333H2.66536V12.6667H13.332V7.33333ZM13.332 6V3.33333H2.66536V6H13.332ZM9.33203 10H11.9987V11.3333H9.33203V10Z" fill="#121415"/>
                            </svg>
                        </span>
                        <div className='payment-card__head'>
                            <span>Credit/Debit Card</span>
                            <span className='card-info__detail'>Mastercard, VISA, RuPay & more</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <CardPayment />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className={activeId === '1' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('1')}>
                        <span>
                            <img src={upiLogo} className='img img-fluid' alt='' />
                        </span>
                        <div className='payment-card__head'>
                            <span>UPI</span>
                            <span className='card-info__detail'>Google Pay, Amazon Pay, PhonePe & more</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='product-highlight__otherInfo'>
                            <UPIPayment />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className={activeId === '2' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('2')}>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.33203 13.3334H14.6654V14.6667H1.33203V13.3334ZM2.66536 8.00004H3.9987V12.6667H2.66536V8.00004ZM5.9987 8.00004H7.33203V12.6667H5.9987V8.00004ZM8.66536 8.00004H9.9987V12.6667H8.66536V8.00004ZM11.9987 8.00004H13.332V12.6667H11.9987V8.00004ZM1.33203 4.66671L7.9987 1.33337L14.6654 4.66671V7.33337H1.33203V4.66671ZM2.66536 5.49071V6.00004H13.332V5.49071L7.9987 2.82404L2.66536 5.49071ZM7.9987 5.33337C7.82189 5.33337 7.65232 5.26314 7.52729 5.13811C7.40227 5.01309 7.33203 4.84352 7.33203 4.66671C7.33203 4.4899 7.40227 4.32033 7.52729 4.1953C7.65232 4.07028 7.82189 4.00004 7.9987 4.00004C8.17551 4.00004 8.34508 4.07028 8.4701 4.1953C8.59513 4.32033 8.66536 4.4899 8.66536 4.66671C8.66536 4.84352 8.59513 5.01309 8.4701 5.13811C8.34508 5.26314 8.17551 5.33337 7.9987 5.33337Z" fill="#121415"/>
                            </svg>
                        </span>
                        <div className='payment-card__head'>
                            <span>Netbanking</span>
                            <span className='card-info__detail'>SBI, HDFC, Axis & more</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <NetBanking />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className={activeId === '3' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('3')}>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.9987 14.6667C4.3167 14.6667 1.33203 11.682 1.33203 8.00004C1.33203 4.31804 4.3167 1.33337 7.9987 1.33337C11.6807 1.33337 14.6654 4.31804 14.6654 8.00004C14.6654 11.682 11.6807 14.6667 7.9987 14.6667ZM7.9987 13.3334C9.41319 13.3334 10.7697 12.7715 11.7699 11.7713C12.7701 10.7711 13.332 9.41453 13.332 8.00004C13.332 6.58555 12.7701 5.229 11.7699 4.2288C10.7697 3.22861 9.41319 2.66671 7.9987 2.66671C6.58421 2.66671 5.22766 3.22861 4.22746 4.2288C3.22727 5.229 2.66536 6.58555 2.66536 8.00004C2.66536 9.41453 3.22727 10.7711 4.22746 11.7713C5.22766 12.7715 6.58421 13.3334 7.9987 13.3334Z" fill="#121415"/>
                                <path d="M8.64352 7.02154C8.60031 7.47505 8.44444 7.84135 8.17593 8.12044C7.90741 8.39952 7.50463 8.58442 6.96759 8.67512L8.97222 11.3334H8L6 8.69082L6.09259 7.92682H6.51852C6.66975 7.92682 6.80247 7.91984 6.91667 7.90589C7.03086 7.89194 7.12963 7.87275 7.21296 7.84833C7.29938 7.82042 7.37346 7.78728 7.43519 7.7489C7.49691 7.70704 7.55401 7.65995 7.60648 7.60762C7.74228 7.4611 7.83025 7.26574 7.87037 7.02154H6V6.25754H7.86111C7.83642 6.12148 7.7963 6.00636 7.74074 5.91217C7.68827 5.81449 7.61883 5.72727 7.53241 5.65053C7.44599 5.57378 7.33179 5.51796 7.18981 5.48307C7.05093 5.44819 6.8642 5.43075 6.62963 5.43075H6V4.66675H10V5.43075H8.22222C8.33025 5.51796 8.41975 5.63308 8.49074 5.77611C8.56173 5.91915 8.61111 6.07962 8.63889 6.25754H10V7.02154H8.64352Z" fill="#121415"/>
                            </svg>
                        </span>
                        <div className='payment-card__head'>
                            <span>EMI & Cardless EMI</span>
                            <span className='card-info__detail'>LazyPay, Zebpay & more</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <CardlessEmiPayment />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className={activeId === '4' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('4')}>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.332 4.66679V3.33346H2.66536V12.6668H13.332V11.3335H7.9987C7.82189 11.3335 7.65232 11.2632 7.52729 11.1382C7.40227 11.0132 7.33203 10.8436 7.33203 10.6668V5.33346C7.33203 5.15664 7.40227 4.98708 7.52729 4.86205C7.65232 4.73703 7.82189 4.66679 7.9987 4.66679H13.332ZM1.9987 2.00012H13.9987C14.1755 2.00012 14.3451 2.07036 14.4701 2.19538C14.5951 2.32041 14.6654 2.48998 14.6654 2.66679V13.3335C14.6654 13.5103 14.5951 13.6798 14.4701 13.8049C14.3451 13.9299 14.1755 14.0001 13.9987 14.0001H1.9987C1.82189 14.0001 1.65232 13.9299 1.52729 13.8049C1.40227 13.6798 1.33203 13.5103 1.33203 13.3335V2.66679C1.33203 2.48998 1.40227 2.32041 1.52729 2.19538C1.65232 2.07036 1.82189 2.00012 1.9987 2.00012ZM8.66536 6.00012V10.0001H13.332V6.00012H8.66536ZM9.9987 7.33346H11.9987V8.66679H9.9987V7.33346Z" fill="#121415"/>
                            </svg>
                        </span>
                        <div className='payment-card__head'>
                            <span>Wallets</span> 
                            <span className='card-info__detail'>Paytm, Amazon & more</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <WalletsPayment istoggle={istoggle} setToggle={setToggle} />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className={activeId === '5' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('5')}>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.3346 13.3336C13.3346 13.5104 13.2644 13.68 13.1394 13.805C13.0143 13.93 12.8448 14.0003 12.668 14.0003H3.33464C3.15782 14.0003 2.98826 13.93 2.86323 13.805C2.73821 13.68 2.66797 13.5104 2.66797 13.3336V7.33361H0.667969L7.55263 1.07494C7.67538 0.963257 7.83536 0.901367 8.0013 0.901367C8.16725 0.901367 8.32723 0.963257 8.44997 1.07494L15.3346 7.33361H13.3346V13.3336ZM12.0013 12.6669V6.10494L8.0013 2.46894L4.0013 6.10494V12.6669H12.0013Z" fill="#121415"/>
                                <path d="M7.79991 6.87429H6H5.95V6.92429V7.68828V7.73828H6H7.81036C7.76781 7.9472 7.68718 8.11345 7.57048 8.23966C7.52067 8.28924 7.46651 8.33392 7.40795 8.37371C7.35071 8.40917 7.28102 8.44051 7.19824 8.46729C7.11834 8.49065 7.0226 8.50932 6.9106 8.52301C6.79893 8.53665 6.66832 8.54357 6.51852 8.54357H6.09259H6.04829L6.04296 8.58755L5.95036 9.35155L5.94793 9.37162L5.96013 9.38774L7.96013 12.0303L7.97514 12.0502H8H8.97222H9.07255L9.01214 11.9701L7.05647 9.37668C7.56043 9.28032 7.94745 9.09676 8.21196 8.82185C8.48052 8.54272 8.63861 8.18039 8.68858 7.73828H10H10.05V7.68828V6.92429V6.87429H10H8.68123C8.65178 6.70858 8.60333 6.55725 8.53553 6.42063C8.48349 6.31578 8.42145 6.22457 8.34921 6.14749H10H10.05V6.09749V5.3335V5.2835H10H6H5.95V5.3335V6.09749V6.14749H6H6.62963C6.86234 6.14749 7.04444 6.16486 7.17763 6.19832L7.17788 6.19838C7.31401 6.23183 7.42026 6.28455 7.49921 6.35466C7.58142 6.42767 7.64712 6.51029 7.69669 6.60258L7.69666 6.6026L7.69767 6.60432C7.74161 6.67881 7.77592 6.76855 7.79991 6.87429Z" fill="#121415" stroke="#121415" strokeWidth="0.1"/>
                            </svg>
                        </span>
                        <div className='payment-card__head'>
                            <span>Cash on Delivery</span>
                            <span className='card-info__detail'>Pay at home</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <CODPayment />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default PaymentAccordion