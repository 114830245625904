import React from 'react';
import { Link } from '@reach/router';
import OrderIcon from '../../Images/product/OrderState.svg';

const OrderState = () => {
    return (
        <>
            <div className='home-order-state'>
                <div className='home-cart__wrap'>
                    <div className='cart__product d-flex align-items-center'>
                        <div className='cart-media__product'>
                            <img src={OrderIcon} className='img-fluid' alt='' />
                        </div>
                        <div className='order-info__product ml-3'>
                            <div className='order-total__product'>Order Placed</div>
                            <div className='order-price__product'>Expected delivery by Wed, 6th Jan</div>
                        </div> 
                    </div>
                    <div className='home-cart__btn'>
                        <Link to='/OrderTrack' className='btn btn-dark-custom btn-space-22'>Track</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderState