import React, { useState } from 'react';
import { Link } from '@reach/router';
import StickyFilter from '../../Components/Product/StickyFilter';
import SponsoredPhonesCarousel from '../../Components/Product/SponsoredPhonesCarousel';
import AppleIphonePro from '../../Images/product/product-1.png';
import FilteredResult from '../../Components/Filter/FilteredResult';
import Compare from '../../Components/Compare/Compare';
import { productsData } from '../../Data';

const ProductList = () => {
    const [ wishlistActive, setWishlistState ] = useState(false);

    const handleToggle = () => {
        setWishlistState(!wishlistActive);
    } 
    return (
        <>
            <FilteredResult />            
            <div className='product-wrapper'>
                {productsData.slice(0, 3).map(product => (
                    <div className='product-list h-1_2-1' key={product._id}>
                        {product.label ?
                            <div className='product-label-tag m-h-1'>{product.label}</div> : ''
                        }
                        <button 
                            type='button' 
                            className={`product-wishlist ${wishlistActive ? 'Active' : ''}`}
                            onClick={handleToggle} 
                            aria-hidden="false" 
                            id={product._id}
                        >
                            {wishlistActive ?
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.35164 10.6616C1.55948 9.73785 1.10148 8.57421 1.05146 7.35831C1.00143 6.14241 1.36231 4.94509 2.07592 3.95935C2.78952 2.9736 3.81427 2.25688 4.98499 1.9247C6.1557 1.59252 7.40417 1.66424 8.52914 2.12831H8.52748L4.99247 5.66498L6.17081 6.84331L9.70581 3.30831C10.7216 2.29242 12.0993 1.72166 13.5359 1.72158C14.9725 1.7215 16.3503 2.29211 17.3662 3.30789C18.3821 4.32367 18.9529 5.70141 18.953 7.13802C18.953 8.57462 18.3824 9.95242 17.3666 10.9683L10.0008 18.3333L2.34081 10.6733L2.35164 10.6616Z" fill="#E25100" />
                                </svg> :
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.8685 3.96415C18.7535 5.85415 18.8185 8.86415 17.0652 10.8275L9.99852 17.9042L2.93352 10.8275C1.18019 8.86415 1.24602 5.84915 3.13019 3.96415C5.01769 2.07748 8.03685 2.01415 10.0002 3.77415C11.9577 2.01665 14.9827 2.07498 16.8685 3.96415ZM4.30935 5.14332C3.06769 6.38498 3.00519 8.37248 4.14935 9.68582L9.99935 15.545L15.8494 9.68665C16.9944 8.37248 16.9319 6.38748 15.6885 5.14165C14.4494 3.89998 12.4544 3.83998 11.1444 4.98665L7.64269 8.48915L6.46352 7.31082L8.81769 4.95498L8.74935 4.89748C7.43685 3.84332 5.51852 3.93332 4.30935 5.14332Z" fill="#121415" />
                                </svg>
                            }
                        </button>
                        <div className='product-wrapper-flex'>
                            <div className='product-thumb'>
                                <img src={AppleIphonePro} className='img-fluid img w-100' alt={product.productName} />
                            </div>
                            <div className='product-details'>
                                <h2>
                                    <Link to='/ProductDetails'>{product.productName}</Link>
                                </h2>
                                <p className='product-muted'>
                                    {product.productColor},
                                    {product.productStorage.Storage} +
                                    {product.productStorage.Storage}
                                </p>
                                <div className='product-price'>
                                    <div className='new-price'>₹{product.productNewPrice}</div>
                                    <div className='old-price'>₹{product.productOldPrice}</div>
                                    <div className='price-discount'>{product.productOffer}</div>
                                </div>
                                <div className='product-offer'>
                                    <div className='product-offer-list'>
                                        <div className='offer-icon'>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.45019 4.58579L10.9504 4.58579L10.9504 8.08597L9.95052 8.08596L9.95016 6.29239L5.29351 10.9497L4.5864 10.2426L9.24341 5.58563L7.45019 5.58563V4.58579ZM1.05087 3.91403L2.05072 3.91403L2.05072 5.70726L6.70773 1.05025L7.41483 1.75736L2.75783 6.41436H4.55105L4.55105 7.41421H1.05087V3.91403Z" fill="#121415" />
                                            </svg>
                                        </div>
                                        <div className='offer-label'>Upto ₹11,680 Off on Exchange</div>
                                    </div>
                                    <div className='product-offer-list'>
                                        <div className='offer-icon'>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.5 1.5H6.086L1 6.586L5.414 11L10.5 5.914V1.5ZM8.75 2.5C9.164 2.5 9.5 2.836 9.5 3.25C9.5 3.664 9.164 4 8.75 4C8.336 4 8 3.664 8 3.25C8 2.836 8.336 2.5 8.75 2.5Z" fill="#121415" />
                                            </svg>
                                        </div>
                                        <div className='offer-label'>10% Off on Axis Bank Credit Cards</div>
                                    </div>
                                </div>
                                {['bottom'].map((placement, idx) => (
                                    <Compare key={idx} placement={placement} name={placement} />
                                ))}
                            </div>
                        </div>

                        <div className='product-tags'>
                            <div className='tag-label'>PhotoGraphy</div>
                            <div className='tag-label'>Wireless Charge</div>
                            <div className='tag-label'>Retina Display</div>
                            <div className='tag-label'>Gaming</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='product-wrapper product__0 mt-0'>
                <div className='product-filter__wrap px-0'>
                    <h4 className='product-filter__head p-x-16'>Filter By Price</h4>
                    <div className='product-filter__lists ml--16'>
                        <div className='product-filter__item'>Under ₹10,000</div>
                        <div className='product-filter__item'>₹10,000 to ₹20,000</div>
                        <div className='product-filter__item'>₹20,000 to ₹50,000</div>
                    </div>
                </div>
                <div className='mar-spacer h-1_2' />
                {productsData.slice(0, 1).map(data => (
                    <div className='product-list' key={data._id}>
                        {data.label ?
                            <div className='product-label-tag'>{data.label}</div> : ''
                        }
                        <button 
                            type='button' 
                            className={`product-wishlist ${wishlistActive ? 'Active' : ''}`}
                            onClick={handleToggle} 
                            aria-hidden="false" 
                            id={data._id}
                        >
                            {wishlistActive ?
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.35164 10.6616C1.55948 9.73785 1.10148 8.57421 1.05146 7.35831C1.00143 6.14241 1.36231 4.94509 2.07592 3.95935C2.78952 2.9736 3.81427 2.25688 4.98499 1.9247C6.1557 1.59252 7.40417 1.66424 8.52914 2.12831H8.52748L4.99247 5.66498L6.17081 6.84331L9.70581 3.30831C10.7216 2.29242 12.0993 1.72166 13.5359 1.72158C14.9725 1.7215 16.3503 2.29211 17.3662 3.30789C18.3821 4.32367 18.9529 5.70141 18.953 7.13802C18.953 8.57462 18.3824 9.95242 17.3666 10.9683L10.0008 18.3333L2.34081 10.6733L2.35164 10.6616Z" fill="#E25100" />
                                </svg> :
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.8685 3.96415C18.7535 5.85415 18.8185 8.86415 17.0652 10.8275L9.99852 17.9042L2.93352 10.8275C1.18019 8.86415 1.24602 5.84915 3.13019 3.96415C5.01769 2.07748 8.03685 2.01415 10.0002 3.77415C11.9577 2.01665 14.9827 2.07498 16.8685 3.96415ZM4.30935 5.14332C3.06769 6.38498 3.00519 8.37248 4.14935 9.68582L9.99935 15.545L15.8494 9.68665C16.9944 8.37248 16.9319 6.38748 15.6885 5.14165C14.4494 3.89998 12.4544 3.83998 11.1444 4.98665L7.64269 8.48915L6.46352 7.31082L8.81769 4.95498L8.74935 4.89748C7.43685 3.84332 5.51852 3.93332 4.30935 5.14332Z" fill="#121415" />
                                </svg>
                            }
                        </button>
                        <div className='product-thumb'>
                            <img src={AppleIphonePro} className='img-fluid img w-100' alt={data.productName} />
                        </div>
                        <div className='product-details'>
                            <h2>
                                <Link to='/ProductDetails'>{data.productName}</Link>
                            </h2>
                            <p className='product-muted'>
                                {data.productColor},
                                {data.productStorage.Storage} +
                                {data.productStorage.Storage}
                            </p>
                            <div className='product-price'>
                                <div className='new-price'>₹{data.productNewPrice}</div>
                                <div className='old-price'>₹{data.productOldPrice}</div>
                                <div className='price-discount'>{data.productOffer}</div>
                            </div>
                            <div className='product-offer'>
                                <div className='product-offer-list'>
                                    <div className='offer-icon'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.45019 4.58579L10.9504 4.58579L10.9504 8.08597L9.95052 8.08596L9.95016 6.29239L5.29351 10.9497L4.5864 10.2426L9.24341 5.58563L7.45019 5.58563V4.58579ZM1.05087 3.91403L2.05072 3.91403L2.05072 5.70726L6.70773 1.05025L7.41483 1.75736L2.75783 6.41436H4.55105L4.55105 7.41421H1.05087V3.91403Z" fill="#121415" />
                                        </svg>
                                    </div>
                                    <div className='offer-label'>Upto ₹11,680 Off on Exchange</div>
                                </div>
                                <div className='product-offer-list'>
                                    <div className='offer-icon'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.5 1.5H6.086L1 6.586L5.414 11L10.5 5.914V1.5ZM8.75 2.5C9.164 2.5 9.5 2.836 9.5 3.25C9.5 3.664 9.164 4 8.75 4C8.336 4 8 3.664 8 3.25C8 2.836 8.336 2.5 8.75 2.5Z" fill="#121415" />
                                        </svg>
                                    </div>
                                    <div className='offer-label'>10% Off on Axis Bank Credit Cards</div>
                                </div>
                            </div>
                            {['bottom'].map((placement, idx) => (
                                <Compare key={idx} placement={placement} name={placement} />
                            ))}
                        </div>
                        <div className='product-tags'>
                            <div className='tag-label'>PhotoGraphy</div>
                            <div className='tag-label'>Wireless Charge</div>
                            <div className='tag-label'>Retina Display</div>
                            <div className='tag-label'>Gaming</div>
                        </div>
                    </div>
                ))}
                <div className='product-sponsored__wrap px-0'> 
                    <h4 className='product-sponsored__head p-x-16'>Sponsored Phones</h4>
                    <div className='ml--16'>
                        <SponsoredPhonesCarousel />
                    </div>
                </div>
                <div />
            </div>
            <div className='product-wrapper product__0 mt-0'>
                <div className='mar-spacer h-1_2' />
                {productsData.slice(0, 1).map(data => (
                    <div className='product-list' key={data._id}>
                        {data.label ?
                            <div className='product-label-tag'>{data.label}</div> : ''
                        }
                        <button 
                            type='button' 
                            className={`product-wishlist ${wishlistActive ? 'Active' : ''}`}
                            onClick={handleToggle} 
                            aria-hidden="false" 
                            id={data._id}
                        >
                            {wishlistActive ?
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.35164 10.6616C1.55948 9.73785 1.10148 8.57421 1.05146 7.35831C1.00143 6.14241 1.36231 4.94509 2.07592 3.95935C2.78952 2.9736 3.81427 2.25688 4.98499 1.9247C6.1557 1.59252 7.40417 1.66424 8.52914 2.12831H8.52748L4.99247 5.66498L6.17081 6.84331L9.70581 3.30831C10.7216 2.29242 12.0993 1.72166 13.5359 1.72158C14.9725 1.7215 16.3503 2.29211 17.3662 3.30789C18.3821 4.32367 18.9529 5.70141 18.953 7.13802C18.953 8.57462 18.3824 9.95242 17.3666 10.9683L10.0008 18.3333L2.34081 10.6733L2.35164 10.6616Z" fill="#E25100" />
                                </svg> :
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.8685 3.96415C18.7535 5.85415 18.8185 8.86415 17.0652 10.8275L9.99852 17.9042L2.93352 10.8275C1.18019 8.86415 1.24602 5.84915 3.13019 3.96415C5.01769 2.07748 8.03685 2.01415 10.0002 3.77415C11.9577 2.01665 14.9827 2.07498 16.8685 3.96415ZM4.30935 5.14332C3.06769 6.38498 3.00519 8.37248 4.14935 9.68582L9.99935 15.545L15.8494 9.68665C16.9944 8.37248 16.9319 6.38748 15.6885 5.14165C14.4494 3.89998 12.4544 3.83998 11.1444 4.98665L7.64269 8.48915L6.46352 7.31082L8.81769 4.95498L8.74935 4.89748C7.43685 3.84332 5.51852 3.93332 4.30935 5.14332Z" fill="#121415" />
                                </svg>
                            }
                        </button>
                        <div className='product-thumb'>
                            <img src={AppleIphonePro} className='img-fluid img w-100' alt={data.productName} />
                        </div>
                        <div className='product-details'>
                            <h2>
                                <Link to='/ProductDetails'>{data.productName}</Link>
                            </h2>
                            <p className='product-muted'>
                                {data.productColor},
                                {data.productStorage.Storage} +
                                {data.productStorage.Storage}
                            </p>
                            <div className='product-price'>
                                <div className='new-price'>₹{data.productNewPrice}</div>
                                <div className='old-price'>₹{data.productOldPrice}</div>
                                <div className='price-discount'>{data.productOffer}</div>
                            </div>
                            <div className='product-offer'>
                                <div className='product-offer-list'>
                                    <div className='offer-icon'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.45019 4.58579L10.9504 4.58579L10.9504 8.08597L9.95052 8.08596L9.95016 6.29239L5.29351 10.9497L4.5864 10.2426L9.24341 5.58563L7.45019 5.58563V4.58579ZM1.05087 3.91403L2.05072 3.91403L2.05072 5.70726L6.70773 1.05025L7.41483 1.75736L2.75783 6.41436H4.55105L4.55105 7.41421H1.05087V3.91403Z" fill="#121415" />
                                        </svg>
                                    </div>
                                    <div className='offer-label'>Upto ₹11,680 Off on Exchange</div>
                                </div>
                                <div className='product-offer-list'>
                                    <div className='offer-icon'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.5 1.5H6.086L1 6.586L5.414 11L10.5 5.914V1.5ZM8.75 2.5C9.164 2.5 9.5 2.836 9.5 3.25C9.5 3.664 9.164 4 8.75 4C8.336 4 8 3.664 8 3.25C8 2.836 8.336 2.5 8.75 2.5Z" fill="#121415" />
                                        </svg>
                                    </div>
                                    <div className='offer-label'>10% Off on Axis Bank Credit Cards</div>
                                </div>
                            </div>
                            {['bottom'].map((placement, idx) => (
                                <Compare key={idx} placement={placement} name={placement} />
                            ))}
                        </div>
                        <div className='product-tags'>
                            <div className='tag-label'>PhotoGraphy</div>
                            <div className='tag-label'>Wireless Charge</div>
                            <div className='tag-label'>Retina Display</div>
                            <div className='tag-label'>Gaming</div>
                        </div>
                        <div className='mar-spacer h-3' />
                    </div>
                ))}
            </div>
            <StickyFilter />
        </>
    )
}

export default ProductList
