import React, { useState } from 'react';
import NoResult from './NoResult';
// import StoreListItem from './StoreListItem';
import ReqCallback from './ReqCallback';
import Banner from '../../Images/storeLocator/banner.png';

const StoreSearch = () => {
    const [searchTerm, setSearchTerm] = useState("");

    const handleChange = (e) => {
        setSearchTerm(e.target.value);
    }    

    const SearchedQuery = [
        {
            _id: 1,
            Title: 'Sangeetha Mobiles Pvt Ltd - Banaswadi-3',
            AddressText: 'Near: Hotel Empire, # 19, Mosque Rd, Pulikeshi Nagar, Bengaluru, Karnataka 560068',
            PinCode: 560068,
            contantInfo: 9826611234,
            distance: '2.7 km away',
            Time: '9:00am - 8:00pm'
        }, {
            _id: 2,
            Title: 'Sangeetha Mobiles Pvt Ltd - Fraser Town-1',
            AddressText: '#57, IGN Towers, Dodda Banaswadi Main Rd, Bengaluru, Karnataka 560068',
            PinCode: 560068,
            contantInfo: 9826611234,
            distance: '1.2 km away',
            Time: '9:00am - 8:00pm'
        }, {
            _id: 3,
            Title: 'Sangeetha Mobiles Pvt Ltd - CMH Road',
            AddressText: 'Near: Hotel Empire, # 19, Mosque Rd, Pulikeshi Nagar, Bengaluru, Karnataka 462029',
            PinCode: 462029,
            contantInfo: 9826611234,
            distance: '1.2 km away',
            Time: '9:00am - 8:00pm'
        }
    ];

    const results = !searchTerm
        ? SearchedQuery
        : SearchedQuery.filter(person =>
            person.AddressText.toLowerCase().includes(searchTerm.toLocaleLowerCase())
        );

    return (
        <>
            <div 
                className='store__search'
                style={{ 
                    backgroundImage:`url(${Banner})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'bottom center',
                    backgroundSize: 'cover',
                }}
            >
                <p className='store_info'>Enter pincode to find a store near you</p>
                <div className='input-group-address'>
                    <div className='cart-address__wrap'>
                        <div className='position-relative'>
                            <input 
                                type="number" 
                                className='form-control form-control-cart' 
                                placeholder='Eg: 350014'
                                value={searchTerm}
                                onChange={handleChange}
                            /> 
                        </div>  
                        <button type='button' className='btn btn-dark-button absolute-cart-input btn-space'>Check</button>
                    </div>
                </div>
            </div>
            {searchTerm.length > 1 ?
                <div className='px-3 list-store-head'>
                    <div>
                        {results.length > 0 ? 
                            <span>{results.length} stores nearby</span> : <NoResult />
                        }
                    </div>
                </div> : null
            }
            {searchTerm.length > 1 ?
                <>
                    <div className='item-item__category'>
                        {results.map(item => (
                            <div className='item-links__store' key={item._id}>
                                <div className='d-flex flex-column px-3'>
                                    <h2 className='store-head__title'>Sangeetha Mobiles Pvt Ltd - Banaswadi-3</h2>
                                    <div className='store-info__title'>Near: Hotel Empire, # 19, Mosque Rd, Pulikeshi Nagar, Bengaluru, Karnataka 462029</div>
                                    <div className='store-info__call'>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.244 7.12133C6.86956 8.22032 7.77968 9.13044 8.87867 9.756L9.468 8.93067C9.56277 8.79796 9.7029 8.70459 9.86187 8.66822C10.0208 8.63186 10.1876 8.65502 10.3307 8.73333C11.2735 9.24862 12.3148 9.55852 13.386 9.64267C13.5532 9.65592 13.7092 9.73169 13.823 9.85488C13.9368 9.97807 14 10.1396 14 10.3073V13.282C14 13.4471 13.9388 13.6063 13.8282 13.7288C13.7177 13.8513 13.5655 13.9285 13.4013 13.9453C13.048 13.982 12.692 14 12.3333 14C6.62667 14 2 9.37333 2 3.66667C2 3.308 2.018 2.952 2.05467 2.59867C2.0715 2.43446 2.14866 2.28235 2.27119 2.17176C2.39373 2.06116 2.55294 1.99996 2.718 2H5.69267C5.86037 1.99998 6.02193 2.06317 6.14512 2.17697C6.26831 2.29077 6.34408 2.44682 6.35733 2.614C6.44148 3.68519 6.75138 4.72645 7.26667 5.66933C7.34498 5.81238 7.36814 5.97916 7.33178 6.13813C7.29541 6.2971 7.20204 6.43723 7.06933 6.532L6.244 7.12133ZM4.56267 6.68333L5.82933 5.77867C5.46986 5.00273 5.22357 4.17923 5.098 3.33333H3.34C3.336 3.444 3.334 3.55533 3.334 3.66667C3.33333 8.63733 7.36267 12.6667 12.3333 12.6667C12.4447 12.6667 12.556 12.6647 12.6667 12.66V10.902C11.8208 10.7764 10.9973 10.5301 10.2213 10.1707L9.31667 11.4373C8.95244 11.2958 8.59867 11.1287 8.258 10.9373L8.21933 10.9153C6.91172 10.1711 5.82885 9.08828 5.08467 7.78067L5.06267 7.742C4.87127 7.40133 4.70419 7.04756 4.56267 6.68333Z" fill="#6B7586"/>
                                        </svg>
                                        <span className='ml-2'>9826611234</span>
                                    </div>
                                    <div className='store-info__call'>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.99998 14.6666C4.31798 14.6666 1.33331 11.682 1.33331 7.99998C1.33331 4.31798 4.31798 1.33331 7.99998 1.33331C11.682 1.33331 14.6666 4.31798 14.6666 7.99998C14.6666 11.682 11.682 14.6666 7.99998 14.6666ZM7.99998 13.3333C9.41447 13.3333 10.771 12.7714 11.7712 11.7712C12.7714 10.771 13.3333 9.41447 13.3333 7.99998C13.3333 6.58549 12.7714 5.22894 11.7712 4.22874C10.771 3.22855 9.41447 2.66665 7.99998 2.66665C6.58549 2.66665 5.22894 3.22855 4.22874 4.22874C3.22855 5.22894 2.66665 6.58549 2.66665 7.99998C2.66665 9.41447 3.22855 10.771 4.22874 11.7712C5.22894 12.7714 6.58549 13.3333 7.99998 13.3333ZM8.66665 7.99998H11.3333V9.33331H7.33331V4.66665H8.66665V7.99998Z" fill="#6B7586"/>
                                        </svg>
                                        <span className='ml-2'>9:00am - 8:00pm </span>
                                    </div>
                                </div>
                                <div className='store-btn__wrap mt-2'>
                                    <div className='store-btn__info' role='button' aria-hidden='true'>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.33332 0.666687V2.03908C4.53714 2.34326 2.34323 4.53717 2.03905 7.33335H0.666656V8.66669H2.03905C2.34323 11.4629 4.53714 13.6568 7.33332 13.961V15.3334H8.66666V13.961C11.4628 13.6568 13.6568 11.4629 13.9609 8.66669H15.3333V7.33335H13.9609C13.6568 4.53717 11.4628 2.34326 8.66666 2.03908V0.666687H7.33332ZM7.99999 3.33335C10.6 3.33335 12.6667 5.40002 12.6667 8.00002C12.6667 10.6 10.6 12.6667 7.99999 12.6667C5.39999 12.6667 3.33332 10.6 3.33332 8.00002C3.33332 5.40002 5.39999 3.33335 7.99999 3.33335ZM7.99999 6.00002C7.46956 6.00002 6.96085 6.21073 6.58578 6.58581C6.2107 6.96088 5.99999 7.46959 5.99999 8.00002C5.99999 8.53046 6.2107 9.03916 6.58578 9.41424C6.96085 9.78931 7.46956 10 7.99999 10C8.53042 10 9.03913 9.78931 9.41421 9.41424C9.78928 9.03916 9.99999 8.53046 9.99999 8.00002C9.99999 7.46959 9.78928 6.96088 9.41421 6.58581C9.03913 6.21073 8.53042 6.00002 7.99999 6.00002Z" fill="#121415"/>
                                        </svg>
                                        <span className='ml-1'>Get Direction</span>
                                    </div>
                                    {['bottom'].map((placement, idx) => (
                                        <ReqCallback key={idx} placement={placement} name={placement} backdrop={true} />
                                    ))}
                                </div>
                            </div>))}
                    </div>
                    <div className='mar-spacer' />
                </> : ''
            }
        </>
    )
}

export default StoreSearch