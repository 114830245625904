import { Link } from '@reach/router';
import React from 'react';

const AddressSearch = () => {

    return (
        <>
            <div className='input-group-location'>
                <Link className='form-control form-control-cart d-flex align-items-center cursor-remove' to='/InputSearch'>
                    <span>Search for drop location</span>
                </Link>
                <span className='form-control-search'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.0259 13.8475L18.5951 17.4158L17.4159 18.595L13.8476 15.0258C12.5199 16.0901 10.8684 16.669 9.16675 16.6666C5.02675 16.6666 1.66675 13.3066 1.66675 9.16663C1.66675 5.02663 5.02675 1.66663 9.16675 1.66663C13.3067 1.66663 16.6667 5.02663 16.6667 9.16663C16.6692 10.8683 16.0903 12.5197 15.0259 13.8475ZM13.3542 13.2291C14.4118 12.1415 15.0025 10.6837 15.0001 9.16663C15.0001 5.94329 12.3892 3.33329 9.16675 3.33329C5.94341 3.33329 3.33341 5.94329 3.33341 9.16663C3.33341 12.3891 5.94341 15 9.16675 15C10.6838 15.0023 12.1417 14.4117 13.2292 13.3541L13.3542 13.2291Z" fill="#121415"/>
                    </svg>
                </span>
            </div>
        </>
    )
}

export default AddressSearch