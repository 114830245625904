import React from 'react';
import RemoveCartExchange from './RemoveCartExchange';


const CartExchangeProduct = () => {
    return (
        <>
            <div className=''>
                <div className='shopping-cart__vas'>
                    <div className='shopping-vas__info'>
                        <div className='shopping-vas__media'>
                            <span>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white"/><g><path d="M26.7663 21.643L32.7645 21.643L32.7645 27.4766L31.0511 27.4766L31.0505 24.4873L23.0705 32.2496L21.8587 31.071L29.8393 23.3094L26.7663 23.3094V21.643ZM15.7999 20.5234L17.5133 20.5234L17.5133 23.5121L25.494 15.7504L26.7057 16.9289L18.7251 24.6906L21.7981 24.6906L21.7981 26.357L15.7999 26.357L15.7999 20.5234Z" fill="#121415"/></g><defs></defs></svg>


                            </span> 
                        </div>
                        <div className='d-flex flex-column'>
                            <div className='shopping-vas__head'>Exchange your old product </div>
                            <div className='shopping-vas__label'>Save upto <span className='new-price exchange-new-price'> ₹24,000 </span> </div>
                        </div>
                    </div>
                    <div className='shopping-vas__close'>
                        {['bottom'].map((placement, idx) => (
                            <RemoveCartExchange key={idx} placement={placement} name={placement} />
                        ))} 
                    </div>
                    <div className='cart-horizontal-line my-2 w-100'></div>
                    <div className='add-cart__vas'>
                        <div className='price-offer mb-0'>
                            <div className='new-price'></div>
                        </div>
                        <button type='button' className='btn btn-dark-button btn-space'>Exchange</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CartExchangeProduct