import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

const CartItemQty = ({...props }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <span role="button" onClick={handleShow} aria-hidden="true">1</span>

            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Select Quantity</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='cart-qty__wrap'>
                        <div className='cart-qty__item active'>1</div>
                        <div className='cart-qty__item'>2</div>
                        <div className='cart-qty__item'>3</div>
                        <div className='cart-qty__item'>4</div>
                        <div className='cart-qty__item'>5</div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default CartItemQty