import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

import keyFeature from '../../Images/product/keyFeature.png';

const ProductKeyFeatures = ({ ...props }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const closeButton = () => setShow(false);

    return (
        <>
            <button 
                type='button' 
                className='features-section'
                onClick={handleShow}
            >
                <div className='feature-icon'>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.99978 2C11.5944 2 14.5851 4.58667 15.2124 8C14.5858 11.4133 11.5944 14 7.99978 14C4.40511 14 1.41444 11.4133 0.787109 8C1.41378 4.58667 4.40511 2 7.99978 2ZM7.99978 12.6667C9.35942 12.6664 10.6787 12.2045 11.7417 11.3568C12.8047 10.509 13.5484 9.32552 13.8511 8C13.5473 6.67554 12.8031 5.49334 11.7402 4.64668C10.6773 3.80003 9.35865 3.33902 7.99978 3.33902C6.64091 3.33902 5.32224 3.80003 4.25936 4.64668C3.19648 5.49334 2.45229 6.67554 2.14844 8C2.45117 9.32552 3.19489 10.509 4.25787 11.3568C5.32085 12.2045 6.64013 12.6664 7.99978 12.6667ZM7.99978 11C7.20413 11 6.44107 10.6839 5.87846 10.1213C5.31585 9.55871 4.99978 8.79565 4.99978 8C4.99978 7.20435 5.31585 6.44129 5.87846 5.87868C6.44107 5.31607 7.20413 5 7.99978 5C8.79543 5 9.55849 5.31607 10.1211 5.87868C10.6837 6.44129 10.9998 7.20435 10.9998 8C10.9998 8.79565 10.6837 9.55871 10.1211 10.1213C9.55849 10.6839 8.79543 11 7.99978 11ZM7.99978 9.66667C8.4418 9.66667 8.86573 9.49107 9.17829 9.17851C9.49085 8.86595 9.66644 8.44203 9.66644 8C9.66644 7.55797 9.49085 7.13405 9.17829 6.82149C8.86573 6.50893 8.4418 6.33333 7.99978 6.33333C7.55775 6.33333 7.13383 6.50893 6.82127 6.82149C6.5087 7.13405 6.33311 7.55797 6.33311 8C6.33311 8.44203 6.5087 8.86595 6.82127 9.17851C7.13383 9.49107 7.55775 9.66667 7.99978 9.66667Z" fill="#121415"/>
                    </svg>
                </div>
                <span className='feature-text'>Show Features</span>
            </button>
            <Offcanvas className='custom-Offcanvas keyFeature' show={show} onHide={handleClose} {...props} backdrop={false} >
                <Offcanvas.Header>
                    <Offcanvas.Title>&nbsp;</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='key-feature-wrapper'>
                        <div className='feature-media__wrap'>
                            <img src={keyFeature} className='img img-fluid' alt='' />
                        </div>
                        <div className='feature-content__wrap'>
                            <h2 className='feature-content__heading'>Key Features</h2>

                            <div className='feature-content__list'>
                                <div className='feature__list'>
                                    <div className='list-media__wrap'>
                                        <span>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.16603 3.5013C1.16603 3.1793 1.43144 2.91797 1.74469 2.91797H12.254C12.5737 2.91797 12.8327 3.17755 12.8327 3.5013V11.668C12.8327 11.99 12.5673 12.2513 12.254 12.2513H1.74469C1.66835 12.2511 1.59279 12.2359 1.52235 12.2065C1.4519 12.1771 1.38797 12.134 1.3342 12.0798C1.28044 12.0256 1.2379 11.9613 1.20904 11.8907C1.18018 11.82 1.16557 11.7443 1.16603 11.668V3.5013ZM2.33269 4.08464V11.0846H11.666V4.08464H2.33269ZM8.16603 9.33463C8.63015 9.33463 9.07527 9.15026 9.40346 8.82207C9.73165 8.49388 9.91603 8.04876 9.91603 7.58464C9.91603 7.12051 9.73165 6.67539 9.40346 6.3472C9.07527 6.01901 8.63015 5.83464 8.16603 5.83464C7.7019 5.83464 7.25678 6.01901 6.92859 6.3472C6.6004 6.67539 6.41603 7.12051 6.41603 7.58464C6.41603 8.04876 6.6004 8.49388 6.92859 8.82207C7.25678 9.15026 7.7019 9.33463 8.16603 9.33463ZM8.16603 10.5013C7.39248 10.5013 6.65061 10.194 6.10363 9.64703C5.55665 9.10005 5.24936 8.35818 5.24936 7.58464C5.24936 6.81109 5.55665 6.06922 6.10363 5.52224C6.65061 4.97526 7.39248 4.66797 8.16603 4.66797C8.93957 4.66797 9.68144 4.97526 10.2284 5.52224C10.7754 6.06922 11.0827 6.81109 11.0827 7.58464C11.0827 8.35818 10.7754 9.10005 10.2284 9.64703C9.68144 10.194 8.93957 10.5013 8.16603 10.5013ZM2.33269 1.16797H5.83269V2.33464H2.33269V1.16797Z" fill="#121415"/>
                                            </svg>
                                        </span>
                                        <span className='feature__text'>23 Mp Triple Camera</span>
                                    </div>
                                    <div className='list-text__wrap'>Photography</div>
                                </div>

                                <div className='feature__list'>
                                    <div className='list-media__wrap'>
                                        <span>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.08268 2.33464V11.668H9.91602V2.33464H4.08268ZM3.49935 1.16797H10.4994C10.6541 1.16797 10.8024 1.22943 10.9118 1.33882C11.0212 1.44822 11.0827 1.59659 11.0827 1.7513V12.2513C11.0827 12.406 11.0212 12.5544 10.9118 12.6638C10.8024 12.7732 10.6541 12.8346 10.4994 12.8346H3.49935C3.34464 12.8346 3.19627 12.7732 3.08687 12.6638C2.97747 12.5544 2.91602 12.406 2.91602 12.2513V1.7513C2.91602 1.59659 2.97747 1.44822 3.08687 1.33882C3.19627 1.22943 3.34464 1.16797 3.49935 1.16797ZM6.99935 9.91797C7.15406 9.91797 7.30243 9.97943 7.41183 10.0888C7.52122 10.1982 7.58268 10.3466 7.58268 10.5013C7.58268 10.656 7.52122 10.8044 7.41183 10.9138C7.30243 11.0232 7.15406 11.0846 6.99935 11.0846C6.84464 11.0846 6.69627 11.0232 6.58687 10.9138C6.47747 10.8044 6.41602 10.656 6.41602 10.5013C6.41602 10.3466 6.47747 10.1982 6.58687 10.0888C6.69627 9.97943 6.84464 9.91797 6.99935 9.91797Z" fill="#121415"/>
                                            </svg>
                                        </span>
                                        <span className='feature__text'>3k AMOLED Display</span>
                                    </div>
                                    <div className='list-text__wrap'>Clarity of Disply, Panda Glass</div>
                                </div>

                                <div className='feature__list'>
                                    <div className='list-media__wrap'>
                                        <span>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.58268 7.0013H9.33268L6.41602 11.0846V8.16797H4.66602L7.58268 4.08464V7.0013ZM6.41602 3.5013H4.08268V11.668H9.91602V3.5013H7.58268V2.33464H6.41602V3.5013ZM5.24935 2.33464V1.7513C5.24935 1.59659 5.31081 1.44822 5.4202 1.33882C5.5296 1.22943 5.67797 1.16797 5.83268 1.16797H8.16602C8.32073 1.16797 8.4691 1.22943 8.5785 1.33882C8.68789 1.44822 8.74935 1.59659 8.74935 1.7513V2.33464H10.4994C10.6541 2.33464 10.8024 2.39609 10.9118 2.50549C11.0212 2.61489 11.0827 2.76326 11.0827 2.91797V12.2513C11.0827 12.406 11.0212 12.5544 10.9118 12.6638C10.8024 12.7732 10.6541 12.8346 10.4994 12.8346H3.49935C3.34464 12.8346 3.19627 12.7732 3.08687 12.6638C2.97747 12.5544 2.91602 12.406 2.91602 12.2513V2.91797C2.91602 2.76326 2.97747 2.61489 3.08687 2.50549C3.19627 2.39609 3.34464 2.33464 3.49935 2.33464H5.24935Z" fill="#121415"/>
                                            </svg>
                                        </span>
                                        <span className='feature__text'>7000 mAh</span>
                                    </div>
                                    <div className='list-text__wrap'>Long Battery Life, Warp Charge</div>
                                </div>

                                <div className='feature__list'>
                                    <div className='list-media__wrap'>
                                        <span>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.50065 4.56764V11.668H10.5007V2.33464H5.73365L3.50065 4.56764ZM2.5049 3.9143L5.25065 1.16797H11.084C11.2387 1.16797 11.3871 1.22943 11.4965 1.33882C11.6059 1.44822 11.6673 1.59659 11.6673 1.7513V12.2513C11.6673 12.406 11.6059 12.5544 11.4965 12.6638C11.3871 12.7732 11.2387 12.8346 11.084 12.8346H2.91732C2.76261 12.8346 2.61424 12.7732 2.50484 12.6638C2.39544 12.5544 2.33398 12.406 2.33398 12.2513V4.32614C2.33402 4.17144 2.3955 4.02309 2.5049 3.91372V3.9143ZM8.75065 2.91797H9.91732V5.2513H8.75065V2.91797ZM7.00065 2.91797H8.16732V5.2513H7.00065V2.91797ZM5.25065 3.5013H6.41732V5.2513H5.25065V3.5013Z" fill="#121415"/>
                                            </svg>

                                        </span>
                                        <span className='feature__text'>Upto 512GB</span>
                                    </div>
                                    <div className='list-text__wrap'>Photography</div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center hide-feature' role="button" onClick={closeButton} aria-hidden="true">
                        Hide Features
                        <span className='ml-1'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.00048 7.05781L11.3005 3.75781L12.2431 4.70048L8.94315 8.00048L12.2431 11.3005L11.3005 12.2431L8.00048 8.94315L4.70048 12.2431L3.75781 11.3005L7.05781 8.00048L3.75781 4.70048L4.70048 3.75781L8.00048 7.05781Z" fill="white"/>
                            </svg>
                        </span>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default ProductKeyFeatures