import React from 'react';
import StoreList from '../Components/StoreLocator/StoreList';

import Banner from '../Images/storeLocator/banner.png';
import { Link } from '@reach/router';

const StoreLocator = () => {

    return (
        <>	
            <div className='store__wrapper'>
                <div 
                    className='store__search'
                    style={{ 
                        backgroundImage:`url(${Banner})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'bottom center',
                        backgroundSize: 'cover',
                    }}
                >
                    <p className='store_info'>Enter pincode to find a store near you</p>
                    <div className='input-group-address'>
                        <Link to='/StoreSearch'>
                            <div className='cart-address__wrap'>
                                <input type="text" className='form-control form-control-cart' placeholder='Eg: 350014'/>
                                <button type='button' className='btn btn-dark-button absolute-cart-input btn-space'>Check</button>
                            </div>
                        </Link>
                    </div>
                </div>
                <StoreList />
                <div className='mar-spacer h-3' />
            </div>
        </>
    )
}

export default StoreLocator