import React from 'react'
import Carousel from "react-multi-carousel";
import productImg from '../../Images/product/productImg1.png';

const CategoryOffer = () => {
    const productsData = [
        {
            "_id": "1",
            "Image": productImg,
            "productName": "Galaxy Watch 4",
            "prductBrand": "The watch that knows you best",
            "productColor": "Awesome Violet",
            "productStorage": {
                "ram": "169GB",
                "Storage": "128GB"
            },
            "productNewPrice": 12559,
            "productOldPrice": 15559,
            "label": "12% Off"
        }, {
            "_id": "2",
            "Image": productImg,
            "productName": "Samsung Buds Pro",
            "prductBrand": "Leave noise out of the conversation",
            "productColor": "Awesome Violet",
            "productStorage": {
                "ram": "8GB",
                "Storage": "128GB"
            },
            "productNewPrice": 89999,
            "productOldPrice": 93559,
            "label": ""
        },
    ];
    const responsive = {
        tablet: {
            breakpoint: { max: 768, min: 567 },
            items: 2,
            partialVisibilityGutter: 0
        },
        mobile: {
            breakpoint: { max: 567, min: 0 },
            items: 1,
            partialVisibilityGutter: 100
        }
    };
    return (
        <>
            <Carousel                
                partialVisible={true}
                arrows={false}
                itemClass="image-item best-offer-phone__wrap"
                responsive={responsive}
            >   
                {productsData.map((RemommendProduct, idx) => {
                    return (
                        <div className='product-carousel-wrapper' key={idx}>
                            {RemommendProduct.label ?
                                <div className='product-label-tag'>{RemommendProduct.label}</div> : null
                            }
                            
                            <div className='carousel-media-wrapper'>
                                <img
                                    draggable={true}
                                    style={{ width: "100%", height: "100%" }}
                                    src={RemommendProduct.Image}
                                    alt={RemommendProduct.productName}
                                    className='img'
                                />
                            </div>
                            <div className='carousel-content-wrapper'>
                                <div className='carousel-product-title'>{RemommendProduct.productName}</div>  
                                <div className='carousel-product-brand'>{RemommendProduct.prductBrand}</div>
                                <div className='flexible'></div>
                                <div className="price-offer">
                                    <div className="new-price">₹43,659</div>
                                    <div className="old-price">₹45,659</div>
                                </div>
                                <div className="price-offer__tags">
                                    <div className='offer-label__tags'>Waterproof</div>
                                    <div className='offer-label__tags'>Calling</div>
                                    <div className='offer-label__tags'>Notifications</div>
                                    <div className='offer-label__tags'>Wireless Charge</div>
                                    <div className='offer-label__tags'>Heartbeat Sensor</div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </>
    )
}

export default CategoryOffer