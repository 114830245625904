import React from 'react';
import Form from 'react-bootstrap/Form';

import call from '../../Images/DIY/DIYHeadphone/1.svg'
import Sports from '../../Images/DIY/DIYHeadphone/2.svg'
import Movies from '../../Images/DIY/DIYHeadphone/3.svg'
import Gaming from '../../Images/DIY/DIYHeadphone/4.svg'
import Travelling from '../../Images/DIY/DIYHeadphone/5.svg'
import Music from '../../Images/DIY/DIYHeadphone/6.svg'


const Step1 = ({ formData, setFormData }) => {
    const availableCategories = [
        {
            _id : 1,
            name: 'Call and meetings',
            status: 'checked',
            Image: call
        }, {
            _id : 2,
            name: 'Sports & Gym',
            status: 'checked',
            Image: Sports
        }, {
            _id : 3,
            name: 'Movies and TV',
            status: 'unchecked',
            Image: Movies
        }, {
            _id : 4,
            name: 'Gaming',
            status: 'unchecked',
            Image: Gaming
        }, {
            _id : 5,
            name: '  Travelling',
            status: 'checked',
            Image: Travelling
        }, {
            _id : 6,
            name: 'Music Production & Recording',
            status: 'unchecked',
            Image: Music
        }
    ];
    return (
        <>
            <div className="sign-up-container">
                <div className="exchange-data">
                    <h3 className='steps__head'>What activities would you use your headphones for?</h3>
                    <p className='steps__info'>Select upto 3 options from below : </p>

                    <div className='filter-tab__lists'>     
                        {availableCategories.map((Data) => (
                            <div className={Data.status === 'checked' ? 'filter-tab__flex changeexchange-div active' : 'filter-tab__flex changeexchange-div'} key={Data._id}>
                                <div className='steps__media'>
                                    <img src={Data.Image} className='img-fluid' alt={Data.name} />
                                </div>
                                <div className='steps__content'>
                                    <label htmlFor="label" className='filter_exchange ml-2'>{Data.name}</label>
                                    <Form.Check 
                                        className='filter__checkbox sort__checkbox' 
                                        aria-label="option 1" 
                                        value={Data}
                                        checked={Data.status === 'checked' ? 'checked' : null}
                                        onChange={(event) =>
                                            setFormData({ ...formData, password: event.target.value })
                                        }
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>                
            </div>
        </>
    )
}

export default Step1
