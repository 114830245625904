import { Link } from '@reach/router';
import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import CompareSearch from './CompareSearch';
import { productsData } from '../../Data';

import Iphone from '../../Images/Compare/iPhone.png'
import Samsung from '../../Images/Compare/Samsung.png';

const Compare = ({ ...props }) => {
    const [ compareSearch, setCompareSearch ] = useState(true);
    const [show, setShow] = useState(false);    
 
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className='product-compare' onClick={handleShow} aria-hidden="true">+ Compare</div>

            <Offcanvas show={show} onHide={handleClose} {...props} className='notify-me__offCanvas-'>
                <Offcanvas.Header closeButton>
                    {compareSearch ?
                        <Offcanvas.Title className='cart-heading'>Compare</Offcanvas.Title> :
                        <Offcanvas.Title className='cart-heading d-flex align-items-center'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.625 6.25058L7.80333 7.42891L5.69 9.54225H16.6667V11.2089H5.69L7.80333 13.3222L6.625 14.5006L2.5 10.3756L6.625 6.25058Z" fill="#121415"/>
                            </svg>
                            <span className='ml-2'>Search</span>
                        </Offcanvas.Title>
                    }
                </Offcanvas.Header>
                <Offcanvas.Body className='p-0'>
                    <div className='' style={{ display: compareSearch ? "block" : "none" }}>
                        <div className='compare__wrap p-3'>
                            <div className='compare__row'>
                                <div className='compare__col'>
                                    <div className='compare__bg' style={{ minHeight: '104px' }}>
                                        <div className='compare__media'>
                                            <img src={Iphone} className='img-fluid' alt='' />
                                        </div>
                                        <div className='compare__content'>
                                            <div className='compare__title'>Apple iPhone 13 Pro</div>
                                            <div className='compare__price'>₹89,999</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='compare__col flex_auto'>
                                    <div className='compare_vs'>VS</div>
                                </div>
                                <div className='compare__col'>
                                    <div className='compare__bg' style={{ minHeight: '104px' }}>
                                        <div className='compare__media' style={{ width: '30px'}}>
                                            <img src={Samsung} className='img-fluid' alt='' />
                                        </div>
                                        <div className='compare__content'>
                                            <div className='compare__title'>Samsung Galaxy FoldZ 5G</div>
                                            <div className='compare__price'>₹81,999</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mar-spacer h-2' />
                            <div className='compare__search'>
                                <h4 className='compare-title'>Select 1 more product to compare</h4>
                                <div className='header-home__search'>
                                    <form className="form">
                                        <div className="form-group">
                                            <div className="input-icon">
                                                <input type="text" className="bannerInput-search" placeholder="Search for mobiles and brands" onClick={() => setCompareSearch(false)}/>
                                                <span className="bannerSearch-icon">
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M14.0262 12.8473L17.5953 16.4157L16.4162 17.5948L12.8478 14.0257C11.5201 15.09 9.86866 15.6689 8.16699 15.6665C4.02699 15.6665 0.666992 12.3065 0.666992 8.1665C0.666992 4.0265 4.02699 0.666504 8.16699 0.666504C12.307 0.666504 15.667 4.0265 15.667 8.1665C15.6694 9.86817 15.0905 11.5196 14.0262 12.8473ZM12.3545 12.229C13.4121 11.1414 14.0027 9.68353 14.0003 8.1665C14.0003 4.94317 11.3895 2.33317 8.16699 2.33317C4.94366 2.33317 2.33366 4.94317 2.33366 8.1665C2.33366 11.389 4.94366 13.9998 8.16699 13.9998C9.68402 14.0022 11.1419 13.4116 12.2295 12.354L12.3545 12.229Z" fill="#999999"/>
                                                    </svg>                                                        
                                                </span>
                                            </div>                                            
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>	
                        <h6 className='p-x-16 recommend__head'>Recommended products</h6>
                        <div className='product-wrapper'>
                            {productsData.slice(0, 2).map(product => (
                                <div className='product-list' key={product._id}>
                                    {product.label ?
                                        <div className='product-label-tag m-h-1'>{product.label}</div> : ''
                                    }
                                    <div className='product-wrapper-flex w-100'>
                                        <div className='product-thumb'>
                                            <img src={Samsung} className='img-fluid img w-100' alt={product.productName} />
                                        </div>
                                        <div className='product-details d-flex align-items-start justify-content-center flex-column'>
                                            <h2>
                                                <Link to='/ProductDetails'>{product.productName}</Link>
                                            </h2>
                                            <p className='product-muted'>
                                                {product.productColor},
                                                {product.productStorage.Storage} +
                                                {product.productStorage.Storage}
                                            </p>
                                            <div className='product-price'>
                                                <div className='new-price'>₹{product.productNewPrice}</div>
                                                <div className='old-price'>₹{product.productOldPrice}</div>
                                                <div className='price-discount'>{product.productOffer}</div>
                                            </div>                                        
                                        </div>
                                        <div className='d-flex align-items-start justify-content-center flex-column'>
                                            <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" />
                                        </div>
                                    </div>

                                    <div className='product-tags mt-0'>
                                        <div className='tag-label'>PhotoGraphy</div>
                                        <div className='tag-label'>Wireless Charge</div>
                                        <div className='tag-label'>Retina Display</div>
                                        <div className='tag-label'>Gaming</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='check-EMI__offers' style={{ display: compareSearch ? "none" : "block" }}>
                        <CompareSearch />
                    </div>
                </Offcanvas.Body>
                {compareSearch ? 
                    <div className='sticky-btn'>
                        <button type='button' className='btn btn-dark-custom w-100'>Compare Now</button>
                    </div> : null
                }   
            </Offcanvas>
        </>
    )
}

export default Compare