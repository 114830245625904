import React from 'react'

const OrderSummary = () => {
    return (
        <>
            <div className='cart-order__summary'>
                <h4 className='heading-order__summary'>
                    <span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.6667 18.3337H3.33333C3.11232 18.3337 2.90036 18.2459 2.74408 18.0896C2.5878 17.9333 2.5 17.7213 2.5 17.5003V2.50033C2.5 2.27931 2.5878 2.06735 2.74408 1.91107C2.90036 1.75479 3.11232 1.66699 3.33333 1.66699H16.6667C16.8877 1.66699 17.0996 1.75479 17.2559 1.91107C17.4122 2.06735 17.5 2.27931 17.5 2.50033V17.5003C17.5 17.7213 17.4122 17.9333 17.2559 18.0896C17.0996 18.2459 16.8877 18.3337 16.6667 18.3337ZM15.8333 16.667V3.33366H4.16667V16.667H15.8333ZM6.66667 7.50033H13.3333V9.16699H6.66667V7.50033ZM6.66667 10.8337H13.3333V12.5003H6.66667V10.8337Z" fill="#121415"/>
                        </svg>
                    </span>Order Summary
                </h4>
                <div className='order-summary__table'>
                    <div className='order-summary__body'>
                        <div className='order-summary__row'>
                            <div className='order-summary__col'>
                                <label htmlFor="label">Samsung Galaxy Z Fold 2 5G</label>
                            </div>
                            <div className='order-summary__col'>
                                <span>₹19,999</span>
                            </div>
                        </div>
                        <div className='order-summary__row'>
                            <div className='order-summary__col'>
                                <label htmlFor="label">Money and Damage Protection</label>
                            </div>
                            <div className='order-summary__col'>
                                <span>₹999</span>
                            </div>
                        </div>
                        <div className='order-summary__row'>
                            <div className='order-summary__col'>
                                <label htmlFor="label">Discount</label>
                            </div>
                            <div className='order-summary__col details-text'>
                                <span>- ₹400</span>
                            </div>
                        </div>
                        <div className='order-summary__row order-summary--dashed-border'>
                            <div className='order-summary__col'>
                                <label htmlFor="label">Watercolor gradient Phone grip holder...</label>
                            </div>
                            <div className='order-summary__col'>
                                <span>₹1,999</span>
                            </div>
                        </div>
                        <div className='order-summary__row'>
                            <div className='order-summary__col'>
                                <label htmlFor="label">Applied Coupon</label>
                            </div>
                            <div className='order-summary__col'>
                                <span>- ₹500</span>
                            </div>
                        </div>
                        <div className='order-summary__row'>
                            <div className='order-summary__col'>
                                <label htmlFor="label">Delivery Charges</label>
                            </div>
                            <div className='order-summary__col details-text'>
                                <span className='text-uppercase'>Free</span>
                            </div>
                        </div>
                    </div>
                    <div className='order-summary__footer'>
                        <div className='order-summary__row'>
                            <div className='order-summary__col'>
                                <span>Total Amount</span>
                            </div>
                            <div className='order-summary__col'>
                                <span>₹22,097</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderSummary