import React, { useState } from 'react';
import { Link } from '@reach/router';
import { CardlessEMIData } from '../../Data';
import emi from '../../Images/product/emi.png';

const CardlessEMI = () => {
    const [ isEligible ] = useState(true);

    return (
        <>
            <div className="emi-info__wrap">
                {isEligible ?
                    (<>
                        <div className="emi__label">Pay easy monthly installments instead of lump-sum amount. No credit card required. </div>
                        <div className='mar-spacer h-0_5' />
                        <div className="emi__specific">
                            {CardlessEMIData.slice(0, 3).map((Cardless) => {
                                return ( 
                                    <div className="emi__lists" key={Cardless._id}>
                                        <div className="emi__content">
                                            <div className="emi__icon">
                                                <img src={Cardless.Img} className='img img-fluid' alt='' />
                                            </div>
                                            <div className='d-flex flex-column'>
                                                <h6>{Cardless.Title}</h6>
                                                <p>{Cardless.EMIInfo}</p>
                                            </div>
                                        </div>
                                        <div className="emi__link">
                                            <Link to='/' className="link-check">Check Eligibility</Link>
                                        </div>
                                    </div>
                                );
                            })}                    
                        </div>
                    </>) : 
                    (
                        <div className='eligible__section EMI__offers'>
                            <div className='EMI__offers-media'>
                                <img src={emi} className='img img-fluid' alt='' />
                            </div>
                            <div className='EMI__offers-text'>
                                <h6>0% downpayment available!</h6>
                                <p>Login to check your eligibility and visit our Finance Center</p>
                            </div>
                            <button type='button' className='btn btn-dark-theme'>Visit Finance Center</button>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default CardlessEMI