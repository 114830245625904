import React,{useState} from 'react';
import { Link } from '@reach/router';

import box1 from '../../Images/CategoryDetails/Order/Arriving_product1.png';
import box2 from '../../Images/CategoryDetails/Order/Arriving_product2.png';

import Helpimg from '../../Images/CategoryDetails/Order/orderdetails/question-answer-line.png'
import servicebox1 from '../../Images/CategoryDetails/Order/orderdetails/order_detail-user.png';

import servicebox2 from '../../Images/CategoryDetails/Order/orderdetails/orderdetail_going.png';

import servicebox3 from '../../Images/CategoryDetails/Order/orderdetails/userdetails-shiled.png';

import Ordermap from '../../Images/CategoryDetails/Order/orderdetails/order-map.png';
import OrderSummary from '../Payment/OrderSummary';
import Union from '../../Images/product/Union.png';
import ProductRecommend from '../Product/ProductRecommend';
import ProductFooter from '../Footer/ProductFooter';
import DownloadImg from '../../Images/CategoryDetails/Order/orderdetails/download_invoice.png';

import CancellationNotPossible from './CancellationNotPossible'

const OrderCanceledDetails = () => {
    const  [cancelorder] = useState("")
    return (
        <>
            <div className='Order_Id'>
                <span className='order-id-txt'>ORDER ID: </span> <span className='order-id-txt'> 983729203490</span>
            </div>
            {cancelorder?(
                <div className='order-este__delivery mb-0'>
                    <div className='address-este____media'>
                        <span>
                            <svg width="35" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.0001 0L23.7883 4.00815L31.609 7.98371L23.7883 11.9919L16.0001 16L8.21189 11.9919L0.391113 7.98371L8.21189 4.00815L16.0001 0Z" fill="#D9AC80"/><path d="M25.7434 14.6313V11.0142L21.833 13.0019V16.6191L23.9837 13.9795L25.7434 14.6313Z" fill="#D7BAA4"/><path d="M16 16.0002V32.0002L31.609 24.0165V7.98389L25.7434 11.0144L25.613 11.0796V14.599L23.9837 13.9798L21.833 16.6193V13.0022L16 16.0002Z" fill="#A67E4F"/><path d="M16.0001 32.0002V16.0002L8.21189 11.992L0.391113 7.98389V24.0165L16.0001 32.0002Z" fill="#926E43"/><path d="M6.25659 4.98583L21.833 13.0021L25.613 11.0795L25.7434 11.0143L10.167 2.99805L8.21179 4.00823L6.25659 4.98583Z" fill="#F2D3BA"/><path d="M23 26C23 22.6863 25.6863 20 29 20C32.3137 20 35 22.6863 35 26C35 29.3137 32.3137 32 29 32C25.6863 32 23 29.3137 23 26Z" fill="#E22900"/><path d="M29.0001 25.2929L31.4751 22.8179L32.1821 23.5249L29.7071 25.9999L32.1821 28.4749L31.4751 29.1819L29.0001 26.7069L26.5251 29.1819L25.8181 28.4749L28.2931 25.9999L25.8181 23.5249L26.5251 22.8179L29.0001 25.2929Z" fill="white"/></svg>
                        </span>
                    </div>
                    <div className='order-este__details'>
                        <h5>Order Cancelled</h5>
                        <div className="ordder_deliverTo__info">
                            <span className="order_info__similarProd">Refund of ₹78,999 has been initiated  &nbsp;</span>
                        </div>
                    </div>
                    <div className='orders-link_wrap'>
                        <Link to='/OrderDetails'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                            </svg>
                        </Link>
                    </div>
                </div>
            ):( 
                <div className='order-este__delivery align-items-start cancelorderbrd'>
                    <div className='address-este____media w-auto d-block'>
                        <span>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5 22C6.977 22 2.5 17.523 2.5 12C2.5 6.477 6.977 2 12.5 2C18.023 2 22.5 6.477 22.5 12C22.5 17.523 18.023 22 12.5 22ZM11.5 11V17H13.5V11H11.5ZM11.5 7V9H13.5V7H11.5Z" fill="#98A0AE"/></svg>
                        </span>
                    </div>
                    <div className='order-este__details'>
                        <div className="ordder_deliverTo__info order-cancelsuccess">
                            <span className="order_info__similarProd">Total Refund amount of ₹78,849 will be refunded to your respective payment method within the next 3-7 days.&nbsp;</span>
                        </div>
                    </div>
                  
                </div>
            ) 
            }

            <div className="order_details-Model">
                <h4 className='Order_Pending-msg'>Items Included</h4>
            </div>

            <div className='order-este__include mb-0'>
                <div className='orderdetails-este____media'>
                    <img src={box1} className='img img-fluid' alt=''/>
                </div>
                <div className='order-include__details'>
                    <h5>Samsung Galaxy Z Fold 2 5G</h5>
                    <div className="ordder_deliverTo__info">
                        <span className="order_info__similarProd">Mystic Purple  &nbsp;</span>
                        <span className="order_info__similarProd">, 256GB + 8GB &nbsp;</span>
                    </div>
                </div>
            </div>
            <div className="order_detail-cart__vas order_detail-mr">
                <div className="shopping-vas__info">
                    <div className="shopping-vas__media">
                        <span>
                            <svg width="24" height="24" viewBox="11 9 25 31" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white"></rect><path d="M15.783 14.826L24 13L32.217 14.826C32.4391 14.8754 32.6377 14.999 32.78 15.1764C32.9224 15.3538 33 15.5745 33 15.802V25.789C32.9999 26.7767 32.756 27.7492 32.2899 28.62C31.8238 29.4908 31.1499 30.2331 30.328 30.781L24 35L17.672 30.781C16.8503 30.2332 16.1765 29.4911 15.7103 28.6205C15.2442 27.7498 15.0002 26.7776 15 25.79V15.802C15 15.5745 15.0776 15.3538 15.22 15.1764C15.3623 14.999 15.5609 14.8754 15.783 14.826ZM25 22V17L20 24H23V29L28 22H25Z" fill="#121415"></path></svg>
                        </span>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="shopping-vas__head">Money and Damage Protection (MAD) </div>
                        <div className="shopping-vas__label">1 Year Protection</div>
                    </div>
                </div>                
                                
                                    
            </div>
            <div className='order-este__include order_incluid-p mb-0'>
                <div className='orderdetails-este____media'>
                    <img src={box2} className='img img-fluid' alt='' />
                </div>
                <div className='order-include__details'>
                    <h5>Watercolor gradient Phone grip holder and stand</h5>
                    <div className="ordder_deliverTo__info">
                        <span className="order_info__similarProd">Qty :    &nbsp;</span>
                        <span className="order_info__similarProd"> 1 &nbsp;</span>
                    </div>
                </div>
            </div>
            <div className="mar-spacer bg-theme"></div>
            <div className="order_details-Model need_help">
                <div className="need_help__img">
                    <img src={Helpimg} alt="need_help__img"  className='img-fluid'/>
                    <h4 className='Order_Pending-msg'>Need help with order?</h4>
                </div>
                <p className='order-cancellationtxt'>Order cancellation window open till the order is dispatched</p>
                {['bottom'].map((placement, idx) => (
                    <CancellationNotPossible key={idx} placement={placement} />
                ))}

            </div>
            <div className="mar-spacer bg-theme"></div>
            <div className="order-details_service_container">
                <div className="delivery-service_heading">
                    <h5 className='deliveryser_heading order-free-service-mb'>Free Services</h5>
                    <div className='service_categoarybox'>
                        <img src={servicebox1} alt="" className='img' />
                        <p  className='order_Service-para'>Delivery by expert.</p>

                    </div>
                    <div className='service_categoarybox'>
                        <img src={servicebox3} alt="" className='img' />
                        <p className='order_Service-para'>Tempered Glass Installation at home</p>

                    </div>
                    <div className='service_categoarybox'>
                        <img src={servicebox2} alt=""className='img' />
                        <p className='order_Service-para'>Data Transfer at home</p>

                    </div>
                
                </div>
            </div>
            <div className="mar-spacer bg-theme"></div>
            <div className="payment-details_service_container">
                <div className="delivery-service_heading">
                    <h5 className='deliveryser_heading order-payment-service-mb'>
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99984 18.3332C5.39734 18.3332 1.6665 14.6023 1.6665 9.99984C1.6665 5.39734 5.39734 1.6665 9.99984 1.6665C14.6023 1.6665 18.3332 5.39734 18.3332 9.99984C18.3332 14.6023 14.6023 18.3332 9.99984 18.3332ZM9.99984 16.6665C11.7679 16.6665 13.4636 15.9641 14.7139 14.7139C15.9641 13.4636 16.6665 11.7679 16.6665 9.99984C16.6665 8.23173 15.9641 6.53603 14.7139 5.28579C13.4636 4.03555 11.7679 3.33317 9.99984 3.33317C8.23173 3.33317 6.53603 4.03555 5.28579 5.28579C4.03555 6.53603 3.33317 8.23173 3.33317 9.99984C3.33317 11.7679 4.03555 13.4636 5.28579 14.7139C6.53603 15.9641 8.23173 16.6665 9.99984 16.6665Z" fill="#121415"/><path d="M10.8044 8.77698C10.7504 9.34387 10.5556 9.80175 10.2199 10.1506C9.88426 10.4995 9.38079 10.7306 8.70949 10.844L11.2153 14.1668H10L7.5 10.8636L7.61574 9.90859H8.14815C8.33719 9.90859 8.50309 9.89987 8.64583 9.88242C8.78858 9.86498 8.91204 9.841 9.0162 9.81047C9.12423 9.77559 9.21682 9.73416 9.29398 9.68619C9.37114 9.63386 9.44252 9.57499 9.5081 9.50958C9.67786 9.32643 9.78781 9.08223 9.83796 8.77698H7.5V7.82198H9.82639C9.79552 7.65192 9.74537 7.50801 9.67593 7.39027C9.61034 7.26817 9.52353 7.15915 9.41551 7.06322C9.30748 6.96728 9.16474 6.89751 8.98727 6.8539C8.81366 6.8103 8.58025 6.78849 8.28704 6.78849H7.5V5.8335H12.5V6.78849H10.2778C10.4128 6.89751 10.5247 7.04142 10.6134 7.2202C10.7022 7.39899 10.7639 7.59959 10.7986 7.82198H12.5V8.77698H10.8044Z" fill="#121415"/></svg>

                        </span>
                        
                        Payment method</h5>
                    <div className='order-payment_para'>
                        <span className='payment-despo'>UPI payment from nanda.rohan@okicici</span>
                    </div>                  
            
                </div>
                <div className="ordercancel_refundinitiate">
                    <p>Refund of ₹78,999 has been initiated</p>

                    <p>Total Refund amount will be refunded to your respective payment method within the next 3-7 days.</p>
                </div>

            </div>
            <div className="mar-spacer bg-theme"></div>
            <div className="order_addresscontainer">
                <div className='orderdetails-address__head'>
                    <div className="order-map">
                        <img src={Ordermap} alt="order-map" />
                    </div>
                    <h6>Delivery Address</h6>
                </div>
                <div className='loggedIn__list order_detail-address'>               
                    <div className='loggedIn__label'>Rohan Nanda <span className='default__address'>(Default)</span></div>
                    <div className="btn btn-login btn-tag">Home</div>
                    <div className='cart-address__section'>
                        <div className='loggedIn-address__label'>
                                    103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103
                        </div>
                        <div className='loggedIn-address__contact' id='mobile'>
                            <label htmlFor="mobile">Mobile : </label>
                            <span>7382901648</span>
                        </div>
                    </div>
                </div>

            </div>

            <div className="mar-spacer bg-theme"></div>
            <div className='payment-order__summary'>
                <OrderSummary />
                <div className='payment-order__media'>
                    <img src={Union} className='img img-fluid w-100' alt='' />
                </div>
            </div>


            <div className="order_details-btns">
                <button type="button" className="btn btn-block btn-dark-outline mrg16">
                    <span className='download_invoice'>
                        <img src={DownloadImg} alt="Download_img" />
                    </span>
                    <span className="ml-1">Download Invoice</span>
                </button>
                <button type="button" className="btn btn-block btn-dark-custom">Back to Home</button>
            
            </div>
            <div className='mar-spacer h-3'></div>
            <div className='orderdetails_recomanded p-0 m-0'>  
                <h4 className='specification-heading p-x-16'>Sangeetha Recommends</h4>
                <div className='ml--0'>
                    <ProductRecommend deviceType="mobile"/>
                </div>
            </div>
            <ProductFooter />



        </>
    )
}

export default OrderCanceledDetails