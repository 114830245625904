import React from 'react';
import { Link } from "@reach/router";
import img1 from '../../Images/Home/Gadget1.png'
import img2 from '../../Images/Home/Gadget2.png'
import img3 from '../../Images/Home/Gadget3.png'

const SmartGadgets = () => {
    const DealsData = [
        {   
            _Id: 1,
            Image: img1,
            Name: 'Smart Gadgets for Home',
            TextInfo: 'Smart Lights, Security Camers, Speakers and much more'
        }, {   
            _Id: 2,
            Image: img2,
            Name: 'Smart Gadgets for Kitchen',
            TextInfo: 'Smart Lights, Security Camers, Speakers and much more'
        }, {   
            _Id: 3,
            Image: img3,
            Name: 'Smart Gadgets for Office',
            TextInfo: 'Smart Lights, Security Camers, Speakers and much more'
        }
    ];
    return (
        <>
            <div className='flex-container__wrap p-x-16'>
                {DealsData.map((Data) => (
                    <div className='flex-list__box' key={Data._Id}>
                        <div className='flex-box__media'>
                            <img src={Data.Image} className='img-fluid' alt='' />
                        </div>
                        <div className='flex-box__content'>
                            <h6 className='flex-box__heading'>{Data.Name}</h6>
                            <p className='flex-box__text'>{Data.TextInfo}</p>
                            <Link to='/' className='btn-category btn__spacer bg-transparent' style={{ 'width' : 'auto' }}>Explore</Link>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default SmartGadgets