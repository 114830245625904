import React from 'react';
import Form from 'react-bootstrap/Form';

import HDFCLogo from '../../../Images/product/emi/HDFCLogo.png';
import IciciLogo from '../../../Images/product/emi/IciciLogo.png';
import AxisLogo from '../../../Images/product/emi/AxisLogo.png';
import SBI from '../../../Images/product/emi/SBI.png';

const Step1 = () => {
    return (
        <>
            <div className='input-group-banks input-group-location mb-4'>
                <input type='number' className='form-control form-control-cart' placeholder='Search for banks' />
                <span className='form-control-search'>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.0155 8.3085L11.157 10.4495L10.4495 11.157L8.3085 9.0155C7.51187 9.65411 6.521 10.0015 5.5 10C3.016 10 1 7.984 1 5.5C1 3.016 3.016 1 5.5 1C7.984 1 10 3.016 10 5.5C10.0015 6.521 9.65411 7.51187 9.0155 8.3085ZM8.0125 7.9375C8.64706 7.28494 9.00143 6.41021 9 5.5C9 3.566 7.4335 2 5.5 2C3.566 2 2 3.566 2 5.5C2 7.4335 3.566 9 5.5 9C6.41021 9.00143 7.28494 8.64706 7.9375 8.0125L8.0125 7.9375Z" fill="#98A0AE"/>
                    </svg>
                </span>
            </div>
            <h6 className='heading-group-banks'>Credit Card Emi</h6>
            <div className='bank-list__wrap'>
                <div className='upi-payment__list justify-content-between flex-row align-items-center'>
                    <div className='upi-payment__wrap'>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                        <div className='upi-payment__media'>
                            <img src={AxisLogo} className='img img-fluid' alt='' />
                        </div>
                        <span className='upi-payment__text'>Axis Bank</span>
                    </div>
                    <div className='emi-card-intrest'>@14%</div>
                </div>
                <div className='upi-payment__list justify-content-between flex-row align-items-center'>
                    <div className='upi-payment__wrap'>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                        <div className='upi-payment__media'>
                            <img src={IciciLogo} className='img img-fluid' alt='' />
                        </div>
                        <span className='upi-payment__text'>ICICI Bank</span>
                    </div>
                    <div className='emi-card-intrest'>@15%</div>
                </div>
                <div className='upi-payment__list justify-content-between flex-row align-items-center'>
                    <div className='upi-payment__wrap'>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                        <div className='upi-payment__media'>
                            <img src={SBI} className='img img-fluid' alt='' />
                        </div>
                        <span className='upi-payment__text'>SBI Bank</span>
                    </div>
                    <div className='emi-card-intrest'>@12%</div>
                </div>
                <div className='upi-payment__list justify-content-between flex-row align-items-center'>
                    <div className='upi-payment__wrap'>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                        <div className='upi-payment__media'>
                            <img src={HDFCLogo} className='img img-fluid' alt='' />
                        </div>
                        <span className='upi-payment__text'>HDFC Bank</span>
                    </div>
                    <div className='emi-card-intrest'>@11%</div>
                </div>
                <div className='mar-spacer h-1_2' />
            </div>    
            <h6 className='heading-group-banks'>Debit Card Emi</h6>   
            <div className='bank-list__wrap'>
                <div className='upi-payment__list justify-content-between flex-row align-items-center'>
                    <div className='upi-payment__wrap'>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                        <div className='upi-payment__media'>
                            <img src={AxisLogo} className='img img-fluid' alt='' />
                        </div>
                        <span className='upi-payment__text'>Axis Bank</span>
                    </div>
                    <div className='emi-card-intrest'>@14%</div>
                </div>
                <div className='upi-payment__list justify-content-between flex-row align-items-center'>
                    <div className='upi-payment__wrap'>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                        <div className='upi-payment__media'>
                            <img src={IciciLogo} className='img img-fluid' alt='' />
                        </div>
                        <span className='upi-payment__text'>ICICI Bank</span>
                    </div>
                    <div className='emi-card-intrest'>@15%</div>
                </div>
            </div>    
            <div className='mar-spacer h-3 mt-4' />  
        </>
    )
}

export default Step1