import React, { useState }from 'react';
import RemoveCartItem from './RemoveCartItem';
import RemoveCartItems from './RemoveCartItems';
import CartExchangeOffer from '../Exchange/CartExchangeOffer';
import CartExchangeProduct  from '../Exchange/CartExchangeProduct'
import CartItemQty from './CartItemQty';

import shoppingCart from '../../Images/product/shoppingCart.png';
import  productImg from '../../Images/product/productImg.png';
import shoppingCart2 from '../../Images/CategoryDetails/Cart/iphone13-pro.png'

const CartItems = ({ isMADAdded }) => {
    const [isExchange]=useState(true)

    return (
        <>
            <div className='shopping-cart___wrap'>
                <div className='shopping-cart__list'>
                    <div className='shopping-cart___media'>
                        <img src={shoppingCart2} className='img img-fluid' alt='' />
                    </div>
                    <div className='shopping-cart__info'>
                        <h4>IPhone 13 Pro</h4>
                        <p>
                            <span>Purple </span>,
                            <span>256GB </span> + 
                            <span> 8GB</span>
                        </p>
                        <div className='shopping-cart__qty'>
                            <span>Qty :</span> 
                            {['bottom'].map((placement, idx) => (
                                <CartItemQty key={idx} placement={placement} name={placement} />
                            ))}
                        </div>
                        <div className='price-offer addToCart-offcanvas'>
                            <div className='new-price'>₹79,999</div>
                            <div className='old-price'>₹24,559</div>
                        </div>
                        <div className="deliverTo__info">
                            <span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3337 5.33337H13.3337L15.3337 8.03737V12H13.977C13.8977 12.5562 13.6205 13.0652 13.1961 13.4333C12.7718 13.8015 12.2288 14.0042 11.667 14.0042C11.1052 14.0042 10.5622 13.8015 10.1379 13.4333C9.71351 13.0652 9.43624 12.5562 9.35699 12H5.97699C5.89774 12.5562 5.62047 13.0652 5.19611 13.4333C4.77175 13.8015 4.2288 14.0042 3.66699 14.0042C3.10518 14.0042 2.56223 13.8015 2.13787 13.4333C1.71352 13.0652 1.43625 12.5562 1.35699 12H0.666992V4.00004C0.666992 3.82323 0.73723 3.65366 0.862254 3.52864C0.987279 3.40361 1.15685 3.33337 1.33366 3.33337H10.667C10.8438 3.33337 11.0134 3.40361 11.1384 3.52864C11.2634 3.65366 11.3337 3.82323 11.3337 4.00004V5.33337ZM11.3337 6.66671V8.66671H14.0003V8.47671L12.6617 6.66671H11.3337Z" fill="#C4CAD0"/>
                                </svg>
                            </span>
                            <span className="info__similarProd ml-1">Delivery by &nbsp;</span>
                            <span className="label__similarProd">Monday, 24 Jan.</span>
                        </div>
                    </div>
                </div>
                <div className='mar-spacer h-1_2' />
                <div className='shopping-cart__btn'>
                    {['bottom'].map((placement, idx) => (
                        <RemoveCartItems key={idx} placement={placement} name={placement} />
                    ))} 
                    <button type='button' className='btn btn-save-for-later btn-cart-h'>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.53333 2.40002H12C12.1414 2.40002 12.2771 2.45621 12.3771 2.55623C12.4771 2.65625 12.5333 2.79191 12.5333 2.93336V13.143C12.5334 13.1906 12.5207 13.2375 12.4965 13.2786C12.4723 13.3197 12.4375 13.3535 12.3958 13.3766C12.3541 13.3997 12.3069 13.4112 12.2593 13.4099C12.2116 13.4085 12.1652 13.3945 12.1248 13.3691L8.26667 10.9494L4.40853 13.3686C4.3682 13.3939 4.32181 13.408 4.27419 13.4093C4.22657 13.4107 4.17946 13.3992 4.13776 13.3762C4.09606 13.3532 4.06128 13.3194 4.03706 13.2784C4.01283 13.2374 4.00004 13.1906 4 13.143V2.93336C4 2.79191 4.05619 2.65625 4.15621 2.55623C4.25623 2.45621 4.39188 2.40002 4.53333 2.40002ZM11.4667 3.46669H5.06667V11.6971L8.26667 9.69122L11.4667 11.6971V3.46669Z" fill="#121415"/>
                            </svg>                                      
                        </span>
                        <span className='ml-1'>Save For Later</span>
                    </button>
                </div>
                <div className='mar-spacer h-3' />
                <div className='shopping-cart__list'>
                    <div className='shopping-cart___media'>
                        <img src={shoppingCart} className='img img-fluid' alt='' />
                    </div>
                    <div className='shopping-cart__info'>
                        <h4>Samsung Galaxy Z Fold 2 5G</h4>
                        <p>
                            <span>Mystic Purple</span>,
                            <span>256GB</span> + 
                            <span> 8GB</span>
                        </p>
                        <div className='shopping-cart__qty'>
                            <span>Qty :</span> 
                            {['bottom'].map((placement, idx) => (
                                <CartItemQty key={idx} placement={placement} name={placement} />
                            ))}
                        </div>
                        <div className='price-offer addToCart-offcanvas'>
                            <div className='new-price'>₹19,999</div>
                            <div className='old-price'>₹24,559</div>
                        </div>
                        <div className="deliverTo__info">
                            <span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3337 5.33337H13.3337L15.3337 8.03737V12H13.977C13.8977 12.5562 13.6205 13.0652 13.1961 13.4333C12.7718 13.8015 12.2288 14.0042 11.667 14.0042C11.1052 14.0042 10.5622 13.8015 10.1379 13.4333C9.71351 13.0652 9.43624 12.5562 9.35699 12H5.97699C5.89774 12.5562 5.62047 13.0652 5.19611 13.4333C4.77175 13.8015 4.2288 14.0042 3.66699 14.0042C3.10518 14.0042 2.56223 13.8015 2.13787 13.4333C1.71352 13.0652 1.43625 12.5562 1.35699 12H0.666992V4.00004C0.666992 3.82323 0.73723 3.65366 0.862254 3.52864C0.987279 3.40361 1.15685 3.33337 1.33366 3.33337H10.667C10.8438 3.33337 11.0134 3.40361 11.1384 3.52864C11.2634 3.65366 11.3337 3.82323 11.3337 4.00004V5.33337ZM11.3337 6.66671V8.66671H14.0003V8.47671L12.6617 6.66671H11.3337Z" fill="#C4CAD0"/>
                                </svg>
                            </span>
                            <span className="info__similarProd ml-1">Delivery by &nbsp;</span>
                            <span className="label__similarProd">Monday, 24 Jan.</span>
                            <span className="deliver-type text-uppercase">Free</span>
                        </div>
                    </div>
                </div>
                {isMADAdded ?
                    <div className='shopping-cart__vas'>
                        <div className='shopping-vas__info'>
                            <div className='shopping-vas__media'>
                                <span>
                                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="48" height="48" fill="white"/>
                                        <path d="M15.783 14.826L24 13L32.217 14.826C32.4391 14.8754 32.6377 14.999 32.78 15.1764C32.9224 15.3538 33 15.5745 33 15.802V25.789C32.9999 26.7767 32.756 27.7492 32.2899 28.62C31.8238 29.4908 31.1499 30.2331 30.328 30.781L24 35L17.672 30.781C16.8503 30.2332 16.1765 29.4911 15.7103 28.6205C15.2442 27.7498 15.0002 26.7776 15 25.79V15.802C15 15.5745 15.0776 15.3538 15.22 15.1764C15.3623 14.999 15.5609 14.8754 15.783 14.826ZM25 22V17L20 24H23V29L28 22H25Z" fill="#121415"/>
                                    </svg>
                                </span>
                            </div>
                            <div className='d-flex flex-column'>
                                <div className='shopping-vas__head'>Money and Damage Protection (MAD) </div>
                                <div className='shopping-vas__label'>1 Year Protection</div>
                                <div className='price-offer mb-0'>
                                    <div className='new-price'>₹19,999</div>
                                </div>
                            </div>
                        </div>
                        <div className='shopping-vas__close'>
                            {['bottom'].map((placement, idx) => (
                                <RemoveCartItem key={idx} placement={placement} name={placement} />
                            ))} 
                        </div>
                    </div> :
                    <div className=''>
                        <div className='shopping-cart__vas'>
                            <div className='shopping-vas__info'>
                                <div className='shopping-vas__media'>
                                    <span>
                                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="48" height="48" fill="white"/>
                                            <path d="M15.783 14.826L24 13L32.217 14.826C32.4391 14.8754 32.6377 14.999 32.78 15.1764C32.9224 15.3538 33 15.5745 33 15.802V25.789C32.9999 26.7767 32.756 27.7492 32.2899 28.62C31.8238 29.4908 31.1499 30.2331 30.328 30.781L24 35L17.672 30.781C16.8503 30.2332 16.1765 29.4911 15.7103 28.6205C15.2442 27.7498 15.0002 26.7776 15 25.79V15.802C15 15.5745 15.0776 15.3538 15.22 15.1764C15.3623 14.999 15.5609 14.8754 15.783 14.826ZM25 22V17L20 24H23V29L28 22H25Z" fill="#121415"/>
                                        </svg>
                                    </span>
                                </div>
                                <div className='d-flex flex-column'>
                                    <div className='shopping-vas__head'>Money and Damage Protection (MAD) </div>
                                    <div className='shopping-vas__label'>1 Year Protection</div>
                                </div>
                            </div>
                            <div className='shopping-vas__close'>
                                {['bottom'].map((placement, idx) => (
                                    <RemoveCartItem key={idx} placement={placement} name={placement} />
                                ))} 
                            </div>
                            <div className='cart-horizontal-line my-2 w-100'></div>
                            <div className='add-cart__vas'>
                                <div className='price-offer mb-0'>
                                    <div className='new-price'>₹999</div>
                                </div>
                                <button type='button' className='btn btn-dark-button btn-space'>Add</button>
                            </div>
                        </div>
                    </div>
                }
                {isExchange ?
                    <CartExchangeOffer/> :
                    <CartExchangeProduct/>
                }
                <div className='mar-spacer h-1_2' />
                <div className='shopping-cart__btn'>
                    {['bottom'].map((placement, idx) => (
                        <RemoveCartItems key={idx} placement={placement} name={placement} />
                    ))} 
                    <button type='button' className='btn btn-save-for-later btn-cart-h'>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.53333 2.40002H12C12.1414 2.40002 12.2771 2.45621 12.3771 2.55623C12.4771 2.65625 12.5333 2.79191 12.5333 2.93336V13.143C12.5334 13.1906 12.5207 13.2375 12.4965 13.2786C12.4723 13.3197 12.4375 13.3535 12.3958 13.3766C12.3541 13.3997 12.3069 13.4112 12.2593 13.4099C12.2116 13.4085 12.1652 13.3945 12.1248 13.3691L8.26667 10.9494L4.40853 13.3686C4.3682 13.3939 4.32181 13.408 4.27419 13.4093C4.22657 13.4107 4.17946 13.3992 4.13776 13.3762C4.09606 13.3532 4.06128 13.3194 4.03706 13.2784C4.01283 13.2374 4.00004 13.1906 4 13.143V2.93336C4 2.79191 4.05619 2.65625 4.15621 2.55623C4.25623 2.45621 4.39188 2.40002 4.53333 2.40002ZM11.4667 3.46669H5.06667V11.6971L8.26667 9.69122L11.4667 11.6971V3.46669Z" fill="#121415"/>
                            </svg>                                      
                        </span>
                        <span className='ml-1'>Save For Later</span>
                    </button>
                </div>
                <div className='mar-spacer h-3' />
            </div>

            <div className='shopping-cart___wrap'>
                <div className='shopping-cart__list'>
                    <div className='shopping-cart___media'>
                        <span className='few-left-tag'>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.5 5H10L5.5 11.5V7H2L6.5 0.5V5Z" fill="white"/>
                            </svg>
                            <span>Only few left </span>
                        </span>
                        <img src={productImg} className='img img-fluid' alt='' />
                    </div>
                    <div className='shopping-cart__info'>
                        <h4>IPhone 13 Pro</h4>
                        <p>
                            <span>Purple</span>,
                            <span>256GB</span> + 
                            <span> 8GB</span>
                        </p>
                        <div className='shopping-cart__qty'>
                            <span>Qty :</span> 
                            {['bottom'].map((placement, idx) => (
                                <CartItemQty key={idx} placement={placement} name={placement} />
                            ))}
                        </div>
                        <div className='price-offer addToCart-offcanvas'>
                            <div className='new-price'>₹19,999</div>
                            <div className='old-price'>₹24,559</div>
                        </div>
                        <div className="deliverTo__info">
                            <span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3337 5.33337H13.3337L15.3337 8.03737V12H13.977C13.8977 12.5562 13.6205 13.0652 13.1961 13.4333C12.7718 13.8015 12.2288 14.0042 11.667 14.0042C11.1052 14.0042 10.5622 13.8015 10.1379 13.4333C9.71351 13.0652 9.43624 12.5562 9.35699 12H5.97699C5.89774 12.5562 5.62047 13.0652 5.19611 13.4333C4.77175 13.8015 4.2288 14.0042 3.66699 14.0042C3.10518 14.0042 2.56223 13.8015 2.13787 13.4333C1.71352 13.0652 1.43625 12.5562 1.35699 12H0.666992V4.00004C0.666992 3.82323 0.73723 3.65366 0.862254 3.52864C0.987279 3.40361 1.15685 3.33337 1.33366 3.33337H10.667C10.8438 3.33337 11.0134 3.40361 11.1384 3.52864C11.2634 3.65366 11.3337 3.82323 11.3337 4.00004V5.33337ZM11.3337 6.66671V8.66671H14.0003V8.47671L12.6617 6.66671H11.3337Z" fill="#C4CAD0"/>
                                </svg>
                            </span>
                            <span className="info__similarProd ml-1">Delivery in &nbsp;</span>
                            <span className="label__similarProd">2 Hrs.</span>
                            <span className="deliver-type text-uppercase ml-1">Free</span>
                        </div>
                    </div>
                </div>
                <div className='mar-spacer h-1_2' />
                <div className='shopping-cart__btn'>
                    {['bottom'].map((placement, idx) => (
                        <RemoveCartItems key={idx} placement={placement} name={placement} />
                    ))} 
                    <button type='button' className='btn btn-save-for-later btn-cart-h'>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.53333 2.40002H12C12.1414 2.40002 12.2771 2.45621 12.3771 2.55623C12.4771 2.65625 12.5333 2.79191 12.5333 2.93336V13.143C12.5334 13.1906 12.5207 13.2375 12.4965 13.2786C12.4723 13.3197 12.4375 13.3535 12.3958 13.3766C12.3541 13.3997 12.3069 13.4112 12.2593 13.4099C12.2116 13.4085 12.1652 13.3945 12.1248 13.3691L8.26667 10.9494L4.40853 13.3686C4.3682 13.3939 4.32181 13.408 4.27419 13.4093C4.22657 13.4107 4.17946 13.3992 4.13776 13.3762C4.09606 13.3532 4.06128 13.3194 4.03706 13.2784C4.01283 13.2374 4.00004 13.1906 4 13.143V2.93336C4 2.79191 4.05619 2.65625 4.15621 2.55623C4.25623 2.45621 4.39188 2.40002 4.53333 2.40002ZM11.4667 3.46669H5.06667V11.6971L8.26667 9.69122L11.4667 11.6971V3.46669Z" fill="#121415"/>
                            </svg>                                      
                        </span>
                        <span className='ml-1'>Save For Later</span>
                    </button>
                </div>
                <div className='mar-spacer h-3' />
            </div>
        </>
    )
}

export default CartItems