import { Link } from '@reach/router';
import React from 'react';
import ProductList from '../../Components/Product/ProductList'

const ResultList = () => {
    return (
        <>
            <div className='diy-result-heading'>Based on what you’ve told us, here is what we recommend</div>
            <div className='mar-spacer h-1_1' />
            <ProductList />
            <div className='diy-result-bg'>
                <div className='selected-diy-data'>
                    <div className='selected-diy-list'>
                        <span>Activities: </span>
                        <div className='selected-tags-list'>
                            <span className='selected-tags'>Photography</span>
                            <span className='selected-tags'>Social Networking</span>
                            <span className='selected-tags'>Music</span>
                        </div>
                    </div>
                    <div className='selected-diy-list'>
                        <span>Screen Size: </span>
                        <div className='selected-tags-list'>
                            <span className='selected-tags'>Small</span>
                            <span className='selected-tags'>Medium</span>
                        </div>
                    </div>
                    <div className='selected-diy-list'>
                        <span>Budget: </span>
                        <div className='selected-tags-list'>
                            <span className='selected-tags'>₹20,000 - ₹29,999</span>
                            <span className='selected-tags'>₹30,999 - ₹39,999</span>
                        </div>
                    </div>
                </div>
                <Link to='/DIY' className='btn btn-cart-outline w-100 bg-transparent'>Retake Quiz</Link>
            </div>
        </>
    )
}

export default ResultList
