import React from 'react';
import Carousel from "react-multi-carousel";
import { Link } from '@reach/router';
import DealsBanner from '../../Images/Home/DealsBanner.png';

const HottestDeals = () => {
    const ExclusiveData = [
        {   
            _Id: 1,
            Image: DealsBanner,
            Name: 'OPPO Enco Buds',
            TextInfo: 'Improve your gaming experience',
            Tag: 'Newly Launched',
            theme: 'light'
        }, {   
            _Id: 2,
            Image: DealsBanner,
            Name: 'Oneplus Nord 2 5G',
            TextInfo: 'Setup your Game',
            Tag: 'Newly Launched',
            theme: 'dark'
        }
    ];
    const responsive = {
        tablet: {
            breakpoint: { max: 768, min: 468 },
            items: 2,
            partialVisibilityGutter: 0
        },
        mobile: {
            breakpoint: { max: 468, min: 0 },
            items: 1,
            partialVisibilityGutter: 20
        }
    };
    return (
        <>
            <Carousel                
                partialVisible={true}
                arrows={false}
                itemClass="carousel-image-item"
                responsive={responsive}
            >   
                {ExclusiveData.map((ProductBox) => {
                    return (
                        <div 
                            className='exclusive-items-wrapper deals-items-wrapper m-l-16' 
                            key={ProductBox._Id}
                            style={{ 
                                backgroundImage:`url(${ProductBox.Image})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'right center',
                                backgroundSize: 'cover',
                            }}
                        >
                            {/* CLASS for dark theme 'dark-text__wrap' */}
                            <div className={ProductBox.theme === 'dark' ? 'exclusive-content-wrapper dark-text__wrap' : 'exclusive-content-wrapper'}>
                                <div className="exclusive-text">
                                    <div className='product-label-tag'>{ProductBox.Tag}</div>
                                    <h6 className="exclusive-head">{ProductBox.Name}</h6>
                                    <div className='exclusive-label'>{ProductBox.TextInfo}</div>
                                    <Link to='/' className='btn btn-dark-custom'>Explore</Link>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </>
    )
}

export default HottestDeals