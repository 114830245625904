import React from 'react';
import OrderReplaceMentTrackStrap from './OrderReplaceMentTrackStrap'
import Ontheway from '../../../Images/CategoryDetails/Order/orderdetails/Ontheway.png';
import NonTrackshipMentstatus from './NonTrackshipMentstatus'
import OrderCanceledStatus from '../OrderStatus/OrderCanceledStatus'





const OrderTrackerStatus = () => {
    return (
        <>
            <div className="order-track__list"><div className="order-track_info order-cancelled__text "><div className="order-placed__text">1. Replacement, Sangeetha geography</div></div></div>
            <div className='order-este__delivery'>
                <div className='address-este____media'>
                    <span>
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8ZM24 38C16.268 38 10 31.732 10 24C10 16.268 16.268 10 24 10C31.732 10 38 16.268 38 24C38 31.732 31.732 38 24 38Z" fill="black"/><path d="M27.71 14.2891L26.29 15.7091L28.59 17.9991H18C16.8954 17.9991 16 18.8945 16 19.9991V22.9991H18V19.9991H28.59L26.3 22.2891L27.71 23.6991L31.71 19.6991C32.0977 19.309 32.0977 18.6791 31.71 18.2891L27.71 14.2891Z" fill="black"/><path d="M30.0008 28.0008H19.4108L21.7008 25.7108L20.2908 24.3008L16.2908 28.3008C15.9031 28.6908 15.9031 29.3207 16.2908 29.7108L20.2908 33.7108L21.7008 32.3008L19.4108 30.0008H30.0008C31.1054 30.0008 32.0008 29.1053 32.0008 28.0008V25.0008H30.0008V28.0008Z" fill="black"/></svg>
                    </span>
                </div>
                <div className='order-este__details'>
                    <h5>Replacement Requested</h5>
                    <div className="">
                        <span className="order_info__similarProd">Usually takes 2-3 days for replacement  &nbsp;</span>
                    </div>
                </div>
                {['bottom'].map((placement, idx) => (
                    <OrderReplaceMentTrackStrap key={idx} placement={placement} name={placement} />
                ))}
            </div>
            <div className='order-este__delivery'>
                <div className='address-este____media'>
                    <span>
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25.3333 22.6659V15.9992H22.6667V25.3326H30.6667V22.6659H25.3333ZM32.4853 31.1512L24 39.6366L15.5147 31.1512C13.8365 29.473 12.6936 27.3348 12.2306 25.007C11.7676 22.6793 12.0052 20.2665 12.9135 18.0738C13.8217 15.8811 15.3598 14.0069 17.3332 12.6884C19.3066 11.3698 21.6266 10.666 24 10.666C26.3734 10.666 28.6934 11.3698 30.6668 12.6884C32.6402 14.0069 34.1783 15.8811 35.0865 18.0738C35.9948 20.2665 36.2324 22.6793 35.7694 25.007C35.3064 27.3348 34.1636 29.473 32.4853 31.1512Z" fill="#121415"/></svg>
                    </span>
                </div>
                <div className='order-este__details'>
                    <h5>Pickup Scheduled</h5>
                    <div className="ordder_deliverTo__info">
                        <span className="order_info__similarProd">Pick up expected by Today&nbsp;</span>
                    </div>
                </div>
                <button type='button' className='btn btn-dark-button btn-space bnt-h'>Track</button>
            </div>
            <div className='order-este__delivery'>
                <div className='address-este____media'>
                    <span>
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M30.8125 14.6885C33.5869 15.3323 34.6482 16.9546 35.9788 19.1875H30.8125V14.6885ZM14.5986 19.375C15.1418 19.375 15.5361 19.6372 15.5361 20.25C15.5361 20.7668 15.1179 21.186 14.6013 21.1875H8.9375C8.41968 21.1875 8 21.6074 8 22.125C8 22.6428 8.41968 23.0625 8.9375 23.0625H17.375C17.8977 23.0625 18.3162 23.4819 18.3162 24C18.3162 24.5178 17.8965 24.9375 17.3787 24.9375H8.9375C8.41968 24.9375 8 25.3572 8 25.875C8 26.3928 8.41968 26.8125 8.9375 26.8125H11V29.625C11 30.1428 11.4197 30.5625 11.9375 30.5625H13.822C14.158 32.2173 15.6201 33.4375 17.3438 33.4375C19.0674 33.4375 20.5295 32.2173 20.8655 30.5625H30.3845C30.7205 32.2173 32.1826 33.4375 33.9062 33.4375C35.6299 33.4375 37.092 32.2173 37.428 30.5625H39.0625C39.5803 30.5625 40 30.1428 40 29.625V24C40 21.2493 37.0986 21.0667 37.0959 21.0625H29.875C29.3572 21.0625 28.9375 20.6428 28.9375 20.125V14.5H11.9375C11.4197 14.5 11 14.9197 11 15.4375V17.5H9.875C9.35718 17.5 8.9375 17.9197 8.9375 18.4375C8.9375 18.9553 9.35718 19.375 9.875 19.375H14.5986ZM35.1216 28.6284C35.7927 29.2996 35.7927 30.3882 35.1216 31.0593C34.042 32.1389 32.1875 31.3708 32.1875 29.8438C32.1875 28.3169 34.042 27.5488 35.1216 28.6284ZM18.5591 28.6284C19.2302 29.2996 19.2302 30.3882 18.5591 31.0593C17.4795 32.1389 15.625 31.3708 15.625 29.8438C15.625 28.3169 17.4795 27.5488 18.5591 28.6284Z" fill="black"/></svg>
                    </span>
                </div>
                <div className='order-este__details'>
                    <h5>Item is on the way to the store</h5>
                    <div className="">
                        <span className="order_info__similarProd">Expected shipment to the store by Fri, 8th Jan&nbsp;</span>
                    </div>
                </div>
                <button type='button' className='btn btn-dark-button btn-space bnt-h'>Track</button>
            </div>
            <div className='order-este__delivery'>
                <div className='address-este____media'>
                    <span>
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31.4853 28.1512L23 36.6366L14.5147 28.1512C12.8365 26.473 11.6936 24.3348 11.2306 22.007C10.7676 19.6793 11.0052 17.2665 11.9135 15.0738C12.8217 12.8811 14.3598 11.0069 16.3332 9.68837C18.3066 8.3698 20.6266 7.66602 23 7.66602C25.3734 7.66602 27.6934 8.3698 29.6668 9.68837C31.6402 11.0069 33.1783 12.8811 34.0865 15.0738C34.9948 17.2665 35.2324 19.6793 34.7694 22.007C34.3064 24.3348 33.1636 26.473 31.4853 28.1512ZM23 22.3326C23.7072 22.3326 24.3855 22.0516 24.8856 21.5515C25.3857 21.0514 25.6667 20.3732 25.6667 19.6659C25.6667 18.9587 25.3857 18.2804 24.8856 17.7803C24.3855 17.2802 23.7072 16.9992 23 16.9992C22.2928 16.9992 21.6145 17.2802 21.1144 17.7803C20.6143 18.2804 20.3333 18.9587 20.3333 19.6659C20.3333 20.3732 20.6143 21.0514 21.1144 21.5515C21.6145 22.0516 22.2928 22.3326 23 22.3326Z" fill="#121415"/><path d="M25 33.5C25 37.6423 28.3578 41 32.5 41C36.6423 41 40 37.6423 40 33.5C40 29.3578 36.6423 26 32.5 26C28.3578 26 25 29.3578 25 33.5Z" fill="#278C4C"/><path d="M35.7617 30.8543L35.6246 30.7254L35.4876 30.8544L31.5624 34.5491L29.512 32.6192L29.3749 32.4902L29.2378 32.6192L28.6129 33.2073L28.4582 33.353L28.6129 33.4986L31.4254 36.1456L31.5624 36.2747L31.6995 36.1456L36.3871 31.7338L36.5419 31.5881L36.387 31.4424L35.7617 30.8543Z" fill="white" stroke="white"/></svg>
                    </span>
                </div>
                <div className='order-este__details'>
                    <h5>Received at Sangeetha store</h5>
                    <div className="">
                        <span className="order_info__similarProd">Expected verification by Today&nbsp;</span>
                    </div>
                </div>
                <button type='button' className='btn btn-dark-button btn-space bnt-h'>Track</button>
                          
            </div>
            <div className='order-este__delivery'>
                <div className='address-este____media'>
                    <span>
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M40 22.0921C40 14.3219 33.6781 8 25.9079 8C18.1372 8 11.8157 14.3219 11.8157 22.0921C11.8157 29.8627 18.1372 36.1842 25.9079 36.1842C33.6781 36.1842 40 29.8628 40 22.0921ZM14.4173 22.0921C14.4173 15.7563 19.572 10.6016 25.9078 10.6016C32.2436 10.6016 37.3984 15.7563 37.3984 22.0921C37.3984 28.4279 32.2437 33.5826 25.9079 33.5826C19.5721 33.5826 14.4173 28.4279 14.4173 22.0921Z" fill="black"/><path d="M10.2214 39.6199L17.6794 32.1619C18.1876 31.6537 18.1876 30.8307 17.6794 30.3225C17.1717 29.8144 16.3478 29.8144 15.84 30.3225L8.38202 37.7806C8.12795 38.0346 8.00089 38.3673 8.00089 38.7003C8.00089 39.0328 8.12795 39.3658 8.38202 39.6199C8.88977 40.1281 9.71365 40.1281 10.2214 39.6199Z" fill="black"/><path d="M19 22C19 25.8661 22.1339 29 26 29C29.8661 29 33 25.8661 33 22C33 18.1339 29.8661 15 26 15C22.1339 15 19 18.1339 19 22Z" fill="#121415"/><path d="M29.0534 19.5203L28.9163 19.3914L28.7793 19.5204L25.1249 22.9602L23.2203 21.1675L23.0832 21.0385L22.9462 21.1675L22.3629 21.7165L22.2082 21.8621L22.3629 22.0078L24.9879 24.4783L25.1249 24.6073L25.262 24.4783L29.6371 20.3606L29.7919 20.2149L29.637 20.0693L29.0534 19.5203Z" fill="white" stroke="white"/></g><defs></defs></svg>
                    </span>
                </div>
                <div className='order-este__details'>
                    <h5>Verification Successful</h5>
                    <div className="">
                        <span className="order_info__similarProd">Replaced item expected to be shipped by Today&nbsp;</span>
                    </div>
                </div>
                <button type='button' className='btn btn-dark-button btn-space bnt-h'>Track</button>
            </div>
            <div className='order-este__delivery'>
                <div className='address-este____media'>
                    <img src={Ontheway} alt="ontheWay" className='img-fluid' />
                </div>
                <div className='order-este__details'>
                    <h5>Replacement is on the way</h5>
                    <div className="ordder_deliverTo__info">
                        <span className="order_info__similarProd">ETA: &nbsp;</span>
                        <span className="order_info__similarProd">30 mins&nbsp;</span>
                    </div>
                </div>
                <button type='button' className='btn btn-dark-button btn-space bnt-h'>Track</button>
            </div>
            <div className='order-este__delivery'>
                <div className='address-este____media'>
                    <span>
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 24C5 32.8368 12.1632 40 21 40C29.8368 40 37 32.8368 37 24C37 15.1632 29.8368 8 21 8C12.1632 8 5 15.1632 5 24Z" fill="#278C4C"/><path d="M27.803 18.5203L27.6659 18.3914L27.5289 18.5204L18.9999 26.5486L14.4702 22.2853L14.3331 22.1563L14.196 22.2853L12.8629 23.54L12.7082 23.6857L12.8629 23.8313L18.8628 29.4783L18.9999 29.6073L19.137 29.4783L29.1371 20.0663L29.2919 19.9207L29.137 19.775L27.803 18.5203Z" fill="white" stroke="white"/></svg>
                    </span>
                </div>
                <div className='order-este__details'>
                    <h5>Replacement Delivered</h5>
                    <div className="">
                        <span className="order_info__similarProd">Delivered on Wed, 6th Jan, 2022 - 3:45pm&nbsp;</span>
                    </div>
                </div>
                <button type='button' className='btn btn-dark-button btn-space bnt-h'>Track</button>
            </div>
            <div className='order-este__delivery'>
                <div className='address-este____media'>
                    <span>
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M40 22.0921C40 14.3219 33.6781 8 25.9079 8C18.1372 8 11.8157 14.3219 11.8157 22.0921C11.8157 29.8627 18.1372 36.1842 25.9079 36.1842C33.6781 36.1842 40 29.8628 40 22.0921ZM14.4173 22.0921C14.4173 15.7563 19.572 10.6016 25.9078 10.6016C32.2436 10.6016 37.3984 15.7563 37.3984 22.0921C37.3984 28.4279 32.2437 33.5826 25.9079 33.5826C19.5721 33.5826 14.4173 28.4279 14.4173 22.0921Z" fill="black"/><path d="M10.2214 39.6199L17.6794 32.1619C18.1876 31.6537 18.1876 30.8307 17.6794 30.3225C17.1717 29.8144 16.3478 29.8144 15.84 30.3225L8.38202 37.7806C8.12795 38.0346 8.00089 38.3673 8.00089 38.7003C8.00089 39.0328 8.12795 39.3658 8.38202 39.6199C8.88977 40.1281 9.71365 40.1281 10.2214 39.6199Z" fill="black"/><path d="M19 22C19 25.8661 22.1339 29 26 29C29.8661 29 33 25.8661 33 22C33 18.1339 29.8661 15 26 15C22.1339 15 19 18.1339 19 22Z" fill="#121415"/><path d="M29.0534 19.5203L28.9163 19.3914L28.7793 19.5204L25.1249 22.9602L23.2203 21.1675L23.0832 21.0385L22.9462 21.1675L22.3629 21.7165L22.2082 21.8621L22.3629 22.0078L24.9879 24.4783L25.1249 24.6073L25.262 24.4783L29.6371 20.3606L29.7919 20.2149L29.637 20.0693L29.0534 19.5203Z" fill="white" stroke="white"/></g><defs></defs></svg>
                    </span>
                </div>
                <div className='order-este__details'>
                    <h5>Verification Failed</h5>
                    <div className="">
                        <span className="order_info__similarProd">Original item will be returned to you by Today&nbsp;</span>
                    </div>
                </div>
                <button type='button' className='btn btn-dark-button btn-space bnt-h'>Track</button>
            </div>
            <div className='order-este__delivery'>
                <div className='address-este____media'>
                    <img src={Ontheway} alt="ontheWay" className='img-fluid' />
                </div>
                <div className='order-este__details'>
                    <h5>Original Item is on the way</h5>
                    <div className="ordder_deliverTo__info">
                        <span className="order_info__similarProd">ETA: &nbsp;</span>
                        <span className="order_info__similarProd">30 mins&nbsp;</span>
                    </div>
                </div>
                <button type='button' className='btn btn-dark-button btn-space bnt-h'>Track</button>
            </div>
            <div className='order-este__delivery'>
                <div className='address-este____media'>
                    <span>
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 24C5 32.8368 12.1632 40 21 40C29.8368 40 37 32.8368 37 24C37 15.1632 29.8368 8 21 8C12.1632 8 5 15.1632 5 24Z" fill="#278C4C"/><path d="M27.803 18.5203L27.6659 18.3914L27.5289 18.5204L18.9999 26.5486L14.4702 22.2853L14.3331 22.1563L14.196 22.2853L12.8629 23.54L12.7082 23.6857L12.8629 23.8313L18.8628 29.4783L18.9999 29.6073L19.137 29.4783L29.1371 20.0663L29.2919 19.9207L29.137 19.775L27.803 18.5203Z" fill="white" stroke="white"/></svg>
                    </span>
                </div>
                <div className='order-este__details'>
                    <h5>Item Delivered</h5>
                    <div className="">
                        <span className="order_info__similarProd">Delivered on Wed, 6th Jan, 2022 - 3:45pm&nbsp;</span>
                    </div>
                </div>
                <button type='button' className='btn btn-dark-button btn-space bnt-h'>Track</button>
            </div>


            <div className="order-track__list"><div className="order-track_info order-cancelled__text "><div className="order-placed__text">2. 2 hr delivery by expert</div></div></div>
            <div className='order-este__delivery'>
                <div className='address-este____media'>
                    <img src={Ontheway} className='img img-fluid my-2' alt='' />
                </div>
                <div className='order-este__details'>
                    <h5>Order is on the way</h5>
                    <div className="ordder_deliverTo__info">
                        <span className="order_info__similarProd">ETA :  &nbsp;</span>
                        <span className="order_info__similarProd">30 mins &nbsp;</span>
                    </div>
                </div>
                {['bottom'].map((placement, idx) => (
                    <NonTrackshipMentstatus key={idx} placement={placement} name={placement} />
                ))}
            </div>


            <div className="order-track__list"><div className="order-track_info order-cancelled__text "><div className="order-placed__text">3. order canceled </div></div></div>

            <div className='order-este__delivery'>
                <div className='address-este____media'>
                    <span>
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 24C8 15.1634 15.1634 8 24 8C32.8366 8 40 15.1634 40 24C40 32.8366 32.8366 40 24 40C15.1634 40 8 32.8366 8 24Z" fill="#121415"/><path d="M24.001 22.1146L30.601 15.5146L32.4863 17.4L25.8863 24L32.4863 30.6L30.601 32.4853L24.001 25.8853L17.401 32.4853L15.5156 30.6L22.1156 24L15.5156 17.4L17.401 15.5146L24.001 22.1146Z" fill="white"/></svg>
                    </span>
                </div>
                <div className='order-este__details'>
                    <h5>Order Cancelled</h5>
                    <div className="">
                        <span className="order_info__similarProd">Refund of ₹19,999 has been initiated  &nbsp;</span>
                    </div>
                </div>
                {['bottom'].map((placement, idx) => (
                    <OrderCanceledStatus key={idx} placement={placement} name={placement} />
                ))}
            </div>

            
        </>
      
    
    )
}

export default OrderTrackerStatus