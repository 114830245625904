import React from 'react'
import specific from '../../../Images/CategoryDetails/heighlight/earphone2.png'
import EarphoneSpecificationTab from '../CategoryDetails/EarphoneSpecificationTab';

const ProductSpecifications = () => {
    return (
        <>
            <div className='product-specifications-section'>
                <div className='product-details__specific'>
                    <div className='specific-wrapper__list'>
                        <div className='specific-wrapper__media'>
                            <img src={specific} className='img img-fluid' alt='' />
                        </div>
                        <div className='specific-wrapper__content'>
                            <h4>boAt Airdopes 441 Bluetooth Truly Wireless in Ear Earbuds with Mic</h4>
                            <p>Awesome Violet</p>
                            <div className="price-offer justify-content-start">
                                <div className="new-price">₹9790</div>
                                <div className="old-price">₹10,000</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='product-details__tabs'>
                    <EarphoneSpecificationTab />
                </div>
            </div>
        </>
    )
}

export default ProductSpecifications;