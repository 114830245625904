import React from 'react';
import Form from 'react-bootstrap/Form';

const Step4 = () => {
    return (
        <>
            <div className="exchange-data">
                <div className='exchange-brand__lists bg-white mt-0'>
                    <h3 className="Exchangehead col-12 mb-2"> Tell us your phone&apos;s Storage </h3>
                    <div className='exchange_flex mt-0'>
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" defaultChecked/>
                            <label htmlFor="label" className="filter_exchange col d-flex align-items-center justify-content-between">
                                <span className='ml-2'>6GB + 128GB</span>
                            </label>
                        </div>
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon' aria-label="radio 1"/>
                            <label htmlFor="label" className="filter_exchange col d-flex align-items-center justify-content-between">
                                <span className='ml-2'>8GB + 256GB</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>            
            <div className='px-3'>
                <div className='exchange-bg__storage'>
                    <div className='font-500 mb-2'>How to check phone’s storage?</div>
                    <ul>
                        <li>Go to the Settings or Options menu</li>
                        <li>Scroll to the bottom of the list, and check ‘About phone’, ‘About device’ or similar. </li>
                        <li>The device storage should be listed.</li>
                    </ul>
                </div>
            </div>
            <div className='mar-spacer h-3 mb-4' />
        </>
    )
}

export default Step4