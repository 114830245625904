import React,{ useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import trackimg from '../../Images/CategoryDetails/logo/track.png';
import ArrivingToday from '../../Components/Order/ArrivingToday';
import OrderTrackingStarapperShipped from '../Order/OrderTrackingStarapperShipped';
import OrderTrackingStarpper from '../../Components/Order/OrderTrackingStarpper';


const TrackShipment = ({...props}) => {

    const [show, setShow] = useState(false);
    const [trackStrap ]=useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
    // <div>TrackShipment</div>
        <>
            <button  onClick={handleShow} className="btn btn-remove btn-cart-h track-border-r">
                <span>
                    <img src={trackimg} alt="" />                                       
                </span>
                <span className='ml-1'>Track Order</span>
            </button>
            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Track Shipment <span className='track_shipment-items'>(2 items)</span></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>

                    <div className='product-inbox specification-head trackshipmentcanva'>
                        <ArrivingToday />
                    </div>
                    <div className="mar-spacer h-1_2"></div>
                    <div className="trackshipment_canva-Model">
                        <div className="ordersuccesful_message">
                            <p className='trackshipment_canva-para'>Expected delivery on </p>
                            <h4 className='trackshipment_canva-msg'>Web, 6th january,2022</h4>
                        </div>
                    </div>
                    <div className="mar-spacer h-1"></div>
                    {trackStrap ?
                        <OrderTrackingStarpper/>:

                        <OrderTrackingStarapperShipped/>
                    }

                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default TrackShipment