import { Link } from '@reach/router';
import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

const LangaugeAccordion = () => {
    const [activeId, setActiveId] = useState('0');

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    return (
        <>
            <Accordion className='cart-payment__accordion footer__accordion w-100' defaultActiveKey={activeId}>
                <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('0')} className='px-0'>
                        <div className='payment-card__head m-0'>
                            <span>Language</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='item-item__category'>
                            <Link to='/' className='item-links__category active'>Hindi</Link>
                            <Link to='/' className='item-links__category'>English</Link>
                            <Link to='/' className='item-links__category'>Kannada</Link>
                            <Link to='/' className='item-links__category'>Tamil</Link>
                            <Link to='/' className='item-links__category'>Malayalam</Link>
                            <Link to='/' className='item-links__category'>Telugu</Link>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default LangaugeAccordion