import React from 'react';
import OrderTracking from '../../Images/CategoryDetails/Order/OrderTracking.png';
import servicebox1 from '../../Images/CategoryDetails/Order/bag_blackbg.png';
import servicebox2 from '../../Images/CategoryDetails/Order/startShield.png';
import servicebox3 from '../../Images/CategoryDetails/Order/check_Shiled.png';
import servicebox4 from '../../Images/CategoryDetails/Order/data-transfer.png';
import ArrivingToday from '../../Components/Order/OrderTime/ArrivingToday';
import ArrivingTime from '../../Components/Order/OrderTime/ArrivingTime';
import ArrivingDelivered from '../../Components/Order/OrderTime/ArrivingDelivered';
import ArrivingTomorrow from '../../Components/Order/OrderTime/ArrivingTomorrow';

import OrderStrap from '../../Components/Order/OrderStrap'





import { useState } from 'react';

const OrderTrack = () => {
    const [onTime]=useState(true);
    const[Arriving2hr]=useState(true)




    return (
        <>
            <div className="Order_successfull">
                <img src={OrderTracking} alt="TrackImages" className='img w-100' />
                <div className="order_tracking-ModelHead">
                    {Arriving2hr?
                        <div className="ordersuccesful_message order_tracking_model">
                            {/* ontime */}

                            {onTime ?                        
                                <div className="order_arriving_track">
                                    <div className="arrivingheading">
                                        <h4 className='Order_Successful-msg'>Arriving in 2 hours</h4>
                                    </div>
                                    <div className="ordertrack_ontime">
                                        <p className='order_ontime_txt'>On Time</p>
                                    </div>
                                </div> :
                                                        
                                <div className="order_arriving_track">
                                    <div className="arrivingheading">
                                        <h4 className='Order_Successful-msg'>Arriving in 5 mins</h4>
                                    </div>
                                    <div className="ordertrack_ontime_delayed">
                                        <p className='order_ontime_txt_delayed'>Delayed</p>
                                    </div>
                                </div>
                            }

                            <p className='order-Location-para'>Rohan Home 103, Udgam Belvedere, Kadubeesana...
                            </p>
                        </div>:""}
                    <ArrivingToday/>
                    <ArrivingTime/>
                    <ArrivingDelivered/>
                    <ArrivingTomorrow/>          
                </div>
                <div className="mar-spacer h-1_2 blue_bg"></div>
                <div className="mar-spacer h-1_2"></div>

                <div className="needhelp_container">
                    <div className="needhelp">
                        <div className='contaact_need-help-txt_dark'>Need help ?</div>
                        <div className='contaact_need-help-txt'>Contact Our support team</div>
                    </div>
                    <div>
                        <button className='help_button_'> Help</button>
                    </div>
                </div>
                <div className="mar-spacer h-1_2 blue_bg"></div>
                <div className="mar-spacer h-1_2"></div>

                <div className="dilivery_service_container">
                    <div className="delivery-service_heading">
                        <h5 className='deliveryser_heading'>Delivery Services coming your way!</h5>
                        <div className='service_categoarybox'>
                            <img src={servicebox1} alt="" className='img' />
                            <p  className='service_categoarybox_para'>Value Added Services for your purchase</p>

                        </div>
                        <div className='service_categoarybox'>
                            <img src={servicebox2} alt="" className='img' />
                            <p className='service_categoarybox_para'>Accessories for your purchase</p>

                        </div>
                        <div className='service_categoarybox'>
                            <img src={servicebox3} alt=""className='img' />
                            <p className='service_categoarybox_para'>Tempered Glass Installation at home</p>

                        </div>
                        <div className='service_categoarybox mb-0'>
                            <img src={servicebox4} alt="" className='img'/>
                            <p className='service_categoarybox_para'>Data Transfer at home</p>
                        </div>
                    </div>
                </div>
                <div className="mar-spacer h-1_2 blue_bg"></div>
                <div className="mar-spacer h-1_2"></div>
                <OrderStrap/>

                
            </div>
        </>
    )
}

export default OrderTrack
