import React from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import mapImg from '../../Images/product/mapImg.png';

const AddAddressDetails = () => {
    return (
        <>
            <div className='map-location'>
                <img src={mapImg} className="img img-fluid" alt='' />
            </div>
            <div className='map-card__wrapper'>
                <div className='map-card__wrap'>
                    <div className='map-card__icon'>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.2427 11.576L8 15.8187L3.75734 11.576C2.91823 10.7369 2.34679 9.66777 2.11529 8.50389C1.88378 7.34 2.0026 6.13361 2.45673 5.03726C2.91086 3.9409 3.6799 3.00384 4.66659 2.34455C5.65328 1.68527 6.81332 1.33337 8 1.33337C9.18669 1.33337 10.3467 1.68527 11.3334 2.34455C12.3201 3.00384 13.0891 3.9409 13.5433 5.03726C13.9974 6.13361 14.1162 7.34 13.8847 8.50389C13.6532 9.66777 13.0818 10.7369 12.2427 11.576ZM8 9.99999C8.70725 9.99999 9.38552 9.71904 9.88562 9.21894C10.3857 8.71884 10.6667 8.04057 10.6667 7.33332C10.6667 6.62608 10.3857 5.9478 9.88562 5.4477C9.38552 4.94761 8.70725 4.66666 8 4.66666C7.29276 4.66666 6.61448 4.94761 6.11438 5.4477C5.61429 5.9478 5.33334 6.62608 5.33334 7.33332C5.33334 8.04057 5.61429 8.71884 6.11438 9.21894C6.61448 9.71904 7.29276 9.99999 8 9.99999ZM8 8.66665C7.64638 8.66665 7.30724 8.52618 7.05719 8.27613C6.80715 8.02608 6.66667 7.68694 6.66667 7.33332C6.66667 6.9797 6.80715 6.64056 7.05719 6.39051C7.30724 6.14046 7.64638 5.99999 8 5.99999C8.35362 5.99999 8.69276 6.14046 8.94281 6.39051C9.19286 6.64056 9.33334 6.9797 9.33334 7.33332C9.33334 7.68694 9.19286 8.02608 8.94281 8.27613C8.69276 8.52618 8.35362 8.66665 8 8.66665Z" fill="#121415"/>
                            </svg>
                        </span>
                        <h6>Royal Orchard</h6>
                    </div>
                    <div className='map-card__details'>Royal Orchard, Major district 40, Manikaran Road, Bengaluru, Karnataka 560017</div>
                </div>
            </div>
            <div className='mar-spacer h-0_5' />
            <div className='page__wrapper'>
                <h6 className='address-form__heading'>Contact Details<sup>*</sup></h6>
                <div className='address-form__details'>
                    <div className='form-group-custom'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Your Name"
                        >
                            <Form.Control type="text" className='form-control form-control-custom' value="Siddharth Sharma" />
                        </FloatingLabel>
                    </div>
                    <div className='form-group-custom'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Mobile Number"
                        >
                            <Form.Control type='text' className='form-control form-control-custom' value="9898091827" />
                        </FloatingLabel>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme' />
            <div className='page__wrapper'>
                <h6 className='address-form__heading mt-3'>Address<sup>*</sup></h6>
                <div className='address-form__details'>
                    <div className='form-group-custom'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Pincode"
                        >
                            <Form.Control type="text" className='form-control form-control-custom' placeholder="Your Name" />
                        </FloatingLabel>
                    </div>
                    <div className='form-group-custom'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Address (House no, Building, Street, Area)"
                        >
                            <Form.Control type='text' className='form-control form-control-custom' placeholder='Mobile Number' />
                        </FloatingLabel>
                    </div>
                    <div className='form-group-custom'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Locality"
                        >
                            <Form.Control type='text' className='form-control form-control-custom' placeholder='Mobile Number' />
                        </FloatingLabel>
                    </div>
                    <div className='form-group-custom'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="City"
                        >
                            <Form.Control type='text' className='form-control form-control-custom' placeholder='Mobile Number' />
                        </FloatingLabel>
                    </div>
                    <div className='form-group-custom'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="State"
                        >
                            <Form.Control type='text' className='form-control form-control-custom' placeholder='Mobile Number' />
                        </FloatingLabel>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme' />
            <div className='address-card__wrap'>
                <h6 className='address-form__heading'>Save Address As<sup>*</sup></h6>
                <div className='mt-1 address-btn__save'>
                    <button type='button' className='btn btn-category'>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.3333 13.3334C13.3333 13.5102 13.2631 13.6797 13.1381 13.8048C13.013 13.9298 12.8435 14 12.6667 14H3.33333C3.15652 14 2.98695 13.9298 2.86193 13.8048C2.7369 13.6797 2.66666 13.5102 2.66666 13.3334V7.33336H0.666664L7.55133 1.07469C7.67407 0.963013 7.83405 0.901123 8 0.901123C8.16594 0.901123 8.32592 0.963013 8.44866 1.07469L15.3333 7.33336H13.3333V13.3334ZM7.33333 8.66669V12.6667H8.66666V8.66669H7.33333Z" fill="#121415"/>
                            </svg>
                        </span>
                        <span className='ml-1'>Home</span>
                    </button>
                    <button type='button' className='btn btn-category'>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.66666 3.33341V1.33341C4.66666 1.1566 4.7369 0.987034 4.86192 0.86201C4.98695 0.736986 5.15652 0.666748 5.33333 0.666748H10.6667C10.8435 0.666748 11.013 0.736986 11.1381 0.86201C11.2631 0.987034 11.3333 1.1566 11.3333 1.33341V3.33341H14C14.1768 3.33341 14.3464 3.40365 14.4714 3.52868C14.5964 3.6537 14.6667 3.82327 14.6667 4.00008V13.3334C14.6667 13.5102 14.5964 13.6798 14.4714 13.8048C14.3464 13.9298 14.1768 14.0001 14 14.0001H1.99999C1.82318 14.0001 1.65361 13.9298 1.52859 13.8048C1.40357 13.6798 1.33333 13.5102 1.33333 13.3334V4.00008C1.33333 3.82327 1.40357 3.6537 1.52859 3.52868C1.65361 3.40365 1.82318 3.33341 1.99999 3.33341H4.66666ZM11.3333 4.66675V8.00008H13.3333V4.66675H11.3333ZM10 4.66675H6V8.00008H10V4.66675ZM4.66666 4.66675H2.66666V8.00008H4.66666V4.66675ZM6 2.00008V3.33341H10V2.00008H6Z" fill="#121415"/>
                            </svg>
                        </span>
                        <span className='ml-1'>Work</span>
                    </button>
                </div>
            </div>
            <div className='mar-spacer bg-theme' />
            <div className='address-card__wrap'>
                <Form.Check
                    inline
                    label="Make this my default address"
                    name="group1"                    
                />
            </div>
            <div className='mar-spacer h-3 mt-3' />
            <section className="sticky-menus filter-sticky-menus">
                <div className="sticky-price__wrap px-3 w-100">
                    <button type='button' className='btn btn-place-order btn-dark-custom w-100'>Add Address</button>                    
                </div>
            </section>
        </>
    )
}

export default AddAddressDetails