import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import SmartwatchHighlightCarousel from '../CategoryDetails/SmartwatchHighlightCarousel';
import ProductPolicy from './../ProductPolicy';

//Images
import notificatification from '../../../Images/CategoryDetails/logo/notifications.png';
import warenty from '../../../Images/CategoryDetails/logo/warenty.png';
import storage from '../../../Images/product/storage.svg';
import cpu from '../../../Images/product/cpu.svg';
import ram from '../../../Images/product/ram.svg';
import battery from '../../../Images/product/battery.svg';
import { Link } from '@reach/router';


const SmartWatchHighlight = () => {

    const [activeId, setActiveId] = useState('1');

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }
    const ProductSpecifications = [
        {   
            _id: 1,
            Image: storage,
            label: 'Internal Memory',
            Name: '8GB'
        }, {   
            _id: 2,
            Image: ram,
            label: 'Resolution',
            Name: '256GB'
        }, {   
            _id: 3,
            Image: notificatification,
            label: 'Notification',
            Name: 'Messages, Calendar, Mail'
        }, {
            _id: 4,
            Image: cpu,
            label: 'Operating Systen',
            Name: 'watchOS 4'
        }, {
            _id: 5,
            Image: battery,
            label: 'Battery Life',
            Name: 'Upto 18 hours'
        }, {
            _id: 6,
            Image: warenty,
            label: 'Warranty',
            Name: '1 year'
        }
    ];

    return (
        <>             
            <Accordion defaultActiveKey={activeId}>
                <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('0')}>Specifications</Accordion.Header>
                    <Accordion.Body>
                        <div className='product-specification__wrap'>
                            {ProductSpecifications.map((Specification) => {
                                return (
                                    <div className='specification__list' key={Specification._id}>
                                        <div className='specification-list__icon'>
                                            <img src={Specification.Image} className='img img-fluid' alt={Specification.Name} />
                                        </div>
                                        <div className='specification-list__content'>
                                            <label>{Specification.label}</label>
                                            <span>{Specification.Name}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='product-specification__otherInfo'>
                            <h6 className='specification-info__head'>Other Details</h6>
                            <div className='specification-info__table'>
                                <table className='table specification-striped__table'>
                                    <tbody>
                                        <tr>
                                            <th>Compatible Device</th>
                                            <td>iPhone 6s or later with iOS 14 or more</td>
                                        </tr>
                                        <tr>
                                            <th>Call Function </th>
                                            <td>No</td>
                                        </tr>
                                        <tr>
                                            <th>GPS </th>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <th>Diaplay Resolution</th>
                                            <td>312 x 390 pixels</td>
                                        </tr>
                                        <tr>
                                            <th>Scratch Resistant</th>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <th>Other Features</th>
                                            <td>Built-in fitness tracker, Ion-X (Strengthened Glass), Support Force Touch</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Link to='/CategoryDetails/SmartwatchSpecification' className='specification-info__head text-decoration-underline'>View All Details</Link>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className={activeId === '1' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('1')}>Highlights</Accordion.Header>
                    <Accordion.Body>
                        <div className='product-highlight__otherInfo'>
                            <SmartwatchHighlightCarousel />
                            <Link to='/CategoryDetails/SmartwatchSpecification' className='specification-info__head text-decoration-underline'>View More Highlights</Link>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className={activeId === '2' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('2')}>Cancellation & Return Policy</Accordion.Header>
                    <Accordion.Body>
                        <ProductPolicy />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default SmartWatchHighlight;