import { Link } from '@reach/router';
import React, { useState } from 'react';
import CategoryBanner from '../Components/Category/CategoryBanner';
import OffersCarousel from '../Components/Category/OffersCarousel';
import BrandCarousel from '../Components/Home/BrandCarousel';
import SponsoredPhonesCarousel from '../Components/Product/SponsoredPhonesCarousel';
import GamingPhoneCarousel from '../Components/Category/GamingPhoneCarousel';
import MobileOffer from '../Components/Home/MobileOffer';
import PhoneOffersTab from '../Components/Category/PhoneOffersTab';
import ProductList from '../Components/Product/ProductList';

import phoneBanner from '../Images/Home/phoneBanner.png';
import brandBanner from '../Images/brands/banner/brandBanner.png';
import arrowVictor from '../Images/brands/banner/arrowVictor.png';

const Category = () => {
    const[perfectPhone,setperfectPhone]=useState(false);
    const handleToggle1 = () => {
        setperfectPhone(!perfectPhone);
        console.log('handleToggle')
    };
    const[perfectPhone1,setperfectPhone1]=useState(false);
    const handleToggle2 = () => {
        setperfectPhone1(!perfectPhone1);
        console.log('handleToggle')
    };
    const[perfectPhone2,setperfectPhone2]=useState(false);
    const handleToggle3 = () => {
        setperfectPhone2(!perfectPhone2);
        console.log('handleToggle')
    };
    const[perfectPhone3,setperfectPhone3]=useState(false);
    const handleToggle4 = () => {
        setperfectPhone3(!perfectPhone3);
        console.log('handleToggle')
    };
    const[perfectPhone4,setperfectPhone4]=useState(false);
    const handleToggle5 = () => {
        setperfectPhone4(!perfectPhone4);
        console.log('handleToggle')
    };
    const[perfectPhone5,setperfectPhone5]=useState(false);
    const handleToggle6 = () => {
        setperfectPhone5(!perfectPhone5);
        console.log('handleToggle')
    };
    const[perfectPhone6,setperfectPhone6]=useState(false);
    const handleToggle7 = () => {
        setperfectPhone6(!perfectPhone6);
        console.log('handleToggle')
    };
  
    return (
        <>
            <div className='brand-section__wrap'>
                <CategoryBanner />
                <section className="brands-wrapper exclusive-section border-0">
                    <div className='all-category__content ml--16'>
                        <OffersCarousel />
                    </div>
                </section>
                <section className="brands-wrapper exclusive-section">
                    <div className='top-head__wrap'>
                        <h4 className="brand-heading">Choose from Popular Brands</h4>
                    </div>
                    <div className='p-x-16'>
                        <BrandCarousel />
                    </div>
                </section>
                <section className="brands-wrapper exclusive-section">
                    <div className='top-head__wrap'>
                        <h4 className="brand-heading">New Launches</h4>
                    </div>
                    <div className='product-sponsored__wrap p-0 ml--16'>
                        <SponsoredPhonesCarousel />
                    </div>
                </section>
                <section className="brands-wrapper exclusive-section">
                    <div className='top-head__wrap'>
                        <h4 className="brand-heading">Best Gaming Phones</h4>
                    </div>
                    <GamingPhoneCarousel />
                </section>
                <section className="brands-wrapper exclusive-section p-x-16">
                    <div 
                        className='phome-banner__wrap'
                        style={{ 
                            backgroundImage:`url(${phoneBanner})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'bottom right',
                            backgroundSize: 'inherit',
                            backgroundColor: '#000',
                            padding: '24px',
                            color: '#fff'
                        }}    
                    >
                        <div className='phome-banner__info exclusive-text'>
                            <h5>Upgrade your phone every year!</h5>
                            <p>Join our subscription program and get the best deals</p>
                            <Link to='/' className='btn btn-dark-custom'>Know More</Link>
                        </div>
                    </div>
                </section>
                <section className="brands-wrapper exclusive-section border-0">
                    <div className='top-head__wrap'>
                        <h4 className="brand-heading">Top Selling Smartphones</h4>
                    </div>
                    <MobileOffer />
                </section>
                <section className="p-x-16 mb-2">
                    <div 
                        className='phome-banner__wrap position-relative'
                        style={{ 
                            backgroundColor: '#DAEBE8',
                            padding: '22px 26px',
                            zIndex: 1,
                        }}    
                    >
                        <img src={brandBanner} className='img-fluid phone-victor' alt='' />
                        <img src={arrowVictor} className='img-fluid arrow-victor' alt='' />
                        <div className='phome-banner__info'>
                            <h5>Undo the damage</h5>
                            <p>Airtight protection plans. Guaranteed.</p>
                            <Link to='/' className='btn btn-dark-custom space-padding p-x-16'>Know More</Link>
                        </div>
                    </div>
                </section>
                <section className="brands-wrapper exclusive-section border-0">
                    <div className='top-head__wrap pb-0'>
                        <h4 className="brand-heading">Phones for You</h4>
                    </div>
                    <PhoneOffersTab />
                </section>
                <div className='mar-spacer h-0_5' />
                <section className="brands-wrapper border-0 p-x-16">
                    <div className='suggestion-section light-grey-bg py-4'>
                        <h3 className='suggestion-heading'>Let us help you find your perfect phone</h3>
                        <p className='suggestion-info'>Select upto 3 activities you use your phone for</p>
                        <div className='btn-suggestion__wrap'>
                            <Link to='' className={`btn-suggestion btn-right-space mb-3 ${perfectPhone?"border_active":""}`} onClick={handleToggle1}>Performance</Link>
                            <Link to='' className={`btn-suggestion btn-right-space mb-3 ${perfectPhone1?"border_active":""}`} onClick={handleToggle2}>Gaming</Link> 
                            <Link to='' className={`btn-suggestion btn-right-space mb-3 ${perfectPhone2?"border_active":""}`} onClick={handleToggle3}>Photography</Link><br/>
                            <Link to='' className={`btn-suggestion btn-right-space mb-3 ${perfectPhone3?"border_active":""}`} onClick={handleToggle4}>Music</Link> 
                            <Link to='' className={`btn-suggestion btn-right-space mb-3 ${perfectPhone4?"border_active":""}`} onClick={handleToggle5}>Mails</Link> 
                            <Link to='' className={`btn-suggestion btn-right-space mb-3 ${perfectPhone5?"border_active":""}`} onClick={handleToggle6}>Online Classes</Link><br/>
                            <Link to='' className={`btn-suggestion btn-right-space mb-3 ${perfectPhone6?"border_active":""}`} onClick={handleToggle7}>Entertainment</Link>
                        </div>
                        <button type='button' className='btn btn-dark-custom space-padding'>Let’s Go!</button>
                    </div>
                </section>
                <section className="brands-wrapper exclusive-section">
                    <div className='top-head__wrap'>
                        <h4 className="brand-heading">Curated for You</h4>
                    </div>
                    <div className='product-sponsored__wrap'>
                        <div className='brand-product__filter'>
                            <button type='button' className='btn btn-brand'>Brands</button>
                            <button type='button' className='btn btn-brand'>Price</button>
                            <button type='button' className='btn btn-brand'>Offers</button>
                            <button type='button' className='btn btn-brand'>Performance</button>
                        </div>
                    </div>
                    <ProductList  />
                </section>
            </div>
        </>
    )
}

export default Category 