import React from 'react'
import vas1 from '../../Images/vas/1.svg';
import vas2 from '../../Images/vas/2.svg';
import vas3 from '../../Images/vas/3.svg';
import vas4 from '../../Images/vas/4.svg';
import vas from '../../Images/Home/vas.png';
import { Link } from '@reach/router';

const ProtectionPlans = () => {
    return (
        <>
            <div className='mar-spacer h-1' />
            <div className='protection-plan__home'>
                <div className="top-head__wrap">
                    <h4 className="brand-heading">Airtight Protection for <br/>your Phone</h4>
                </div>
                <div className='vas__home p-x-16'>
                    <div className='vas-home__flex'>
                        <div className='vas-flex__info'>
                            <img src={vas1} className='img-fluid' alt='' />
                        </div>
                        <div className='vas-text__info mt-2'>Damage</div>
                    </div>
                    <div className='vas-home__flex'>
                        <div className='vas-flex__info'>
                            <img src={vas2} className='img-fluid' alt='' />
                        </div>
                        <div className='vas-text__info mt-2'>Theft</div>
                    </div>
                    <div className='vas-home__flex'>
                        <div className='vas-flex__info'>
                            <img src={vas3} className='img-fluid' alt='' />
                        </div>
                        <div className='vas-text__info mt-2'>Fire</div>
                    </div>
                    <div className='vas-home__flex'>
                        <div className='vas-flex__info'>
                            <img src={vas4} className='img-fluid' alt='' />
                        </div>
                        <div className='vas-text__info mt-2'>Price Drop</div>
                    </div>
                </div>
                <Link to='/WhySangeethaExplore' className='protection-plan__link'>Learn More</Link>
                <div className='protection-plan__comment p-x-16'>
                    <div className='protection-plan__comment-box'>
                        <div className='protection-plan__comment-text'>“Sangeetha protects <br/>butterfingers like me”</div>
                    </div>
                    <img src={vas} className='img-fluid' alt='' />
                </div>
            </div>
        </>
    )
}

export default ProtectionPlans