
import React from 'react'

const SmartTvManufaturer = () => {
    return (
        <>
            <div className='specifications-tab'>
                <h4 className='specifications-tab__head'>General</h4>
                <div className='specification-info__table'>
                    <table className='table specification-striped__table'>
                        <tbody>
                            <tr>
                                <th>Generic Name</th>
                                <td>Television</td>
                            </tr>
                            <tr>
                                <th>Country of Origin</th>
                                <td> India, Vietnam  </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='specific-horizontal-line'></div>
                <h4 className='specifications-tab__head'>Manufacturer’s Details</h4>
                <p className='specifications-Subtab__head'>Manufactured by one of the following :</p>
                <ul className='specifications-tab__manufacture'>
                    <li className='tvmanufacture_li'>Samsung india ElectronicsPVTLTD 6th floor,DLF Centre,sansand Marg,New delhi-110001</li>
                    <li className='tvmanufacture_li'>Samsung HCMC Complex Co. Ltd Lot I-11, D2 Road, Saigonhi, I-Tech Park, DST-9, Ho Chi Minh City,Vietnam </li>
                </ul>
                <h4 className='specifications-tab__head'>Importer’s Details</h4>
                <p className='specifications-Subtab__head'>Imported by one of the following :</p>
                <ul className='specifications-tab__manufacture'>
                    <li className='tvmanufacture_li'>Samsung india ElectronicsPVTLTD 6th floor,DLF Centre,sansand Marg,New delhi-110001</li>
                    <li className='tvmanufacture_li'>Samsung HCMC Complex Co. Ltd Lot I-11, D2 Road, Saigonhi, I-Tech Park, DST-9, Ho Chi Minh City,Vietnam </li>
                </ul>
                <h4 className='specifications-tab__head'>Packer’s Details</h4>
                <p className='specifications-Subtab__head'>Packed by one of the following :</p>
                <ul className='specifications-tab__manufacture'>
                    <li className='tvmanufacture_li'>Samsung india ElectronicsPVTLTD 6th floor,DLF Centre,sansand Marg,New delhi-110001</li>
                    <li className='tvmanufacture_li'>Samsung HCMC Complex Co. Ltd Lot I-11, D2 Road, Saigonhi, I-Tech Park, DST-9, Ho Chi Minh City,Vietnam </li>

                </ul>
            </div>
        </>
    )
}

export default SmartTvManufaturer;
