import React from 'react';
// import Form from 'react-bootstrap/Form';
import img1 from '../../Images/Orders/img1.png';
import img2 from '../../Images/Orders/img2.png';
// import ChooseCancel from '../../Components/Ordercancellation/ChooseCancel'

// import Photography from '../../Images/DIY/1.svg'
// import Networking from '../../Images/DIY/2.svg'
// import Gaming from '../../Images/DIY/3.svg'
// import Music from '../../Images/DIY/4.svg'
// import Travelling from '../../Images/DIY/5.svg'
// import Office from '../../Images/DIY/6.svg'

 

const OrderCancelStep1 = ({ formData, setFormData }) => {
    // const availableCategories = [
    //     {
    //         __id : 1,
    //         name: 'Photography',
    //         status: 'unchecked',
    //         Image: Photography
    //     }, {
    //         __id : 2,
    //         name: 'Social Networking',
    //         status: 'checked',
    //         Image: Networking
    //     }, {
    //         __id : 3,
    //         name: 'Gaming',
    //         status: 'unchecked',
    //         Image: Gaming
    //     }, {
    //         __id : 4,
    //         name: 'Music',
    //         status: 'checked',
    //         Image: Music
    //     }, {
    //         __id : 5,
    //         name: 'Travelling',
    //         status: 'unchecked',
    //         Image: Travelling
    //     }, {
    //         __id : 6,
    //         name: 'Office',
    //         status: 'unchecked',
    //         Image: Office
    //     }
    // ];
    return (
        <>
            {/* <div className="sign-up-container">
                <div className="exchange-data">
                    <h3 className='steps__head'>What activities would you use your phone for?</h3>
                    <p className='steps__info'>Select upto 3 options from below : </p>

                    <div className='filter-tab__lists'>     
                        {availableCategories.map((Data) => (
                            <div className={Data.status === 'checked' ? 'filter-tab__flex changeexchange-div active' : 'filter-tab__flex changeexchange-div'} key={Data.__id}>
                                <div className='steps__media'>
                                    <img src={Data.Image} className='img-fluid' alt={Data.name} />
                                </div>
                                <div className='steps__content'>
                                    <label htmlFor="label" className='filter_exchange ml-2'>{Data.name}</label>
                                    <Form.Check 
                                        className='filter__checkbox sort__checkbox' 
                                        aria-label="option 1" 
                                        value={Data}
                                        checked={Data.status === 'checked' ? 'checked' : null}
                                        onChange={(event) =>
                                            setFormData({ ...formData, password: event.target.value })
                                        }
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>                
            </div> */}
            <div className="cancellation_offcanvas">
                <div className="cancelbody" >
                    <div className='order-track__wrap px-0'>
                        <div className='orders_wrap'>
                            <div className='orders-list_wrap'>
                                <div className='orders-media__wrap'>
                                    <img src={img1} className='img-fluid' alt='' />
                                    <div className='orders__tag'>
                                        <div className='orders_content'>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className='orders-media__wrap'>
                                    <img src={img2} className='img-fluid' alt='' />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="selectcancelorder-txt proceedcancellation-padding">
                        This entire order will be cancelled. Are you sure you want to proceed?
                    </div>

                </div>                    
              
            </div>
        </>
    )
}

export default OrderCancelStep1;
