import React, {useState} from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CheckEligibility from './CheckEligibility';
import emi from '../../Images/product/emi.png'

const CartEMIOfferList = ({ ...props }) => {
    const [isShow, setIsShow] = useState(false);
    const [checkAvailiblity, setCheckAvailiblity] = useState(true);
    
    const handleClose = () => setIsShow(false);
    const handleShow = () => setIsShow(true);

    return (
        <>
            <div role="button" className='offer-link' onClick={handleShow} aria-hidden="true">6 More Offers</div>
            <Offcanvas show={isShow} onHide={handleClose} {...props} className={checkAvailiblity ? 'notify-me__offCanvas-height' : 'notify-me__offCanvas-height eligiblity__offCanvas' }>
                <Offcanvas.Header closeButton>
                    {checkAvailiblity ?
                        <Offcanvas.Title className='cart-heading'>Cardless EMI Offers</Offcanvas.Title> :
                        <Offcanvas.Title className='cart-heading'>Check Eligibility</Offcanvas.Title>
                    }
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='EMI__offers' style={{ display: checkAvailiblity ? "block" : "none" }}>  
                        <div className='EMI__offers-media'>
                            <img src={emi} className='img img-fluid' alt='' />
                        </div>
                        <div className='EMI__offers-text'>
                            <h6>0% downpayment available!</h6>
                            <p>Login to check your eligibility and visit our Finance Center</p>
                        </div>
                        <button type='button' className='btn btn-dark-theme' onClick={() => setCheckAvailiblity(false)}>Visit Finance Center</button>
                    </div>                     
                    <div className='check-EMI__offers' style={{ display: checkAvailiblity ? "none" : "block" }}>
                        <div className='eligible__section'>
                            <p className='eligible-info__section'>Enter your mobile number registered with bank to check cardless EMI eligibility</p>
                            <div className='check-EMI__input'>
                                <div className="check-input-box">
                                    <input className='input-emi' placeholder='Mobile Number' />
                                    <span className="unit">+91 <span className='unit-bar'>|</span></span>
                                </div>
                                <div className='flexible'></div>
                                {['bottom'].map((placement, idx) => (
                                    <CheckEligibility key={idx} placement={placement} name={placement} />
                                ))}
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default CartEMIOfferList