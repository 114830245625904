import { Link } from '@reach/router';
import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

//Images
import HighlistImage1 from '../../../Images/CategoryDetails/heighlight/Smartwatch-highlight1.png';
import HighlistImage2 from '../../../Images/CategoryDetails/heighlight/Smartwatch-highlight2.png';
import HighlistImage3 from '../../../Images/CategoryDetails/heighlight/Smartwatch-highlight3.png';
import HighlistImage4 from '../../../Images/CategoryDetails/heighlight/Smartwatch-highlight4.png';

const SmartwatchHighlightTab = () => {
    const [activeId, setActiveId] = useState('0');

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    const ProductHighlightsTab = [
        {   
            _id: 0,
            Image: HighlistImage1,
            TabName: 'The Ultimate Sports Watch ',
            info: 'Challenge yourself to explore new heights and keep a track of your feats with the GPS and altimeter. Gruelling physical tasks are bearable when you have a backup of your favorite tracks.'
        }, {   
            _id: 1,
            Image: HighlistImage2,
            TabName: 'Intelligent Activity Tracker',
            info: 'Keeps tabs on how active you are each day with the watch’s three activity rings. Smart Coaching brings you personalised progress updates in the mornings, and suggestions on how t.'
        }, {   
            _id: 2,
            Image: HighlistImage3,
            TabName: 'Powerful Health Tool',
            info: 'Keeps tabs on your heart rate when you’re walking, resting or working out. The watch also packs a Breathe App which guides you when you’re stressed. There are also third party health ap.'
        }, {
            _id: 3,
            Image: HighlistImage4,
            TabName: 'All Day Assistant ',
            info: 'Sends messages, makes calls, get meeting invitations, and keep tabs on your messages from your wrist. Customise your watch’s face with information you want to keep at a glance, photos, or.'
        },
    ];

    return (
        <>  
            <div className='highlight-tab__wrap specifications-tab'>
                <div className="watchhightlightTab_para">
                    <p className='highlightpara'>The Apple watch series 3 is a sleek accessory thats a must have if youre all about staying fit. The watch deatures an enhanced heart rate app, and a built in altimeter. Also carry and listen to your favorite songs on your wrist. Equipped with Siri, this smartwatch makes being active and staying connected enjoyable. </p>
                </div>
                <div className="mar-spacer h-1"></div>
                {ProductHighlightsTab && ProductHighlightsTab.map((HighlightsTab) => {
                    return (
                        <Accordion defaultActiveKey={HighlightsTab._id}  key={HighlightsTab._id}>
                            <div className="mar-spacer h-1"></div>
                            <Accordion.Item eventKey={HighlightsTab._id} className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                                <Accordion.Header onClick={() => toggleActive('0')}>{HighlightsTab.TabName}</Accordion.Header>
                                <Accordion.Body>
                                    <div className='highlight-body__wrap'>
                                        <p className='highlight-body__info'>This Samsung mobile phone features an Infinity Flex Display with an Under Display Camera, allowing the device to function both as a smartphone and tablet, As a result, you can experienc a...</p>
                                        <Link to='/' className='highlight-body__link'>Read More</Link>
                                        <div className='mar-spacer h-1_2'></div>
                                        <img src={HighlightsTab.Image} className='img img-fluid' alt='' />
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    );
                })}
            </div>
        </>
    )
}

export default SmartwatchHighlightTab;