import React from 'react';
import Form from 'react-bootstrap/Form';


const OfferFilter = () => {
    return (
        <>
            <div className='filter-tab__lists'>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <label htmlFor="label" className='filter_price'>Bank offers</label>
                    </div>
                    <span className='filter-tab__count'>20</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <label htmlFor="label" className='filter_price'>Brand offers</label>
                    </div>
                    <span className='filter-tab__count'>20</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />                    
                        <label htmlFor="label" className='filter_price'>Cardless EMI</label>
                    </div>
                    <span className='filter-tab__count'>14</span>
                </div>
             
           
            </div>
        </>
    )
}

export default OfferFilter