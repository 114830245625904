import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

const CartSentGiftcart = ({...props}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className='shopping-cart__wrapper' onClick={handleShow} aria-hidden="true">
                <span>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.0837 1.66667C12.5759 1.6665 13.0601 1.7909 13.4913 2.02828C13.9225 2.26566 14.2866 2.60829 14.5497 3.02428C14.8128 3.44027 14.9663 3.91607 14.996 4.40738C15.0257 4.89869 14.9306 5.38952 14.7195 5.83417L17.5003 5.83334C17.7213 5.83334 17.9333 5.92113 18.0896 6.07741C18.2459 6.2337 18.3337 6.44566 18.3337 6.66667V10C18.3337 10.221 18.2459 10.433 18.0896 10.5893C17.9333 10.7455 17.7213 10.8333 17.5003 10.8333H16.667V17.5C16.667 17.721 16.5792 17.933 16.4229 18.0893C16.2666 18.2455 16.0547 18.3333 15.8337 18.3333H4.16699C3.94598 18.3333 3.73402 18.2455 3.57774 18.0893C3.42146 17.933 3.33366 17.721 3.33366 17.5V10.8333H2.50033C2.27931 10.8333 2.06735 10.7455 1.91107 10.5893C1.75479 10.433 1.66699 10.221 1.66699 10V6.66667C1.66699 6.44566 1.75479 6.2337 1.91107 6.07741C2.06735 5.92113 2.27931 5.83334 2.50033 5.83334L5.28116 5.83417C4.98429 5.21056 4.91835 4.50184 5.09508 3.83417C5.2718 3.1665 5.67973 2.58321 6.24624 2.18813C6.81275 1.79305 7.50108 1.61183 8.18869 1.67673C8.8763 1.74162 9.51857 2.04842 10.0012 2.5425C10.272 2.26461 10.5959 2.0439 10.9536 1.89346C11.3113 1.74302 11.6956 1.6659 12.0837 1.66667ZM15.0003 10.8333H5.00033V16.6667H15.0003V10.8333ZM16.667 7.5H3.33366V9.16667H16.667V7.5ZM7.91699 3.33334C7.59598 3.3335 7.28733 3.45715 7.055 3.67868C6.82268 3.9002 6.68448 4.20262 6.66906 4.52327C6.65363 4.84391 6.76215 5.1582 6.97214 5.401C7.18213 5.64381 7.47748 5.79653 7.79699 5.8275L7.91699 5.83334H9.16699V4.58334C9.16698 4.2726 9.05123 3.97301 8.84231 3.74299C8.63339 3.51296 8.34629 3.369 8.03699 3.33917L7.91699 3.33334ZM12.0837 3.33334L11.9637 3.33917C11.6748 3.36684 11.4046 3.49415 11.1993 3.69926C10.9941 3.90437 10.8665 4.1745 10.8387 4.46334L10.8337 4.58334V5.83334H12.0837L12.2037 5.8275C12.5128 5.79753 12.7998 5.6535 13.0086 5.42349C13.2174 5.19348 13.333 4.89398 13.333 4.58334C13.333 4.2727 13.2174 3.97319 13.0086 3.74318C12.7998 3.51317 12.5128 3.36915 12.2037 3.33917L12.0837 3.33334Z" fill="#121415"/>
                    </svg>
                </span>
                <span>Send this as gift</span>
            </div>
            <span role="button" onClick={handleShow} aria-hidden="true">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99967 14.6663C4.31767 14.6663 1.33301 11.6817 1.33301 7.99967C1.33301 4.31767 4.31767 1.33301 7.99967 1.33301C11.6817 1.33301 14.6663 4.31767 14.6663 7.99967C14.6663 11.6817 11.6817 14.6663 7.99967 14.6663ZM7.33501 10.6663L12.0483 5.95234L11.1057 5.00967L7.33501 8.78101L5.44901 6.89501L4.50634 7.83767L7.33501 10.6663Z" fill="#278C4C"/></svg>
            </span>


            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Send this as a gift</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='cart-gift__wrap'>
                        <div className='textarea-wrapper'>
                            <textarea className='textarea-form-control' cols={3} rows={4} value='Hi Nishanth,
                            A very happy birthday. Hope you enjoy this gift 🎂 Have Fun.'></textarea>
                        </div>  
                        <p className='cart-gift__text'>Note: ₹100 would be charged to deliver this product as a gift</p>
                        <button type='button' className='btn btn-dark-theme w-100'>Save Changes</button>                          
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

        </>
    )
}

export default CartSentGiftcart