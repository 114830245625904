import React, { useState } from 'react'

const OrderStrap = () => {


    const[Delivery]=useState(true);


    // const[failed]=useState(true);

    // const[pendingtxt]=useState(true);
 


    // const[stapperactive]=useState(true);

    // const[failedtitelactive]=useState(true)



    return (
        <>
            <div className="order_tracking-strapper">
                   
                {/* <ul className="StepProgress">
                    <li className="StepProgress-item is-done line-going">
                        <strong className="orderplace going_on">Order Placed</strong>
                        <span className='tracking_timing'>Today at 3:46 pm</span>
                          
                    </li>
                    <li className="StepProgress-item is-done line-going">
                        <strong className='orderplace going_on'>Order is on the way</strong>
                        
                        <span className='tracking_timing'>Arriving in 2 hrs</span>
                    </li>
                    {Delivery?
                        <li className="StepProgress-item is-done">
                            <strong>Delivery</strong>
                            <span className='tracking_timing'>Arriving in 2 hours</span>
                        </li>:
                        <li className={`StepProgress-item ${stapperactive ? 'is-done-failed':'is-done'}`}>                          
                        
                            {failed?    
                                <strong className={`${failedtitelactive?'delivery-failed-titel':'null'}`}>Delivery Failed</strong>:""}
                            {pendingtxt?        
                                <span className='tracking_timing'>Expected Delivery by 4:20pm, Today</span>:""}
                            
                        </li>
                    }
                    
                </ul>    */}

                <div className="stepper trackingstrappper">
                    <div className="d-flex mb-1">
                        <div className="starpper_round-linemain">
                            <div className="circleroundedstraper circle-check">
                            </div>
                            <div className="line line-dark"></div>
                        </div>
                        <div className='stapper_leftpadding '>
                            <strong className='strapperhead'>Order Placed</strong>
                            <span className='tracking_timing'>Today at 3:46 pm</span>
                        </div>
                    </div>
                    <div className="d-flex mb-1">
                        <div className="starpper_round-linemain">
                            <div className="circleroundedstraper circle-check"></div>
                            <div className="line"></div>
                        </div>
                        <div className='stapper_leftpadding '>
                            <strong className="strapperhead ">Order is on the way</strong>
                            <span className='tracking_timing'>Arriving in 2 hrs</span>
                        </div>
                    </div>
                    {Delivery?
                        <div className="d-flex mb-1">
                            <div className="starpper_round-linemain">
                                <div className="circleroundedstraper"></div>
                            </div>
                            <div className='stapper_leftpadding '>
                                <strong className="strapperhead strapperhead-mute">Delivery</strong>
                                <span className='tracking_timing'>Arriving in 2 hours</span>
                            </div>
                        </div>:
                    
                    
                    
                    
                        <div className="d-flex mb-1">
                            <div className="starpper_round-linemain">
                                <div className="circleroundedstraper circle-uncheck"></div>
                            </div>
                            <div className='stapper_leftpadding '>
                                <strong className='strapperhead delivery-failed-titel'>Delivery Failed</strong>
                                <span className='tracking_timing'>Expected Delivery by 4:20pm, Today</span>
                            </div>
                        </div>
                    }
   
                </div> 
                <div className="mar-spacer h-1_2"></div>


            </div>
        </>
    )
}

export default OrderStrap