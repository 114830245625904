import React from 'react';

import earphoneimg1 from '../../../Images/CategoryDetails/color-earphone.png';
import earphoneimg2 from '../../../Images/CategoryDetails/color-earphone2.png';
import earphoneimg3 from '../../../Images/CategoryDetails/color-earphone3.png';
import earphoneimg4 from '../../../Images/CategoryDetails/color-earphone4.png';
import earphoneimg5 from '../../../Images/CategoryDetails/color-earphone5.png';

const EarphoneColor = () => {
    return (
        <>
            <div className='product-colors-box'>
                <div className='product-colorFrame active'>
                    <div className='product-colorFrame-media'>
                        <img src={earphoneimg1} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Midnight Blue</div>
                </div>
                <div className='product-colorFrame'>
                    <div className='product-colorFrame-media'>
                        <img src={earphoneimg2} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Special : Iron Man </div>
                </div>
                <div className='product-colorFrame'>
                    <div className='product-colorFrame-media'>
                        <img src={earphoneimg3} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Awesome Black</div>
                </div>
                <div className='product-colorFrame'>
                    <div className='product-colorFrame-media'>
                        <img src={earphoneimg4} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Crimson Cream</div>
                </div>
                <div className='product-colorFrame out-of-stock'>
                    <div className='product-colorFrame-media'>
                        <img src={earphoneimg5} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Wakanda Edition</div>
                </div>
            </div>
        </>
    )
}

export default EarphoneColor;
