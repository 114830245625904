import React from 'react';
import Banner from '../../Images/brands/banner/banner.png';

const BrandBanner = () => {
    return (
        <>
            <div className='brand-banner__wrap'
                style={{ 
                    backgroundImage:`url(${Banner})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'bottom center',
                    backgroundSize: 'cover',
                }}
            >
                <div className='brand-banner__content'>
                    <h1 className='brand-banner__head'>Redmi</h1>
                    <p className='brand-banner__info'>Upto 40% off on branded phones</p>
                </div>
            </div>
        </>
    )
}

export default BrandBanner