import React from 'react'

const OrderTrackingStarapperShipped = () => {
    return (
        <>
            <div className="order_tracking-shipiment-strapper">
                <ul className="StepProgress">
                    <li className="StepProgress-item is-done line-going">
                        <strong className="orderplace going_on">Order Placed</strong>
                        <span className='tracking_timing'>Wednesday, 1 Jan, 2022</span>
                    </li>
                    <li className="StepProgress-item is-done line-going">
                        <strong className='orderplace going_on'>Packed</strong>
                        <span className='tracking_timing'>1 January, 2022</span>
                    </li>
                    <li className="StepProgress-item is-done line-going">
                        <strong className='orderplace'>Shipped</strong>
                        <span className='tracking_timing'> Delhivery : FMSOWHR920 </span>

                        <span className='tracking_timing'>Friday, 3 Jan, 2022</span>
                    </li>
                    <li className="StepProgress-item">
                        <strong>Delivery</strong>
                        <span className='tracking_timing'>Expected delivery by Thursday, 4th Jan, 2022</span>
                    </li>
                </ul>
                <div className="mar-spacer h-1_2"></div>

            </div>
        </>
    )
}

export default OrderTrackingStarapperShipped;