import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const AllCategories = () => { 
    const [ activeMenu, setActiveMenu] = useState(false);

    const handleToggle = () => {
        setActiveMenu(!activeMenu);
    };
    return (
        <>	
            <li 
                className={activeMenu ? 'nav-item nav-expand active' : 'nav-item nav-expand'}
                aria-hidden="true"
            >
                <Link 
                    className='nav-link nav-expand-link'
                    to='/'
                    onClick={handleToggle} 
                >
                    <div className='d-flex align-items-center'>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.6668 8.666V13.3333C14.6668 13.5101 14.5966 13.6797 14.4716 13.8047C14.3465 13.9298 14.177 14 14.0002 14H8.66683V8.666H14.6668ZM7.3335 8.666V14H2.00016C1.82335 14 1.65378 13.9298 1.52876 13.8047C1.40373 13.6797 1.3335 13.5101 1.3335 13.3333V8.666H7.3335ZM7.3335 2V7.33267H1.3335V2.66667C1.3335 2.48986 1.40373 2.32029 1.52876 2.19526C1.65378 2.07024 1.82335 2 2.00016 2H7.3335ZM14.0002 2C14.177 2 14.3465 2.07024 14.4716 2.19526C14.5966 2.32029 14.6668 2.48986 14.6668 2.66667V7.33267H8.66683V2H14.0002Z" fill="#121415"/>
                            </svg>

                        </span>
                        <span className='nav-title'>All Categories</span>
                    </div>
                    <span>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.78145 7.99999L5.48145 4.69999L6.42411 3.75732L10.6668 7.99999L6.42411 12.2427L5.48145 11.3L8.78145 7.99999Z" fill="#121415"/>
                        </svg>
	
                    </span>
                </Link>
                <ul className='nav-items nav-expand-content'>
                    <li 
                        className='nav-item'
                        aria-hidden="true"
                    >
                        <Link to='/' className='nav-link nav-back-link' onClick={() => setActiveMenu(false)}>
                            <div className='d-flex align-items-center'>
                                <span>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.02341 10.0001L13.1484 14.1251L11.9701 15.3034L6.66675 10.0001L11.9701 4.69678L13.1484 5.87511L9.02341 10.0001Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span>All Categories</span>
                            </div>
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <a href='/' className='nav-link'>Home</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/SearchResult' className='nav-link'>Search Page</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/Categories' className='nav-link'>Category List</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/Category' className='nav-link'>Category Details</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/Brands' className='nav-link'>Brand List</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/Brand' className='nav-link'>Brand Details</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/ProductDetails' className='nav-link'>Product Details</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/ProductRenewed' className='nav-link'>Renewed Product Details</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/PreBooking' className='nav-link'>Pre Launch</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/ProductOutOfStock' className='nav-link'>Product Out Of Stock</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/ProductEarphone' className='nav-link'>Product Category Earphone</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/ProductSmartWatch' className='nav-link'>Product Category Smart Watch</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/ProductAccessories' className='nav-link'>Product Category Accessories</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/ProductSmartTv' className='nav-link'>Product Category Smart TV</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/ProductList' className='nav-link'>Product List</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/Compare' className='nav-link'>Compare</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/SmartWatchList' className='nav-link'>Smart Watch List</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/MobileAccessoriesList' className='nav-link'>Mobile Accessories List</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/HeadPhoneList' className='nav-link'>Head Phone List</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/Cart' className='nav-link'>Cart</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/Address' className='nav-link'>Address</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/Payment' className='nav-link'>Payment</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/Order' className='nav-link'>Order</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/OrderConfirmation' className='nav-link'>Order Confirmation</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/OrdersuccessfullDetails' className='nav-link'>Order Successful Details</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/OrderTrack' className='nav-link'>OrderTrack</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/DIY' className='nav-link'>DIY</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/DIYLaptop' className='nav-link'>DIY Laptop</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/DIYHeadphone' className='nav-link'>DIY Headphone</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/OrderCancellationDetails' className='nav-link'>Order Cancellation Details</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/OrderCancelled' className='nav-link'>Order Cancelled</a> 
                    </li>
                    <li className='nav-item'>
                        <a href='/OrderCanceledDetails' className='nav-link'>Cancelled Order Details</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/RenewedPrelaunchList' className='nav-link'>Renewed & Pre Launch List</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/StoreLocator' className='nav-link'>Store Locator</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/OrderStatus' className='nav-link'>Order Status</a>
                    </li>
                    <li className='nav-item'>
                        <a href='/OrderTrackerStatus' className='nav-link'>Order Track Status</a>
                    </li>
                </ul>
            </li>
        </>
    )
}

export default AllCategories