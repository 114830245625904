import React from 'react';
import Form from 'react-bootstrap/Form';

import Apple from '../../../Images/brands/1.svg';
import OnePlus from '../../../Images/brands/2.svg';
import Samsung from '../../../Images/brands/3.svg';
import MI from '../../../Images/brands/4.svg';
import Vivo from '../../../Images/brands/5.svg';
import Google from '../../../Images/brands/6.svg';
import Huawei from '../../../Images/brands/7.svg';
import LG from '../../../Images/brands/8.svg';

const Step2 = () => {

    return (
        <>
            <div className="exchange-data all-brands">
                <div className='px-3'>
                    <h3 className="Exchangehead"> Tell us your phone&apos;s brand </h3>
                    <div className='header-home__search'>
                        <form className="form">
                            <div className="form-group">
                                <div className="input-icon">
                                    <input type="text" className="bannerInput-search" placeholder="Search for brands"/>
                                    <span className="bannerSearch-icon d-flex">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.0257 13.8478L18.5948 17.4162L17.4157 18.5953L13.8473 15.0262C12.5196 16.0905 10.8682 16.6694 9.1665 16.667C5.0265 16.667 1.6665 13.307 1.6665 9.16699C1.6665 5.02699 5.0265 1.66699 9.1665 1.66699C13.3065 1.66699 16.6665 5.02699 16.6665 9.16699C16.6689 10.8687 16.09 12.5201 15.0257 13.8478ZM13.354 13.2295C14.4116 12.1419 15.0022 10.684 14.9998 9.16699C14.9998 5.94366 12.389 3.33366 9.1665 3.33366C5.94317 3.33366 3.33317 5.94366 3.33317 9.16699C3.33317 12.3895 5.94317 15.0003 9.1665 15.0003C10.6835 15.0027 12.1414 14.4121 13.229 13.3545L13.354 13.2295Z" fill="#121415"/>
                                        </svg>               
                                    </span>
                                </div>                                            
                            </div>
                        </form>
                    </div>
                </div>
                <div className='exchange-brand__lists'>
                    <div className='exchange_heading col-12'>Offers on Brands</div>
                    <div className='exchange_list'>
                        <img src={Apple} className='img-fluid' alt='' />
                    </div>
                    <div className='exchange_list'>
                        <img src={OnePlus} className='img-fluid' alt='' />
                    </div>
                    <div className='exchange_list'>
                        <img src={Samsung} className='img-fluid' alt='' />
                    </div>
                    <div className='exchange_list'>
                        <img src={MI} className='img-fluid' alt='' />
                    </div>
                    <div className='exchange_list'>
                        <img src={Vivo} className='img-fluid' alt='' />
                    </div>
                </div>
                <div className='exchange-brand__lists bg-white'>
                    <div className='exchange_heading col-12'>All Brands</div>
                    <div className='exchange_flex'>
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" defaultChecked/>
                            <label htmlFor="label" className="filter_exchange ml-2">
                                <img src={Google} className='img-fluid' alt='' />
                                <span className='ml-2'>Google</span>
                            </label>
                        </div>
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon' aria-label="radio 1"/>
                            <label htmlFor="label" className="filter_exchange ml-2">
                                <img src={Huawei} className='img-fluid' alt='' />
                                <span className='ml-2'>Huawei</span>
                            </label>
                        </div>
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon' aria-label="radio 1"/>
                            <label htmlFor="label" className="filter_exchange ml-2">
                                <img src={LG} className='img-fluid' alt='' />
                                <span className='ml-2'>LG</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer h-3 mb-2' />
        </>
    )
}

export default Step2