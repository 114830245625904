import React from 'react';
import { Link } from '@reach/router';

const EarphonePayment = () => {
    return (
        <>
            <div className='product-payment-offers'>                        
                <Link to='/EMIListTab' className='payment-offer-options'>
                    <div className='payment-offer-content'>
                        <div className='payment-offer-icon'>
                            <span>
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.8498 8.33333V16.6667C18.8498 16.8877 18.7595 17.0996 18.5988 17.2559C18.4381 17.4122 18.2202 17.5 17.9929 17.5H2.56973C2.34249 17.5 2.12454 17.4122 1.96385 17.2559C1.80316 17.0996 1.71289 16.8877 1.71289 16.6667V8.33333H18.8498ZM18.8498 6.66667H1.71289V3.33333C1.71289 3.11232 1.80316 2.90036 1.96385 2.74408C2.12454 2.5878 2.34249 2.5 2.56973 2.5H17.9929C18.2202 2.5 18.4381 2.5878 18.5988 2.74408C18.7595 2.90036 18.8498 3.11232 18.8498 3.33333V6.66667ZM12.8519 13.3333V15H16.2792V13.3333H12.8519Z" fill="#121415"/>
                                </svg>
                            </span>
                        </div>
                        <div className='payment-offer-info'>
                            <h6>EMI Options</h6>
                            <p className='details-text'>Starting from <span> ₹3,950/month</span></p>
                        </div>
                    </div>
                    <div className='payment-offer-link'>
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.172 12.0007L8.222 7.05072L9.636 5.63672L16 12.0007L9.636 18.3647L8.222 16.9507L13.172 12.0007Z" fill="#121415"/>
                            </svg>
                        </span>
                    </div>
                </Link>
                <Link to='/' className='payment-offer-options'>
                    <div className='payment-offer-content'>
                        <div className='payment-offer-icon'>
                            <span>
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.7663 7.64027L18.7645 7.64027L18.7645 13.4739L17.0511 13.4739L17.0505 10.4846L9.07045 18.2469L7.85869 17.0684L15.8393 9.30668L12.7663 9.30669V7.64027ZM1.79989 6.52068L3.51332 6.52068L3.51332 9.50939L11.494 1.74771L12.7057 2.92623L4.72508 10.6879L7.79811 10.6879L7.7981 12.3543L1.79989 12.3543L1.79989 6.52068Z" fill="#121415"/>
                                </svg>
                            </span>
                        </div>
                        <div className='payment-offer-info'>
                            <h6>Exchange your old Earphone </h6>
                            <p className='details-text'>Save upto <span> ₹9,800</span></p>
                        </div>
                    </div>
                    <div className='payment-offer-link'>
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.172 12.0007L8.222 7.05072L9.636 5.63672L16 12.0007L9.636 18.3647L8.222 16.9507L13.172 12.0007Z" fill="#121415"/>
                            </svg>
                        </span>
                    </div>
                </Link>
            </div>
        </>
    )
}

export default EarphonePayment
