import React from 'react';
import Form from 'react-bootstrap/Form';

const Step3 = ({ formData, setFormData }) => {
    const availableCategories = [
        {
            __id : 1,
            name: '₹19,999 and below',
            status: 'unchecked',
            val: 72
        }, {
            __id : 2,
            name: '₹20,000 - ₹29,999',
            status: 'unchecked',
            val: 34
        }, {
            __id : 3,
            name: '₹30,999 - ₹39,999',
            status: 'checked',
            val: 45
        }, {
            __id : 4,
            name: '₹40,000 - ₹49,999',
            status: 'checked',
            val: 45
        }, {
            __id : 5,
            name: '₹50,000 - ₹74,999',
            status: 'unchecked',
            val: 45
        }, {
            __id : 6,
            name: '₹75,000 - ₹99,999',
            status: 'unchecked',
            val: 45
        }, {
            __id : 7,
            name: '₹1,00,000 and above ',
            status: 'unchecked',
            val: 45
        }, {
            __id : 8,
            name: '₹1,00,000 and above ',
            status: 'unchecked',
            val: 45
        }, {
            __id : 9,
            name: '₹1,00,000 and above ',
            status: 'unchecked',
            val: 45
        }, {
            __id : 10,
            name: '₹1,00,000 and above ',
            status: 'unchecked',
            val: 14
        }, {
            __id : 11,
            name: '₹1,00,000 and above ',
            status: 'unchecked',
            val: 12
        }, {
            __id : 12,
            name: '₹1,00,000 and above ',
            status: 'unchecked',
            val: 7
        }
    ];
    return (
        <>
            <div className="sign-up-container">
                <div className="exchange-data">
                    <h3 className='steps__head'>What is your budget?</h3>
                    <p className='steps__info'>Select upto 2 options from below :  </p>

                    <div className='filter-tab__lists'>     
                        {availableCategories.map((Data) => (
                            <div className={Data.status === 'checked' ? 'filter-tab__flex changeexchange-div active' : 'filter-tab__flex changeexchange-div'} key={Data.__id}>
                                <div className='steps__content'>
                                    <div className='d-flex align-items-center justify-content-between budget-flex'>
                                        <label htmlFor="label" className='filter_exchange m-0'>{Data.name}</label>
                                        <span>{Data.val}</span>
                                    </div>
                                    
                                    <Form.Check 
                                        className='filter__checkbox sort__checkbox' 
                                        aria-label="option 1" 
                                        value={Data}
                                        checked={Data.status === 'checked' ? 'checked' : null}
                                        onChange={(event) =>
                                            setFormData({ ...formData, password: event.target.value })
                                        }
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>                
            </div>
        </>
    )
}

export default Step3
