import React from 'react';
import NavSearchList from './NavSearchList';

const NavSearchBar = () => {

    return (
        <>
            {['top'].map((placement, idx) => (
                <NavSearchList key={idx} placement={placement} name={placement} />
            ))}           
        </>
    )
}

export default NavSearchBar