import React from 'react';
import fire from'../../../../Images/CategoryDetails/logo/fire.png'
const PeopleOrdered = () => {
    return (
        <div className='product-specifications'>
            <div className='padding_class background'>
                <div className="images">
                    <img src={fire} alt="Fire_Images"className='img img-fluid w-100' />
                </div>
                <p className='order-para'>
      210 people ordered this in the last 7 days
                </p>
            </div>
        </div>
    )
}

export default PeopleOrdered;

