import React from 'react';
import Form from 'react-bootstrap/Form'

const ColorFilter = () => {
    return (
        <>
            <div className='filter-tab__lists'>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#D6EEFF'}}></span>
                        </div>
                        <label htmlFor="label">Pastel Blue</label>
                    </div>
                    <span className='filter-tab__count'>20</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#000000'}}></span>
                        </div>
                        <label htmlFor="label">Black</label>
                    </div>
                    <span className='filter-tab__count'>14</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#ffffff', 'border': '1px solid #EAECF0'}}></span>
                        </div>
                        <label htmlFor="label">White</label>
                    </div>
                    <span className='filter-tab__count'>20</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#ECECEC'}}></span>
                        </div>
                        <label htmlFor="label">Grey</label>
                    </div>
                    <span className='filter-tab__count'>12</span>
                </div>
            </div>
            <div className='filter-tab__heading'>
                <h4>All Colors</h4>
            </div>
            <div className='filter-tab__lists'>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#EECCEE'}}></span>
                        </div>
                        <label htmlFor="label">Purple</label>
                    </div>
                    <span className='filter-tab__count'>15</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#606060'}}></span>
                        </div>
                        <label htmlFor="label">Charcoal</label>
                    </div>
                    <span className='filter-tab__count'>2</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#78E29F'}}></span>
                        </div>
                        <label htmlFor="label">Green</label>
                    </div>
                    <span className='filter-tab__count'>55</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#ECECCE'}}></span>
                        </div>
                        <label htmlFor="label">Beige</label>
                    </div>
                    <span className='filter-tab__count'>8</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#FFB7B7'}}></span>
                        </div>
                        <label htmlFor="label">Pink</label>
                    </div>
                    <span className='filter-tab__count'>3</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#000000'}}></span>
                        </div>
                        <label htmlFor="label">Black</label>
                    </div>
                    <span className='filter-tab__count'>10</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#03436F'}}></span>
                        </div>
                        <label htmlFor="label">Navy Blue</label>
                    </div>
                    <span className='filter-tab__count'>1</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#000000'}}></span>
                        </div>
                        <label htmlFor="label">Black</label>
                    </div>
                    <span className='filter-tab__count'>8</span>
                </div>
            </div>
        </>
    )
}

export default ColorFilter
