import React from 'react';
import Packagebox from '../../Images/CategoryDetails/Order/Package_box.png'

const Lookoutdelivery = () => {
    return (
        <>
            <div className="offer-box why-buy lookout_delivery">
                <div className="lookoutbox_wrap">
                    <div className="">
                        <img src={Packagebox} alt="" className='img' />
                    </div>
                    <p className="mb-0 offer-box-info_msg">Look out for <span className='offer-box-info_msg-free'>FREE</span>  services by Sangeetha on your Delivery</p>
                </div>
            </div>
        </>
    )
}

export default Lookoutdelivery;