import React from 'react';
import { Link } from "@reach/router";
import Covers from '../../Images/Home/accessories/1.png';
import CableManagers from '../../Images/Home/accessories/2.png';
import Gimble from '../../Images/Home/accessories/3.png';
import PopSockets from '../../Images/Home/accessories/4.png';

const Accessories = () => {
    return (
        <>    
            <div className="category__wrap phone-accessories__wrap p-x-16 m-0">
                <div className="category-list__wrap">
                    <div className="catg-item-wrapper">
                        <div className="catg-img-wrapper">
                            <img src={Covers} className="img-fluid" alt=""/>
                        </div>
                        <Link to='/' className="catg-content-wrapper">
                            <span>Phone Covers</span>
                        </Link>
                    </div>
                </div>
                <div className="category-list__wrap">
                    <div className="catg-item-wrapper">
                        <div className="catg-img-wrapper">
                            <img src={CableManagers} className="img-fluid" alt=""/>
                        </div>
                        <Link to='/' className="catg-content-wrapper">
                            <span>Pop Sockets</span>
                        </Link>
                    </div>
                </div>
                <div className="category-list__wrap">
                    <div className="catg-item-wrapper">
                        <div className="catg-img-wrapper">
                            <img src={Gimble} className="img-fluid" alt=""/>
                        </div>
                        <Link to='/' className="catg-content-wrapper">
                            <span>Gimbals</span>
                        </Link>
                    </div>
                </div>
                <div className="category-list__wrap">
                    <div className="catg-item-wrapper">
                        <div className="catg-img-wrapper">
                            <img src={PopSockets} className="img-fluid" alt=""/>
                        </div>
                        <Link to='/' className="catg-content-wrapper">
                            <span>Wireless Charger</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Accessories
