import React from 'react';
import Carousel from "react-multi-carousel";
import CartBankOfferList from './CartBankOfferList';
import CartEMIOfferList from './CartEMIOfferList';
import Discount from '../../Images/product/emi/Discount.png';
import Group from '../../Images/product/emi/Group.png';
import hdfc from '../../Images/product/emi/hdfc.png';
import Zestpay from '../../Images/product/emi/zest.png';


const CartOffers = () => {
    const responsive = {
        tablet: {
            breakpoint: { max: 768, min: 567 },
            items: 3,
            partialVisibilityGutter: 0
        },
        mobile: {
            breakpoint: { max: 567, min: 0 },
            items: 1,
            partialVisibilityGutter: 50
        }
    };

    return (
        <>
            <Carousel                
                partialVisible={true}
                arrows={false}
                itemClass="image-item"
                responsive={responsive}
            >   
                <div className='offer-box product-offer-wrapper- offer-box- why-buy-'>
                    <div className='card__wrap'>
                        <h5>
                            <img src={Discount} className='img img-fluid' alt='' />
                            <span>Bank Offers</span>
                        </h5>
                        <p className='mb-0 offer-box-info'>
                            <img src={hdfc} className='img img-fluid' alt='' />
                            <span>10% Instant Savings on Citi Credit and Debit Cards on a min of ₹2,999 TCA</span>
                        </p>
                        <div className='flexible'></div>
                        {['bottom'].map((placement, idx) => (
                            <CartBankOfferList key={idx} placement={placement} name={placement} />
                        ))}
                    </div>
                </div>
                <div className='offer-box product-offer-wrapper- offer-box- why-buy-'>
                    <div className='card__wrap'>
                        <h5>
                            <img src={Group} className='img img-fluid' alt='' />
                            <span>Cardless EMI</span>
                        </h5>
                        <p className='mb-0 offer-box-info'>
                            <img src={Zestpay} className='img img-fluid' alt='' />
                            <span>Buy your new phone @ ₹2515/month and Save ₹2,999 on interest</span>
                        </p>
                        <div className='flexible'></div>
                        {['bottom'].map((placement, idx) => (
                            <CartEMIOfferList key={idx} placement={placement} name={placement} />
                        ))}
                    </div>
                </div>
            </Carousel>
        </>
    )
}

export default CartOffers