import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import EarphoneHighlightCarousel from '../CategoryDetails/EarphoneHighlightCarousel';
import ProductPolicy from './../ProductPolicy';

import wireless from '../../../Images/CategoryDetails/logo/Vector.png';
import bluetooth from '../../../Images/CategoryDetails/logo/bluetooth.png';
import size from '../../../Images/CategoryDetails/logo/size.png';
import Weight from '../../../Images/CategoryDetails/logo/weight.png';
import microphone from '../../../Images/CategoryDetails/logo/micear.png';
import warenty from '../../../Images/CategoryDetails/logo/warenty.png';
import { Link } from '@reach/router';


const EarphoneHeighlight = () => {

    const [activeId, setActiveId] = useState('1');

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }
    const ProductSpecifications = [
        {   
            _id: 1,
            Image: wireless,
            label: 'Type',
            Name: 'Wireless'
        }, {   
            _id: 2,
            Image: bluetooth,
            label: 'Connectivity',  
            Name: 'Bluetooth'
        }, {   
            _id: 3,
            Image: size,
            label: 'Size',
            Name: '3.8 x 3.05 x 7 cm'
        }, {
            _id: 4,
            Image: Weight,
            label: 'Weight',
            Name: '48.5 grams'
        }, {
            _id: 5,
            Image: microphone,
            label: 'Microphone',
            Name: 'With Microphone'
        }, {
            _id: 6,
            Image: warenty,
            label: 'Warranty',
            Name: '1 year'
        }
    ];

    return (
        <>             
            <Accordion defaultActiveKey={activeId}>
                <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('0')}>Specifications</Accordion.Header>
                    <Accordion.Body>
                        <div className='product-specification__wrap'>
                            {ProductSpecifications.map((Specification) => {
                                return (
                                    <div className='specification__list' key={Specification._id}>
                                        <div className='specification-list__icon'>
                                            <img src={Specification.Image} className='img img-fluid' alt={Specification.Name} />
                                        </div>
                                        <div className='specification-list__content'>
                                            <label htmlFor="label">{Specification.label}</label>
                                            <span>{Specification.Name}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='product-specification__otherInfo'>
                            <h6 className='specification-info__head'>Other Details</h6>
                            <div className='specification-info__table'>
                                <table className='table specification-striped__table'>
                                    <tbody>
                                        <tr>
                                            <th>Designed for</th>
                                            <td>Android, Windows, iOS</td>
                                        </tr>
                                        <tr>
                                            <th>Water Resistant </th>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <th>Sweat Resistant</th>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <th>Headphone Design</th>
                                            <td>Earbud</td>
                                        </tr>
                                        <tr>
                                            <th>Accessories Included</th>
                                            <td>Airdopes 131,Charging Case, Type-c Charging, User Manual, Warranty Card</td>
                                        </tr>
                                        <tr>
                                            <th>Battery</th>
                                            <td>12 hours</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Link to='/CategoryDetails/ProductSpecifications' className='specification-info__head text-decoration-underline'>View All Details</Link>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className={activeId === '1' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('1')}>Highlights</Accordion.Header>
                    <Accordion.Body>
                        <div className='product-highlight__otherInfo'>
                            <EarphoneHighlightCarousel />
                            <Link to='/CategoryDetails/ProductSpecifications' className='specification-info__head text-decoration-underline'>View More Highlights</Link>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className={activeId === '2' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('2')}>Cancellation & Return Policy</Accordion.Header>
                    <Accordion.Body>
                        <ProductPolicy />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}
 
export default EarphoneHeighlight;