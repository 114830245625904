import { Link } from '@reach/router';
import React from 'react';
import BrandOne from '../../Images/Home/brand/1.png';
import BrandTwo from '../../Images/Home/brand/2.png';
import BrandThree from '../../Images/Home/brand/3.png';
import BrandFour from '../../Images/Home/brand/4.png';
import BrandFive from '../../Images/Home/brand/5.png';
import BrandSix from '../../Images/Home/brand/6.png';
import BrandSeven from '../../Images/Home/brand/7.png';
import BrandEight from '../../Images/Home/brand/8.png';
 
const TopBrands = ({ brandData }) => {
    console.log('Brand component', brandData);
    return (
        <>      
            <div className='brand-items-wrapper'>
                {/* {brandData && brandData.map((Data) => {
                    return (
                        <Link to='/' className='brand-content-wrapper' key={Data.brand_id}>
                            <div className="brand-img">
                                <img src={Data.image} className="img-fluid brand-image-style" alt="Apple"/>
                            </div>
                        </Link>
                    );
                })} */}
                <Link to='/' className='brand-content-wrapper'>
                    <div className="brand-img">
                        <img src={BrandOne} className="img-fluid brand-image-style" alt="Apple"/>
                    </div>
                </Link>
                <Link to='/' className='brand-content-wrapper'>
                    <div className="brand-img">
                        <img src={BrandTwo} className="img-fluid brand-image-style" alt="Apple"/>
                    </div>
                </Link>
                <Link to='/' className='brand-content-wrapper'>
                    <div className="brand-img">
                        <img src={BrandThree} className="img-fluid brand-image-style" alt="Apple"/>
                    </div>
                </Link>
                <Link to='/' className='brand-content-wrapper'>
                    <div className="brand-img">
                        <img src={BrandFour} className="img-fluid brand-image-style" alt="Apple"/>
                    </div>
                </Link>
                <Link to='/' className='brand-content-wrapper'>
                    <div className="brand-img">
                        <img src={BrandFive} className="img-fluid brand-image-style" alt="Apple"/>
                    </div>
                </Link>
                <Link to='/' className='brand-content-wrapper'>
                    <div className="brand-img">
                        <img src={BrandSix} className="img-fluid brand-image-style" alt="Apple"/>
                    </div>
                </Link>

                <Link to='/' className='brand-content-wrapper'>
                    <div className="brand-img">
                        <img src={BrandSeven} className="img-fluid brand-image-style" alt="Apple"/>
                    </div>
                </Link>

                <Link to='/' className='brand-content-wrapper'>
                    <div className="brand-img">
                        <img src={BrandEight} className="img-fluid brand-image-style" alt="Apple"/>
                    </div>
                </Link>

            </div>
        </>
    )
}

export default TopBrands
