import React from 'react';
import { FaStar } from 'react-icons/fa';

const ReviewComments = () => {
    return (
        <>
            <div className='review-comments'>
                <h6 className='reviewer-name'>Very nice</h6>
                <div className='review-stars'>
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                </div>
                <div className='review-text'>Finally received this product! Thank you for these stylish and functional, head turners!</div>
                <div className='review-info'>
                    <div className='reviewer-info'>
                        <span>Tina Munim</span><span>10 Jun, 2022</span>
                    </div>
                    <div className='reviewer-likes_dislikes'>
                        <div className='reviewer-likes'>
                            <span>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.3 4.0002H10.5C10.7652 4.0002 11.0196 4.10555 11.2071 4.29309C11.3946 4.48063 11.5 4.73498 11.5 5.0002V6.0522C11.5001 6.18288 11.4747 6.31232 11.425 6.4332L9.8775 10.1907C9.83975 10.2823 9.77563 10.3606 9.69329 10.4157C9.61094 10.4708 9.51408 10.5002 9.415 10.5002H1C0.867392 10.5002 0.740215 10.4475 0.646447 10.3538C0.552678 10.26 0.5 10.1328 0.5 10.0002V5.0002C0.5 4.86759 0.552678 4.74041 0.646447 4.64664C0.740215 4.55287 0.867392 4.5002 1 4.5002H2.741C2.82105 4.50022 2.89993 4.48102 2.97102 4.44421C3.0421 4.40741 3.10331 4.35407 3.1495 4.2887L5.876 0.425196C5.91047 0.376352 5.9613 0.341476 6.01927 0.326891C6.07724 0.312306 6.13852 0.318978 6.192 0.345696L7.099 0.799196C7.35425 0.926777 7.55817 1.13785 7.67687 1.39735C7.79556 1.65685 7.8219 1.94916 7.7515 2.2257L7.3 4.0002ZM3.5 5.2942V9.5002H9.08L10.5 6.0522V5.0002H7.3C7.1477 5.00017 6.99742 4.96537 6.86061 4.89843C6.72381 4.83148 6.60411 4.73418 6.51064 4.61394C6.41716 4.4937 6.35239 4.35369 6.32126 4.20461C6.29013 4.05552 6.29346 3.9013 6.331 3.7537L6.7825 1.9797C6.79663 1.92436 6.79139 1.86585 6.76765 1.81391C6.7439 1.76197 6.70309 1.71972 6.652 1.6942L6.3215 1.5292L3.9665 4.8652C3.8415 5.0422 3.6815 5.1872 3.5 5.2942ZM2.5 5.5002H1.5V9.5002H2.5V5.5002Z" fill="#6B7586"/>
                                </svg>
                            </span>
                            <span>273</span>
                        </div>
                        <div className='reviewer-dislikes'>
                            <span>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.7 8H1.5C1.23478 8 0.98043 7.89464 0.792894 7.70711C0.605357 7.51957 0.500001 7.26522 0.500001 7V5.948C0.499866 5.81732 0.525346 5.68788 0.575001 5.567L2.123 1.81C2.16064 1.71838 2.22465 1.64 2.3069 1.58481C2.38915 1.52961 2.48595 1.5001 2.585 1.5H11C11.1326 1.5 11.2598 1.55268 11.3536 1.64645C11.4473 1.74021 11.5 1.86739 11.5 2V7C11.5 7.13261 11.4473 7.25979 11.3536 7.35355C11.2598 7.44732 11.1326 7.5 11 7.5H9.259C9.17895 7.49998 9.10007 7.51918 9.02899 7.55598C8.9579 7.59279 8.89669 7.64612 8.8505 7.7115L6.124 11.5745C6.08953 11.6233 6.03871 11.6582 5.98073 11.6728C5.92276 11.6874 5.86148 11.6807 5.808 11.654L4.901 11.2C4.64575 11.0724 4.44183 10.8613 4.32314 10.6018C4.20444 10.3423 4.1781 10.05 4.2485 9.7735L4.7 8ZM8.5 6.706V2.5H2.92L1.5 5.948V7H4.7C4.8523 7.00002 5.00259 7.03483 5.13939 7.10177C5.27619 7.16871 5.39589 7.26601 5.48936 7.38626C5.58284 7.5065 5.64761 7.6465 5.67874 7.79559C5.70988 7.94467 5.70654 8.0989 5.669 8.2465L5.2175 10.0205C5.20337 10.0758 5.20861 10.1343 5.23236 10.1863C5.2561 10.2382 5.29691 10.2805 5.348 10.306L5.6785 10.471L8.0335 7.135C8.1585 6.958 8.3185 6.813 8.5 6.706ZM9.5 6.5H10.5V2.5H9.5V6.5Z" fill="#6B7586"/>
                                </svg>
                            </span>
                            <span>75</span>
                        </div>
                    </div>
                </div>   
            </div>

            <div className='review-comments'>
                <h6 className='reviewer-name'>Very nice</h6>
                <div className='review-stars'>
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                </div>
                <div className='review-text'>Finally received this product! Thank you for these stylish and functional, head turners!</div>
                <div className='review-info'>
                    <div className='reviewer-info'>
                        <span>Tina Munim</span><span>10 Jun, 2022</span>
                    </div>
                    <div className='reviewer-likes_dislikes'>
                        <div className='reviewer-likes'>
                            <span>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.3 4.0002H10.5C10.7652 4.0002 11.0196 4.10555 11.2071 4.29309C11.3946 4.48063 11.5 4.73498 11.5 5.0002V6.0522C11.5001 6.18288 11.4747 6.31232 11.425 6.4332L9.8775 10.1907C9.83975 10.2823 9.77563 10.3606 9.69329 10.4157C9.61094 10.4708 9.51408 10.5002 9.415 10.5002H1C0.867392 10.5002 0.740215 10.4475 0.646447 10.3538C0.552678 10.26 0.5 10.1328 0.5 10.0002V5.0002C0.5 4.86759 0.552678 4.74041 0.646447 4.64664C0.740215 4.55287 0.867392 4.5002 1 4.5002H2.741C2.82105 4.50022 2.89993 4.48102 2.97102 4.44421C3.0421 4.40741 3.10331 4.35407 3.1495 4.2887L5.876 0.425196C5.91047 0.376352 5.9613 0.341476 6.01927 0.326891C6.07724 0.312306 6.13852 0.318978 6.192 0.345696L7.099 0.799196C7.35425 0.926777 7.55817 1.13785 7.67687 1.39735C7.79556 1.65685 7.8219 1.94916 7.7515 2.2257L7.3 4.0002ZM3.5 5.2942V9.5002H9.08L10.5 6.0522V5.0002H7.3C7.1477 5.00017 6.99742 4.96537 6.86061 4.89843C6.72381 4.83148 6.60411 4.73418 6.51064 4.61394C6.41716 4.4937 6.35239 4.35369 6.32126 4.20461C6.29013 4.05552 6.29346 3.9013 6.331 3.7537L6.7825 1.9797C6.79663 1.92436 6.79139 1.86585 6.76765 1.81391C6.7439 1.76197 6.70309 1.71972 6.652 1.6942L6.3215 1.5292L3.9665 4.8652C3.8415 5.0422 3.6815 5.1872 3.5 5.2942ZM2.5 5.5002H1.5V9.5002H2.5V5.5002Z" fill="#6B7586"/>
                                </svg>
                            </span>
                            <span>273</span>
                        </div>
                        <div className='reviewer-dislikes'>
                            <span>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.7 8H1.5C1.23478 8 0.98043 7.89464 0.792894 7.70711C0.605357 7.51957 0.500001 7.26522 0.500001 7V5.948C0.499866 5.81732 0.525346 5.68788 0.575001 5.567L2.123 1.81C2.16064 1.71838 2.22465 1.64 2.3069 1.58481C2.38915 1.52961 2.48595 1.5001 2.585 1.5H11C11.1326 1.5 11.2598 1.55268 11.3536 1.64645C11.4473 1.74021 11.5 1.86739 11.5 2V7C11.5 7.13261 11.4473 7.25979 11.3536 7.35355C11.2598 7.44732 11.1326 7.5 11 7.5H9.259C9.17895 7.49998 9.10007 7.51918 9.02899 7.55598C8.9579 7.59279 8.89669 7.64612 8.8505 7.7115L6.124 11.5745C6.08953 11.6233 6.03871 11.6582 5.98073 11.6728C5.92276 11.6874 5.86148 11.6807 5.808 11.654L4.901 11.2C4.64575 11.0724 4.44183 10.8613 4.32314 10.6018C4.20444 10.3423 4.1781 10.05 4.2485 9.7735L4.7 8ZM8.5 6.706V2.5H2.92L1.5 5.948V7H4.7C4.8523 7.00002 5.00259 7.03483 5.13939 7.10177C5.27619 7.16871 5.39589 7.26601 5.48936 7.38626C5.58284 7.5065 5.64761 7.6465 5.67874 7.79559C5.70988 7.94467 5.70654 8.0989 5.669 8.2465L5.2175 10.0205C5.20337 10.0758 5.20861 10.1343 5.23236 10.1863C5.2561 10.2382 5.29691 10.2805 5.348 10.306L5.6785 10.471L8.0335 7.135C8.1585 6.958 8.3185 6.813 8.5 6.706ZM9.5 6.5H10.5V2.5H9.5V6.5Z" fill="#6B7586"/>
                                </svg>
                            </span>
                            <span>75</span>
                        </div>
                    </div>
                </div>   
            </div>

            <div className='review-comments'>
                <h6 className='reviewer-name'>Very nice</h6>
                <div className='review-stars'>
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                </div>
                <div className='review-text'>Finally received this product! Thank you for these stylish and functional, head turners!</div>
                <div className='review-info'>
                    <div className='reviewer-info'>
                        <span>Tina Munim</span><span>10 Jun, 2022</span>
                    </div>
                    <div className='reviewer-likes_dislikes'>
                        <div className='reviewer-likes'>
                            <span>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.3 4.0002H10.5C10.7652 4.0002 11.0196 4.10555 11.2071 4.29309C11.3946 4.48063 11.5 4.73498 11.5 5.0002V6.0522C11.5001 6.18288 11.4747 6.31232 11.425 6.4332L9.8775 10.1907C9.83975 10.2823 9.77563 10.3606 9.69329 10.4157C9.61094 10.4708 9.51408 10.5002 9.415 10.5002H1C0.867392 10.5002 0.740215 10.4475 0.646447 10.3538C0.552678 10.26 0.5 10.1328 0.5 10.0002V5.0002C0.5 4.86759 0.552678 4.74041 0.646447 4.64664C0.740215 4.55287 0.867392 4.5002 1 4.5002H2.741C2.82105 4.50022 2.89993 4.48102 2.97102 4.44421C3.0421 4.40741 3.10331 4.35407 3.1495 4.2887L5.876 0.425196C5.91047 0.376352 5.9613 0.341476 6.01927 0.326891C6.07724 0.312306 6.13852 0.318978 6.192 0.345696L7.099 0.799196C7.35425 0.926777 7.55817 1.13785 7.67687 1.39735C7.79556 1.65685 7.8219 1.94916 7.7515 2.2257L7.3 4.0002ZM3.5 5.2942V9.5002H9.08L10.5 6.0522V5.0002H7.3C7.1477 5.00017 6.99742 4.96537 6.86061 4.89843C6.72381 4.83148 6.60411 4.73418 6.51064 4.61394C6.41716 4.4937 6.35239 4.35369 6.32126 4.20461C6.29013 4.05552 6.29346 3.9013 6.331 3.7537L6.7825 1.9797C6.79663 1.92436 6.79139 1.86585 6.76765 1.81391C6.7439 1.76197 6.70309 1.71972 6.652 1.6942L6.3215 1.5292L3.9665 4.8652C3.8415 5.0422 3.6815 5.1872 3.5 5.2942ZM2.5 5.5002H1.5V9.5002H2.5V5.5002Z" fill="#6B7586"/>
                                </svg>
                            </span>
                            <span>273</span>
                        </div>
                        <div className='reviewer-dislikes'>
                            <span>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.7 8H1.5C1.23478 8 0.98043 7.89464 0.792894 7.70711C0.605357 7.51957 0.500001 7.26522 0.500001 7V5.948C0.499866 5.81732 0.525346 5.68788 0.575001 5.567L2.123 1.81C2.16064 1.71838 2.22465 1.64 2.3069 1.58481C2.38915 1.52961 2.48595 1.5001 2.585 1.5H11C11.1326 1.5 11.2598 1.55268 11.3536 1.64645C11.4473 1.74021 11.5 1.86739 11.5 2V7C11.5 7.13261 11.4473 7.25979 11.3536 7.35355C11.2598 7.44732 11.1326 7.5 11 7.5H9.259C9.17895 7.49998 9.10007 7.51918 9.02899 7.55598C8.9579 7.59279 8.89669 7.64612 8.8505 7.7115L6.124 11.5745C6.08953 11.6233 6.03871 11.6582 5.98073 11.6728C5.92276 11.6874 5.86148 11.6807 5.808 11.654L4.901 11.2C4.64575 11.0724 4.44183 10.8613 4.32314 10.6018C4.20444 10.3423 4.1781 10.05 4.2485 9.7735L4.7 8ZM8.5 6.706V2.5H2.92L1.5 5.948V7H4.7C4.8523 7.00002 5.00259 7.03483 5.13939 7.10177C5.27619 7.16871 5.39589 7.26601 5.48936 7.38626C5.58284 7.5065 5.64761 7.6465 5.67874 7.79559C5.70988 7.94467 5.70654 8.0989 5.669 8.2465L5.2175 10.0205C5.20337 10.0758 5.20861 10.1343 5.23236 10.1863C5.2561 10.2382 5.29691 10.2805 5.348 10.306L5.6785 10.471L8.0335 7.135C8.1585 6.958 8.3185 6.813 8.5 6.706ZM9.5 6.5H10.5V2.5H9.5V6.5Z" fill="#6B7586"/>
                                </svg>
                            </span>
                            <span>75</span>
                        </div>
                    </div>
                </div>   
            </div> 
            <div className="moreReview_rating">
                <h5>View More Reviews and Ratings</h5>
            </div>
        </>
    )
}

export default ReviewComments