import React, {useState} from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import AllCategories from './AllCategories';
import { Link } from '@reach/router';
import lang1 from '../../Images/lang1.png';
import loggedUser from '../../Images/loggedUser.png';

const NavHamburger = () => {
    const [ isUserLoggedIn ] = useState(true);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="menu-wrapper" onClick={handleShow} aria-hidden="true">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.75 3H2.25V4.5H15.75V3ZM15.75 8.25H2.25V9.75H15.75V8.25ZM15.75 13.5H2.25V15H15.75V13.5Z" fill="white"/>
                </svg>
            </div>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Body className='p-0'>
                    <div className='header-navigation padd-header'>
                        {isUserLoggedIn ?
                            <a href='/Account' className='btn btn-dark-custom w-100 d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <img src={loggedUser} className='img img-fluid' alt='' />
                                    <span className='ml-2'>Hello, <span>7869693389</span></span>
                                </div>
                                <span>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.9766 10.0001L6.85156 5.87511L8.0299 4.69678L13.3332 10.0001L8.0299 15.3034L6.85156 14.1251L10.9766 10.0001Z" fill="white"/>
                                    </svg>
                                </span>
                            </a> :
                            <button className='btn btn-dark-custom w-100'>Login / Sign Up</button>
                        }
                    </div>
                    <div className='nav-drill'>
                        <ul className='nav-items nav-level-1'>
                            <AllCategories />
                            <li className='nav-item nav-expand'>
                                <Link 
                                    className='nav-link nav-expand-link'
                                    to='/Order'
                                >
                                    <div className='d-flex align-items-center'>
                                        <span>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.99992 2H13.9999C14.1767 2 14.3463 2.07024 14.4713 2.19526C14.5963 2.32029 14.6666 2.48986 14.6666 2.66667V13.3333C14.6666 13.5101 14.5963 13.6797 14.4713 13.8047C14.3463 13.9298 14.1767 14 13.9999 14H1.99992C1.82311 14 1.65354 13.9298 1.52851 13.8047C1.40349 13.6797 1.33325 13.5101 1.33325 13.3333V2.66667C1.33325 2.48986 1.40349 2.32029 1.52851 2.19526C1.65354 2.07024 1.82311 2 1.99992 2ZM5.99992 8C5.99992 8.53043 6.21063 9.03914 6.5857 9.41421C6.96078 9.78929 7.46949 10 7.99992 10C8.53035 10 9.03906 9.78929 9.41413 9.41421C9.78921 9.03914 9.99992 8.53043 9.99992 8H13.3333V3.33333H2.66659V8H5.99992Z" fill="#121415"/>
                                            </svg>
                                        </span>
                                        <span className='nav-title'>Orders</span>
                                    </div>
                                    <span></span>
                                </Link>
                            </li>
                            <li className='nav-item nav-expand'>
                                <Link 
                                    className='nav-link nav-expand-link'
                                    to='/'
                                >
                                    <div className='d-flex align-items-center'>
                                        <span>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.9998 8.82816V13.3335H14.6665V14.6668H1.33317V13.3335H1.99984V8.82816C1.58942 8.55422 1.25298 8.18319 1.02038 7.74801C0.787788 7.31282 0.666232 6.82694 0.666504 6.3335C0.666504 5.78216 0.815838 5.25083 1.0885 4.79816L2.8965 1.66683C2.95501 1.56549 3.03917 1.48133 3.14051 1.42282C3.24186 1.3643 3.35682 1.3335 3.47384 1.3335H12.5265C12.6435 1.3335 12.7585 1.3643 12.8598 1.42282C12.9612 1.48133 13.0453 1.56549 13.1038 1.66683L14.9052 4.78816C15.3029 5.44874 15.4307 6.23719 15.2621 6.98959C15.0935 7.74199 14.6414 8.40048 13.9998 8.82816ZM12.6665 9.31483C12.2081 9.36625 11.7441 9.31119 11.3104 9.15393C10.8768 8.99667 10.4854 8.74146 10.1665 8.40816C9.88664 8.70088 9.55037 8.93384 9.17798 9.093C8.80559 9.25215 8.40481 9.33419 7.99984 9.33416C7.59496 9.33436 7.19424 9.25253 6.82186 9.09361C6.44947 8.93469 6.11314 8.70197 5.83317 8.4095C5.51425 8.74267 5.12275 8.99776 4.68914 9.1549C4.25552 9.31205 3.7915 9.367 3.33317 9.3155V13.3335H12.6665V9.3155V9.31483ZM3.85917 2.66683L2.23717 5.4755C2.07956 5.86493 2.07482 6.29951 2.22392 6.69228C2.37303 7.08505 2.66496 7.40702 3.04129 7.59376C3.41763 7.78049 3.8506 7.81822 4.25356 7.69937C4.65652 7.58053 4.99973 7.3139 5.2145 6.95283C5.43784 6.39483 6.22784 6.39483 6.45184 6.95283C6.57537 7.26244 6.7889 7.5279 7.06485 7.7149C7.34081 7.9019 7.66649 8.00186 7.99984 8.00186C8.33318 8.00186 8.65887 7.9019 8.93482 7.7149C9.21077 7.5279 9.42431 7.26244 9.54784 6.95283C9.77117 6.39483 10.5612 6.39483 10.7852 6.95283C10.8717 7.1658 11.0013 7.35856 11.1659 7.51901C11.3305 7.67946 11.5266 7.80413 11.7417 7.88518C11.9568 7.96622 12.1863 8.00189 12.4159 7.98995C12.6454 7.97801 12.87 7.91871 13.0756 7.81578C13.2811 7.71285 13.4631 7.56852 13.6102 7.39185C13.7573 7.21519 13.8662 7.01001 13.9302 6.78921C13.9941 6.56842 14.0117 6.33678 13.9818 6.10886C13.9519 5.88095 13.8752 5.66167 13.7565 5.46483L12.1398 2.66683H3.85984H3.85917Z" fill="#121415"/>
                                            </svg>
                                        </span>
                                        <span className='nav-title'>Find a Store</span>
                                    </div>
                                    <span></span>
                                </Link>
                            </li>
                            <li className='nav-item nav-expand'>
                                <Link 
                                    className='nav-link nav-expand-link'
                                    to='/'
                                >
                                    <div className='d-flex align-items-center'>
                                        <span>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.10736 2.97944C2.88074 2.20593 3.91803 1.7535 5.01109 1.71294C6.10416 1.67239 7.17212 2.0467 8.0007 2.76078C8.82853 2.04789 9.89509 1.674 10.9868 1.71395C12.0786 1.7539 13.115 2.20476 13.8885 2.97625C14.662 3.74774 15.1156 4.78293 15.1584 5.87457C15.2012 6.96621 14.8301 8.03374 14.1194 8.86344L8.94269 14.0568C8.70486 14.2947 8.38588 14.4339 8.04971 14.4465C7.71353 14.4591 7.38502 14.3442 7.13003 14.1248L7.05669 14.0574L1.8807 8.86344C1.17035 8.03446 0.799082 6.96791 0.841105 5.87703C0.883129 4.78615 1.33535 3.75132 2.10736 2.97944ZM3.05003 3.92211C2.50486 4.46743 2.1906 5.20174 2.17247 5.97262C2.15435 6.74351 2.43375 7.49177 2.95269 8.06211L3.05003 8.16478L8.00003 13.1148L11.5354 9.57878L9.17869 7.22211L8.47203 7.92878C8.28634 8.11455 8.06588 8.26194 7.82323 8.36251C7.58058 8.46309 7.3205 8.51488 7.05783 8.51495C6.52736 8.51507 6.01855 8.30446 5.64336 7.92944C5.26817 7.55443 5.05732 7.04573 5.05719 6.51525C5.05707 5.98477 5.26768 5.47597 5.64269 5.10078L7.04403 3.69878C6.48634 3.25369 5.7883 3.0219 5.07515 3.04499C4.362 3.06809 3.68042 3.34455 3.15269 3.82478L3.05003 3.92211ZM8.70736 5.80744C8.83238 5.68246 9.00192 5.61225 9.17869 5.61225C9.35547 5.61225 9.52501 5.68246 9.65003 5.80744L12.478 8.63544L12.95 8.16478C13.5043 7.61087 13.8199 6.86205 13.8292 6.07848C13.8386 5.29491 13.541 4.53879 13 3.97181C12.4591 3.40484 11.7178 3.07202 10.9347 3.04455C10.1515 3.01707 9.3887 3.29711 8.80936 3.82478L8.70736 3.92211L6.58603 6.04344C6.47047 6.15892 6.40134 6.3128 6.39176 6.47589C6.38217 6.63897 6.4328 6.79989 6.53403 6.92811L6.58603 6.98611C6.7015 7.10166 6.85539 7.17079 7.01847 7.18038C7.18155 7.18996 7.34248 7.13934 7.4707 7.03811L7.5287 6.98611L8.70736 5.80744Z" fill="#121415"/>
                                            </svg>
                                        </span>
                                        <span className='nav-title'>Refer a Friend</span>
                                    </div>
                                    <span></span>
                                </Link>
                            </li>
                            <li className='nav-item nav-expand'>
                                <div className='navigation-wrapper'>
                                    <h4>Why Sangeetha</h4>
                                    <div className='navigation-links'>
                                        <Link to='/' className='navigation-link'>Contact Us</Link>
                                        <Link to='/' className='navigation-link'>FAQs</Link>
                                        <Link to='/' className='navigation-link'>Legal</Link>
                                    </div>
                                </div>
                            </li>
                            <li className='nav-item nav-expand'>
                                <Link 
                                    className='nav-link nav-expand-link'
                                    to='/'
                                >
                                    <div className='d-flex align-items-center'>
                                        <span className='nav-title selectedMenu'>Language 
                                            <span className='ml-2 text-dark'>English
                                                <span className='ml-2'>
                                                    <img src={lang1} className='img imgl-fluid' alt='' />
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                    <span>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.78145 7.99999L5.48145 4.69999L6.42411 3.75732L10.6668 7.99999L6.42411 12.2427L5.48145 11.3L8.78145 7.99999Z" fill="#121415"/>
                                        </svg>
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default NavHamburger
