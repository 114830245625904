import React,{ useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from '@reach/router';

import gamestrapbanner from '../../../Images/gamestrap_banner.png'
const NonTrackshipMentstatus = ({...props}) => {
    const [show, setShow] = useState(false);
    // const [nonsangeethatrackStrap ]=useState(2);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [exceptdeliverystrap]=useState(true)
    const [expected]=useState(false)

    const [deliverOn]=useState(true)
    // gamepic add 
    const[gamepic]=useState(false);


    // const
    return (
        <>
            <button type='button' className='btn btn-dark-button btn-space bnt-h' onClick={handleShow}>Track</button>

            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Track Shipment</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {expected?

                        <div className="trackshipment_canva-Model expected">
                            <div className="ordersuccesful_message">
                                <p className='trackshipment_canva-para expected-para'>Expected delivery by</p>
                                <h4 className='trackshipment_canva-msg expected-para'>Wed, 6th Jan  </h4>
                            </div>
                        </div>
                        :""}
                    
                     
                         
                    {deliverOn?   
                        <div className="trackshipment_canva-Model">
                            <div className="ordersuccesful_message">
                                <p className='trackshipment_canva-para'>Delivered on  </p>
                                <h4 className='trackshipment_canva-msg'>Wed, 6th Jan, 2022 - 3:45pm</h4>
                            </div>
                        </div>:
                    
                    
                        <div className="trackshipment_canva-Model failed">
                            <div className="ordersuccesful_message">
                                <p className='trackshipment_canva-para failed'>Delivery failed  </p>
                                <h4 className='trackshipment_canva-msg blacked'>We will reattempt the delivery tomorrow</h4>
                            </div>
                        </div> 
                    }
                    

                    <div className="mar-spacer h-1"></div>                    
                    <div className="order_tracking-shipiment-strapper">
                        <ul className="stepper trackingstrappper">
                            <div className="d-flex mb-1">
                                <div className="starpper_round-linemain">
                                    <div className="circleroundedstraper circle-check">
                                    </div>
                                    <div className="line line-dark"></div>
                                </div>
                                <div className='stapper_leftpadding '>
                                    <strong className='strapperhead'>Order Placed</strong>
                                    <span className='tracking_timing'>At 3:45pm, Today</span>
                                </div>
                            </div>
                            <div className="d-flex mb-1">
                                <div className="starpper_round-linemain">
                                    <div className="circleroundedstraper circle-check">
                                    </div>
                                    <div className="line line-dark"></div>

                                </div>
                                <div className='stapper_leftpadding '>
                                    <strong className='strapperhead'>Order is on the way</strong>
                                    <span className='tracking_timing'>At 3:45pm, Today</span>
                                    <Link to="/" className='StaperShow-details'>Show details</Link>  

                                </div>
                            </div>
                          
                            {exceptdeliverystrap ?
                                <div className="d-flex mb-1">
                                    <div className="starpper_round-linemain">
                                        <div className="circleroundedstraper circle-check"></div>
    
                                    </div>
                                    <div className='stapper_leftpadding '>
                                        <strong className="strapperhead">Delivererd</strong>
    
                                        <span className='tracking_timing'>Delivered on Wed, 6th Jan, 2022 - 3:45pm</span>
                                    </div>
                                </div>:
                                <div className="d-flex mb-1">
                                    <div className="starpper_round-linemain">
                                        <div className="circleroundedstraper circle-check"></div>
    
                                    </div>
                                    <div className='stapper_leftpadding '>
                                        <strong className="strapperhead">Delivery Pending</strong>
    
                                        <span className='tracking_timing'>Expected Delivery by Wed, 6th Jan</span>
                                    </div>
                                </div>

                            }
                        </ul>
                        {gamepic?
                            <img src={gamestrapbanner} alt="" className='img-fluid'/>:""}
                        <div className="mar-spacer h-1_2"></div>

                    </div>                                     
                     

                </Offcanvas.Body>
            </Offcanvas>

        </>
    )
}

export default NonTrackshipMentstatus