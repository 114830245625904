import React from 'react'

const AxisEmi = () => {
    return (
        <div>
            <table className='table debitemi-table'>
                <thead>
                    <tr>
                        <th>Months</th>
                        <th>EMI</th>
                        <th>Overall Cost</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>3</td>
                        <td>₹6,131  <span>(13%)</span> </td>
                        <td>₹18,391</td>
                       
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>₹3,115 <span>(13%)</span></td>
                        <td>₹18,688</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>₹2,110 <span>(13%)</span></td>
                        <td>₹18,988</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default AxisEmi