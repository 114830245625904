import React, { useState } from 'react';
import ProductFooter from '../Components/Footer/ProductFooter';
import EmptyWishlist from '../Components/Wishlist/EmptyWishlist';
import WishlistOutOfStock from '../Components/Wishlist/WishlistOutOfStock';
import WishlistSimilarItems from '../Components/Wishlist/WishlistSimilarItems';
import { productsData } from '../Data';

import AppleIphonePro from '../Images/product/product-1.png';

const Wishlist = () => {
    const [ isCartEmpty ] = useState(false);
    return (
        <>
            {isCartEmpty ? 
                <EmptyWishlist /> : 
                <>
                    <div className='product-wrapper wishlist__wrap'>
                        {productsData.slice(0, 2).map( (product) => (
                            <div className='product-list' key={productsData._id}>
                                {product.label ?
                                    <div className='product-label-tag'>{product.label}</div> : ''
                                }
                                <button type='button' className='product-wishlist'>
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 6C0 2.68629 2.68629 0 6 0V0C9.31371 0 12 2.68629 12 6V6C12 9.31371 9.31371 12 6 12V12C2.68629 12 0 9.31371 0 6V6Z" fill="#98A0AE"/>
                                        <path d="M5.99987 5.29312L8.47487 2.81812L9.18187 3.52512L6.70687 6.00012L9.18187 8.47512L8.47487 9.18212L5.99987 6.70712L3.52487 9.18212L2.81787 8.47512L5.29287 6.00012L2.81787 3.52512L3.52487 2.81812L5.99987 5.29312Z" fill="white"/>
                                    </svg>
                                </button>
                                <div className='product-thumb'>
                                    <img src={AppleIphonePro} className='img-fluid img w-100' alt={product.productName} />
                                </div>
                                <div className='product-details'>
                                    <h2>{product.productName}</h2>
                                    <p className='product-muted'>
                                        {product.productColor}, 
                                        {product.productStorage.Storage} + 
                                        {product.productStorage.Storage}
                                    </p>
                                    <div className='product-price'>
                                        <div className='new-price'>₹{product.productNewPrice}</div>
                                        <div className='old-price'>₹{product.productOldPrice}</div>
                                        <div className='price-discount'>{product.productOffer}</div>
                                    </div>
                                    <div className='product-offer'>
                                        <div className='product-offer-list'>
                                            <div className='offer-icon'>
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.5 1.5H6.086L1 6.586L5.414 11L10.5 5.914V1.5ZM8.75 2.5C9.164 2.5 9.5 2.836 9.5 3.25C9.5 3.664 9.164 4 8.75 4C8.336 4 8 3.664 8 3.25C8 2.836 8.336 2.5 8.75 2.5Z" fill="#121415"/>
                                                </svg>
                                            </div>
                                            <div className='offer-label'>Upto 15% Cashback on Axis Credit Cards</div>
                                        </div>
                                    </div>
                                    <div className='product-tags'>
                                        <div className='tag-label'>Photography</div>
                                        <div className='tag-label'>Fast Charge</div>
                                        <div className='tag-label'>Official Work</div>
                                    </div>
                                    <div className='product-compare'>
                                        + Compare
                                    </div>
                                </div>
                                <div className='mar-spacer h-1_2' />
                                <div className='shopping-cart__btn'>
                                    {['bottom'].map((placement, idx) => (
                                        <WishlistSimilarItems key={idx} placement={placement} name={placement} />
                                    ))}
                                    <button type='button' className='btn btn-save-for-later btn-cart-h'>
                                        <span>
                                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.96657 9.86657V3.46657H2.8999V2.3999H4.4999C4.64135 2.3999 4.77701 2.45609 4.87703 2.55611C4.97705 2.65613 5.03324 2.79179 5.03324 2.93324V9.33324H11.6668L12.7335 5.06657H6.0999V3.9999H13.4172C13.4983 3.9999 13.5783 4.01839 13.6512 4.05395C13.724 4.08951 13.7878 4.14121 13.8377 4.20512C13.8876 4.26903 13.9222 4.34348 13.939 4.42279C13.9558 4.5021 13.9543 4.5842 13.9346 4.66284L12.6012 9.99617C12.5723 10.1115 12.5057 10.2139 12.412 10.287C12.3183 10.3602 12.2028 10.3999 12.0839 10.3999H4.4999C4.35845 10.3999 4.2228 10.3437 4.12278 10.2437C4.02276 10.1437 3.96657 10.008 3.96657 9.86657ZM5.03324 13.5999C4.75034 13.5999 4.47903 13.4875 4.27899 13.2875C4.07895 13.0874 3.96657 12.8161 3.96657 12.5332C3.96657 12.2503 4.07895 11.979 4.27899 11.779C4.47903 11.5789 4.75034 11.4666 5.03324 11.4666C5.31613 11.4666 5.58744 11.5789 5.78748 11.779C5.98752 11.979 6.0999 12.2503 6.0999 12.5332C6.0999 12.8161 5.98752 13.0874 5.78748 13.2875C5.58744 13.4875 5.31613 13.5999 5.03324 13.5999ZM11.4332 13.5999C11.1503 13.5999 10.879 13.4875 10.679 13.2875C10.479 13.0874 10.3666 12.8161 10.3666 12.5332C10.3666 12.2503 10.479 11.979 10.679 11.779C10.879 11.5789 11.1503 11.4666 11.4332 11.4666C11.7161 11.4666 11.9874 11.5789 12.1875 11.779C12.3875 11.979 12.4999 12.2503 12.4999 12.5332C12.4999 12.8161 12.3875 13.0874 12.1875 13.2875C11.9874 13.4875 11.7161 13.5999 11.4332 13.5999Z" fill="#121415"/>
                                            </svg>                                     
                                        </span>
                                        <span className='ml-1'>Move to cart</span>
                                    </button>
                                </div>
                                <div className='mar-spacer h-0_5' />
                            </div>
                        ))}
                        <div className='mar-spacer' />
                        <WishlistOutOfStock />
                    </div>
                    <ProductFooter />
                </>
            }
        
        </>
    )
}

export default Wishlist