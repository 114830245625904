import React from 'react';
import emptyCart from '../../Images/product/emptyCart.png';
import ProductAccessories from '../Product/ProductAccessories';
import ProductRelatedCategory from '../Product/ProductRelatedCategory';

const EmptyCart = () => {
    return (
        <>
            <div className='cartEmpty__wrap'>
                <div className='cart-empty__wrap'>
                    <div className='cart-empty__media'>
                        <img src={emptyCart} className='img img-fluid' alt='' />
                    </div>
                    <div className='cart-empty__info'>
                        <h5>Smart gadgets are just a step away</h5>
                        <p>Your cart is empty. Let’s add some items</p>
                    </div>
                    <div className='h-1 mar-spacer' />
                    <button type='button' className='btn btn-dark-theme'>Add Items from wishlist</button>
                </div>

            </div>
            <div className='cart-horizontal-line m-0' />
            <div className='product-specifications specification-head px-0'>
                <div className='h-3 mar-spacer' />
                <h4 className='specification-heading p-x-16'>Recently Viewed Items</h4>
                <ProductAccessories />
                <div className='h-3 mar-spacer' />
                <h4 className='specification-heading p-x-16'>Categories For You</h4>
                <div className='category-carousel ml--16'>
                    <ProductRelatedCategory />
                </div>
                <div className='h-3 mar-spacer' />
            </div>
        </>
    )
}

export default EmptyCart