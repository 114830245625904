import React,{ useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from '@reach/router';

const OrderCanceledStatus = ({...props}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [refundStatus]=useState(true)
    return (
        <>
            <button type='button' className='btn btn-dark-button btn-space bnt-h'onClick={handleShow}>Track</button>
            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Track Shipment</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {refundStatus?    
                        <div className="trackshipment_canva-Model expected">
                            <div className="ordersuccesful_message">
                                <p className='trackshipment_canva-para expected-para'>Expected delivery by</p>
                                <h4 className='trackshipment_canva-msg expected-para'>Wed, 6th Jan  </h4>
                            </div>
                        </div>
                        : 
                        <div className="trackshipment_canva-Model">
                            <div className="ordersuccesful_message">
                                <p className='trackshipment_canva-para refund-para'>Refund Successful</p>
                                <h4 className='trackshipment_canva-msg refundpara'>Refund of ₹19,999 has been processed successfully on Wed, 1 Jan - 3:50pm  </h4>
                            </div>
                        </div>                                      
                    }

                    <div className="mar-spacer h-1"></div>                    
                    <div className="order_tracking-shipiment-strapper">
                        <div className="stepper trackingstrappper">
                            
                            <div className="d-flex mb-1">
                                <div className="starpper_round-linemain">
                                    <div className="circleroundedstraper circle-check"></div>
                                    <div className="line line-dark"></div>

                                </div>
                                <div className='stapper_leftpadding '>
                                    <strong className='strapperhead'>Order Placed</strong>
                                    <span className='tracking_timing'>Wed, 1 Jan, 2022 - 3:45pm</span>


                                </div>
                            </div>
                            
                           
                            <div className="d-flex mb-1">
                                <div className="starpper_round-linemain">
                                    <div className="circleroundedstraper circle-check"></div>
                                    <div className="line line-dark"></div>

                                </div>
                                <div className='stapper_leftpadding '>
                                    <strong className='strapperhead'>Order is on the way</strong>
                                    <span className='tracking_timing'>Thursday, 2 Jan, 2022</span>
                                    <Link to="/" className='StaperShow-details'>Show details</Link>



                                </div>
                            </div>                               
                           
                            <div className="d-flex mb-1">
                                <div className="starpper_round-linemain">
                                    <div className="circleroundedstraper circle-check"></div>
                                    <div className="line line-dark"></div>
                                </div>
                                <div className='stapper_leftpadding '>
                                    <strong className='strapperhead'>Order Cancelled</strong>
                                    <span className='tracking_timing'>Wed, 1 Jan - 3:50pm</span>
                                    <Link to="/" className='StaperShow-details'>Show details</Link>
                                </div>
                            </div> 
                            
                         
                           
                            <div className="d-flex mb-1">
                                <div className="starpper_round-linemain">
                                    <div className="circleroundedstraper circle-check"></div>
                                    <div className="line "></div>
                                </div>
                                <div className='stapper_leftpadding '>
                                    <strong className='strapperhead'>Refund Successful</strong>
                                    <span className='tracking_timing'>Refund of ₹19,999 has been processed successfully on Wed, 1 Jan - 3:50pm</span>
                                </div>
                            </div> 
                           
                            <div className="d-flex mb-1">
                                <div className="starpper_round-linemain">
                                    <div className="circleroundedstraper "></div>
                                </div>
                                <div className='stapper_leftpadding '>
                                    <strong className='strapperhead'>Delivery Pending</strong>
                                    <span className='tracking_timing'>Delhivery : 3477KL67</span>
                                </div>
                            </div> 
                        </div>
                        <div className="mar-spacer h-1_2"></div>

                    </div>                                     
                     

                </Offcanvas.Body>
            </Offcanvas>

        </>
    )
}

export default OrderCanceledStatus;