import React from 'react';
import Carousel from "react-multi-carousel";
import box1 from '../../../../Images/product/box1.png';
import box2 from '../../../../Images/product/box2.png';

const TvProductBox = () => {

    const ProductBox = [
        {   
            Image: box1,
            Name: 'Headphone'
        }, {   
            Image: box2,
            Name: 'Speakers'
        }, {   
            Image: box1,
            Name: 'Headphone'
        }
    ];

    const responsive = {
        tablet: {
            breakpoint: { max: 768, min: 468 },
            items: 3,
            partialVisibilityGutter: 0
        },
        mobile: {
            breakpoint: { max: 468, min: 0 },
            items: 2,
            partialVisibilityGutter: 20
        }
    };

    return (
        <>  
            <h4 className='specification-heading'>What’s In The Box?</h4>
            <h6 className='inbox-info'>Samsung Galaxy Fold, Type C Cable, Pin</h6>
            <Carousel                
                partialVisible={true}
                arrows={false}
                itemClass="carousel-image-item"
                responsive={responsive}
            >   
                {ProductBox.map((ProductBox, idx) => {
                    return (
                        <div className='buy-together__wrap mt-4' key={idx}>
                            <div className='buy-together__card'>
                                <div className='buy-together__media px-2'>
                                    <img
                                        draggable={true}
                                        style={{ width: "100%", height: "100%" }}
                                        src={ProductBox.Image}
                                        alt={ProductBox.productName}
                                        className='img img-fluid'
                                    />
                                </div>
                            </div>
                        </div>
                        // <div className='product-carousel-wrapper' key={idx}>
                        //     <div className='carousel-media-wrapper carousel-catg'>
                        //         <img
                        //             draggable={true}
                        //             style={{ width: "100%", height: "100%" }}
                        //             src={ProductBox.Image}
                        //             alt={ProductBox.productName}
                        //             className='img'
                        //         />
                        //     </div>
                        //     <div className='category-overlay-text'>
                        //         {ProductBox.Name}
                        //     </div>
                        // </div>
                    );
                })}
            </Carousel>
            {/* <h4 className='specification-heading'>What’s In The Box?</h4>
            <h6 className='inbox-info'>Samsung Galaxy Fold, Type C Cable, Pin</h6>
            <div className='buy-together__wrap mt-4'>
                <div className='buy-together__card'>
                    <div className='buy-together__media'>
                        <img src={box1} className='img img-fluid' alt='' />
                    </div>
                </div>
                <div className='buy-together__card'>
                    <div className='buy-together__media'>
                        <img src={box2} className='img img-fluid' alt='' />
                    </div>
                </div>
                <div className='buy-together__card'>
                    <div className='buy-together__media'>
                        <img src={box1} className='img img-fluid' alt='' />
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default TvProductBox
