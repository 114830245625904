import React,{useState} from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import Exchange from '../../Images/product/wishlist.png'


const ExchangeApplied = ({...props}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [ExchangeNotApplied]=useState(true)
    return (
        <>
            <div role='button' className='payment-offer-options off' onClick={handleShow} aria-hidden="true">
                <div className='payment-offer-content align-items-start'>
                    <div className='payment-offer-icon'>
                        <span>
                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.7663 7.64027L18.7645 7.64027L18.7645 13.4739L17.0511 13.4739L17.0505 10.4846L9.07045 18.2469L7.85869 17.0684L15.8393 9.30668L12.7663 9.30669V7.64027ZM1.79989 6.52068L3.51332 6.52068L3.51332 9.50939L11.494 1.74771L12.7057 2.92623L4.72508 10.6879L7.79811 10.6879L7.7981 12.3543L1.79989 12.3543L1.79989 6.52068Z" fill="#121415"/>
                            </svg>
                        </span>
                    </div>
                    {ExchangeNotApplied ?
                        <div className='payment-offer-info'>
                            <div className='felxexchange-applied'> 
                                <h6>Exchange offer applied </h6>  
                                <span className='exchangeApplied-checksvg'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99992 14.6666C4.31792 14.6666 1.33325 11.6819 1.33325 7.99992C1.33325 4.31792 4.31792 1.33325 7.99992 1.33325C11.6819 1.33325 14.6666 4.31792 14.6666 7.99992C14.6666 11.6819 11.6819 14.6666 7.99992 14.6666ZM7.33525 10.6666L12.0486 5.95259L11.1059 5.00992L7.33525 8.78125L5.44925 6.89525L4.50659 7.83792L7.33525 10.6666Z" fill="#278C4C"/></svg>
                                </span>
                            </div> 
                        
                            <p className='details-text Selected-device'>Selected Device <span> : Iphone XR 128GB</span></p> 

                            <p className='details-text'>Exchange Value <span> : ₹24,000</span></p>
                        </div>:
                        <div className="payment-offer-info">
                            <h6>Exchange your old Phone </h6>
                            <p className="details-text">
              Save upto <span> ₹9,800</span>
                            </p>
                            <p className='exchange-error-msg-notApplied'>Apple iPhone XR cannot be exchanged on this product.</p>
                        </div>}
                    
                </div>
                <div className='payment-offer-link'>
                    <span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.172 12.0007L8.222 7.05072L9.636 5.63672L16 12.0007L9.636 18.3647L8.222 16.9507L13.172 12.0007Z" fill="#121415"/>
                        </svg>
                    </span>
                </div>
            </div>
            <Offcanvas show={show} onHide={handleClose} {...props} className='notify-me__offCanvas-  Exchangeapplied-Canvas'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Exchange offer </Offcanvas.Title>
                </Offcanvas.Header>
                <div className='cart-horizontal-line m-0'></div>
                <Offcanvas.Body>
                    <div className='similar-product__wrap w-100'>    
                        <div className="exchangepara_head">
                            You are currently saving  <span> ₹24,000</span>  on exchange
                        </div>                    
                        <div className='specific-wrapper__list exchange_specific'>
                            <div className='specific-wrapper__media'>
                                <img src={Exchange} className='img img-fluid' alt='' />
                            </div>
                            <div className='specific-wrapper__content'>
                                <h4>Samsung Galaxy Z Fold 2 5G</h4>
                                <p>Mystic Purple, 256GB + 8GB</p>
                                <div className="price-offer justify-content-start">
                                    <div className="new-price">₹19,999</div>
                                    <div className="old-price">₹22,999</div>
                                </div>
                            </div>
                        </div>
                        <div className="exchange_specific-hr"></div>
                        <div className='cart-btn-wrappper'>
                            <button type='button' className='btn btn-block btn-cart-outline w-100'>
                                
                                <span className='ml-1'>Edit</span>
                            </button>
                            <button type='button' className='btn btn-block btn-cart text-nowrap w-100 ml-2'>
                                
                                <span className='ml-1'>Remove</span>
                            </button>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default ExchangeApplied