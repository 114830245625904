import React from 'react';

import accessoriesmaincolor1 from '../../../../Images/CategoryDetails/accessoriesmain-color1.png';
import accessoriesmaincolor2 from '../../../../Images/CategoryDetails/accessoriesmain-color2.png';

const AccessoriesColor = () => {
    return (
        <>
            <div className='product-colors-box'>
                <div className='product-colorFrame active'>
                    <div className='product-colorFrame-media'>
                        <img src={accessoriesmaincolor1} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Black, Blue</div>
                </div>
                <div className='product-colorFrame'>
                    <div className='product-colorFrame-media'>
                        <img src={accessoriesmaincolor2} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Black, Green </div>
                </div>
             
            </div>
        </>
    )
}

export default AccessoriesColor;
