import React from 'react';
import { Link } from '@reach/router';
import Carousel from "react-multi-carousel";
import { BankOfferData } from '../../Data';

const BankOffers = () => {
    const responsive = {
        tablet: {
            breakpoint: { max: 768, min: 567 },
            items: 3,
            partialVisibilityGutter: 0
        },
        mobile: {
            breakpoint: { max: 567, min: 0 },
            items: 1,
            partialVisibilityGutter: 100
        }
    };

    return (
        <>  
            <Carousel                
                partialVisible={true}
                arrows={false}
                itemClass="image-item"
                responsive={responsive}
            >   
                {BankOfferData.map((BankOffer) => {
                    return (
                        <div className='offer-box product-offer-wrapper- offer-box- why-buy- m-l-16' key={BankOffer._id}>
                            <div className='card__wrap'>
                                <h5>{BankOffer.Title}</h5>
                                <p className='mb-0 offer-box-info'>{BankOffer.OfferDetails}</p>
                                <div className='flexible'></div>
                                <Link to={`/BankOffersList/`} className='offer-link' >View 4 Offers</Link>
                                {/* <Link to={`/BankOffersList/${BankOffer._id}`}>View</Link> */}
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </>
    )
}

export default BankOffers
