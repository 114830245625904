import React from 'react';
import Itemremovealert from './Itemremovealert';

const TwocartmodelAlert = () => {
    return (
        <>
            <div className='twoCartAlertShow'>
                <div className="twoitemsremove">2 items removed from cart and added to Save for later 

                    {['bottom'].map((placement, idx) => (
                        <Itemremovealert key={idx} placement={placement} name={placement} />
                    ))}
                </div>
    
            </div>
              
        </>
    )
}

export default TwocartmodelAlert