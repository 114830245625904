import React from 'react';
import '../../../../Styles/productspecifications.css';

const SmarttvspecificationFullTab = () => {

    return (
        <>
            <div className='specifications-tab' >
                <div className='specifications-tab_box'>
                    <div className='specifications-tab_heading'> <h6>Key Features </h6></div>
                    <ul className="specification_listingdata">
                        <li className='specifications-tab_para'>     Supported Apps : Netflix, Disney + Hotstar, Youtube</li>
                        <li className='specifications-tab_para'>     Operating System : Tizen</li>
                        <li className='specifications-tab_para'>    Resolution : Ultra HD (4K) 3840 x 2160 Pixels</li>
                        <li className='specifications-tab_para'>3 Activity Rings Tracking - Move, Exercise, Stand</li>
                        <li className='specifications-tab_para'>    Sound Output : 20 W   </li>
                        <li className='specifications-tab_para'>    Refresh Rate : 60 Hz</li>
                    </ul>
                </div>
                <hr className='specification_hr_para'/>
                <div className='specifications-tab_heading'> <h6>General </h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>What’s in the Box </th>
                            <td>LED TV, Power Cord, Battery AAA Size Alkaline : 2, Remocon,  Stand (Left & Right)</td>
                        </tr>
                        <tr>
                            <th>Model Name </th>
                            <td>UA43AUE70AKLXL</td>
                        </tr>
                        <tr>
                            <th>Display Size </th>
                            <td>108 cm (43 inch)</td>
                        </tr>
                        <tr>
                            <th>Screen Type</th>
                            <td>LED</td>
                        </tr>
                        <tr>
                            <th>HD Technology & Resolution  </th>
                            <td>Ultra HD (4K), 3840 x 2160 </td>
                        </tr>
                        <tr>
                            <th>Series  </th>
                            <td>Crystal 4K Pro</td>
                        </tr>
                        <tr>
                            <th>Smart Tv </th>
                            <td>Yes   </td>
                        </tr>
                        <tr>
                            <th>HDMI </th>
                            <td>3</td>

                        </tr>
                        <tr>
                            <th>USB</th>
                            <td>1</td>
                        </tr>
                        <tr>
                            <th>Built In Wi-Fi</th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Launch Year</th>
                            <td>2021</td>


                        </tr>
                        <tr>
                            <th> Dial Material </th>
                            <td>Aluminium Case</td>
                        </tr>

                        <tr>
                            <th>Ideal for </th>
                            <td>Women and Men</td>
                        </tr>
   

                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Video Features </h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>LED Display Type </th>
                            <td>Back Lit LED</td>
                        </tr>
                        <tr>
                            <th>Refresh Rate </th>
                            <td>60 Hz</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Audio Features </h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Number of Speakers  </th>
                            <td>2</td>
                        </tr>
                        <tr>
                            <th>Speaker Output RMS</th>
                            <td>20W</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Smart TV Features </h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Processor  </th>
                            <td>Crystal Processor 4K</td>
                        </tr>
                        <tr>
                            <th>Supported App - Netflix  </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Supported App - Youtube</th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Supported App - Disney+Hotstar</th>
                            <td>Yes</td>
                        
                        </tr>
                        <tr>
                            <th>Operating System</th>
                            <td>Tizen</td>
                        </tr>
                        <tr>
                            <th>Screen Mirroring </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Bluetooth</th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Built In Wi-Fi </th>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Connectivity Features</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>HDMI   </th>
                            <td>3 side</td>
                        </tr>
                        <tr>
                            <th>USV </th>
                            <td>1 side</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Power Features</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Power Requirement  </th>
                            <td>AC 220 - 240 V, 50/60 Hz</td>
                        </tr>
                        <tr>
                            <th>Power Consumption  </th>
                            <td>0.5 W (Standby)</td>
                        </tr>
                       
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Dimensions</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Width x Height x Depth (without stand) </th>
                            <td>963.9 mm x 59.6 mm </td>
                        </tr>
                        <tr>
                            <th>Width x Height x Depth (with stand) </th>
                            <td>963.9 mm x 627.8 mm x 192.5 mm  </td>
                        </tr>
                        <tr>
                            <th>Weight (with stand)</th>
                            <td>8.3 kgs</td>
                        </tr>                       
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Warranty</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Warranty Summary </th>
                            <td>1 Year Product Warranty and 1 Year Additional on Panel </td>
                        </tr>
                        <tr>
                            <th>Not Covered in Warranty</th>
                            <td>Warranty Does Not Cover Any External Accessories (Such as Battery, Cable, Carrying Bag), Damage Caused to the Product Due to Improper Installation by Customer, Normal Wear and Tear and Damages Caused to the Product by Accident, Lightening, Ingress of Water, Fire, Dropping or Excessive Shock, Any Damage Caused Due to Tampering of the Product by an Unauthorized Agent.  </td>
                        </tr>
                        <tr>
                            <th>Warranty Service Type</th>
                            <td>Technician Visit</td>
                        </tr>                       
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Installation & Demo</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Installation & Demo Details </th>
                            <td>We&apos;ll facilitate the installation and demo through authorized service engineer at your convenience The installation will be done within 48 hours of delivery of the TV.The service engineer will install your new TV, either on wall mount or on table top. Installation and demo are provided free of cost.The engineer will also help you understand your new TV&apos;s features.The process generally covers:Wall-mounted or table-top installation, as requested.Physical check of all ports, including power and USB ports. Accessories also checked.Demonstration of features and settingsQuick run-through on how to operate the TV </td>
                        </tr>                      
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Note</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <td>Samsung reserves the right to contact the customer through mail, sms or call for product feedback, related information on product usage on the contact information as provided by Sangeetha.</td>
                        </tr>                      
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default SmarttvspecificationFullTab;