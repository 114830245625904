import React from 'react'

const ProductManufacturer = () => {
    return (
        <>
            <div className='specifications-tab'>
                <h4 className='specifications-tab__head'>General</h4>
                <div className='specification-info__table'>
                    <table className='table specification-striped__table'>
                        <tbody>
                            <tr>
                                <th>Generic Name</th>
                                <td>Mobiles</td>
                            </tr>
                            <tr>
                                <th>Country of Origin</th>
                                <td>India, China (Originated from one of these countries) </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='specific-horizontal-line'></div>
                <h4 className='specifications-tab__head'>Manufacturer’s Details</h4>
                <ul className='specifications-tab__manufacture'>
                    <li>Samsung India Electronics Pvt. Ltd. 6th Floor DLF Center Sansad Marg New Delhi</li>
                </ul>
                <h4 className='specifications-tab__head'>Importer’s Details</h4>
                <ul className='specifications-tab__manufacture'>
                    <li>Samsung India Electronics Pvt. Ltd. 6th Floor DLF Center Sansad Marg New Delhi</li>
                </ul>
                <h4 className='specifications-tab__head'>Packer’s Details</h4>
                <ul className='specifications-tab__manufacture'>
                    <li>Samsung India Electronics Pvt. Ltd. 6th Floor DLF Center Sansad Marg New Delhi</li>
                </ul>
            </div>
        </>
    )
}

export default ProductManufacturer
