import React from 'react';
import Carousel from "react-multi-carousel";
import accessiories from '../../../../Images/CategoryDetails/TvWallMount.png';

const SmartTvAccessories = ({ Wishlist }) => {

    const ProductAccessories = [
        {   
            Image: accessiories,
            productName: 'TJOM New TV Wall Stand Universal Slim Tilt and Swivel',
            productNewPrice: 279,
            productOldPrice: 1090,
            label: '15% Off'
        }, {   
            Image: accessiories,
            productName: 'Samsung WMN750M Mini Wall Mount for Select Samsung 33 to 65" TVs',
            productNewPrice: 11500,
            productOldPrice: 15959,
            label: ''
        }, {   
            Image: accessiories,
            productName: 'Apple iPhone 13 Pro',
            productNewPrice: 199,
            productOldPrice: 559,
            label: '15% Off'
        }
    ];
    const responsive = {
        tablet: {
            breakpoint: { max: 768, min: 567 },
            items: 2,
            partialVisibilityGutter: 0
        },
        mobile: {
            breakpoint: { max: 567, min: 0 },
            items: 1,
            partialVisibilityGutter: 100
        }
    };
    
    return (
        <>
            <Carousel                
                partialVisible={true}
                arrows={false}
                itemClass="image-item"
                responsive={responsive}
            >   
                {ProductAccessories.map((ProductAccessories, idx) => {
                    return (
                        <div className='product-carousel-wrapper' key={idx}>
                            {ProductAccessories.label ?
                                <div className='product-label-tag'>{ProductAccessories.label}</div> : ''
                            }
                            <button 
                                type='button' 
                                className='product-wishlist'
                            >
                                {Wishlist ?
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.35164 10.6616C1.55948 9.73785 1.10148 8.57421 1.05146 7.35831C1.00143 6.14241 1.36231 4.94509 2.07592 3.95935C2.78952 2.9736 3.81427 2.25688 4.98499 1.9247C6.1557 1.59252 7.40417 1.66424 8.52914 2.12831H8.52748L4.99247 5.66498L6.17081 6.84331L9.70581 3.30831C10.7216 2.29242 12.0993 1.72166 13.5359 1.72158C14.9725 1.7215 16.3503 2.29211 17.3662 3.30789C18.3821 4.32367 18.9529 5.70141 18.953 7.13802C18.953 8.57462 18.3824 9.95242 17.3666 10.9683L10.0008 18.3333L2.34081 10.6733L2.35164 10.6616Z" fill="#E25100"/>
                                    </svg> :
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.8685 3.96415C18.7535 5.85415 18.8185 8.86415 17.0652 10.8275L9.99852 17.9042L2.93352 10.8275C1.18019 8.86415 1.24602 5.84915 3.13019 3.96415C5.01769 2.07748 8.03685 2.01415 10.0002 3.77415C11.9577 2.01665 14.9827 2.07498 16.8685 3.96415ZM4.30935 5.14332C3.06769 6.38498 3.00519 8.37248 4.14935 9.68582L9.99935 15.545L15.8494 9.68665C16.9944 8.37248 16.9319 6.38748 15.6885 5.14165C14.4494 3.89998 12.4544 3.83998 11.1444 4.98665L7.64269 8.48915L6.46352 7.31082L8.81769 4.95498L8.74935 4.89748C7.43685 3.84332 5.51852 3.93332 4.30935 5.14332Z" fill="#121415"/>
                                    </svg>
                                }
                            </button>
                            <div className='carousel-media-wrapper'>
                                <img
                                    draggable={true}
                                    style={{ width: "100%", height: "100%" }}
                                    src={ProductAccessories.Image}
                                    alt={ProductAccessories.productName}
                                    className='img img-fluid d-block m-auto'
                                />
                            </div>
                            <div className='carousel-content-wrapper'>
                                <div className='carousel-product-title'>{ProductAccessories.productName}</div> 
                                <div className='flexible'></div>
                                <div className="price-offer">
                                    <div className="new-price">₹{ProductAccessories.productNewPrice}</div>
                                    <div className="old-price">₹{ProductAccessories.productOldPrice}</div>
                                </div>
                                <div className='flexible'></div>
                                <button type='button' className='btn btn-block btn-dark-outline w-100'>
                                    <span>Add To Cart</span>
                                </button>
                            </div>
                           
                        </div>
                    );
                })}
            </Carousel>
        </>
    )
}

export default SmartTvAccessories
