import React from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import MenuAccordion from './MenuAccordion';
import LangaugeAccordion from './LangaugeAccordion';

const ProductFooter = () => {   
    return (
        <>
            <div className='page-footer-wrapper'>
                <div className='footer-link__wrap w-100'>
                    <MenuAccordion />
                </div>
                <div className='footer-social__wrap footer-link__wrap flex-wrap'>
                    <div className='Follow-us__title col-12 px-0'>Follow Us On</div>
                    <div className='social__wrap'>
                        <FaFacebookF />
                    </div>
                    <div className='social__wrap'>
                        <FaInstagram />
                    </div>
                    <div className='social__wrap'>
                        <FaTwitter />
                    </div>
                    <div className='social__wrap'>
                        <FaYoutube />
                    </div>
                </div>
                <div className='footer-link__wrap w-100'>
                    <LangaugeAccordion />
                </div>
                <div className='footer-copyright-text'>
                    <p className='mb-0'>Copyright 2022 © Sangeetha Mobiles. All Rights Reserved.</p>
                </div>
            </div>
        </>
    )
}

export default ProductFooter
