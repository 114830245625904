import React from 'react'
import specific from '../../../Images/CategoryDetails/heighlight/Smart-watchdetail-main.png'
import SmartwatchSpecificationTab from '../CategoryDetails/SmartwatchSpecificationTab';

const SmartwatchSpecification = () => {
    return (
        <>
            <div className='product-specifications-section'>
                <div className='product-details__specific'>
                    <div className='specific-wrapper__list'>
                        <div className='specific-wrapper__media'>
                            <img src={specific} className='img img-fluid' alt='' />
                        </div>
                        <div className='specific-wrapper__content'>
                            <h4>Apple Watch Series 3 GPS</h4>
                            <p>Space Grey Aluminum Case with Sports Band</p>
                            <div className="price-offer justify-content-start">
                                <div className="new-price">₹41,900</div>
                                <div className="old-price">₹45,990</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='product-details__tabs'>
                    <SmartwatchSpecificationTab />
                </div>
            </div>
        </>
    )
}

export default SmartwatchSpecification;