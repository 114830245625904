import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import canara from '../../Images/product/emi/canara.png';
import Catholic from '../../Images/product/emi/Catholic.png';
import City from '../../Images/product/emi/City.png';
import Corporation from '../../Images/product/emi/Corporation.png';
import DCB from '../../Images/product/emi/DCB.png';
import Deutsche from '../../Images/product/emi/Deutsche.png';
import Dhanlaxmi from '../../Images/product/emi/Dhanlaxmi.png';

const NetBankingModalList = ({...props }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className='select-modal-dropdown' onClick={handleShow} aria-hidden="true">
                <span>Drop Down</span>
                <span>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.0013 11.6663L6.66797 8.33301H13.3346L10.0013 11.6663Z" fill="#121415"/>
                    </svg>
                </span>
            </div>

            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Other Banks</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='pt-1'>
                    <div className='input-group-banks input-group-location mb-4'>
                        <input type='number' className='form-control form-control-cart' placeholder='Search for banks' />
                        <span className='form-control-search'>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.0155 8.3085L11.157 10.4495L10.4495 11.157L8.3085 9.0155C7.51187 9.65411 6.521 10.0015 5.5 10C3.016 10 1 7.984 1 5.5C1 3.016 3.016 1 5.5 1C7.984 1 10 3.016 10 5.5C10.0015 6.521 9.65411 7.51187 9.0155 8.3085ZM8.0125 7.9375C8.64706 7.28494 9.00143 6.41021 9 5.5C9 3.566 7.4335 2 5.5 2C3.566 2 2 3.566 2 5.5C2 7.4335 3.566 9 5.5 9C6.41021 9.00143 7.28494 8.64706 7.9375 8.0125L8.0125 7.9375Z" fill="#98A0AE"/>
                            </svg>
                        </span>
                    </div>
                    <div className='bank-list__wrap'>
                        <div className='upi-payment__list'>
                            <div className='upi-payment__wrap'>
                                <div className='upi-payment__media m-0'>
                                    <img src={canara} className='img img-fluid' alt='' />
                                </div>
                                <span className='upi-payment__text ml-2'>Canara Bank</span>
                            </div>
                        </div>
                        <div className='upi-payment__list'>
                            <div className='upi-payment__wrap'>
                                <div className='upi-payment__media m-0'>
                                    <img src={Catholic} className='img img-fluid' alt='' />
                                </div>
                                <span className='upi-payment__text ml-2'>Catholic Syrian Bank</span>
                            </div>
                        </div>
                        <div className='upi-payment__list'>
                            <div className='upi-payment__wrap'>
                                <div className='upi-payment__media m-0'>
                                    <img src={City} className='img img-fluid' alt='' />
                                </div>
                                <span className='upi-payment__text ml-2'>City Union Bank</span>
                            </div>
                        </div>
                        <div className='upi-payment__list'>
                            <div className='upi-payment__wrap'>
                                <div className='upi-payment__media m-0'>
                                    <img src={Corporation} className='img img-fluid' alt='' />
                                </div>
                                <span className='upi-payment__text ml-2'>Corporation Bank</span>
                            </div>
                        </div>
                        <div className='upi-payment__list'>
                            <div className='upi-payment__wrap'>
                                <div className='upi-payment__media m-0'>
                                    <img src={DCB} className='img img-fluid' alt='' />
                                </div>
                                <span className='upi-payment__text ml-2'>DCB BANK Personal</span>
                            </div>
                        </div>
                        <div className='upi-payment__list'>
                            <div className='upi-payment__wrap'>
                                <div className='upi-payment__media m-0'>
                                    <img src={Deutsche} className='img img-fluid' alt='' />
                                </div>
                                <span className='upi-payment__text ml-2'>Deutsche Bank</span>
                            </div>
                        </div>
                        <div className='upi-payment__list'>
                            <div className='upi-payment__wrap'>
                                <div className='upi-payment__media m-0'>
                                    <img src={Dhanlaxmi} className='img img-fluid' alt='' />
                                </div>
                                <span className='upi-payment__text ml-2'>Dhanlaxmi Bank</span>
                            </div>
                        </div>
                    </div>                        
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default NetBankingModalList