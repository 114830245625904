import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SimilarProducts from './SimilarProducts';


const WishlistSimilarItems = ({ ...props }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>            
            <button type='button' onClick={handleShow} className='btn btn-remove btn-cart-h'>
                <span>Show similar items</span>
            </button>

            <Offcanvas show={show} onHide={handleClose} {...props} className='address-offcanvas offcanvas-bottom'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Similar Items</Offcanvas.Title>
                </Offcanvas.Header>
                <div className='cart-horizontal-line m-0'></div>
                <Offcanvas.Body>
                    <div className='notify__wrap'>
                        <SimilarProducts />
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default WishlistSimilarItems