import React,{useState} from 'react';
import Ontheway from '../../../Images/CategoryDetails/Order/orderdetails/Ontheway.png';


const ArrivingTime = () => {
    const[arrivingTime]=useState(false)

    return (
        <>
            { arrivingTime ?
                <div className="ordersuccesful_message order_tracking_model arriving-imges">   
                    <div>                                
                        <div className="order_arriving_track">
                            <div className="arrivingheading">
                                <h4 className='Order_Successful-msg'>Arriving in 30mins</h4>
                            </div>                      
                        </div>                                                     
                        <p className='order-Location-para'>Rohan Home 103, Udgam Belvedere, Kadubeesana...</p>
                    </div>
                    <div className='d-flex'>
                        <img src={Ontheway} className='img img-fluid my-2' alt=''/>
                    </div>
                    
                </div>:""
            }
        </>
    )
}

export default ArrivingTime