import React, { useState } from 'react';
import { Link } from '@reach/router';



const RequestReplace = () => {
    const[requestReplace]=useState(true)
    return (
        <>
            {requestReplace?
                <div className='order-track__wrap'>
                    <div className='orders_wrap replace_wrap'>
                        <div className='orders-list_wrap'>  <div className="shopping-vas__info">
                            <div className="shopping-vas__media">
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM12 22.5C6.201 22.5 1.5 17.799 1.5 12C1.5 6.201 6.201 1.5 12 1.5C17.799 1.5 22.5 6.201 22.5 12C22.5 17.799 17.799 22.5 12 22.5Z" fill="#278C4C"/><path d="M14.7825 4.71729L13.7175 5.78229L15.4425 7.49979H7.5C6.67158 7.49979 6 8.17136 6 8.99979V11.2498H7.5V8.99979H15.4425L13.725 10.7173L14.7825 11.7748L17.7825 8.77479C18.0733 8.48224 18.0733 8.00983 17.7825 7.71729L14.7825 4.71729Z" fill="#278C4C"/><path d="M16.5006 15.0001H8.55811L10.2756 13.2826L9.21811 12.2251L6.21811 15.2251C5.9273 15.5176 5.9273 15.9901 6.21811 16.2826L9.21811 19.2826L10.2756 18.2251L8.55811 16.5001H16.5006C17.329 16.5001 18.0006 15.8285 18.0006 15.0001V12.7501H16.5006V15.0001Z" fill="#278C4C"/></svg>
                                </span>
                            </div>
                            <div className="d-flex flex-column replace_wrap-content">
                                <div className="shopping-vas__head">Requested Replacement </div>
                                <div className="shopping-vas__label">In progress</div>
                            </div>
                        </div>

                        
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg> */}
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#278C4C"/></svg>
                            </Link>
                        </div>
                    </div>
                </div>:""
            }

           

        </>
    )
}

export default RequestReplace