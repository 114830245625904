import React from 'react'
import Product1 from '../../Images/product/Product1.png';
import Product2 from '../../Images/product/Product2.png';
import Product3 from '../../Images/product/Product3.png';

const ProductTrending = () => {
    const TrendingProduct = [
        {   
            _id: 1,
            Image: Product1,
            Title: 'Apple iPhone 13 Pro Max',
            Variant: 'Graphite, 1TB, 8GB'
        }, {   
            _id: 2,
            Image: Product2,
            Title: 'boAt Airdopes Bluetooth Eardbuds',
            Variant: 'Midnight Blue, True Wireless'
        }, {   
            _id: 3,
            Image: Product3,
            Title: 'ASUS TUF Gaming Laptop F15',
            Variant: 'Core i5, 512GB SSD, 10th Gen'
        }
    ];
    return (
        <>
            <div className='product-trending__wrap'>
                {TrendingProduct.map(Data => (
                    <div className='product-trending__lists' key={Data._id}>
                        <div className='product-trending__media'>
                            <img src={Data.Image} className='img img-fluid' alt={Data.Title} />
                        </div>
                        <div className='product-trending__details'>
                            <div className='product-trending__title'>{Data.Title}</div>
                            <div className='product-trending__label'>{Data.Variant}</div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default ProductTrending