import React, { useState } from 'react';
import MyCouponDetails from '../../Account/MyCouponDetails';

import Banner2 from '../../../Images/coupon/banner2.png';
import coupon1 from '../../../Images/coupon/coupon1.png';

const Step5 = () => { 
    const [ inputState ] = useState(true);
    
    return (
        <>
            <div className="exchange-data all-brands">
                <div className='px-3'>
                    <h3 className="Exchangehead">Verify your phone&apos;s IMEI number</h3>
                    <div className={`input-group-address border-0 ${inputState === true ? 'valid-state' : inputState === false ? 'error-state' : null}`}>
                        {/* error-state  */}
                        <div className="cart-address__wrap pb-2 exchange-details">
                            <input
                                type="text"
                                className="form-control form-control-exchange"
                                placeholder="Enter IMEI number"
                            />
                            {inputState ? 
                                <div className='edit__coupon'>Edit</div> :
                                <button
                                    type="button"
                                    className={`btn btn-dark-button absolute-cart-input btn-space ${inputState === false ? 'not-selected' : null}`}
                                >Verify</button>
                            }
                        </div>
                        <span className="d-flex align-items-center">
                            {inputState ? 
                                (<label className="d-flex align-items-center" htmlFor="text">
                                    <span>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 6C0 9.3138 2.6862 12 6 12C9.3138 12 12 9.3138 12 6C12 2.6862 9.3138 0 6 0C2.6862 0 0 2.6862 0 6Z" fill="#34B171"/>
                                            <path d="M8.63676 3.85431L8.49968 3.72539L8.36265 3.85437L5.24995 6.78432L3.63699 5.26622L3.49991 5.13721L3.36284 5.26622L2.86293 5.73673L2.70819 5.88237L2.86293 6.02801L5.11288 8.14564L5.24996 8.27465L5.38703 8.14564L9.13707 4.61615L9.29187 4.47046L9.13702 4.32482L8.63676 3.85431Z" fill="white" stroke="white" strokeWidth="0.4"/>
                                        </svg>
                                    </span>
                                    <span className='ml-1 exchange-valid'>IMEI Verified</span>
                                    {/* exchange-error  */}
                                </label>) : 
                                inputState === false ?
                                    (<label className="exchange-error" htmlFor="text">IMEI Verification failed as IMEI number doesn&apos;t match with Iphone 13</label>) : null
                            }
                        </span>
                        <div className='imei-info'>Dial *#06# to check your 15 digit IMEI number. Incase of 2 IMEIs enter the first IMEI number(IMEI1).</div>
                    </div>
                    {inputState ?
                        (<div className='d-flex flex-column align-items-start'>
                            <div className='valid_coupon_info'>You get an additional coupon of ₹3000 as your device was purchased from a Sangeetha Store</div>

                            <div className='coupon-card__wrapper'>
                                <img src={Banner2} className='img-fluid w-100 coupon-img' alt='' />
                                <div className='coupon-card__bg'>
                                    <div className='coupon-card__content'>
                                        <h5>Yay! ₹3000 off </h5>
                                        <p>Sangeetha Exchange Bonus</p>
                                        <hr className='hr-coupon' />
                                        <div className='coupon-card__info'>VALIDITY: only on current purchase</div>
                                        {['bottom'].map((placement, idx) => (
                                            <MyCouponDetails key={idx} placement={placement} name={placement} />
                                        ))} 
                                    </div>
                                    <div className='coupon-card__media'>
                                        <img src={coupon1} className='img img-fluid' alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>) : null
                    }
                </div>
            </div>
            <div className='px-3'>
                <div className='exchange-bg__storage'>
                    <div className='font-500 mb-2'>How to check phone’s storage?</div>
                    <ul>
                        <li>Go to the Settings or Options menu</li>
                        <li>Scroll to the bottom of the list, and check ‘About phone’, ‘About device’ or similar. </li>
                        <li>The device storage should be listed.</li>
                    </ul>
                </div>
            </div>
            <div className='mar-spacer h-3 mb-4' />
        </>
    )
}

export default Step5