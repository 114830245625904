import { Link } from '@reach/router';
import React from 'react';
import BrandBanner from '../Components/Brand/BrandBanner';
import OffersCarousel from '../Components/Brand/OffersCarousel';
import BrandCarousel from '../Components/Home/BrandCarousel';
import SponsoredPhonesCarousel from '../Components/Product/SponsoredPhonesCarousel';
import ProductList from '../Components/Product/ProductList';

import brandBanner from '../Images/brands/banner/brandBanner.png';
import arrowVictor from '../Images/brands/banner/arrowVictor.png';

const Brand = () => {

    return (
        <>
            <div className='brand-section__wrap'>
                <BrandBanner />
                <section className="brands-wrapper exclusive-section">
                    <div className='top-head__wrap'>
                        <h4 className="brand-heading">Products for you</h4>
                        <p className='brand-head__info'>Upgrade your home with the latest tech, and save on the go!</p>
                    </div>
                    <div className='all-category__content ml--16'>
                        <BrandCarousel />
                    </div>
                </section>
                <section className="brands-wrapper exclusive-section">
                    <div className='p-x-16'>
                        <OffersCarousel />
                    </div>
                </section>
                <section className="brands-wrapper exclusive-section border-0">
                    <div className='top-head__wrap'>
                        <h4 className="brand-heading">New Launches</h4>
                    </div>
                    <div className='product-sponsored__wrap p-0 ml--16'>
                        <SponsoredPhonesCarousel />
                    </div>
                </section>
                <section className="p-x-16">
                    <div 
                        className='phome-banner__wrap position-relative'
                        style={{ 
                            backgroundColor: '#DAEBE8',
                            padding: '22px 26px',
                            zIndex: 1,
                        }}    
                    >
                        <img src={brandBanner} className='img-fluid phone-victor' alt='' />
                        <img src={arrowVictor} className='img-fluid arrow-victor' alt='' />
                        <div className='phome-banner__info'>
                            <h5>Undo the damage</h5>
                            <p>Airtight protection plans. Guaranteed.</p>
                            <Link to='/' className='btn btn-dark-custom space-padding p-x-16'>Know More</Link>
                        </div>
                    </div>
                </section>
                <section className="brands-wrapper exclusive-section">
                    <div className='top-head__wrap'>
                        <h4 className="brand-heading">Curated for You</h4>
                    </div>
                    <div className='product-sponsored__wrap'>
                        <div className='brand-product__filter'>
                            <button type='button' className='btn btn-brand'>Products</button>
                            <button type='button' className='btn btn-brand'>Price</button>
                            <button type='button' className='btn btn-brand'>Offers</button>
                        </div>
                    </div>
                    <ProductList  />
                </section>
            </div>
        </>
    )
}

export default Brand