import React from 'react';
import { productsData } from '../../Data';
import AppleIphonePro from '../../Images/product/product-1.png';

const SimilarProductList = () => {

    return (
        <>
            {productsData.slice(0, 3).map(product => (
                <div className='similar-product__item' key={product._id}>
                    <div className='product-list' to='/ProductDetails'>
                        {product.label ?
                            <div className='product-label-tag'>{product.label}</div> : ''
                        }
                        <div className='product-thumb'>
                            <img src={AppleIphonePro} className='img-fluid img w-100' alt={product.productName} />
                        </div>
                        <div className='product-details'>
                            <h2>{product.productName}</h2>
                            <p className='product-muted'>{product.productInfo}</p>
                            <div className='product-price'>
                                <div className='new-price'>₹{product.productNewPrice}</div>
                                <div className='old-price'>₹{product.productOldPrice}</div>
                            </div>
                            <div className='similar-prod__deliverTo'>
                                <div className='deliverTo__icon'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.3327 5.33398H13.3327L15.3327 8.03798V12.0007H13.976C13.8968 12.5568 13.6195 13.0658 13.1951 13.4339C12.7708 13.8021 12.2278 14.0048 11.666 14.0048C11.1042 14.0048 10.5613 13.8021 10.1369 13.4339C9.71254 13.0658 9.43527 12.5568 9.35602 12.0007H5.97602C5.89676 12.5568 5.61949 13.0658 5.19513 13.4339C4.77078 13.8021 4.22783 14.0048 3.66602 14.0048C3.1042 14.0048 2.56125 13.8021 2.1369 13.4339C1.71254 13.0658 1.43527 12.5568 1.35602 12.0007H0.666016V4.00065C0.666016 3.82384 0.736253 3.65427 0.861278 3.52925C0.986302 3.40422 1.15587 3.33398 1.33268 3.33398H10.666C10.8428 3.33398 11.0124 3.40422 11.1374 3.52925C11.2624 3.65427 11.3327 3.82384 11.3327 4.00065V5.33398ZM11.3327 6.66732V8.66732H13.9993V8.47732L12.6607 6.66732H11.3327Z" fill="#C4CAD0"/>
                                    </svg>

                                </div>
                                <div className='deliverTo__info'>
                                    <span className='info__similarProd'>Delivery by </span>
                                    <span className='label__similarProd'>Monday, 24 Jan.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <div className='flexible'></div>
            <button className='btn btn-block btn-dark-button w-100 mb-5'>Explore More</button>
        </>
    )
}

export default SimilarProductList
