import React from 'react';
import Form from 'react-bootstrap/Form'


const PriceFilter = () => {
    return (
        <>
            <div className='filter-tab__lists'>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <label htmlFor="label" className='filter_price'>₹1,000 - ₹5,000</label>
                    </div>
                    <span className='filter-tab__count'>20</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />    
                        <label htmlFor="label" className='filter_price'>₹5,000  -  ₹10,000</label>
                    </div>
                    <span className='filter-tab__count'>14</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />                   
                        <label htmlFor="label" className='filter_price'>₹10,000 - ₹15,000</label>
                    </div>
                    <span className='filter-tab__count'>14</span>
                </div>
                <div className='filter-tab__list'>  
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <label htmlFor="label" className='filter_price'>₹15,000  -  ₹20,000</label>                
                    </div>
                    <span className='filter-tab__count'>12</span>
                </div>
                <div className='filter-tab__list'>  
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <label htmlFor="label" className='filter_price'>₹20,000 - ₹30,000</label>                
                    </div>
                    <span className='filter-tab__count'>10</span>
                </div>
                <div className='filter-tab__list'>  
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <label htmlFor="label" className='filter_price'>₹30,000 - ₹40,000</label>                
                    </div>
                    <span className='filter-tab__count'>12</span>
                </div>
                <div className='filter-tab__list'>  
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <label htmlFor="label" className='filter_price'>₹40,000 - ₹50,000</label>                
                    </div>
                    <span className='filter-tab__count'>12</span>
                </div>
                <div className='filter-tab__list'>  
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <label htmlFor="label" className='filter_price'>₹50,000 - ₹60,000</label>                
                    </div>
                    <span className='filter-tab__count'>4</span>
                </div>
                <div className='filter-tab__list'>  
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <label htmlFor="label" className='filter_price'>₹60,000 - ₹70,000</label>                
                    </div>
                    <span className='filter-tab__count'>20</span>
                </div>
                <div className='filter-tab__list'>  
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <label htmlFor="label" className='filter_price'>₹70,000 - ₹1,00,000</label>                
                    </div>
                    <span className='filter-tab__count'>20</span>
                </div>
                <div className='filter-tab__list'>  
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <label htmlFor="label" className='filter_price'>Over  ₹1,00,000</label>                
                    </div>
                    <span className='filter-tab__count'>20</span>
                </div>
            </div>

        </>
    )
}

export default PriceFilter