import React from 'react';
import { Link } from '@reach/router';
import OrderPlaced from '../../Components/Order/OrderPlaced';
import ProductFooter from '../../Components/Footer/ProductFooter';

import img1 from '../../Images/Orders/img1.png';
import img2 from '../../Images/Orders/img2.png';


const Order = () => {
    return (
        <>
            <div className="Order-list__wrap">
                <div className='order-list__track'>
                    <div className='order-track_info'>
                        <span>On the Way</span>
                        <span>ETA: 30Mins</span>
                    </div>
                    <Link to='/OrderTrack' className='order-track_link'>Track Order</Link>
                </div>
            </div>
            <div className="Order-head__wrap">
                <span>Recent</span>
            </div>
            <OrderPlaced />
            <div className='mar-spacer bg-theme h-4' />
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info'>
                        <div className='order-placed__text'>Order placed</div>
                        <div className='order-placed__info'>Expected delivery by Wed, 6th Jan</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Order-head__wrap">
                <span>3 months older</span>
            </div>
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info'>
                        <div className='order-placed__text'>Replacement Requested</div>
                        <div className='order-placed__info'>Usually takes 7-8 days for replacement</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='orders-media__wrap'>
                                <img src={img2} className='img-fluid' alt='' />
                            </div>
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info'>
                        <div className='order-placed__text'>Replacement Requested</div>
                        <div className='order-placed__info'>Usually takes 2-3 days for replacement</div>
                    </div>
                    
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Order-head__wrap">
                <span>6 months older</span>
            </div>
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info order-cancelled__text'>
                        <div className='order-placed__text'>Cancelled</div>
                        <div className='order-placed__info'>Refund of ₹19,999 has been initiated</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info order-cancelled__text order-replacement__text'>
                        <div className='order-placed__text'>Delivered</div>
                        <div className='order-placed__info'>1 Item requested for replacement</div>
                    </div>
                    
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='orders-media__wrap'>
                                <img src={img2} className='img-fluid' alt='' />
                            </div>
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <ProductFooter />
        </>
    )
}

export default Order;