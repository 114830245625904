import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

import notifyMe from '../../Images/product/notifyMe.png';

const NotifyMe = ({ ...props }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <button type='button' className='btn btn-block btn-dark-custom' onClick={handleShow}>Notify Me</button>
			
            <Offcanvas show={show} onHide={handleClose} {...props} className='address-offcanvas offcanvas-bottom'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Notify Me</Offcanvas.Title>
                </Offcanvas.Header>
                <div className='cart-horizontal-line m-0'></div>
                <Offcanvas.Body>
                    <div className='notify__wrap'>
                        <div className='notify__flex'>
                            <div className='notify__media'>
                                <img src={notifyMe} className='img img-fluid' alt='' />
                            </div>
                            <div className='notify__content'>
                                <div className='notify__head'>Would you like us to let you know when this item is available ?</div>
                                <div className='notify__label'>Samsung Galaxy A22, Awesome White, 128GB...</div>
                            </div>
                        </div>
                        <div className='check-input-box mb-4'>
                            <input className="input-emi" placeholder="Mobile Number" />
                            <span className="unit">+91 <span className="unit-bar">|</span></span>
                        </div>
                        <div className='notify__btn'>
                            <button className='btn-dark-custom btn-place-order w-100'>Yes, Notify Me</button>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default NotifyMe