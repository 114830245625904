import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import filterSearch from '../../Images/CategoryDetails/search/filter_Search.png';
import SearchProductFilter from '../../Components/Header/SearchProductFilter';
import IphoneSearchTrending from '../../Components/Header/IphoneSearchTrending';
import { useNavigate } from 'react-router-dom';

const NavSearchList = ({ ...props }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [show, setShow] = useState(true);
    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleFocus = (e) => {
        e.target.classList.add("focus");
    };
    const handleBlur = (e) => {
        e.target.classList.remove("focus");
    };   
    const SearchHistory = [
        {
            _id: 1,
            Title: 'iPhone 13  ',
            StorageGB: '128GB'
        }
    ]
    const SearchedQuery = [
        {
            _id: 1,
            Title: 'iPhone 13  ',
            StorageGB: '128GB'
        }, {
            _id: 2,
            Title: 'iPhone 13 Mini',
            StorageGB: '128GB'
        }, {
            _id: 3,
            Title: 'iPhone XR',
            StorageGB: '64GB'
        },
        {
            _id: 3,
            Title: 'Bluetooth Speaker',
            StorageGB: '64GB'
        }
    ];
    const results = !searchTerm
        ? SearchedQuery
        : SearchedQuery.filter(person =>
            person.Title.toLowerCase().includes(searchTerm.toLocaleLowerCase())
        );
    return (
        <>
            <div role="button" onClick={handleShow} className="navbar-tool" aria-hidden="true">
                <div className="navbar-tool-icon">
                    <svg width="21" height="21" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.25 1.5C11.976 1.5 15 4.524 15 8.25C15 11.976 11.976 15 8.25 15C4.524 15 1.5 11.976 1.5 8.25C1.5 4.524 4.524 1.5 8.25 1.5ZM8.25 13.5C11.1502 13.5 13.5 11.1502 13.5 8.25C13.5 5.349 11.1502 3 8.25 3C5.349 3 3 5.349 3 8.25C3 11.1502 5.349 13.5 8.25 13.5ZM14.6137 13.5532L16.7355 15.6742L15.6742 16.7355L13.5532 14.6137L14.6137 13.5532Z" fill="white"/>
                    </svg>

                </div>
            </div>

            <Offcanvas show={show} backdrop={false} onHide={handleClose} {...props} className='Head_searchinput offcanvas offcanvas-top'>
                <Offcanvas.Header>
                    <Offcanvas.Title className='search-title__wrap'>
                        <div className='d-flex align-items-center col'>
                            <div onClick={() => navigate(-1)} className="searcharrow_img" aria-hidden="true">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.625 6.25049L7.80333 7.42882L5.69 9.54216H16.6667V11.2088H5.69L7.80333 13.3222L6.625 14.5005L2.5 10.3755L6.625 6.25049Z" fill="#121415"/>
                                </svg>
                            </div>
                            <div className='inputsearch'>
                                <input  type='text'
                                    className=" form-control-Navsearch"
                                    placeholder="Search for gadgets & brands"
                                    value={searchTerm}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onChange={handleChange} />
                            </div>
                        </div>
                        {searchTerm ? 
                            (<button  onClick={() => setSearchTerm(() => "")} className="searcharrow_img">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.99999 7.05732L11.3 3.75732L12.2427 4.69999L8.94266 7.99999L12.2427 11.3L11.3 12.2427L7.99999 8.94266L4.69999 12.2427L3.75732 11.3L7.05732 7.99999L3.75732 4.69999L4.69999 3.75732L7.99999 7.05732Z" fill="#121415"/>
                                </svg>
                            </button>) : ''
                        }
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='p-0'>
                    {searchTerm.length > 1 ?
                        (<div className="searchingproduct">
                            <div className='searchedProduct-List p-3'>
                                <div className="suggetion_text">
                                    <span className='suggetionclass'>Top Suggestions</span>
                                </div>
                                {SearchHistory.map(seacrh => (
                                    <div className='ProductsearchedLists justify-content-between ' key={seacrh._id}>
                                        <div className='d-flex align-items-center'>
                                            <div className='searched-distance'>
                                                <span>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.0001 1.66663C14.6026 1.66663 18.3334 5.39746 18.3334 9.99996C18.3334 14.6025 14.6026 18.3333 10.0001 18.3333C5.39758 18.3333 1.66675 14.6025 1.66675 9.99996H3.33341C3.33341 13.6816 6.31841 16.6666 10.0001 16.6666C13.6817 16.6666 16.6667 13.6816 16.6667 9.99996C16.6667 6.31829 13.6817 3.33329 10.0001 3.33329C7.94675 3.33329 6.11008 4.26163 4.88758 5.72079L6.66675 7.49996H1.66675V2.49996L3.70591 4.53829C5.23341 2.77996 7.48675 1.66663 10.0001 1.66663ZM10.8334 5.83329V9.65413L13.5359 12.3566L12.3567 13.5358L9.16675 10.3441V5.83329H10.8334Z" fill="#C4CAD0"/>
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className='searched-addr'>
                                                <div className='searched-heading'>{seacrh.Title} <span className='Product_varieant'>{seacrh.StorageGB}</span> </div>
                                            </div>
                                        </div>
                                        <div className=" clearbtn">
                                            <button className='clear'> clear</button>
                                        </div>
                                    </div>
                                ))}
                                {results.map(item => (
                                    <div className='ProductsearchedLists' key={item._id}>
                                        <div className='searched-distance'>
                                            <span>
                                                <img src={filterSearch} alt="" />
                                            </span>
                                        </div>
                                        <div className='searched-addr'>
                                            <div className='searched-heading'>{item.Title} <span className='Product_varieant'>{item.StorageGB}</span> </div>
                                        </div>
                                    </div>
                                    
                                ))}
                                <div className='searchedProduct-List'>
                                    <IphoneSearchTrending  />
                                </div>
                                
                            </div>
                        </div>
                        ) :(
                            <div className='searchedProduct-List'>
                                <SearchProductFilter  />
                            </div>
                        )                                
                    }
                </Offcanvas.Body>
            </Offcanvas> 
        </>
    )
}

export default NavSearchList