import React, { useState } from 'react';
import EmptyCart from '../Components/Cart/EmptyCart';
import CartSteps from '../Components/Cart/CartSteps';
import LoggedAddress from '../Components/Address/LoggedAddress';
import CartOffers from '../Components/Cart/CartOffers';
import CartOfferAdded from '../Components/Cart/CartOfferAdded';
import CartCoupon from '../Components/Cart/CartCoupon';
import CartItems from '../Components/Cart/CartItems';
import ProductAccessories from '../Components/Product/ProductAccessories';
import CartSavedItems from '../Components/Cart/CartSavedItems';
import StickyCart from '../Components/Cart/StickyCart';
import CartGiftCart from '../Components/Cart/CartGiftCart';
import GSTCart from '../Components/Cart/GSTCart';
import CartReplace from '../Components/Cart/CartReplace';
import CartBetterOffer from '../Components/Cart/CartBetterOffer';
import OrderSummary from '../Components/Payment/OrderSummary';
import TwocartmodelAlert from '../Components/Cart/TwocartmodelAlert';
import Union from '../Images/product/Union.png'


const Cart = () => {
    const [ user ] = useState(true);
    const [ isCartEmpty ] = useState(false);
    const [ isOfferApplied ] = useState(false);
    const [ isMADAdded ] = useState(false);
    const [ isCouponApplied ] = useState(false);
 
    return (
        <>  
            {isCartEmpty ? 
                <EmptyCart />
                : 
                <>
                    <div className='cart__wrap'>
                        <div className='cart-steps'>
                            <CartSteps />
                        </div>
                        <div className='step_cart'>
                            <div className='step-location__cart'>
                                <div className='step-location__info'>
                                    <div className='step-location__map'>
                                        <span>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.2427 11.5761L8 15.8188L3.75734 11.5761C2.91823 10.737 2.34679 9.66789 2.11529 8.50401C1.88378 7.34013 2.0026 6.13373 2.45673 5.03738C2.91086 3.94103 3.6799 3.00396 4.66659 2.34467C5.65328 1.68539 6.81332 1.3335 8 1.3335C9.18669 1.3335 10.3467 1.68539 11.3334 2.34467C12.3201 3.00396 13.0891 3.94103 13.5433 5.03738C13.9974 6.13373 14.1162 7.34013 13.8847 8.50401C13.6532 9.66789 13.0818 10.737 12.2427 11.5761ZM8 8.66678C8.35362 8.66678 8.69276 8.5263 8.94281 8.27625C9.19286 8.0262 9.33334 7.68707 9.33334 7.33344C9.33334 6.97982 9.19286 6.64068 8.94281 6.39064C8.69276 6.14059 8.35362 6.00011 8 6.00011C7.64638 6.00011 7.30724 6.14059 7.05719 6.39064C6.80715 6.64068 6.66667 6.97982 6.66667 7.33344C6.66667 7.68707 6.80715 8.0262 7.05719 8.27625C7.30724 8.5263 7.64638 8.66678 8 8.66678Z" fill="#121415"/>
                                            </svg>
                                        </span>
                                    </div>
                                    <div className='step-location__content'>
                                        {user ? 
                                            <>
                                                <h6>Rohan Nanda, 560103</h6>
                                                <p>103, Udgam Belvedere, Kadubeesanahalli, Bella...</p>
                                            </> : 
                                            <div className='step-location__content-loggedOut'>
                                                <label htmlFor="label">Deliver to :</label>
                                                <span>462026</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='step-location__link'>
                                    {['bottom'].map((placement, idx) => (
                                        <LoggedAddress key={idx} placement={placement} name={placement} user={user}/>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='h-1_2 mar-spacer' />
                        <div className='cart-bank__offers'>
                            <div className='p-0'>
                                {isOfferApplied ?
                                    <div className='p-x-16'>
                                        <CartOfferAdded /> 
                                    </div>: 
                                    <div className='ml--16'>
                                        <CartOffers />
                                    </div>
                                }
                            </div>
                            <div className='product-specifications'>
                                <div className='mar-spacer h-1' />
                                <CartCoupon isCouponApplied={isCouponApplied} />
                                <div className='mar-spacer h-3' />
                            </div>
                            <div className='cart__shopping'>
                                <h2 className='shopping-cart__heading'>My Shopping Cart <span>(3)</span></h2>
                                <div className='mar-spacer h-3' />
                                <CartReplace/>
                                <CartBetterOffer/>

                                <CartItems isMADAdded={isMADAdded} />
                                <h2 className='shopping-cart__heading'>Accessories for you</h2>
                                <div className='btn-category__wrap'>
                                    <button type='button' className='btn btn-category selected'>All</button>
                                    <button type='button' className='btn btn-category'>Accessories</button>
                                    <button type='button' className='btn btn-category'>Earphones</button>
                                    <button type='button' className='btn btn-category'>Charger</button>
                                    <button type='button' className='btn btn-category'>Headphone</button>
                                </div>
                                <div className='p-0'>
                                    <ProductAccessories />
                                </div>
                                <div className='product-specifications'>
                                    <div className='h-3 mar-spacer' />
                                    <div className='shopping-cart__gift'>
                                        {['bottom'].map((placement, idx) => (
                                            <CartGiftCart key={idx} placement={placement} name={placement} />
                                        ))}
                                    </div> 
                                    <div className='shopping-cart__gift border-top-0 shopping-cart__gst'>
                                        {['bottom'].map((placement, idx) => (
                                            <GSTCart key={idx} placement={placement} name={placement} />
                                        ))}
                                    </div>
                                    {/* <div className='h-3 mar-spacer' /> */}
                                </div>
                                <div className='payment-order__summary'>
                                    <OrderSummary />
                                    <div className='payment-order__media'>
                                        <img src={Union} className='img img-fluid w-100' alt='' />
                                    </div>
                                </div>
                                <div className='p-x-16'>
                                    <div className='cart-horizontal-line mb-0' />
                                    <CartSavedItems />
                                    <div className='cart-horizontal-line mt-0' />
                                </div>
                                <div className='h-3 mar-spacer' />
                            </div>
                        </div>
                        <TwocartmodelAlert/>
                    </div>
                    <StickyCart />
                </>
            }
        </>
    )
}

export default Cart