import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

import sangeethaSvg from '../../Images/sangeetha-svg.svg';

const MyCouponDetails = ({ ...props }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <button className='btn btn-dark-custom coupon-btn px-2' type='button' onClick={handleShow}>Learn More</button>

            <Offcanvas show={show} onHide={handleClose} {...props} className='notify-me__offCanvas-'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>
                        <img src={sangeethaSvg} className='img-fluid' alt='' />
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='coupon-offcanvas__wrap w-100'>                        
                        <h2 className='coupon-offcanvas__head'>You Avail ₹3000 as Sangeetha Exchange bonus</h2>
                        <div className='input-group-address w-100'>
                            <div className='cart-address__wrap'>
                                <input type="number" className='form-control form-control-cart' placeholder='Enter coupon code here'/>
                                <button type='button' className='btn btn-dark-button absolute-cart-input btn-space'>Copy</button>
                            </div>
                        </div>
                        <div className='coupon-offcanvas__info'>
                            <div className='coupon-offcanvas__label'>Expiry date</div>
                            <div className='coupon-offcanvas__span'>15 May, 2022</div>
                        </div>
                        <div className='coupon-offcanvas__details coupon-offcanvas__info'>
                            <div className='coupon-offcanvas__label'>Details</div>
                            <ul className='coupon-offcanva--list'>
                                <li>This programs is designed to reward the loyalty of the people who have bought their previous phones from Sangeetha Store.</li>
                                <li>Both Offline and online orders and applicable for the discount.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='mar-spacer h-0_5' />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default MyCouponDetails