import React from 'react';
import WalletOffersList from './WalletOffersList';

import OlaMoney from '../../Images/product/emi/OlaMoney.png';
import Payzapp from '../../Images/product/emi/Payzapp.png';
import Mobikiwik from '../../Images/product/emi/Mobikiwik.png';
import FreeCharge from '../../Images/product/emi/FreeCharge.png';

const WalletsPayment = ({ setToggle }) => {

    return (
        <>
            <div className='upi-payment__list'>
                <div className='upi-payment__wrap'>
                    {['bottom'].map((placement, idx) => (
                        <WalletOffersList key={idx} placement={placement} name={placement} setToggle={setToggle}/>
                    ))}
                    <div className='upi-payment__media'>
                        <img src={Mobikiwik} className='img img-fluid' alt='' />
                    </div>
                    <span className='upi-payment__text'>Mobikiwik</span>
                </div>
                <div className='cart-payment__offer mt-2'>Get 50% off on HDFC Debit Card</div>
            </div>
            <div className='upi-payment__list'>
                <div className='upi-payment__wrap'>
                    {['bottom'].map((placement, idx) => (
                        <WalletOffersList key={idx} placement={placement} name={placement} setToggle={setToggle} />
                    ))}
                    <div className='upi-payment__media'>
                        <img src={Payzapp} className='img img-fluid' alt='' />
                    </div>
                    <span className='upi-payment__text'>Payzapp</span>
                </div>
            </div>
            <div className='upi-payment__list'>
                <div className='upi-payment__wrap'>
                    {['bottom'].map((placement, idx) => (
                        <WalletOffersList key={idx} placement={placement} name={placement} setToggle={setToggle} />
                    ))}
                    <div className='upi-payment__media'>
                        <img src={FreeCharge} className='img img-fluid' alt='' />
                    </div>
                    <span className='upi-payment__text'>FreeCharge</span>
                </div>
            </div>
            <div className='upi-payment__list'>
                <div className='upi-payment__wrap'>
                    {['bottom'].map((placement, idx) => (
                        <WalletOffersList key={idx} placement={placement} name={placement} setToggle={setToggle} />
                    ))}
                    <div className='upi-payment__media'>
                        <img src={OlaMoney} className='img img-fluid' alt='' />
                    </div>
                    <span className='upi-payment__text'>OlaMoney</span>
                </div>
            </div>
        </>
    )
}

export default WalletsPayment