import React from 'react';

const BankOffersDetails = () => {

    return (
        <>
            <div className='product-specifications bank-offer-wrapper'>
                <div className='mar-spacer h-3'></div>
                <div className='bank-offer-details'>
                    <h3>5% Cashback on SANGEETHA AXIS Bank Credit Card on orders above ₹1800 </h3>
                    <div className='bank-offer__wrap'>
                        <div className='bank-offer__details'>
                            <div className='bank-offer__heading'>Duration</div>
                            <div className='bank-offer__text'>1st Feb, 2022 (00:00 Hours) to 28th Feb, 2022</div>
                        </div>
                        <div className='bank-offer__details'>
                            <div className='bank-offer__heading'>Duration</div>
                            <ul>
                                <li>Minimum Cart Value- INR 5,000</li>
                                <li>Max. Discount would be applicable as per the below table</li>
                            </ul>
                        </div>
                        <div className='bank-offer__details'>
                            <div className='bank-offer__heading'>Duration</div>
                            <div className='bank-offer__text'>Offer is applicable across Large Appliances, Electronics, 
                                            Furniture & Mobiles category products. However, the offer will not apply to the purchase of select products. 
                                            Please check if the offer is listed on the product page, before making a purchase.  </div>
                        </div>
                        <div className='bank-offer__details'>
                            <div className='bank-offer__heading'>Duration</div>
                            <div className='bank-offer__text'>Offer is applicable across Large Appliances, Electronics, 
                                            Furniture & Mobiles category products. However, the offer will not apply to the purchase of select products. 
                                            Please check if the offer is listed on the product page, before making a purchase.  </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BankOffersDetails
