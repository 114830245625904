import React, { useState } from 'react';
import { Link } from "@reach/router";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Step1 from './EMISteps/Step1';
import Step2 from './EMISteps/Step2';
import Step3 from './EMISteps/Step3';

const CardlessEMIModalList = ({...props }) => {
    const [show, setShow] = useState(false);
    const [page, setPage] = useState(0);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        username: "",
        nationality: "",
        other: "",
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const stepsTitle = ["1/3", "2/3", "3/3"];
 
    const PageDisplay = () => {
        if (page === 0) {
            return <Step1 formData={formData} setFormData={setFormData} />;
        } else if (page === 1) {
            return <Step2 formData={formData} setFormData={setFormData} />;
        } else {
            return <Step3 formData={formData} setFormData={setFormData} />;
        }
    };
    return (
        <>
            <button type='button' className='btn btn-dark-custom space-padding' onClick={handleShow}>Add EMi</button> 

            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Debit/ Credit Card EMI</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='pt-1'>

                    <div className="wizard-steps wizard-steps__exchange- mt-2 p-0">
                        <div className="form">
                            {page === stepsTitle.length - 1 ? '' : 
                                <div className="sticky-progress">
                                    <div className="progress-bar">
                                        <div
                                            className="progress-bar__line"
                                            style={{ width: page === 0 ? "33.3%" : page === 1 ? "66.6%" : "100%" }}
                                            // style={{ width: page === 0 ? "16.66%" : page === 1 ? "33.32%" : page === 2 ? "49.99%" : page === 3 ? "66.65%" : page === 4 ? "83.31%" : page === 5 ? "100%" : "0%" }}
                                        /> 
                                    </div>
                                    <div className="header">
                                        <div className="steps-bar">Step <span>{stepsTitle[page]}</span></div>
                                    </div>
                                </div>
                            }
                            <div className="form-container">
                                <div className="body">{PageDisplay()}</div>
						
                                <div className="steps-footer d-flex align-items-center justify-content-between">
                                    <button
                                        className={page === 0 ? 'btn btn-place-order btn-dark-custom not-selected d-none' : 'btn btn-dark-outline col-flex mr-2_5'}
                                        disabled={page === 0}
                                        onClick={() => {
                                            setPage((currPage) => currPage - 1);
                                        }}
                                    >Back</button>
                                    <Link
                                        className={page >= 1 ? 'btn btn-place-order btn-dark-custom col-flex text-nowrap' : 'btn btn-place-order btn-dark-custom w-100'}
                                        to={page === stepsTitle.length - 1 ? '/Payment' : '' }
                                        onClick={() => {
                                            if (page === stepsTitle.length - 1) {
                                                console.log(formData);
                                            } else {
                                                setPage((currPage) => currPage + 1);
                                            }
                                        }}
                                    >
                                        {page === stepsTitle.length - 1 ? 'Add Payment Method' : 'Next'}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>           
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default CardlessEMIModalList