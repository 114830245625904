import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import TelivisionHightlightCarosols from '../../CategoryDetails/Television_details/TelivisionHightlightCarosols';
import ProductPolicy from '../../ProductPolicy';

//Images
import display from '../../../../Images/CategoryDetails/logo/Display.png';
import SoundOutput from '../../../../Images/CategoryDetails/logo/SoundOutput.png';
import Screentype from '../../../../Images/CategoryDetails/logo/Screentype.png';
import cpu from '../../../../Images/product/cpu.svg';
import size from '../../../../Images/CategoryDetails/logo/size.png';
import weight from '../../../../Images/CategoryDetails/logo/weight.png';
import { Link } from '@reach/router';


const SmartTvhightlight = () => {

    const [activeId, setActiveId] = useState('1');

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }
    const ProductSpecifications = [
        {   
            _id: 1,
            Image: display,
            label: 'Display',
            Name: 'Ultra HD (4K)3840 x 2160 Pixels'
        }, {   
            _id: 2,
            Image: SoundOutput,
            label: 'Sound Output',
            Name: '20 W'
        }, {   
            _id: 3,
            Image: Screentype,
            label: 'Screen Type',
            Name: 'Backlit LED'
        }, {
            _id: 4,
            Image: cpu,
            label: 'Operating Systen',
            Name: 'Tizen'
        }, {
            _id: 5,
            Image: size,
            label: 'Size',
            Name: '1449.4 mm x 59.9 mm'
        }, {
            _id: 6,
            Image: weight,
            label: 'Weight (with Stand)',
            Name: '20.9kg'
        }
    ];

    return (
        <>             
            <Accordion defaultActiveKey={activeId}>
                <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('0')}>Specifications</Accordion.Header>
                    <Accordion.Body>
                        <div className='product-specification__wrap'>
                            {ProductSpecifications.map((Specification) => {
                                return (
                                    <div className='specification__list' key={Specification._id}>
                                        <div className='specification-list__icon'>
                                            <img src={Specification.Image} className='img img-fluid' alt={Specification.Name} />
                                        </div>
                                        <div className='specification-list__content'>
                                            <label>{Specification.label}</label>
                                            <span>{Specification.Name}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='product-specification__otherInfo'>
                            <h6 className='specification-info__head'>Other Details</h6>
                            <div className='specification-info__table'>
                                <table className='table specification-striped__table'>
                                    <tbody>
                                        <tr>
                                            <th>Smart TV</th>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <th>HDMI  </th>
                                            <td>30</td>
                                        </tr>
                                        <tr>
                                            <th>Built - In WIFI </th>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <th>Launch Year</th>
                                            <td>2021</td>
                                        </tr>
                                        <tr>
                                            <th>Wall mount included</th>
                                            <td>No</td>
                                        </tr>
                                        <tr>
                                            <th>Other Features</th>
                                            <td>Supported Apps - Netflix, Amazon Prime, Disney + Hotstar </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Link to='/CategoryDetails/Accessories/SmartTvSpecification' className='specification-info__head text-decoration-underline'>View All Details</Link>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className={activeId === '1' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('1')}>Highlights</Accordion.Header>
                    <Accordion.Body>
                        <div className='product-highlight__otherInfo'>
                            <TelivisionHightlightCarosols />
                            <Link to='/CategoryDetails/Accessories/SmartTvSpecification' className='specification-info__head text-decoration-underline'>View More Highlights</Link>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className={activeId === '2' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('2')}>Cancellation & Return Policy</Accordion.Header>
                    <Accordion.Body>
                        <ProductPolicy />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default SmartTvhightlight;