import { Link } from '@reach/router';
import React from 'react';
import emptyWishlist from '../../Images/product/emptyWishlist.png';
import ProductAccessories from '../Product/ProductAccessories';
import ProductRelatedCategory from '../Product/ProductRelatedCategory';
import ProductFooter from '../../Components/Footer/ProductFooter';

const EmptyWishlist = () => {
    return (
        <>
            <div className='cartEmpty__wrap'>
                <div className='cart-empty__wrap'>
                    <div className='cart-empty__media'>
                        <img src={emptyWishlist} className='img img-fluid' alt='' />
                    </div>
                    <div className='cart-empty__info'>
                        <h5>Your wishlist is empty</h5>
                        <p>Save gadgets that you like in your wishlist and <br/>review them anytime!</p>
                    </div>
                    <div className='h-1 mar-spacer' />
                    <Link to='/' type='button' className='btn btn-dark-theme'>Start Shopping</Link>
                </div>

            </div>
            <div className='cart-horizontal-line m-0' />
            <div className='product-specifications specification-head px-0'>
                <div className='h-3 mar-spacer' />
                <h4 className='specification-heading p-x-16'>Recently Viewed Items</h4>
                <ProductAccessories />
                <div className='h-3 mar-spacer' />
                <h4 className='specification-heading p-x-16'>Categories For You</h4>
                <div className='category-carousel ml--16'>
                    <ProductRelatedCategory />
                </div>
                <div className='h-3 mar-spacer' />
            </div>
            <ProductFooter />
        </>
    )
}
 
export default EmptyWishlist