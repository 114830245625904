import React ,{useState}from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

const ProductReviewWrite = ({...props}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>           
            <button type='button' className='btn btn-login w-100' onClick={handleShow}>Write a Review</button>
            <Offcanvas show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton className='pb-0 mb-0'>
                    <Offcanvas.Title className='heading_review'></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='pt-0'>
                    <div className='heading_review'>
                    Average
                    </div>
                  
                    <div className='cart-gift__wrap'>
                        <div className='review-write-stars'>
                            <span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.0002 17L6.12223 20.59L7.72023 13.89L2.49023 9.41L9.35523 8.86L12.0002 2.5L14.6452 8.86L21.5112 9.41L16.2802 13.89L17.8782 20.59L12.0002 17Z" fill="#121415"/></svg>
                            </span>
                            <span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.0002 17L6.12223 20.59L7.72023 13.89L2.49023 9.41L9.35523 8.86L12.0002 2.5L14.6452 8.86L21.5112 9.41L16.2802 13.89L17.8782 20.59L12.0002 17Z" fill="#121415"/></svg>

                            </span>
                            <span>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d_2466_74810)"><path d="M24.0005 34L12.2445 41.18L15.4405 27.78L4.98047 18.82L18.7105 17.72L24.0005 5L29.2905 17.72L43.0225 18.82L32.5605 27.78L35.7565 41.18L24.0005 34Z" fill="#121415"/></g><defs><filter id="filter0_d_2466_74810" x="-7.01953" y="-3" width="62.043" height="60.1797" filterUnits="userSpaceOnUse"><feFlood floodOpacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="6"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2466_74810"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2466_74810" result="shape"/></filter></defs></svg>

                            </span>
                            <span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.0009 18.26L4.94789 22.208L6.52289 14.28L0.587891 8.792L8.61489 7.84L12.0009 0.5L15.3869 7.84L23.4139 8.792L17.4789 14.28L19.0539 22.208L12.0009 18.26ZM12.0009 15.968L16.2479 18.345L15.2989 13.572L18.8719 10.267L14.0389 9.694L12.0009 5.275L9.96289 9.695L5.12989 10.267L8.70289 13.572L7.75389 18.345L12.0009 15.968Z" fill="#C4CAD0"/></svg>
                            </span>
                            <span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.0009 18.26L4.94789 22.208L6.52289 14.28L0.587891 8.792L8.61489 7.84L12.0009 0.5L15.3869 7.84L23.4139 8.792L17.4789 14.28L19.0539 22.208L12.0009 18.26ZM12.0009 15.968L16.2479 18.345L15.2989 13.572L18.8719 10.267L14.0389 9.694L12.0009 5.275L9.96289 9.695L5.12989 10.267L8.70289 13.572L7.75389 18.345L12.0009 15.968Z" fill="#C4CAD0"/></svg>
                            </span>
                        </div>
                        <div className="input_review-title">
                            <input  type='number' className='form-control form-control-Review   ' placeholder='Add a title'/>
                        </div>
                        <div className='textarea-wrapper-review'>
                            <textarea className='textarea-form-control-review' cols={3} rows={4} placeholder='Add description'></textarea>
                        </div>  
                        <button type='button' className='btn btn-dark-theme w-100 review-btn'>Submit Review</button>                          
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default ProductReviewWrite;