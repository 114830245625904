import React from 'react'

const ProductPolicy = () => {
    return (
        <>
            <div className='product-policy__wrap'>
                <ul className='product-policy__list'>
                    <li>7 days return policy</li>
                    <li>Free standard delivery on all orders and free return within 7 days of your order delivery date.</li>
                </ul>
            </div>
        </>
    )
}

export default ProductPolicy
