import React from "react";
import { Router } from '@reach/router';
import { BrowserRouter } from 'react-router-dom';

//Screens
import Home from "./Containers/Home";
import Category from './Containers/Category';
import Brand from "./Containers/Brand";
import Categories from "./Containers/Categories";
import Brands from "./Containers/Brands";
import LoginScreen from "./Containers/Login";
import ProductDetails from "./Containers/Product/ProductDetails";
import WhySangeethaExplore from "./Components/Product/WhySangeethaExplore";
import ProductRenewed from "./Containers/Product/ProductRenewed";
import PreBooking from "./Containers/Product/PreBooking";
import ProductList from "./Containers/Product/ProductList";
import RenewedPrelaunchList from "./Containers/Product/RenewedPrelaunchList";
import CartDetails from "./Containers/Cart";
import WishlistDetails from "./Containers/Wishlist";
import Address from "./Containers/Address";
import AddAddress from "./Components/Address";
import InputSearch from "./Components/Address/InputSearch";
import AddAddressDetails from "./Components/Address/AddAddressDetails";
import OrderDetails from "./Components/Order/OrderDetails";
import OrderPickupDetails from "./Components/Order/OrderPickupDetails";
import OrderDeliveredDetails from "./Components/Order/OrderDeliveredDetails";
import NavSearchBar from "./Components/Header/NavSearchBar";
import SearchResult from "./Containers/SearchResult";
import Compare from "./Containers/Compare";
import Account from "./Containers/Account";
import Profile from "./Components/Account/Profile";
import AccountAddress from "./Components/Account/Address";
import Payments from "./Components/Account/Payments";
import MyCoupons from "./Components/Account/MyCoupons";

import Payment from "./Containers/Payment";
import BankOffersList from "./Components/Product/BankOffersList";
import BankOffersDetails from "./Components/Product/BankOffersDetails";
import ProductSpecifications from "./Components/Product/ProductSpecifications";
import ProductOutOfStock from "./Containers/Product/ProductOutOfStock";
import EMIListTab from "./Components/Product/EMIListTab";
import DIY from "./Containers/DIY";
import DIYLaptop from "./Containers/DIYLaptop";
import DIYHeadphone from "./Containers/DIYHeadphone";
import ResultList from "./Components/DIY/ResultList";
import StoreLocator from "./Containers/StoreLocator";
import StoreSearch from "./Components/StoreLocator/StoreSearch";

//Product details category pages
import ProductEarphone from "./Containers/Product/ProductEarphone";
import ProductSmartWatch from "./Containers/Product/ProductSmartWatch";
import ProductAccessories from "./Containers/Product/ProductAccessories";
import ProductSmartTv from "./Containers/Product/ProductSmartTv";
import ProductSpecificationsCategory from "./Components/Product/CategoryDetails/ProductSpecifications";
import SmartwatchSpecification from "./Components/Product/CategoryDetails/SmartwatchSpecification";
import SmartTvSpecification from "./Components/Product/CategoryDetails/Television_details/SmartTvSpecification";
import Order from "./Containers/Order/Order";
import OrderConfirmation from "./Containers/Order/OrderConfirmation";
import OrdersuccessfullDetails from "./Containers/Order/OrdersuccessfullDetails";
import OrderTrack from "./Containers/Order/OrderTrack";
import SmartWatchList from "./Containers/Product/SmartWatchList";
import MobileAccessoriesList from "./Containers/Product/MobileAccessoriesList";
import HeadPhoneList from "./Containers/Product/HeadPhoneList";

import OrderCancellationDetails from './Components/Ordercancellation/OrderCancellationDetails';
import OrderCancelled from './Components/Ordercancellation/OrderSuccessCancel'
import OrderCanceledDetails from './Components/Ordercancellation/OrderCanceledDetails';
import OrderDelay from './Components/Order/OrderDelay'

//Components
import Header from "./Components/Header";
import ScrollToTop from "./Components/Footer/ScrollToTop";

// orderstatus
import OrderStatus from './Components/Order/OrderStatus/OrderStatus';
import OrderDelivered from './Components/Order/OrderDelivered';
import OrderTrackerStatus from './Components/Order/OrderStatus/OrderTrackerStatus'









const App = () => {

    return (
        <div className="page-wrapper">

            <BrowserRouter>
                <ScrollToTop />
                <Header />
                <Router>
                    <Home exact path="/" component={Home} />
                    <Category exact path='/Category' />
                    <Brand exact path='/Brand' />
                    <Categories exact path='/Categories' />
                    <Brands exact path='/Brands' />
                    <LoginScreen exact path='/Login' />
                    <NavSearchBar exact path="/NavSearchBar" />
                    <SearchResult exact path="/SearchResult" />
                    <ProductDetails exact path="/ProductDetails" />
                    <WhySangeethaExplore exact path='/WhySangeethaExplore' />
                    <ProductRenewed exact path='/ProductRenewed' />
                    <PreBooking exact path='/PreBooking' />
                    <ProductList exact path="/ProductList" />
                    <RenewedPrelaunchList exact path='/RenewedPrelaunchList' />
                    <Compare exact path='/Compare' />
                    <CartDetails exact path="/Cart" />
                    <WishlistDetails exact path="/Wishlist" />
                    <Address exact path="/Address" />
                    <AddAddress exact path="/AddAddress" /> 
                    <InputSearch exact path="/InputSearch" />
                    <AddAddressDetails exact path="/AddAddressDetails" />
                    <Payment exact path="/Payment" />
                    <BankOffersList path="/BankOffersList" />
                    <BankOffersDetails path="/BankOffersDetails" />
                    <ProductSpecifications path="/ProductSpecifications" />
                    <ProductOutOfStock path="/ProductOutOfStock" />
                    <EMIListTab path="/EMIListTab" />
                    <Account exact path="/Account" />
                    <Profile exact path='/Account/Profile' />
                    <AccountAddress exact path='/Account/AccountAddress' />
                    <Payments exact path='/Account/Payments' />
                    <MyCoupons exact path='/Account/MyCoupons' />
                    <DIY exact path='/DIY' />
                    <DIYLaptop exact path='/DIYLaptop' />
                    <DIYHeadphone exact path='/DIYHeadphone' />
                    <ResultList exact path='/ResultList' />
                    <StoreLocator exact path='/StoreLocator' />
                    <StoreSearch exact path='/StoreSearch' />
                    
                    {/* //Product Category */}
                    <ProductEarphone path="/ProductEarphone"/>
                    <ProductSmartWatch path="/ProductSmartWatch" />
                    <ProductAccessories path="/ProductAccessories" />
                    <ProductSmartTv path="/ProductSmartTv" />
                    <ProductSpecificationsCategory path="/CategoryDetails/ProductSpecifications" />
                    <SmartwatchSpecification path="/CategoryDetails/SmartwatchSpecification" />
                    <SmartTvSpecification path="/CategoryDetails/Accessories/SmartTvSpecification" />
                    <Order path ="/Order" />
                    <OrderConfirmation exact path='/OrderConfirmation' />
                    <OrdersuccessfullDetails path ="/OrdersuccessfullDetails" />
                    <OrderTrack path ="/OrderTrack" />
                    <SmartWatchList exact path="/SmartWatchList" />
                    <MobileAccessoriesList exact path="/MobileAccessoriesList" />
                    <HeadPhoneList exact path="/HeadPhoneList" />
                    <OrderDetails exact path="/OrderDetails" />

                    <OrderPickupDetails exact path="/OrderPickupDetails" />
                    <OrderDeliveredDetails exact path="/OrderDeliveredDetails" />
                    <OrderDelay exact path='/OrderDelay'/>
                    {/* Order Cancellation */}
                    <OrderCancellationDetails exact path="/OrderCancellationDetails" />
                    <OrderCancelled exact path="/OrderCancelled"/>
                    <OrderCanceledDetails exact path='/OrderCanceledDetails'/>
                    {/* orderstatus */}                   
                    <OrderStatus exact path ='/OrderStatus'/>
                    <OrderDelivered exact path ='/OrderDelivered'/>
                    {/* orderReplacement sangeetha */}
                    <OrderTrackerStatus exact path="/OrderTrackerStatus"/>

                </Router>
                {/* <Router>
                    
                </Router> */}
            </BrowserRouter>
        </div>
    );
};
export default App; 
