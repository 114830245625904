import React, { useState, useEffect } from 'react';
import LoadingBar from 'react-top-loading-bar';
import noResult from '../../Images/product/noResult.png';
import ProductTrending from './ProductTrending';
import PopularBrands from './PopularBrands';
import ProductRelatedCategory from '../Product/ProductRelatedCategory';
import SponsoredPhonesCarousel from './SponsoredPhonesCarousel';

const ProductNoResult = () => {
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        setProgress(100);
    }, []);
    return (
        <>  
            {progress ?
                <div className='position-relative progressbar__loader'>
                    <LoadingBar
                        color='#f97b08'
                        progress={progress}
                        onLoaderFinished={() => setProgress(0)}
                    /> 
                </div> :
                <>
                    <div className='cartEmpty__wrap'>
                        <div className='cart-empty__wrap'>
                            <div className='cart-empty__media'>
                                <img src={noResult} className='img img-fluid' alt='' />
                            </div>
                            <div className='cart-empty__info'>
                                <h5>Sorry, no results found!</h5>
                                <p>Please check the spelling or try searching for<br/>something else.</p>
                            </div>                    
                        </div>
                    </div>
                    <div className='cart-horizontal-line m-0' />
                    <div className='product-specifications specification-head product-sponsored__wrap p-0'>
                        <div className='mar-spacer' />
                        <div className='p-x-16'>
                            <h4 className='specification-heading'>Trending Top 3</h4>
                            <ProductTrending />
                            <div className='cart-horizontal-line' />
                            <h4 className='specification-heading'>Popular Brands</h4>
                            <PopularBrands />
                        </div>
                        <div className='cart-horizontal-line' />
                        <h4 className='product-sponsored__head p-x-16'>Sponsored Phones</h4>
                        <div className='ml--16'>
                            <SponsoredPhonesCarousel />
                        </div>
                        <div className='cart-horizontal-line' />
                        <h4 className='specification-heading p-x-16'>Popular Categories</h4>
                        <div className='ml--16'>
                            <ProductRelatedCategory />
                        </div>                        
                        <div className='mar-spacer' />
                    </div>
                </>
            }
        </>
    )
}

export default ProductNoResult