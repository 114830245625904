import React, { useState } from "react";
import CountDownTimer from "./CountDownTimer";

const CdTimerComp = () => {
    const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
    const NOW_IN_MS = new Date().getTime();

    const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
    const [ targetDate ] = useState(
        new Date(dateTimeAfterThreeDays)
    );

    return (
        <div className="pre-launch-2 pt-1">
            <CountDownTimer targetDate={targetDate} />
        </div>
    );
};

export default CdTimerComp;