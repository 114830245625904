import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ProductHighlightCarousel from './ProductHighlightCarousel';
import ProductReviewRating from './ProductReviewRating';
import ProductPolicy from './ProductPolicy';

import ram from '../../Images/product/ram.svg';
import storage from '../../Images/product/storage.svg';
import camera from '../../Images/product/camera.svg';
import display from '../../Images/product/display.svg';
import cpu from '../../Images/product/cpu.svg';
import battery from '../../Images/product/battery.svg';
import { Link } from '@reach/router';

const ProductHighlights = () => {

    const [activeId, setActiveId] = useState();

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }
    const ProductSpecifications = [
        {   
            _id: 1,
            Image: ram,
            label: 'RAM',
            Name: '8GB'
        }, {   
            _id: 2,
            Image: storage,
            label: 'Storage',
            Name: 'Speakers'
        }, {   
            _id: 3,
            Image: camera,
            label: 'Camera',
            Name: '32 MP + 8MP + 2MP'
        }, {
            _id: 4,
            Image: display,
            label: 'Display',
            Name: '6.43” Full HD + AMOLED DISPLAY'
        }, {
            _id: 5,
            Image: cpu,
            label: 'CPU',
            Name: 'Octa Core 2.4 GHz'
        }, {
            _id: 6,
            Image: battery,
            label: 'Battery',
            Name: '4300 mAh'
        }
    ];

    return (
        <>             
            <Accordion defaultActiveKey={activeId}>
                <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('0')}>Specifications</Accordion.Header>
                    <Accordion.Body>
                        <div className='product-specification__wrap'>
                            {ProductSpecifications.map((Specification) => {
                                return (
                                    <div className='specification__list' key={Specification._id}>
                                        <div className='specification-list__icon'>
                                            <img src={Specification.Image} className='img img-fluid' alt={Specification.Name} />
                                        </div>
                                        <div className='specification-list__content'>
                                            <label>{Specification.label}</label>
                                            <span>{Specification.Name}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='product-specification__otherInfo'>
                            <h6 className='specification-info__head'>Other Details</h6>
                            <div className='specification-info__table'>
                                <table className='table specification-striped__table'>
                                    <tbody>
                                        <tr>
                                            <th>Network Type</th>
                                            <td>4G VOLTE, 4G,3G,2G</td>
                                        </tr>
                                        <tr>
                                            <th>SIM Type</th>
                                            <td>Dual Sim</td>
                                        </tr>
                                        <tr>
                                            <th>Expandable Storage</th>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <th>Quick Charging</th>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <th>Audio jack</th>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <th>Operating System</th>
                                            <td>Android 11.0</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Link to='/ProductSpecifications' className='specification-info__head text-decoration-underline'>View All Details</Link>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className={activeId === '1' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('1')}>Highlights</Accordion.Header>
                    <Accordion.Body>
                        <div className='product-highlight__otherInfo'>
                            <ProductHighlightCarousel />
                            <Link to='/ProductSpecifications' className='specification-info__head text-decoration-underline'>View More Highlights</Link>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className={activeId === '2' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('2')}>Reviews and Ratings</Accordion.Header>
                    <Accordion.Body>
                        <ProductReviewRating />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className={activeId === '3' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('3')}>Cancellation & Return Policy</Accordion.Header>
                    <Accordion.Body>
                        <ProductPolicy />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default ProductHighlights;