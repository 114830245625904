import React from 'react'

const OrderCancelStep3 = () => {
    return (
        <>
            <div className='filter-tab__lists sort__by'>
                <div className="order-summary__table">
                    <div className="order-summary__body">
                        <div className="order-summary__row">
                            <div className="order-summary__col">
                                <label htmlFor="label">Order Value</label>
                            </div>
                            <div className="order-summary__col">
                                <span>₹78,999</span>
                            </div>
                        </div>
                        <div className="order-summary__row">
                            <div className="order-summary__col">
                                <label htmlFor="label">Cancellation fees</label>
                            </div>
                            <div className="order-summary__col">
                                <span>- ₹150</span>
                            </div>
                        </div>                                             

                    </div>
                    <div className="order-summary__footer">
                        <div className="order-summary__row">
                            <div className="order-summary__col">
                                <span>Total Refund</span>
                            </div>
                            <div className="order-summary__col">
                                <span>₹78,849</span>
                            </div>
                        </div>
                                                                                                           
                    </div>
                </div>
                        
                       
                <div className="proceedcancellation-padding"></div>

                <div className="product-cancelselectFilter">
                        Total Refund amount will be refunded to your respective payment method within the next 3-7 days.
                </div>
                <div className="proceedcancellation-padding"></div>
                          
         
            </div>

        </>
    )
}

export default OrderCancelStep3