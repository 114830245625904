import React from 'react';
import Carousel from "react-multi-carousel";
import Category1 from '../../Images/product/Catg1.png';
import Category2 from '../../Images/product/Catg2.png';

const ProductRelatedCategory = () => {
    const ProductCategory = [
        {   
            Image: Category1,
            Name: 'Headphone'
        }, {   
            Image: Category2,
            Name: 'Speakers'
        }, {   
            Image: Category1,
            Name: 'Headphone'
        }
    ];

    const responsive = {
        tablet: {
            breakpoint: { max: 768, min: 567 },
            items: 3,
            partialVisibilityGutter: 0
        },
        mobile: {
            breakpoint: { max: 567, min: 0 },
            items: 2,
            partialVisibilityGutter: 20
        }
    };

    return (
        <>
            <Carousel                
                partialVisible={true}
                arrows={false}
                itemClass="image-item"
                responsive={responsive}
            >   
                {ProductCategory.map((ProductCategory, idx) => {
                    return (
                        <div className='product-carousel-wrapper' key={idx}>
                            <div className='carousel-media-wrapper carousel-catg'>
                                <img
                                    draggable={true}
                                    style={{ width: "100%", height: "100%" }}
                                    src={ProductCategory.Image}
                                    alt={ProductCategory.Name}
                                    className='img'
                                />
                            </div>
                            <div className='category-overlay-text'>
                                {ProductCategory.Name}
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </>
    )
}

export default ProductRelatedCategory
