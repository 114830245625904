import React, { useState } from 'react';
import Ontheway from '../../Images/CategoryDetails/Order/orderdetails/Ontheway.png';
import box1 from '../../Images/CategoryDetails/Order/Arriving_product1.png';
import box2 from '../../Images/CategoryDetails/Order/Arriving_product2.png';
import Helpimg from '../../Images/CategoryDetails/Order/orderdetails/question-answer-line.png'
import servicebox1 from '../../Images/CategoryDetails/Order/orderdetails/order_detail-user.png';
import servicebox2 from '../../Images/CategoryDetails/Order/orderdetails/orderdetail_going.png';
import servicebox3 from '../../Images/CategoryDetails/Order/orderdetails/userdetails-shiled.png';
import Ordermap from '../../Images/CategoryDetails/Order/orderdetails/order-map.png';
import OrderSummary from '../../Components/Payment/OrderSummary';
import Union from '../../Images/product/Union.png';
import ProductRecommend from '../../Components/Product/ProductRecommend';
import ProductFooter from '../Footer/ProductFooter';
import DownloadImg from '../../Images/CategoryDetails/Order/orderdetails/download_invoice.png';
import NonTrackshipMentstatus from '../Order/OrderStatus/NonTrackshipMentstatus'

const OrderDetails = () => {
    const [orderway]=useState(true)
  
    return (
        <>
            <div className='Order_Id'>
                <span className='order-id-txt'>ORDER ID: </span> <span className='order-id-txt'> 983729203490</span>
            </div>
            {orderway ?
                <div className='order-este__delivery'>
                    <div className='address-este____media'>
                        <img src={Ontheway} className='img img-fluid my-2' alt='' />
                    </div>
                    <div className='order-este__details'>
                        <h5>Order is on the way</h5>
                        <div className="ordder_deliverTo__info">
                            <span className="order_info__similarProd">ETA :  &nbsp;</span>
                            <span className="order_info__similarProd">30 mins &nbsp;</span>
                        </div>
                    </div>
                    {['bottom'].map((placement, idx) => (
                        <NonTrackshipMentstatus key={idx} placement={placement} name={placement} />
                    ))}
                </div>
                :
                <div className='order-este__delivery'>
                    <div className='address-este____media'>
                        <img src={Ontheway} className='img img-fluid my-2' alt='' />
                    </div>
                    <div className='order-este__details'>
                        <h5>Order placed</h5>
                        <div className="ordder_deliverTo__info">
                            <span className="order_info__similarProd">ETA :  &nbsp;</span>
                            <span className="order_info__similarProd">30 mins &nbsp;</span>
                        </div>
                    </div>
                    <button type='button' className='btn btn-dark-button btn-space bnt-h'>Track</button>
                </div>
            
            }
          

           
            <div className="order_details-Model">
                <h4 className='Order_Pending-msg'>Items Included</h4>
            </div>

            <div className='order-este__include mb-0'>
                <div className='orderdetails-este____media'>
                    <img src={box1} className='img img-fluid' alt=''/>
                </div>
                <div className='order-include__details'>
                    <h5>Samsung Galaxy Z Fold 2 5G</h5>
                    <div className="ordder_deliverTo__info">
                        <span className="order_info__similarProd">Mystic Purple  &nbsp;</span>
                        <span className="order_info__similarProd">, 256GB + 8GB &nbsp;</span>
                    </div>
                </div>
            </div>
            <div className="order_detail-cart__vas">
                <div className="shopping-vas__info">
                    <div className="shopping-vas__media">
                        <span>
                            <svg width="24" height="24" viewBox="11 9 25 31" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white"></rect><path d="M15.783 14.826L24 13L32.217 14.826C32.4391 14.8754 32.6377 14.999 32.78 15.1764C32.9224 15.3538 33 15.5745 33 15.802V25.789C32.9999 26.7767 32.756 27.7492 32.2899 28.62C31.8238 29.4908 31.1499 30.2331 30.328 30.781L24 35L17.672 30.781C16.8503 30.2332 16.1765 29.4911 15.7103 28.6205C15.2442 27.7498 15.0002 26.7776 15 25.79V15.802C15 15.5745 15.0776 15.3538 15.22 15.1764C15.3623 14.999 15.5609 14.8754 15.783 14.826ZM25 22V17L20 24H23V29L28 22H25Z" fill="#121415"></path></svg>
                        </span>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="shopping-vas__head">Money and Damage Protection (MAD) </div>
                        <div className="shopping-vas__label">1 Year Protection</div>
                    </div>
                </div>                
                                  
                                    
            </div>
            <div className="order_detail-cart__vas order_detail-mr">
                <div className="shopping-vas__info">
                    <div className="shopping-vas__media">
                        <span>
                            <svg width="24" height="24" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.7663 7.64027L18.7645 7.64027L18.7645 13.4739L17.0511 13.4739L17.0505 10.4846L9.07045 18.2469L7.85869 17.0684L15.8393 9.30668L12.7663 9.30669V7.64027ZM1.79989 6.52068L3.51332 6.52068L3.51332 9.50939L11.494 1.74771L12.7057 2.92623L4.72508 10.6879L7.79811 10.6879L7.7981 12.3543L1.79989 12.3543L1.79989 6.52068Z" fill="#121415"/>
                            </svg>
                        </span>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="shopping-vas__head">Exchange on Apple iPhone XR 128GBMAD)  </div>
                        <div className="shopping-vas__label">Please keep your old phone ready for verification.</div>
                    </div>
                </div>                
                                  
                                    
            </div>
        

            <div className='order-este__include order_incluid-p mb-0'>
                <div className='orderdetails-este____media'>
                    <img src={box2} className='img img-fluid' alt='' />
                </div>
                <div className='order-include__details'>
                    <h5>Watercolor gradient Phone grip holder and stand</h5>
                    <div className="ordder_deliverTo__info">
                        <span className="order_info__similarProd">Qty :    &nbsp;</span>
                        <span className="order_info__similarProd"> 1 &nbsp;</span>
                    </div>
                </div>
            </div>
            <div className="mar-spacer bg-theme"></div>
           
            <div className="order_details-Model need_help">
                <div className="need_help__img">
                    <img src={Helpimg} alt="need_help__img"  className='img-fluid'/>
                    <h4 className='Order_Pending-msg'>Need help with order?</h4>
                </div>
                <p className='order-cancellationtxt'>Order cancellation window open till the order is dispatched</p>
                <button type="button" className="btn btn-block btn-dark-outline support">
                    <span className="ml-1 contactbtn">Contact Support</span>
                </button>
  
            </div>
            <div className="mar-spacer bg-theme"></div>
            <div className="order-details_service_container">
                <div className="delivery-service_heading">
                    <h5 className='deliveryser_heading order-free-service-mb'>Free Services</h5>
                    <div className='service_categoarybox'>
                        <img src={servicebox1} alt="" className='img' />
                        <p  className='order_Service-para'>Delivery by expert.</p>

                    </div>
                    <div className='service_categoarybox'>
                        <img src={servicebox3} alt="" className='img' />
                        <p className='order_Service-para'>Tempered Glass Installation at home</p>

                    </div>
                    <div className='service_categoarybox'>
                        <img src={servicebox2} alt=""className='img' />
                        <p className='order_Service-para'>Data Transfer at home</p>

                    </div>
                  
                </div>
            </div>
            <div className="mar-spacer bg-theme"></div>
            <div className="payment-details_service_container">
                <div className="delivery-service_heading">
                    <h5 className='deliveryser_heading order-payment-service-mb'>Payment method</h5>
                    <div className='order-payment_para'>
                        <span className='paymentbold'> Cash on Delivery : </span> <span className='payment-despo'>You can pay via Card/Cash or UPI enabled app at the time of delivery. Ask your delivery executive for these options or view this page to recieve a link for online payments.</span>
                    </div>                  
            
                </div>
                <button type="button" className="btn btn-block btn-dark-outline support">
                    <span className="ml-1 contactbtn">Pay now</span>
                </button>
            </div>
            <div className="mar-spacer bg-theme"></div>
            <div className="order_addresscontainer">
                <div className='orderdetails-address__head'>
                    <div className="order-map">
                        <img src={Ordermap} alt="order-map" />
                    </div>
                    <h6>Delivery Address</h6>
                </div>
                <div className='loggedIn__list order_detail-address'>               
                    <div className='loggedIn__label'>Rohan Nanda <span className='default__address'>(Default)</span></div>
                    <div className="btn btn-login btn-tag">Home</div>
                    <div className='cart-address__section'>
                        <div className='loggedIn-address__label'>
                                    103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103
                        </div>
                        <div className='loggedIn-address__contact' id='mobile'>
                            <label htmlFor="mobile">Mobile : </label>
                            <span>7382901648</span>
                        </div>
                    </div>
                </div>

            </div>
            <div className="mar-spacer bg-theme"></div>
            <div className='payment-order__summary'>
                <OrderSummary />
                <div className='payment-order__media'>
                    <img src={Union} className='img img-fluid w-100' alt='' />
                </div>
            </div>


            <div className="order_details-btns">
                <button type="button" className="btn btn-block btn-dark-outline mrg16">
                    <span className='download_invoice'>
                        <img src={DownloadImg} alt="Download_img" />
                    </span>
                    <span className="ml-1">Download Invoice</span>
                </button>
                <button type="button" className="btn btn-block btn-dark-custom">Back to Home</button>
               
            </div>
            <div className='mar-spacer h-3'></div>
            <div className='orderdetails_recomanded p-0 m-0'>  
                <h4 className='specification-heading p-x-16'>Sangeetha Recommends</h4>
                <div className='ml--0'>
                    <ProductRecommend deviceType="mobile"/>
                </div>
            </div>
            <ProductFooter />
        </>


    )
}

export default OrderDetails