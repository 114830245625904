import React from 'react';
import Carousel from "react-multi-carousel";
import CustomDot from './CustomDot';
import ImageLightbox from './ImageLightbox';

import ProductDetail from '../../Images/product/product-detail.png';
import productDetail2 from '../../Images/product/product-detail2.png';

const ProductGallery = () => {
    const GalleryImage = [
        productDetail2,
        ProductDetail,
        ProductDetail,
        ProductDetail
    ]
    return (
        <>
            <Carousel
                partialVisible={false}
                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="carouser-container"
                customDot={<CustomDot />}
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 1
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 1
                    }
                }}
                showDots
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                {GalleryImage.map((Data, index) => (
                    <div className='Light-box' key={index}>
                        {['bottom'].map((placement, idx) => (
                            <ImageLightbox key={idx} placement={placement} name={placement} Data={Data} />
                        ))} 
                    </div>
                ))}
            </Carousel>

        </>
    )
}

export default ProductGallery
