import React from 'react';
import Carousel from "react-multi-carousel";
import Img1 from '../../Images/product/emi/AxisLogo.png';
import Img2 from '../../Images/product/emi/SBI.png';
import Img3 from '../../Images/product/emi/HDFCLogo.png';

const BankOffers = () => {
    const OffersData = [
        {   
            _Id: 1,
            Image: Img1,
            Name: 'AXIS Bank 10X Edge Rewards'
        }, {   
            _Id: 2,
            Image: Img2,
            Name: 'SBI Credit Card'
        }, {   
            _Id: 3,
            Image: Img3,
            Name: 'HDFC Debit Card'
        }
    ];
    const responsive = {
        tablet: {
            breakpoint: { max: 768, min: 468 },
            items: 3,
            partialVisibilityGutter: 0
        },
        mobile: {
            breakpoint: { max: 468, min: 0 },
            items: 2,
            partialVisibilityGutter: 5
        }
    };
    return (
        <>
            <Carousel                
                partialVisible={true}
                arrows={false}
                itemClass="carousel-image-item offers-items-carousel"
                responsive={responsive}
            >   
                {OffersData.map((Data) => {
                    return (
                        <div className='offers-items-wrapper m-l-16' key={Data._Id}>
                            <div className='offers__media'>
                                <img src={Data.Image} className='img img-fluid' alt='' />
                            </div>
                            <div className='offers__title'>{Data.Name}</div>
                        </div>
                    );
                })}
            </Carousel>
        </>
    )
}

export default BankOffers