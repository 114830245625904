import { Link } from '@reach/router';
import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import HighlistImage1 from '../../Images/product/highlighttab.png';
import HighlistImage2 from '../../Images/product/highlighttab2.png';
import HighlistImage3 from '../../Images/product/highlighttab3.png';
import HighlistImage4 from '../../Images/product/highlighttab4.png';
import HighlistImage5 from '../../Images/product/highlighttab5.png';
import HighlistImage6 from '../../Images/product/highlighttab6.png';
import HighlistImage7 from '../../Images/product/highlighttab7.png';

const ProductHighlightTab = () => {
    const [activeId, setActiveId] = useState('0');

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    const ProductHighlightsTab = [
        {   
            _id: 0,
            Image: HighlistImage1,
            TabName: 'Immersive Infinity Flex Display',
            info: 'This Samsung mobile phone features an Infinity Flex Display with an Under Display Camera, allowing the device to function both as a smartphone and tablet, As a result, you can experience'
        }, {   
            _id: 1,
            Image: HighlistImage2,
            TabName: 'Smooth 120 Hz Display',
            info: 'Thanks to the Dynamic AMOLED 2X Display with 120 Hz Adaptive Refresh Rate, you can enjoy smooth browsing and an enhanced user experience on the phone, thus delivering a soothing experience to your eyes.'
        }, {   
            _id: 2,
            Image: HighlistImage3,
            TabName: 'S Pen Stylus',
            info: 'This foldable phone comes with an S Pen under the in-display camera so that you can conviniently sketch your ideas and work intuitively from anywhere. '
        }, {
            _id: 3,
            Image: HighlistImage4,
            TabName: 'Designed to shatter experiences ',
            info: 'Gorilla Glass Victus - Our toughest phone glass on Galaxy Z yet.'
        }, {
            _id: 4,
            Image: HighlistImage5,
            TabName: 'Don’t Sweat it, its water resistant',
            info: 'Make a splash with the world’s first water resistant foldable smartphone, with an IPX8 rating. '
        }, {
            _id: 5,
            Image: HighlistImage6,
            TabName: 'Our strongest aluminium frame yet',
            info: 'Super-strong yet lightweight Armour Aluminium Frame - 10% more durable to protect the interior parts of the hinge. '
        }, {
            _id: 6,
            Image: HighlistImage7,
            TabName: 'Snap, snap, share',
            info: 'Five different cameras in three different spots and the hands-free freedom of flex mode.'
        }
    ];

    return (
        <>  
            <div className='highlight-tab__wrap specifications-tab'>
                {ProductHighlightsTab && ProductHighlightsTab.map((HighlightsTab) => {
                    return (
                        <Accordion defaultActiveKey={HighlightsTab._id}  key={HighlightsTab._id}>
                            <Accordion.Item eventKey={HighlightsTab._id} className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                                <Accordion.Header onClick={() => toggleActive('0')}>{HighlightsTab.TabName}</Accordion.Header>
                                <Accordion.Body>
                                    <div className='highlight-body__wrap'>
                                        <p className='highlight-body__info'>This Samsung mobile phone features an Infinity Flex Display with an Under Display Camera, allowing the device to function both as a smartphone and tablet, As a result, you can experienc a...</p>
                                        <Link to='/' className='highlight-body__link'>Read More</Link>
                                        <div className='mar-spacer h-1_2'></div>
                                        <img src={HighlightsTab.Image} className='img img-fluid' alt='' />
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    );
                })}
            </div>
        </>
    )
}

export default ProductHighlightTab