import React, { useState } from 'react';
import buy1 from '../../Images/product/buy1.png';
import buy2 from '../../Images/product/buy2.png';
import buy3 from '../../Images/product/buy3.png';

const ProductBuyTogether = () => {
    const [isChecked, setIsChecked] = useState(true);
    const [isChecked1, setIsChecked1] = useState(true);
    const [isChecked2, setIsChecked2] = useState(true);

    const handleOnChange = () => {
        setIsChecked(!isChecked);
    };
    const handleOnChange1 = () => {
        setIsChecked1(!isChecked1);
    };
    const handleOnChange2 = () => {
        setIsChecked2(!isChecked2);
    };

    return (
        <>
            <div className='buy-together__wrap'>
                <div className='buy-together__card'>
                    <div className="buy-together__checkBox">
                        {/* <Form.Check 
                            aria-label="option 1" 
                            checked={isChecked} 
                            onChange={handleOnChange}
                        /> */}
                        <input 
                            className="form-check-input" 
                            type="checkbox" 
                            value="1" 
                            checked={isChecked} 
                            onChange={handleOnChange}
                            id="1" />
                        <div className='buy-together__media'>
                            <img src={buy1} className='img img-fluid' alt='' />
                        </div>
                        <div className='buy-together__details'>
                            <h6 className='buy-together__heading'>iPhone 13 Pro</h6>
                            <div className="price-offer">
                                <div className="new-price">₹28,000</div>
                                <div className="old-price">₹30,000</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='buy-together__card'>
                    <div className="form-check- buy-together__checkBox">
                        <input 
                            className="form-check-input" 
                            type="checkbox" 
                            value="2" 
                            checked={isChecked1} 
                            onChange={handleOnChange1}
                            id="2" />
                    </div>
                    <div className='buy-together__media'>
                        <img src={buy2} className='img img-fluid' alt='' />
                    </div>
                    <div className='buy-together__details'>
                        <h6 className='buy-together__heading'>Samsung Type C Adapter</h6>
                        <div className="price-offer">
                            <div className="new-price">₹5,000</div>
                            <div className="old-price">₹6,000</div>
                        </div>
                    </div>
                </div>
                <div className='buy-together__card'>
                    <div className="form-check- buy-together__checkBox">
                        <input 
                            className="form-check-input" 
                            type="checkbox" 
                            value="3" 
                            checked={isChecked2} 
                            onChange={handleOnChange2}
                            id="3" />
                    </div>
                    <div className='buy-together__media' htmlFor="apple">
                        <img src={buy3} className='img img-fluid' alt='' />
                    </div>
                    <div className='buy-together__details'>
                        <h6 className='buy-together__heading'>Samsung Earbuds Black Truly wire...</h6>
                        <div className="price-offer">
                            <div className="new-price">₹28,000</div>
                            <div className="old-price">₹30,000</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='buy-together__savings'>
                <p className='mb-0'>Saving additional ₹3,000 on 3 items</p>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
                <div className="price-offer justify-content-start mt-3">
                    <div className="new-price">₹28,000</div>
                    <div className="old-price">₹30,000</div>
                </div>
                <div className='d-flex'>
                    <button type='button' className='btn btn-block btn-dark-custom mb-2'>Buy Now</button>
                </div>
            </div>
        </>
    )
}

export default ProductBuyTogether
