import React, { useState,useEffect } from "react";

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TabContent from 'react-bootstrap/TabContent';
import SmartTvHighLightTab from '../../../Product/CategoryDetails/Television_details/SmartTvHighLightTab';
import SmartTvManufaturer from '../../../Product/CategoryDetails/Television_details/SmartTvManufaturer';
import SmarttvspecificationFullTab from "../../../Product/CategoryDetails/Television_details/SmarttvspecificationFullTab";
// import StickyTab from './StickyTab';


const SmartTvSpecificationTab = () => {
    const [key, setKey] = useState('profile');
    const [ stickyTab, setStickyTab] = useState(0);

    const handleScroll = (e) => {
        setStickyTab(e.target.scrollTop > 280);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);      
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    return (
        <>              
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className={stickyTab ? "d-flex justify-content-center tab-sticky-top" : "d-flex justify-content-center"}

                // className={isSticky ? "d-flex justify-content-center" : "navbar-sticky sticky"}
                // className={scroll ? "d-flex justify-content-center tab-sticky-top" : "d-flex justify-content-center"}
                // style={{color: scroll}}
            >
                <Tab eventKey="home" title="Highlights">
                    <TabContent>
                        <SmartTvHighLightTab />
                    </TabContent>
                </Tab>
                <Tab eventKey="profile" title="Specifications">
                    <TabContent>
                        <SmarttvspecificationFullTab />
                    </TabContent >
                </Tab>
                <Tab eventKey="contact" title="Manufacturer">
                    <TabContent>
                        <SmartTvManufaturer />
                    </TabContent>
                </Tab>
            </Tabs>
        </>
    )
}

export default SmartTvSpecificationTab;