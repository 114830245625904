import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import CardlessEMIModalList from './CardlessEMIModalList';
import RemoveCartItem from '../Cart/RemoveCartItem';

import hdfc from '../../Images/product/emi/hdfc.png';

const CardlessEmiPayment = () => {
    const [ BankOfferAllpied ] = useState(false);
    const [ istoggle, setToggle ] = useState(false);
    useEffect(() => {}, [istoggle])
    return (
        <>
            {BankOfferAllpied ?
                (<div className='shopping-cart__vas m-0'>
                    <div className='shopping-vas__info'>
                        <div className='shopping-vas__media'>
                            <img src={hdfc} className='img img-fluid' alt='' />
                        </div>
                        <div className='d-flex flex-column'>
                            <div className='shopping-vas__head mb-1'>HDFC Credit Card EMI</div>
                            <div className='shopping-vas__label offer-applied-'>₹2,776 for 3 months @14%</div>
                        </div>
                    </div>
                    <div className='shopping-vas__close'>
                        {['bottom'].map((placement, idx) => (
                            <RemoveCartItem key={idx} placement={placement} name={placement} />
                        ))} 
                    </div>
                </div>) :
                <>
                    <div className='upi-payment__list'>
                        <div className='upi-payment__wrap align-items-start'>
                            <div className='d-flex align-items-center'>
                                <div className='upi-payment__btn'>
                                    <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" onChange={()=>setToggle({toggle: !istoggle})}/>
                                </div>
                                <div className='upi-payment__media'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.6654 6.66667V13.3333C14.6654 13.5101 14.5951 13.6797 14.4701 13.8047C14.3451 13.9298 14.1755 14 13.9987 14H1.9987C1.82189 14 1.65232 13.9298 1.52729 13.8047C1.40227 13.6797 1.33203 13.5101 1.33203 13.3333V6.66667H14.6654ZM14.6654 5.33333H7.9987H1.33203V2.66667C1.33203 2.48986 1.40227 2.32029 1.52729 2.19526C1.65232 2.07024 1.82189 2 1.9987 2H13.9987C14.1755 2 14.3451 2.07024 14.4701 2.19526C14.5951 2.32029 14.6654 2.48986 14.6654 2.66667V5.33333ZM9.9987 10.6667V12H12.6654V10.6667H9.9987Z" fill="#C4CAD0"/>
                                        <path d="M14.6654 5.33333H7.9987H1.33203V2.66667C1.33203 2.48986 1.40227 2.32029 1.52729 2.19526C1.65232 2.07024 1.82189 2 1.9987 2H13.9987C14.1755 2 14.3451 2.07024 14.4701 2.19526C14.5951 2.32029 14.6654 2.48986 14.6654 2.66667V5.33333Z" fill="#121415"/>
                                    </svg>
                                </div>
                            </div>
                            <span className='upi-payment__text d-flex align-items-start flex-column mt-1'>
                                Credit Card / Debit Card EMI
                                {istoggle ? 
                                    <>
                                        <div className='h-1_2 mar-spacer' />
                                        {['bottom'].map((placement, idx) => (
                                            <CardlessEMIModalList key={idx} placement={placement} name={placement} />
                                        ))}
                                    </> : null
                                }
                            </span>
                        </div>
                    </div>
                    <div className='upi-payment__list'>
                        <div className='upi-payment__wrap align-items-start'>
                            <div className='d-flex align-items-center'>
                                <div className='upi-payment__btn'>
                                    <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                                </div>
                                <div className='upi-payment__media'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.6654 6.66667V13.3333C14.6654 13.5101 14.5951 13.6797 14.4701 13.8047C14.3451 13.9298 14.1755 14 13.9987 14H1.9987C1.82189 14 1.65232 13.9298 1.52729 13.8047C1.40227 13.6797 1.33203 13.5101 1.33203 13.3333V6.66667H14.6654ZM14.6654 5.33333H7.9987H1.33203V2.66667C1.33203 2.48986 1.40227 2.32029 1.52729 2.19526C1.65232 2.07024 1.82189 2 1.9987 2H13.9987C14.1755 2 14.3451 2.07024 14.4701 2.19526C14.5951 2.32029 14.6654 2.48986 14.6654 2.66667V5.33333ZM9.9987 10.6667V12H12.6654V10.6667H9.9987Z" fill="#C4CAD0"/>
                                        <path d="M14.6654 5.33333H7.9987H1.33203V2.66667C1.33203 2.48986 1.40227 2.32029 1.52729 2.19526C1.65232 2.07024 1.82189 2 1.9987 2H13.9987C14.1755 2 14.3451 2.07024 14.4701 2.19526C14.5951 2.32029 14.6654 2.48986 14.6654 2.66667V5.33333Z" fill="#121415"/>
                                    </svg>
                                </div>
                            </div>
                            <span className='upi-payment__text d-flex align-items-start flex-column mt-1'>
                                Credit Card / Debit Card EMI
                            </span>
                        </div>
                    </div>
                </>
            }   
        </>
    )
}

export default CardlessEmiPayment