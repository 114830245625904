import React from "react";
import DateTimeDisplay from "./DateTimeDisplay";
import useCountDown from "./useCountDown";

const ExpiredNotice = () => {
    return (
        <div className="expired-notice">
            <span>Expired!!!</span>
            <p>Please select a future date and time.</p>
        </div>
    );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
        <div className="show-counter">
            <DateTimeDisplay value={days} type={"days"} isDanger={days <= 3} />
            <span>:</span>
            <DateTimeDisplay value={hours} type={"hr"} isDanger={false} />
            <span>:</span>
            <DateTimeDisplay value={minutes} type={"min"} isDanger={false} />
            <span>:</span>
            <DateTimeDisplay value={seconds} type={"sec"} isDanger={false} />
        </div>
    );
};

const CountDownTimer = ({ targetDate }) => {
    const [days, hours, minutes, seconds] = useCountDown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return <ExpiredNotice />;
    } else {
        return (
            <ShowCounter
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
            />
        );
    }
};

export default CountDownTimer;