import React from 'react';
import box1 from '../../Images/CategoryDetails/Order/Arriving_product1.png';
import box2 from '../../Images/CategoryDetails/Order/Arriving_product2.png';




const ArrivingToday = () => {

    return (
        <>  
            <div className="order_successimges">
                <img src={box1} alt="" className='img' />
                <img src={box2} alt="" className='img' />
            </div>
            
        </>
    )
}

export default ArrivingToday
