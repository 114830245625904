import React,{useState} from 'react';
import delivered from '../../../Images/delivered.png';


const ArrivingDelivered = () => {
    const[arrivingDelivered]=useState(false)

    return (
        <>

            {arrivingDelivered?
                <div className="ordersuccesful_message order_tracking_model arriving-imges">  
                    <div>                                             
                        <div className="order_arriving_track">
                            <div className="arrivingheading">
                                <h4 className='Order_Successful-msg'>Delivered</h4>
                            </div>
                            
                        </div>                                                     
                        <p className='order-Location-para'>Rohan Home 103, Udgam Belvedere, Kadubeesana...
                        </p>
                    </div>    
                    <div className='d-flex'>
                        <img src={delivered} className='img img-fluid my-2' alt=''/>
                    </div>
                </div>:""
            }
        </>
    )
}

export default ArrivingDelivered