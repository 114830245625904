import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

const RemoveCartItem = ({ ...props }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    

    return (
        <>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleShow} >
                <path d="M0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z" fill="#98A0AE"/>
                <path d="M6.00036 5.29312L8.47536 2.81812L9.18236 3.52512L6.70736 6.00012L9.18236 8.47512L8.47536 9.18212L6.00036 6.70712L3.52536 9.18212L2.81836 8.47512L5.29336 6.00012L2.81836 3.52512L3.52536 2.81812L6.00036 5.29312Z" fill="white"/>
            </svg> 
            <Offcanvas show={show} onHide={handleClose} {...props} className='notify-me__offCanvas-'>
                <Offcanvas.Header>
                    <Offcanvas.Title className='cart-heading'>Remove item from Cart? </Offcanvas.Title>
                </Offcanvas.Header>
                <div className='cart-horizontal-line m-0'></div>
                <Offcanvas.Body>
                    <div className='similar-product__wrap w-100'>
                        <div className='shopping-cart__vas m-0 w-100'>
                            <div className='shopping-vas__info'>
                                <div className='shopping-vas__media'>
                                    <span>
                                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="48" height="48" fill="white"/>
                                            <path d="M15.783 14.826L24 13L32.217 14.826C32.4391 14.8754 32.6377 14.999 32.78 15.1764C32.9224 15.3538 33 15.5745 33 15.802V25.789C32.9999 26.7767 32.756 27.7492 32.2899 28.62C31.8238 29.4908 31.1499 30.2331 30.328 30.781L24 35L17.672 30.781C16.8503 30.2332 16.1765 29.4911 15.7103 28.6205C15.2442 27.7498 15.0002 26.7776 15 25.79V15.802C15 15.5745 15.0776 15.3538 15.22 15.1764C15.3623 14.999 15.5609 14.8754 15.783 14.826ZM25 22V17L20 24H23V29L28 22H25Z" fill="#121415"/>
                                        </svg>
                                    </span>
                                </div>
                                <div className='d-flex flex-column'>
                                    <div className='shopping-vas__head'>Money and Damage Protection (MAD) </div>
                                    <div className='shopping-vas__label'>1 Year Protection</div>
                                    <div className='price-offer mb-0'>
                                        <div className='new-price'>₹19,999</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='cart-btn-wrappper'>
                            <button type='button' className='btn btn-block btn-cart-outline w-100'>Cancel</button>
                            <button type='button' className='btn btn-block btn-cart-outline w-100 ml-2'>
                                <span>
                                    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.766 4.33341V11.8001C10.766 11.9415 10.7098 12.0772 10.6098 12.1772C10.5098 12.2772 10.3741 12.3334 10.2327 12.3334H2.76602C2.62457 12.3334 2.48891 12.2772 2.38889 12.1772C2.28887 12.0772 2.23268 11.9415 2.23268 11.8001V4.33341H1.16602V3.26675H11.8327V4.33341H10.766ZM3.29935 4.33341V11.2667H9.69935V4.33341H3.29935ZM3.83268 1.66675H9.16602V2.73341H3.83268V1.66675ZM5.96602 5.93341H7.03268V9.66675H5.96602V5.93341Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span className='ml-1'>Remove</span>
                            </button>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default RemoveCartItem