import React from 'react';
import MyCouponDetails from './MyCouponDetails';
import ExpiredCouponDetails from './ExpiredCouponDetails';

import Banner1 from '../../Images/coupon/banner1.png'
import Banner2 from '../../Images/coupon/banner2.png';
import Banner3 from '../../Images/coupon/Banner3.svg';
import Banner4 from '../../Images/coupon/Banner4.svg';
import coupon1 from '../../Images/coupon/coupon1.png';
import coupon2 from '../../Images/coupon/coupon2.png';
import coupon3 from '../../Images/coupon/coupon3.svg';
import coupon2_1 from '../../Images/coupon/coupon2_1.png';

const MyCoupons = () => {
    return (
        <>
            <div className='coupons__wrap'>
                <div className='mar-spacer h-1_2' />
                <h4 className='coupons-head__wrap expiring-text'>
                    <span>Expiring Soon</span>
                </h4>

                <div className='coupon-card__wrapper'>
                    <img src={Banner2} className='img-fluid w-100 coupon-img' alt='' />
                    <div className='coupon-card__bg'>
                        <div className='coupon-card__content'>
                            <h5>Yay! ₹3000 off </h5>
                            <p>Sangeetha Exchange Bonus</p>
                            <hr className='hr-coupon' />
                            <div className='coupon-card__info'>VALIDITY: only on current purchase</div>
                            {['bottom'].map((placement, idx) => (
                                <MyCouponDetails key={idx} placement={placement} name={placement} />
                            ))} 
                        </div>
                        <div className='coupon-card__media'>
                            <img src={coupon1} className='img img-fluid' alt='' />
                        </div>
                    </div>
                </div>

                <div className='mar-spacer h-2' />
                <h4 className='coupons-head__wrap'>
                    <span>Recently </span>
                </h4>
                <div className='coupon-card__wrapper red-theme'>
                    <img src={Banner3} className='img-fluid w-100 coupon-img' alt='' />
                    <div className='coupon-card__bg'>
                        <div className='coupon-card__content'>
                            <h5>Get ₹2000 cashback  </h5>
                            <p>Sangeetha Exchange Bonus</p>
                            <hr className='hr-coupon' />
                            <div className='coupon-card__info'>VALIDITY: only on current purchase</div>
                            {['bottom'].map((placement, idx) => (
                                <MyCouponDetails key={idx} placement={placement} name={placement} />
                            ))} 
                        </div>
                        <div className='coupon-card__media'>
                            <img src={coupon3} className='img img-fluid' alt='' />
                        </div>
                    </div>
                </div>
                <div className='mar-spacer h-1_5' />
                <div className='coupon-card__wrapper'>
                    <img src={Banner2} className='img-fluid w-100 coupon-img' alt='' />
                    <div className='coupon-card__bg'>
                        <div className='coupon-card__content'>
                            <h5>Yay! ₹3000 off </h5>
                            <p>Sangeetha Exchange Bonus</p>
                            <hr className='hr-coupon' />
                            <div className='coupon-card__info'>VALIDITY: only on current purchase</div>
                            {['bottom'].map((placement, idx) => (
                                <MyCouponDetails key={idx} placement={placement} name={placement} />
                            ))} 
                        </div>
                        <div className='coupon-card__media'>
                            <img src={coupon1} className='img img-fluid' alt='' />
                        </div>
                    </div>
                </div>
                <div className='mar-spacer h-1_5' />
                <div className='coupon-card__wrapper green-theme'>
                    <img src={Banner1} className='img-fluid w-100 coupon-img' alt='' />
                    <div className='coupon-card__bg'>
                        <div className='coupon-card__content'>
                            <h5>Get ₹1000 off </h5>
                            <p>on your next purchase</p>
                            <hr className='hr-coupon' />
                            <div className='coupon-card__info'>VALIDITY: 31st March ‘21</div>
                            {['bottom'].map((placement, idx) => (
                                <MyCouponDetails key={idx} placement={placement} name={placement} />
                            ))} 
                        </div>
                        <div className='coupon-card__media'>
                            <img src={coupon2} className='img img-fluid' alt='' />
                            <img src={coupon2_1} className='img img-fluid coupon-glitters' alt='' />
                        </div>
                    </div>
                </div>
                <div className='mar-spacer h-1_5' />
                <div className='coupon-card__wrapper disabled-theme'>
                    <img src={Banner4} className='img-fluid w-100 coupon-img' alt='' />
                    <div className='coupon-card__bg'>
                        <div className='coupon-card__content'>
                            <h5>Get ₹1000 off </h5>
                            <p>on your next purchase</p>
                            <hr className='hr-coupon' />
                            <div className='coupon-card__info'>VALIDITY: 31st March ‘21</div>
                            <div className='d-flex align-items-center'>
                                {['bottom'].map((placement, idx) => (
                                    <ExpiredCouponDetails key={idx} placement={placement} name={placement} />
                                ))} 
                                <span className='expired-text-label'>Expired</span>
                            </div>
                        </div>
                        <div className='coupon-card__media'>
                            <img src={coupon2} className='img img-fluid' alt='' />
                            <img src={coupon2_1} className='img img-fluid coupon-glitters' alt='' />
                        </div>
                    </div>
                </div>
                <div className='mar-spacer h-3' />
            </div>
        </>
    )
}

export default MyCoupons