import React,{useState} from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FaCheckCircle } from 'react-icons/fa';

const OffersOffcanvas = ({...props}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <span role='button' onClick={handleShow} aria-hidden="true">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.172 12.0007L8.222 7.05072L9.636 5.63672L16 12.0007L9.636 18.3647L8.222 16.9507L13.172 12.0007Z" fill="#121415"/>
                </svg>
            </span>

            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title />
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='bank-offer-wrapper'>
                        <div className='bank-offer-details'>
                            <h3>5% Cashback on SANGEETHA AXIS Bank Credit Card on orders above ₹1800 </h3>
                            <div className='bank-offer__wrap'>
                                <div className='bank-offer__details'>
                                    <div className='bank-offer__heading'>Duration</div>
                                    <div className='bank-offer__text'><FaCheckCircle />1st Feb, 2022 (00:00 Hours) to 28th Feb, 2022</div>
                                </div>
                                <div className='bank-offer__details'>
                                    <div className='bank-offer__heading'>Duration</div>
                                    <ul>
                                        <li>Minimum Cart Value- INR 5,000</li>
                                        <li>Max. Discount would be applicable as per the below table</li>
                                    </ul>
                                </div>
                                <div className='bank-offer__details'>
                                    <div className='bank-offer__heading'>Duration</div>
                                    <div className='bank-offer__text'>Offer is applicable across Large Appliances, Electronics, 
                                            Furniture & Mobiles category products. However, the offer will not apply to the purchase of select products. 
                                            Please check if the offer is listed on the product page, before making a purchase.  </div>
                                </div>
                                <div className='bank-offer__details'>
                                    <div className='bank-offer__heading'>Duration</div>
                                    <div className='bank-offer__text'>Offer is applicable across Large Appliances, Electronics, 
                                            Furniture & Mobiles category products. However, the offer will not apply to the purchase of select products. 
                                            Please check if the offer is listed on the product page, before making a purchase.  </div>
                                </div>
                            </div>
                        </div>
                        <div className='mar-spacer h-3'></div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default OffersOffcanvas
