import React from 'react'

const GpsModel = () => {
    return (
        <>
            <div className='product-models-box'>
                <div className='product-modelsFrame active'>
                    <span>GPS </span>
                </div>
                <div className='product-modelsFrame'>
                    <span>GPS + Cellular</span>
                </div>
            </div>
        </>
    )
}

export default GpsModel;
