import React,{useState} from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import RemoveStep1 from "../../Components/Cart/RemoveStep1";
import RemoveStep2 from "../../Components/Cart/RemoveStep2";


const Itemremovealert = ({...props}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [page, setPage] = useState(0);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        username: "",
        nationality: "",
        other: "",
    });
    const stepsHead = ["Items removed from cart", "Cannot add to cart"];
    const stepsTitle = ["1/2", "2/2"];


    const PageDisplay = () => {
        if (page === 0) {
            return <RemoveStep1 formData={formData} setFormData={setFormData} />;
        
        } else {
            return <RemoveStep2 formData={formData} setFormData={setFormData} />;
        }
    };
    return (
        <>
            <button type='button'className='moredetailcart-twoitem' onClick={handleShow}> More details</button>

            <Offcanvas show={show} onHide={handleClose} {...props} className='notify-me__offCanvas-'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>{stepsHead[page]} </Offcanvas.Title>
                </Offcanvas.Header>
                <div className='cart-horizontal-line m-0'></div>
                <Offcanvas.Body>

                    <div className="wizard-steps wizard-steps__exchange mt-2 p-0">
                        <div className="form">                           
                            <div className="form-container">
                                <div className="body">{PageDisplay()}</div>                                
                                <div className="steps-footer d-flex align-items-center justify-content-between">
                                    {page === stepsTitle.length - 1 ? 
                                        <>
                                            <button type='button' className='btn btn-dark-outline col-flex mr-2_5'>Replace cart items</button>
                                            <button type='button' className='btn btn-place-order btn-dark-custom col-flex'>Cancel</button>
                                        </> :
                                        <button
                                            type='button'
                                            className='btn btn-place-order btn-dark-custom w-100'
                                            onClick={() => {
                                                setPage((currPage) => currPage + 1);
                                                // console.log(formData);
                                            }}
                                        >Continue</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                 
                    
                </Offcanvas.Body>
            </Offcanvas>                        

        </>
  
    )
}

export default Itemremovealert;