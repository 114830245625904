import React from 'react';
import '../../Styles/order_success.css'
import cancelOrder from '../../Images/cancelOrder.svg';
import { Link } from '@reach/router';

const OrderSuccessCancel = () => {
    return (
        <>
            <div className="Order_Confirm">
                <div className="orderconfirm_img">
                    <div className='d-flex flex-column justify-content-between h-100'>
                        <div className='mar-spacer h-0_5' />
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                            <div className='mb-3'>
                                <img src={cancelOrder} alt="" className='img-fluid'/>
                            </div>
                            <div className="order_confirm-heading">
                                <div className="d-flex flex-column">
                                    <h2 className='order_success-heading'>Order has been cancelled </h2>
                                    <div className='order_success_para mx-0 w-100 px-3 h-100'>Your refund of ₹78,999 is initiated and should reach your account in 3-7 working days. For any query contact our support team.</div>
                                </div>  
                            </div>
                        </div>                                  
                        <div className="d-flex px-3 mb-3">
                            <Link to='/OrderCanceledDetails' className="btn btn-block btn-dark-custom w-100">Return to Shopping</Link>
                        </div> 
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderSuccessCancel