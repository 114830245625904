import React,{useState} from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import Exchange from '../../Images/product/wishlist.png'

const RemoveCartExchange = ({...props}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleShow} >
                <path d="M0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z" fill="#98A0AE"/>
                <path d="M6.00036 5.29312L8.47536 2.81812L9.18236 3.52512L6.70736 6.00012L9.18236 8.47512L8.47536 9.18212L6.00036 6.70712L3.52536 9.18212L2.81836 8.47512L5.29336 6.00012L2.81836 3.52512L3.52536 2.81812L6.00036 5.29312Z" fill="white"/>
            </svg> 
            <Offcanvas show={show} onHide={handleClose} {...props} className='notify-me__offCanvas-  Exchangeapplied-Canvas'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Exchange offer </Offcanvas.Title>
                </Offcanvas.Header>
                <div className='cart-horizontal-line m-0'></div>
                <Offcanvas.Body>
                    <div className='similar-product__wrap w-100'>    
                        <div className="exchangepara_head">
                            You are currently saving  <span> ₹24,000</span>  on exchange
                        </div>                    
                        <div className='specific-wrapper__list exchange_specific'>
                            <div className='specific-wrapper__media'>
                                <img src={Exchange} className='img img-fluid' alt='' />
                            </div>
                            <div className='specific-wrapper__content'>
                                <h4>Samsung Galaxy Z Fold 2 5G</h4>
                                <p>Mystic Purple, 256GB + 8GB</p>
                                <div className="price-offer justify-content-start">
                                    <div className="new-price">₹19,999</div>
                                    <div className="old-price">₹22,999</div>
                                </div>
                            </div>
                        </div>
                        <div className="exchange_specific-hr"></div>
                        <div className='cart-btn-wrappper'>
                            <button type='button' className='btn btn-block btn-cart-outline w-100'>
                                
                                <span className='ml-1'>Edit</span>
                            </button>
                            <button type='button' className='btn btn-block btn-cart text-nowrap w-100 ml-2'>
                                
                                <span className='ml-1'>Remove</span>
                            </button>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
       

        </>
    )
}

export default RemoveCartExchange