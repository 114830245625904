import React from 'react';
import RemoveCartItem from './RemoveCartItem';
import PaymentOfferSList from '../Payment/PaymenyOfferSList';

import hdfc from '../../Images/product/emi/hdfc.png';

const CartOfferAdded = () => {
    return ( 
        <>
            <div className='cart-coupon'>
                <div className='cart-coupon__head'>
                    <div className='coupon-tag'>
                        <span>Applied Offer</span>
                    </div>
                    <span className='coupon-link'>
                        {['bottom'].map((placement, idx) => (
                            <PaymentOfferSList key={idx} placement={placement} name={placement} />
                        ))}
                    </span>
                </div>
                <div className=''>
                    <div className='shopping-cart__vas m-0'>
                        <div className='shopping-vas__info'>
                            <div className='shopping-vas__media'>
                                <img src={hdfc} className='img img-fluid' alt='' />
                            </div>
                            <div className='d-flex flex-column'>
                                <div className='shopping-vas__head'>HDFC Credit and Debit Cards</div>
                                <div className='shopping-vas__label offer-applied'>Saving ₹2,999</div>
                            </div>
                        </div>
                        <div className='shopping-vas__close'>
                            {['bottom'].map((placement, idx) => (
                                <RemoveCartItem key={idx} placement={placement} name={placement} />
                            ))} 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CartOfferAdded