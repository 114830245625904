import React, { useState } from 'react';
import earphoneimg1 from '../../../Images/CategoryDetails/color-earphone.png';
import watchbuy from '../../../Images/CategoryDetails/buywatch.png';
import zipper from '../../../Images/CategoryDetails/zipper.png';

const EarphoneBuyTogether = () => {
    const [isChecked, setIsChecked] = useState(true);

    const handleOnChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <>
            <div className='buy-together__wrap'>
                <div className='buy-together__card'>
                    <div className="buy-together__checkBox">
                        {/* <Form.Check 
                            aria-label="option 1" 
                            checked={isChecked} 
                            onChange={handleOnChange}
                        /> */}
                        <input 
                            className="form-check-input" 
                            type="checkbox" 
                            value="" 
                            checked={isChecked} 
                            onChange={handleOnChange}
                            id="1" />
                        <div className='buy-together__media'>
                            <img src={earphoneimg1} className='img img-fluid' alt='' />
                        </div>
                        <div className='buy-together__details'>
                            <h6 className='buy-together__heading'>Airdopes 441 Bluetooth Truly...</h6>
                            <div className="price-offer">
                                <div className="new-price">₹9790</div>
                                <div className="old-price">₹10,000</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='buy-together__card'>
                    <div className="form-check- buy-together__checkBox">
                        <input 
                            className="form-check-input" 
                            type="checkbox" 
                            value="" 
                            checked={isChecked} 
                            onChange={handleOnChange}
                            id="2" />
                    </div>
                    <div className='buy-together__media'>
                        <img src={watchbuy} className='img img-fluid' alt='' />
                    </div>
                    <div className='buy-together__details'>
                        <h6 className='buy-together__heading'>boAt Flash Edition Smartwatch</h6>
                        <div className="price-offer">
                            <div className="new-price">₹10,599</div>
                            <div className="old-price">₹12,599</div>
                        </div>
                    </div>
                </div>
                <div className='buy-together__card'>
                    <div className="form-check- buy-together__checkBox">
                        <input 
                            className="form-check-input" 
                            type="checkbox" 
                            value="" 
                            checked={isChecked} 
                            onChange={handleOnChange}
                            id="3" />
                    </div>
                    <div className='buy-together__media' htmlFor="apple">
                        <img src={zipper} className='img img-fluid' alt='' />
                    </div>
                    <div className='buy-together__details'>
                        <h6 className='buy-together__heading'>UniGear Polyester Zipper Headpho..</h6>
                        <div className="price-offer">
                            <div className="new-price">₹599</div>
                            <div className="old-price">₹899</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='buy-together__savings'>
                <p className='mb-0'>Saving additional ₹2,000 on 3 items</p>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
                <div className="price-offer justify-content-start mt-3">
                    <div className="new-price">₹18,988</div>
                    <div className="old-price">₹20,998</div>
                </div>
                <div className='d-flex'>
                    <button type='button' className='btn btn-block btn-dark-custom mb-2'>Buy Now</button>
                </div>
            </div>
        </>
    )
}

export default EarphoneBuyTogether;
