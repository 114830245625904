import React from 'react';
import Carousel from "react-multi-carousel";
import box1 from '../../Images/product/box1.png';
import box2 from '../../Images/product/box2.png';

const ProductBox = () => {

    const ProductBox = [
        {   
            Image: box1,
            Name: 'Headphone'
        }, {   
            Image: box2,
            Name: 'Speakers'
        }, {   
            Image: box1,
            Name: 'Headphone'
        }
    ];

    const responsive = {
        tablet: {
            breakpoint: { max: 768, min: 468 },
            items: 3,
            partialVisibilityGutter: 0
        },
        mobile: {
            breakpoint: { max: 468, min: 0 },
            items: 2,
            partialVisibilityGutter: 20
        }
    };

    return (
        <>  
            <Carousel                
                partialVisible={true}
                arrows={false}
                itemClass="carousel-image-item"
                responsive={responsive}
            >   
                {ProductBox.map((ProductBox, idx) => {
                    return (
                        <div className='buy-together__wrap mt-4 m-l-16' key={idx}>
                            <div className='buy-together__card'>
                                <div className='buy-together__media'>
                                    <img
                                        draggable={true}
                                        style={{ width: "100%", height: "100%" }}
                                        src={ProductBox.Image}
                                        alt={ProductBox.Name}
                                        className='img img-fluid'
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </>
    )
}

export default ProductBox
