import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

const RemoveAddress = ({ ...props }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <button className='btn btn-category' type='button' onClick={handleShow}>Remove</button>

            <Offcanvas show={show} onHide={handleClose} {...props} className='notify-me__offCanvas-'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Remove Address? </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='similar-product__wrap w-100'>                        
                        <p>Are you sure you want to remove this address?</p>
                        <div className='cart-btn-wrappper'>
                            <button type='button' className='btn btn-block btn-cart-outline w-100'>Cancel</button>
                            <button type='button' className='btn btn-block btn-cart text-nowrap w-100 ml-2'>Confirm</button>
                        </div>
                    </div>
                    <div className='mar-spacer h-0_5' />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default RemoveAddress