import React from 'react';
// import {useDispatch, useSelector} from 'react-redux';
// import { getProductColors } from '../../store/actions/productAction';
// Images
import img1 from '../../Images/product/img1.png';

const ProductColors = () => {
    // const dispatch = useDispatch();
    // const productColorsData = useSelector(state => state.productDetails);
    // const {productColorsLoading, productColors, errProductColor} = productColorsData;
    // const [errorMessage, setErrorMessage] = useState('');
    // const [showErrMsg, setShowErrMsg] = useState(false);    

    // useEffect(() => {       
    //     dispatch(getProductColors(data))
    // }, [dispatch, data])

    // useEffect(() => {
    //     if (errProductColor !== '') {
    //         setErrorMessage(errProductColor);
    //         setShowErrMsg(true);
    //     }
    // }, [errProductColor]);

    return (
        <>     
            {/* {productColorsLoading ? (
                <div>Loading...</div> 
            ) : showErrMsg ? ( 
                <div>{errProductColor.message}</div>
            ) : (
                !errorMessage && (  */}
            <div className='product-colors-box'>
                {/* {productColors.map((color) => (  */}
                <div className='product-colorFrame active'>
                    <div className='product-colorFrame-media'>
                        <img src={img1} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Awesome Violet</div>
                </div>  
                <div className='product-colorFrame'>
                    <div className='product-colorFrame-media'>
                        <img src={img1} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Awesome Black</div>
                </div>  
                <div className='product-colorFrame'>
                    <div className='product-colorFrame-media'>
                        <img src={img1} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Awesome Green</div>
                </div>  
                <div className='product-colorFrame out-of-stock'>
                    <div className='product-colorFrame-media'>
                        <img src={img1} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Awesome Blue</div>
                </div>  
                {/* ))} */}
            </div>
            {/* )
            )} */}
        </>
    )
}

export default ProductColors