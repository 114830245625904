import React from 'react';
import { productsData } from '../../Data';
import AppleIphonePro from '../../Images/product/product-1.png';
import WishlistNotifyMe from './WishlistNotifyMe';
import WishlistSimilarItems from './WishlistSimilarItems';

const WishlistOutOfStock = () => {
    return (
        <>
            <div className='product-specifications'>
                <h5 className='specification-heading'>Currently Out of Stock</h5>
            </div>
            <div className='product-wrapper wishlist__wrap out-of-stock__wishlist m-0'>
                {productsData.slice(0, 2).map( (product) => (
                    <div className='product-list' key={productsData._id}>
                        {product.label ?
                            <div className='product-label-tag'>{product.label}</div> : ''
                        }
                        <button type='button' className='product-wishlist'>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 6C0 2.68629 2.68629 0 6 0V0C9.31371 0 12 2.68629 12 6V6C12 9.31371 9.31371 12 6 12V12C2.68629 12 0 9.31371 0 6V6Z" fill="#98A0AE"/>
                                <path d="M5.99987 5.29312L8.47487 2.81812L9.18187 3.52512L6.70687 6.00012L9.18187 8.47512L8.47487 9.18212L5.99987 6.70712L3.52487 9.18212L2.81787 8.47512L5.29287 6.00012L2.81787 3.52512L3.52487 2.81812L5.99987 5.29312Z" fill="white"/>
                            </svg>
                        </button>
                        <div className='d-flex flex-nowrap w-100 not-available-mask'>
                            <div className='product-thumb'>
                                <img src={AppleIphonePro} className='img-fluid img w-100' alt={product.productName} />
                            </div>
                            <div className='product-details'>
                                <h2>{product.productName}</h2>
                                <p className='product-muted'>
                                    {product.productColor}, 
                                    {product.productStorage.Storage} + 
                                    {product.productStorage.Storage}
                                </p>
                                <div className='product-price'>
                                    <div className='new-price'>₹{product.productNewPrice}</div>
                                    <div className='old-price'>₹{product.productOldPrice}</div>
                                    <div className='price-discount'>{product.productOffer}</div>
                                </div>
                                <div className='product-offer'>
                                    <div className='product-offer-list'>
                                        <div className='offer-icon'>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.5 1.5H6.086L1 6.586L5.414 11L10.5 5.914V1.5ZM8.75 2.5C9.164 2.5 9.5 2.836 9.5 3.25C9.5 3.664 9.164 4 8.75 4C8.336 4 8 3.664 8 3.25C8 2.836 8.336 2.5 8.75 2.5Z" fill="#121415"/>
                                            </svg>
                                        </div>
                                        <div className='offer-label'>Upto 15% Cashback on Axis Credit Cards</div>
                                    </div>
                                </div>
                                <div className='product-tags'>
                                    <div className='tag-label'>Photography</div>
                                    <div className='tag-label'>Fast Charge</div>
                                    <div className='tag-label'>Official Work</div>
                                </div>
                                <div className='product-compare'>
                                    + Compare
                                </div>
                            </div>
                        </div>
                        <div className='mar-spacer h-1_2' />
                        <div className='shopping-cart__btn'>
                            {['bottom'].map((placement, idx) => (
                                <WishlistSimilarItems key={idx} placement={placement} name={placement} />
                            ))}
                            {['bottom'].map((placement, idx) => (
                                <WishlistNotifyMe key={idx} placement={placement} name={placement} />
                            ))}
                        </div>
                        <div className='mar-spacer h-0_5' />
                    </div>
                ))}
            </div>
        </>
    )
}

export default WishlistOutOfStock