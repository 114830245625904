import React from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import UpdateDetails from './UpdateDetails';

const Profile = () => {
    return (
        <>
            <div className='profile-section p-x-16'>
                
                <div className='address-form__details mt-3 w-100'>
                    <div className='form-group-custom'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Mobile Number*"
                        >
                            <Form.Control type="text" className='form-control form-control-custom' placeholder='Mobile Number*' value="8473529294" />
                            <span className='floating-tick-icon'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.99992 14.6666C4.31792 14.6666 1.33325 11.6819 1.33325 7.99992C1.33325 4.31792 4.31792 1.33325 7.99992 1.33325C11.6819 1.33325 14.6666 4.31792 14.6666 7.99992C14.6666 11.6819 11.6819 14.6666 7.99992 14.6666ZM7.33525 10.6666L12.0486 5.95259L11.1059 5.00992L7.33525 8.78125L5.44925 6.89525L4.50659 7.83792L7.33525 10.6666Z" fill="#278C4C"/>
                                </svg>
                            </span>
                            {['bottom'].map((placement, idx) => (
                                <UpdateDetails key={idx} placement={placement} name={placement} />
                            ))} 
                        </FloatingLabel>
                    </div>
                    <div className='form-group-custom'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Full Name"
                        >
                            <Form.Control type='text' className='form-control form-control-custom' placeholder='Full Name' />
                        </FloatingLabel>
                    </div>
                    <div className='form-group-custom'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Email"
                        >
                            <Form.Control type='text' className='form-control form-control-custom' placeholder='Email' />
                        </FloatingLabel>
                    </div>
                    <div className='form-group-custom'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Date Of Birth"
                        >
                            <Form.Control type='text' className='form-control form-control-custom' placeholder='Date Of Birth' />
                        </FloatingLabel>
                    </div>
                </div>

                <button type='button' className='btn btn-disabled profile-btn'>Save Changes</button>
            </div>
        </>
    )
}

export default Profile