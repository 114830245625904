import { Link } from '@reach/router';
import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
//Images
import HighlistImage1 from '../../../../Images/CategoryDetails/heighlight/smart-tv-highlight(1).png';
import HighlistImage2 from '../../../../Images/CategoryDetails/heighlight/smart-tv-highlight(2).png';
import HighlistImage3 from '../../../../Images/CategoryDetails/heighlight/smart-tv-highlight(3).png';
import HighlistImage4 from '../../../../Images/CategoryDetails/heighlight/smart-tv-highlight(4).png';
import HighlistImage6 from '../../../../Images/CategoryDetails/heighlight/smart-tv-highlight(6).png';
import VideoThumbnail from '../../../../Images/CategoryDetails/Tv-Video-Thumbnail.png';
import PlayButton from '../../../../Images/product/PlayButton.png';


const SmartTvHighLightTab = () => {
    const [activeId, setActiveId] = useState('0');

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    const ProductHighlightsTab = [
        {   
            _id: 0,
            Image: HighlistImage1,
            TabName: 'Crystal 4k Resolution',
            info: 'You can watch crisp, and detailed visuals and scenes on the display of this Samsung TV.'
        }, {   
            _id: 1,
            Image: HighlistImage2,
            TabName: 'PurColor',
            info: 'This feature delivers a wide range of shades to ensure optimal pictures performance. As a result, you can enjoy an immersive visual experience.'
        }, {   
            _id: 2,
            Image: HighlistImage3,
            TabName: 'HDR',
            info: 'This TV features High Dynamic Range (HDR) that enhances the range of light levels. As a result, you can watch a wide spectrum of colors along with stunning visual details even in the dark scenes.'
        }, {
            _id: 3,
            Image: HighlistImage4,
            TabName: 'Voice Assistants ',
            info: 'You can find access contents, get queries answered, and also control the TV using the in-built voice assistants that are Alexa and Bixby.'
        },{
            _id: 4,
            Image: HighlistImage2,
            TabName: 'Motion Xcelerator ',
            info: 'This feature estimates as well as compensates frames automatically from the content source to deliver a clear picture and impressive performance.'
        },{
            _id: 5,
            Image: HighlistImage6,
            TabName: 'Crystal Processor 4k ',
            info: 'You can find access contents, get queries answered, and also control the TV using the in-built voice assistants that are Alexa and Bixby.'
        },
    ];

    return (
        <>  
            <div className='highlight-tab__wrap specifications-tab'>
                <div className='product-review-wrap'>
                    <div className='review-section'>
                        <img src={VideoThumbnail} className='img img-fluid w-100' alt='' />
                        <div className='review-play-btn'>
                            <img src={PlayButton} className='img img-fluid' alt='' />
                        </div>
                    </div>
                </div>
                <div className="mar-spacer h-3"></div>
                {ProductHighlightsTab && ProductHighlightsTab.map((HighlightsTab) => {
                    return (
                        <Accordion defaultActiveKey={HighlightsTab._id}  key={HighlightsTab._id}>
                            <Accordion.Item eventKey={HighlightsTab._id} className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                                <Accordion.Header onClick={() => toggleActive('0')}>{HighlightsTab.TabName}</Accordion.Header>
                                <Accordion.Body>
                                    <div className='highlight-body__wrap'>
                                        <p className='highlight-body__info'>This Samsung mobile phone features an Infinity Flex Display with an Under Display Camera, allowing the device to function both as a smartphone and tablet, As a result, you can experienc a...</p>
                                        <Link to='/' className='highlight-body__link'>Read More</Link>
                                        <div className='mar-spacer h-1_2'></div>
                                        <img src={HighlightsTab.Image} className='img img-fluid' alt='' />
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    );
                })}
            </div>
        </>
    )
}

export default SmartTvHighLightTab;