import React from 'react';
import specific from '../../Images/product/wishlist.png'
import ProductSpecificationTab from './ProductSpecificationTab';


const ProductSpecifications = () => {

    return (
        <>
            <div className='product-specifications-section'>
                <div className='product-details__specific'>
                    <div className='specific-wrapper__list'>
                        <div className='specific-wrapper__media'>
                            <img src={specific} className='img img-fluid' alt='' />
                        </div>
                        <div className='specific-wrapper__content'>
                            <h4>Samsung Galaxy Z Fold 2 5G</h4>
                            <p>Mystic Purple, 256GB + 8GB</p>
                            <div className="price-offer justify-content-start">
                                <div className="new-price">₹19,999</div>
                                <div className="old-price">₹22,999</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='product-details__tabs'>
                    <ProductSpecificationTab />
                </div>
            </div>
        </>
    )
}

export default ProductSpecifications
