import React from 'react';

import smartwatchcolor1 from '../../../Images/CategoryDetails/smartwatchcolor1.png';
import smartwatchcolor2 from '../../../Images/CategoryDetails/smartwatchcolor2.png';
import smartwatchcolor3 from '../../../Images/CategoryDetails/smartwatchcolor3.png';
import smartwatchcolor4 from '../../../Images/CategoryDetails/smartwatchcolor4.png';
import smartwatchcolor5 from '../../../Images/CategoryDetails/smartwatchcolor5.png';

const SmartwatchColor = () => {
    return (
        <>
            <div className='product-colors-box'>
                <div className='product-colorFrame active'>
                    <div className='product-colorFrame-media'>
                        <img src={smartwatchcolor1} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Space Grey</div>
                </div>
                <div className='product-colorFrame'>
                    <div className='product-colorFrame-media'>
                        <img src={smartwatchcolor2} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>RoseGold Alumin </div>
                </div>
                <div className='product-colorFrame'>
                    <div className='product-colorFrame-media'>
                        <img src={smartwatchcolor3} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Gold Alumin</div>
                </div>
                <div className='product-colorFrame'>
                    <div className='product-colorFrame-media'>
                        <img src={smartwatchcolor4} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Silver Alumin</div>
                </div>
                <div className='product-colorFrame out-of-stock'>
                    <div className='product-colorFrame-media'>
                        <img src={smartwatchcolor5} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Charcoal Grey</div>
                </div>
            </div>
        </>
    )
}

export default SmartwatchColor;
