import React from 'react';
import { Link } from '@reach/router';
import SponsoredPhonesCarousel from '../Product/SponsoredPhonesCarousel';
import ProductRelatedCategory from '../Product/ProductRelatedCategory';
import { SearchData } from '../../Data';
import BrandCarousel from '../../Components/Home/BrandCarousel';


const SearchProductFilter = () => {

    return (
        <>
        
            <div className="clearbtn p-x-16 mt-3"><span className="Top_trendingtext ">Recent Searches</span>
                <button className='clear'> clear</button>
            </div>

            <div className="recentSearch p-x-16">
                <span className='search_span'>iPhone 13</span>
                <span className='search_span'>Mobiles</span>
                <span className='search_span'>Earphones</span>
                <span className='search_span'>Smart Gadgets</span>
                <span className='search_span'>Lights</span>
                <span className='search_span'>Smart Lamp</span>
            </div>

            <div className="recentsearch_div"> 

                <div className="Top_trending p-x-16"><span className="Top_trendingtext">Trending Top 3</span></div>
                <div className='product-wrapper product__0 mt-0'>    
                    {SearchData.slice(0, 3).map(data => (
                        <div className='p-x-16' key={data._id}>
                            <div className='product-list mt-0 search_product'>
                                <div className='product-thumb'>
                                    <img src={data.Image} className='img-fluid img w-100' alt={data.productName} />
                                </div>
                                <div className='product-details productsearch_reco'>
                                    <h2>
                                        <Link to='/ProductDetails '>{data.productName}</Link>
                                    </h2>
                                    <p className='product-muted'>
                                        {data.productColor}                                
                                    </p>                                         
                                </div>                     
                            </div>
                        </div>
                    ))}
                    <div className='product-sponsored__wrap searchsponser_container'>
                        <div className='all-category__head top-head__wrap'>
                            <h1 className='brand-heading'>Popular Brands</h1>
                        </div>
                        <div className='all-category__content ml--16'>
                            <BrandCarousel />
                        </div>
                    </div>
                    <div className='product-sponsored__wrap searchsponser_container mt-0'>
                        <h4 className='product-sponsored__head p-x-16'>Sponsored Phones</h4>
                        <div className='ml--16'>
                            <SponsoredPhonesCarousel />  
                        </div>
                    </div>
                    <div className='product-sponsored__wrap searchsponser_container mt-0'>
                        <h4 className='specification-heading p-x-16'>Related Categories</h4>
                        <div className='category-carousel ml--16'>
                            <ProductRelatedCategory />
                        </div>
                    </div>
                    <div className='mar-spacer' />
                </div>  
            </div>       
        </>
    )
}

export default SearchProductFilter