import React, { useState } from 'react';
import { Link } from '@reach/router';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ProductFiltersList from './ProductFiltersList';

const ProductFilters = ({ ...props }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const closeButton = () => setShow(false);

    return (
        <>
            <Link to={show ? '/' : '#'} className="toolbar-menu-item d-table-cell active" onClick={handleShow} aria-hidden="true">
                <div className='d-flex align-items-center justify-content-center'>
                    <span className="toolbar-menu-icon">
                        <svg width="24" height="24" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.3 4.5C6.51903 4.5 5.85872 5.01615 5.61016 5.73077H2.5V6.96154H5.61016C5.85872 7.67615 6.51903 8.19231 7.3 8.19231C8.2924 8.19231 9.1 7.364 9.1 6.34615C9.1 5.32831 8.2924 4.5 7.3 4.5ZM10.3 5.73077V6.96154H14.5V5.73077H10.3ZM10.3 8.80769C9.51903 8.80769 8.85872 9.32385 8.61016 10.0385H2.5V11.2692H8.61016C8.85872 11.9838 9.51903 12.5 10.3 12.5C11.2924 12.5 12.1 11.6717 12.1 10.6538C12.1 9.636 11.2924 8.80769 10.3 8.80769ZM13.3 10.0385V11.2692H14.5V10.0385H13.3Z" fill="#121415"/>
                        </svg>
                    </span>
                    <span className="toolbar-menu-label ml-2">Filters</span>
                </div>
            </Link> 
            
            <Offcanvas className='offcanvas__filters' show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header>
                    <Offcanvas.Title>
                        <div className='filter-head__wrap'>
                            <div className='filter-head__icon' onClick={closeButton} aria-hidden="true">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.625 6.25049L7.80333 7.42882L5.69 9.54216H16.6667V11.2088H5.69L7.80333 13.3222L6.625 14.5005L2.5 10.3755L6.625 6.25049Z" fill="white"/>
                                </svg>
                            </div>
                            <div className='filter-head__text' id='label'>
                                <label htmlFor="label">Filters</label>
                                <span>12403 Products</span>
                            </div>
                        </div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='filter-content__wrap'>
                        <ProductFiltersList />
                    </div>
                </Offcanvas.Body>
                <section className="sticky-menus filter-sticky-menus">
                    <div className="sticky-price__wrap w-100">
                        <div className='sticky-price'>
                            <div className='sticky-clear__all'>Clear All</div>
                        </div>
                        <button type='button' className='btn space-padding btn-dark-custom'>Apply Filters</button>
                    </div>
                </section>
            </Offcanvas>
        </>
    )
}

export default ProductFilters