import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import ExchangeSteps from "../Exchange/ExchangeSteps/ExchangeSteps";

import exchange2 from "../../Images/CategoryDetails/Exchange/discount-pecentage.png";
import exchange1 from "../../Images/CategoryDetails/Exchange/evalutePHN.png";
import exchange3 from "../../Images/CategoryDetails/Exchange/exchange-handover.png";
import check from "../../Images/CategoryDetails/Exchange/check.png";

import ExchangeApplied from "../../Components/Exchange/ExchangeApplied";

const ExchangeMobile = ({ ...props }) => {
    const [show, setShow] = useState(false);
    const [checkExchange, setcheckExchange] = useState(true);
    const [exchangeOfferApplied] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const closeButton = () => setShow(false);

    return (
        <>
            {exchangeOfferApplied ? (
                <div
                    role="button"
                    className="payment-offer-options"
                    onClick={handleShow}
                    aria-hidden="true"
                >
                    <div className="payment-offer-content">
                        <div className="payment-offer-icon">
                            <span>
                                <svg
                                    width="21"
                                    height="20"
                                    viewBox="0 0 21 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12.7663 7.64027L18.7645 7.64027L18.7645 13.4739L17.0511 13.4739L17.0505 10.4846L9.07045 18.2469L7.85869 17.0684L15.8393 9.30668L12.7663 9.30669V7.64027ZM1.79989 6.52068L3.51332 6.52068L3.51332 9.50939L11.494 1.74771L12.7057 2.92623L4.72508 10.6879L7.79811 10.6879L7.7981 12.3543L1.79989 12.3543L1.79989 6.52068Z"
                                        fill="#121415"
                                    />
                                </svg>
                            </span>
                        </div>
                        <div className="payment-offer-info">
                            <h6>Exchange your old Phone </h6>
                            <p className="details-text">
                Save upto <span> ₹9,800</span>
                            </p>
                        </div>
                    </div>
                    <div className="payment-offer-link">
                        <span>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.172 12.0007L8.222 7.05072L9.636 5.63672L16 12.0007L9.636 18.3647L8.222 16.9507L13.172 12.0007Z"
                                    fill="#121415"
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            ) : (
                <ExchangeApplied />
            )}

            <Offcanvas
                show={show}
                onHide={handleClose}
                {...props}
                className={
                    checkExchange
                        ? "notify-me__offCanvas-height"
                        : "notify-me__offCanvas-height eligiblity__offCanvas"
                }
            >
                <Offcanvas.Header>
                    {checkExchange ? (
                        <Offcanvas.Title className="w-100">
                            <div className="coupon__head">
                                <h5 className="cart-heading d-flex align-start justify-content-between">
                                    <span>Exchange your old phone</span>
                                    <div onClick={closeButton} aria-hidden="true" role="button">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M11.9997 10.586L16.9497 5.63599L18.3637 7.04999L13.4137 12L18.3637 16.95L16.9497 18.364L11.9997 13.414L7.04974 18.364L5.63574 16.95L10.5857 12L5.63574 7.04999L7.04974 5.63599L11.9997 10.586Z"
                                                fill="black"
                                            />
                                        </svg>
                                    </div>
                                </h5>
                            </div>
                        </Offcanvas.Title>
                    ) : (
                        <Offcanvas.Title className="w-100">
                            <h5 className="cart-heading d-flex align-start justify-content-between ">
                                <span>Exchange</span>
                                <div onClick={closeButton} aria-hidden="true" role="button">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11.9997 10.586L16.9497 5.63599L18.3637 7.04999L13.4137 12L18.3637 16.95L16.9497 18.364L11.9997 13.414L7.04974 18.364L5.63574 16.95L10.5857 12L5.63574 7.04999L7.04974 5.63599L11.9997 10.586Z"
                                            fill="black"
                                        />
                                    </svg>
                                </div>
                            </h5>
                        </Offcanvas.Title>
                    )}
                </Offcanvas.Header>
                <Offcanvas.Body className="pt-0 px-0">
                    <div className="px-3" style={{ display: checkExchange ? "block" : "none" }}>
                        <div className="exchange_process mt-0">
                            <div className="exchange_firstdiv">
                                <span>
                                    <img src={exchange1} alt="img" className="img-fluid img" />
                                </span>
                                <span className="evaluate-txt">Evaluate your phone</span>
                            </div>
                            <div className="exchange_firstdiv">
                                <span>
                                    <img src={exchange2} alt="img" className="img-fluid img" />
                                </span>
                                <span className="evaluate-txt">Avail instant discount</span>
                            </div>
                            <div className="exchange_firstdiv">
                                <span>
                                    <img src={exchange3} alt="img" className="img-fluid img" />
                                </span>
                                <span className="evaluate-txt">Handover your Phone</span>
                            </div>
                        </div>
                        <p className="checkenter_txt">
              Enter pincode to check exchange serviceability
                        </p>
                        <div className="input-group-address error-state exchangeinput_error-padding">
                            <div className="cart-address__wrap pb-2 exchange-details">
                                <input
                                    type="text"
                                    className="form-control form-control-exchange"
                                    placeholder="Enter Pin Code"
                                    defaultValue="462026"
                                />
                                <button
                                    type="button"
                                    className="btn btn-dark-button absolute-cart-input btn-space"
                                >Check</button>
                            </div>
                            <span className="exchange_error_div">
                                <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0 6C0 2.68629 2.68629 0 6 0V0C9.31371 0 12 2.68629 12 6V6C12 9.31371 9.31371 12 6 12V12C2.68629 12 0 9.31371 0 6V6Z"
                                        fill="#E25100"
                                    ></path>
                                    <path
                                        d="M5.99987 5.29312L8.47487 2.81812L9.18187 3.52512L6.70687 6.00012L9.18187 8.47512L8.47487 9.18212L5.99987 6.70712L3.52487 9.18212L2.81787 8.47512L5.29287 6.00012L2.81787 3.52512L3.52487 2.81812L5.99987 5.29312Z"
                                        fill="white"
                                    ></path>
                                </svg>
                                <label className="exchange-error" htmlFor="text">
                  Exchange unavailable{" "}
                                </label>
                            </span>
                        </div>

                        <h4 className="shopping-cart__heading p-0">Saved Addresses</h4>
                        <div className="coupon-list__wrap">
                            <button
                                className="coupon-list__item exchange_address"
                                onClick={() => setcheckExchange(false)}
                            >
                                <div className="coupon-list__input mt-1">
                                    <Form.Check
                                        type="radio"
                                        name="radioCoupon"
                                        aria-label="radio 1"
                                    />
                                </div>

                                <div className="loggedIn__list">
                                    <div className="loggedIn__label">Rohan Nanda</div>
                                    <div className="btn btn-login btn-tag">Home</div>
                                    <div className="cart-address__section">
                                        <div className="loggedIn-address__label text-start">
                      103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli,
                      Outer Ring Road, Bangalore - 560103
                                        </div>
                                        <div className="exchange-address__contact" id="text">
                                            <label htmlFor="text">
                                                <img src={check} alt="" className="img-fluid img" />
                                            </label>
                                            <span className="exchange_available">
                        Exchange available
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </button>
                            <button className="coupon-list__item exchange_address">
                                <div className="coupon-list__input mt-1">
                                    <Form.Check
                                        type="radio"
                                        name="radioCoupon"
                                        aria-label="radio 1"
                                    />
                                </div>
                                <div className="loggedIn__list">
                                    <div className="loggedIn__label">Simran Param Singh</div>
                                    <div className="btn btn-login btn-tag">Home </div>
                                    <div className="cart-address__section">
                                        <div className="loggedIn-address__label text-start">
                      205, Khalsa Building, Kaverappa Layout, Kadubeesanahalli,
                      Outer Ring Road, Bangalore - 560103
                                        </div>
                                        <div className="exchange-address__contact" id="text">
                                            <label htmlFor="text">
                                                <img src={check} alt="" className="img-fluid img" />
                                            </label>
                                            <span className="exchange_available">
                        Exchange available
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </button>
                            <button className="coupon-list__item exchange_address">
                                <div className="coupon-list__input mt-1">
                                    <Form.Check
                                        type="radio"
                                        name="radioCoupon"
                                        aria-label="radio 1"
                                    />
                                </div>
                                <div className="loggedIn__list">
                                    <div className="loggedIn__label">Avani Soni </div>
                                    <div className="btn btn-login btn-tag">Home</div>
                                    <div className="cart-address__section">
                                        <div className="loggedIn-address__label text-start">
                      205, Khalsa Building, Kaverappa Layout, Kadubeesanahalli,
                      Outer Ring Road, Bangalore - 560017
                                        </div>
                                        <div className="exchange-address__contact" id="text">
                                            <label htmlFor="text">
                                                <svg
                                                    width="12"
                                                    height="12"
                                                    viewBox="0 0 12 12"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M0 6C0 2.68629 2.68629 0 6 0V0C9.31371 0 12 2.68629 12 6V6C12 9.31371 9.31371 12 6 12V12C2.68629 12 0 9.31371 0 6V6Z"
                                                        fill="#E25100"
                                                    ></path>
                                                    <path
                                                        d="M5.99987 5.29312L8.47487 2.81812L9.18187 3.52512L6.70687 6.00012L9.18187 8.47512L8.47487 9.18212L5.99987 6.70712L3.52487 9.18212L2.81787 8.47512L5.29287 6.00012L2.81787 3.52512L3.52487 2.81812L5.99987 5.29312Z"
                                                        fill="white"
                                                    ></path>
                                                </svg>
                                            </label>
                                            <span className="exchange-error">
                        Exchange unavailable
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div style={{ display: checkExchange ? "none" : "block" }}>
                        <ExchangeSteps setShow={setShow} />
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default ExchangeMobile;
