import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import { SponsoredPhones } from '../../Data';

const SponsoredPhonesCarousel = () => {
    const responsive = {
        tablet: {
            breakpoint: { max: 768, min: 567 },
            items: 2,
            partialVisibilityGutter: 0
        },
        mobile: {
            breakpoint: { max: 567, min: 0 },
            items: 1,
            partialVisibilityGutter: 80
        }
    };

    return (
        <>
            <Carousel                
                partialVisible={true}
                arrows={false}
                itemClass="image-item"
                responsive={responsive}
            >   
                {SponsoredPhones.map(ProductCategory => {
                    return (
                        <div className='product-carousel-wrapper' key={ProductCategory._id}>
                            <div className='carousel-media-wrapper carousel-catg'>
                                <img
                                    draggable={true}
                                    style={{ width: "100%", height: "100%" }}
                                    src={ProductCategory.Image}
                                    alt={ProductCategory.productName}
                                    className='img'
                                />
                            </div>
                            <div className='category-overlay-text'>
                                <div className='catg__heading'>{ProductCategory.Name}</div>
                                <div className='catg__price'>{ProductCategory.Price}</div>
                                <div className='catg__btn'>
                                    <Link to='/Cart' className='btn btn-category'>Buy Now</Link>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </>
    )
}

export default SponsoredPhonesCarousel
