import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Overview from '../Components/Compare/Overview';
import ChangeCompareProduct from '../Components/Compare/ChangeCompareProduct';

import Iphone from '../Images/Compare/iPhone.png'
import Samsung from '../Images/Compare/Samsung.png';
import { Link } from '@reach/router';

const Compare = () => {
    const [ compareStickyTop, setCompareStickyTop ] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);      
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); 

    const handleScroll = (evt) => {
        setCompareStickyTop(evt.target.scrollTop > 100);
    };
    return (
        <>
            <div className={`compare-list__wrap ${compareStickyTop ? 'sticky-compare-header' : ''}`}>
                <div className='compare-list__row'>
                    <div className='compare-list__col'>
                        <div className='compare-media__wrap' style={{ width: '40px', margin: '0 auto'}}>
                            <img src={Iphone} className='img-fluid w-100' alt='' />
                        </div>
                        <div className='compare-details__wrap'>
                            <div className='compare_title'>Apple iPhone 13 Pro</div>
                            <div className='compare_price'>
                                <div className='compare-new_price'>₹89,999</div>
                            </div>
                            <div className='compare_color'>
                                <span className='compare_color_item' style={{ backgroundColor: '#AFA6DD'}}></span>
                                <span className='compare_color_item' style={{ backgroundColor: '#A6DADD'}}></span>
                                <span className='compare_color_item' style={{ backgroundColor: '#433F43'}}></span>
                            </div>
                            <Link to='/ProductDetails' className='btn btn-dark-button w-100 mb-1'>View Product</Link>
                            {['bottom'].map((placement, idx) => (
                                <ChangeCompareProduct key={idx} placement={placement} name={placement} />
                            ))}
                        </div>
                    </div>
                    <div className='compare-list__col ml-4'>
                        <div className='compare-media__wrap' style={{ width: '40px', margin: '0 auto'}}>
                            <img src={Samsung} className='img-fluid w-100' alt='' />
                        </div>
                        <div className='compare-details__wrap'>
                            <div className='compare_title'>Samsung Galaxy FoldZ 5G</div>
                            <div className='compare_price'>
                                <div className='compare-new_price'>₹81,999</div>
                                <div className='compare-old_price'>₹83,559</div>
                            </div>
                            <div className='compare_color'>
                                <span className='compare_color_item' style={{ backgroundColor: '#7990BC'}}></span>
                                <span className='compare_color_item' style={{ backgroundColor: '#BC7979'}}></span>
                                <span className='compare_color_item' style={{ backgroundColor: '#FBFCFF'}}></span>
                                <span className='compare_color_item' style={{ backgroundColor: '#433F43'}}></span>
                            </div>
                            <Link to='/ProductDetails' className='btn btn-dark-button w-100 mb-1'>View Product</Link>
                            {['bottom'].map((placement, idx) => (
                                <ChangeCompareProduct key={idx} placement={placement} name={placement} />
                            ))}
                        </div>
                    </div>
                </div> 
                <div className='shopping-cart__gst'>
                    <Form.Check 
                        type='checkbox'
                        id='default-checkbox'
                        label='Show only difference'
                        className='d-flex align-items-start'
                    />
                </div>
            </div>
            <Overview />
        </>
    )
}

export default Compare