import React from 'react';
import '../../Styles/order_success.css'
import OrderSucess from '../../Images/OrderSucess.png';

const OrderConfirmation = () => {
    return (
        <>
            <div className="Order_Confirm">
                <div className="orderconfirm_img">
                    <div className='mar-spacer h-3' />
                    <div className='d-flex align-items-center justify-content-center mt-5'>
                        <img src={OrderSucess} alt="" className='img-fluid'/>
                    </div>

                    <div className="order_confirm-heading">
                        <h2 className='order_success-heading'>Order Successful!</h2>
                        <p className='order_success_para'>Happiness arriving at your doorstep soon!</p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default OrderConfirmation