import React from 'react'

const TvInstalation = () => {
    return (
        <>
            <p className="mb-0 deliver-info">Will be scheduled at your convenience. <span className="deliver-type">FREE</span></p>
        </>
    )
}

export default TvInstalation