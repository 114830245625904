
import React from 'react'

const EarphoneManufaturer = () => {
    return (
        <>
            <div className='specifications-tab'>
                <h4 className='specifications-tab__head'>General</h4>
                <div className='specification-info__table'>
                    <table className='table specification-striped__table'>
                        <tbody>
                            <tr>
                                <th>Generic Name</th>
                                <td>Headphones</td>
                            </tr>
                            <tr>
                                <th>Country of Origin</th>
                                <td> China  </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='specific-horizontal-line'></div>
                <h4 className='specifications-tab__head'>Manufacturer’s Details</h4>
                <ul className='specifications-tab__manufacture'>
                    <li>Fuyong Town, Shenzen, China - 518130</li>
                </ul>
                <h4 className='specifications-tab__head'>Importer’s Details</h4>
                <ul className='specifications-tab__manufacture'>
                    <li>Imagine Marketing Pvt. Ltd., Guru Gobind Industrial Estate, Jay Coach Goregaon East, Mumbai, Maharashtra - 400063</li>
                </ul>
                <h4 className='specifications-tab__head'>Packer’s Details</h4>
                <ul className='specifications-tab__manufacture'>
                    <li>Fuyong Town, Shenzen, China - 518103</li>
                </ul>
            </div>
        </>
    )
}

export default EarphoneManufaturer;
