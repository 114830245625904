import React,{useState} from 'react'

const ArrivingToday = () => {
    const[arrivingToday]=useState(false)

    return (
        <>
            {arrivingToday?

                <div className="ordersuccesful_message order_tracking_model">                                               
                    <div className="order_arriving_track">
                        <div className="arrivingheading">
                            <h4 className='Order_Successful-msg'>Arriving by 4:20 pm, Today</h4>
                        </div>
                        
                    </div>                                                     
                    <p className='order-Location-para'>Rohan Home 103, Udgam Belvedere, Kadubeesana...
                    </p>
                </div>:""

            }
        </>
        
    )
}

export default ArrivingToday