import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import loginlogo1 from '../../Images/CategoryDetails/Login_logo/logintop.png';
import loginlogo2 from '../../Images/CategoryDetails/Login_logo/logindown.png';

import '../../Styles/productspecifications.css';

const ReqCallback = ({ ...props }) => {
    const [ callback, setCallback ] = useState(true);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // eslint-disable-next-line no-unused-vars
    const [number, setNumber] = useState();
    const checkInput = (e) =>{
        if(!((e.keyCode >= 48) && (e.keyCode <= 57 || e.keyCode === 8))){
            e.preventDefault()
        }
        else{   
            setNumber(e.target.value)
        }   
    }
    
    return (
        <>
            <div className={`store-btn__info ${callback ? '' : 'callback'}`} onClick={handleShow} role='button' aria-hidden='true'>
                {callback ?
                    (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.244 7.12133C6.86956 8.22032 7.77968 9.13044 8.87867 9.756L9.468 8.93067C9.56277 8.79796 9.7029 8.70459 9.86187 8.66822C10.0208 8.63186 10.1876 8.65502 10.3307 8.73333C11.2735 9.24862 12.3148 9.55852 13.386 9.64267C13.5532 9.65592 13.7092 9.73169 13.823 9.85488C13.9368 9.97807 14 10.1396 14 10.3073V13.282C14 13.4471 13.9388 13.6063 13.8282 13.7288C13.7177 13.8513 13.5655 13.9285 13.4013 13.9453C13.048 13.982 12.692 14 12.3333 14C6.62667 14 2 9.37333 2 3.66667C2 3.308 2.018 2.952 2.05467 2.59867C2.0715 2.43446 2.14866 2.28235 2.27119 2.17176C2.39373 2.06116 2.55294 1.99996 2.718 2H5.69267C5.86037 1.99998 6.02193 2.06317 6.14512 2.17697C6.26831 2.29077 6.34408 2.44682 6.35733 2.614C6.44148 3.68519 6.75138 4.72645 7.26667 5.66933C7.34498 5.81238 7.36814 5.97916 7.33178 6.13813C7.29541 6.2971 7.20204 6.43723 7.06933 6.532L6.244 7.12133ZM4.56267 6.68333L5.82933 5.77867C5.46986 5.00273 5.22357 4.17923 5.098 3.33333H3.34C3.336 3.444 3.334 3.55533 3.334 3.66667C3.33333 8.63733 7.36267 12.6667 12.3333 12.6667C12.4447 12.6667 12.556 12.6647 12.6667 12.66V10.902C11.8208 10.7764 10.9973 10.5301 10.2213 10.1707L9.31667 11.4373C8.95244 11.2958 8.59867 11.1287 8.258 10.9373L8.21933 10.9153C6.91172 10.1711 5.82885 9.08828 5.08467 7.78067L5.06267 7.742C4.87127 7.40133 4.70419 7.04756 4.56267 6.68333Z" fill="#121415"/>
                    </svg>) : ''
                }
                <span className='ml-1'>{callback ? 'Request Callback' : 'Callback Requested'}</span>
            </div>

            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                {callback ? 
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title className='cart-heading'></Offcanvas.Title>
                    </Offcanvas.Header> : null
                }
                <Offcanvas.Body>
                    <div className='address-loggedIn-section' style={{ display: callback ? "block" : "none" }}>
                        <div className="Loginlogo">
                            <img src={loginlogo1} alt="" className='first_logo' />
                            <img src={loginlogo2} alt="" className='first_logo1'/>
                        </div>
                        <h5 className='cart-heading offcanvas-title h5 text-center mt-4'>Welcome to Sangeetha Mobiles</h5>
                        <p className=' Login-info text-center mt-1'>Please enter your mobile number</p>
                        <div className='loggedIn__wrap login-gsx'>
                            <div className='loggedIn__lists'>
                                <div className='input-group-address'>
                                    <div className='cart-address__wrap'>
                                        <input  type='number' className='form-control form-control-login' placeholder='Mobile Number'  maxLength="10" onKeyDown={(e)=> checkInput(e)}/>
                                        <span className='absolute-login-input'>+91</span> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="loginfooter">
                            <div className="footer-links mb-0 login-footerlink"><span className='fontcolor-light'>By continuing,</span>&nbsp;<span className='fontcolor-light'>I agree to the &nbsp;<a href="/" className='Linkpolicy'>Privacy Policy</a>  </span></div>
                            <div className="footer-links login-footerlink"> &nbsp;<span className='fontcolor-light'>and &nbsp;<a href="/" className='Linkpolicy'>Terms & Conditions</a> </span></div>
                        </div>
                        <div className="continuelogin_btn">
                            <button type='button' className=' btn btn-block btn-dark-custom w-100' onClick={() => setCallback(false)}> Continue</button>
                        </div>                           
                    </div>  
                    <div className='requested-callback py-1' style={{ display: callback ? "none" : "block" }}>
                        <div className='callback_media'>
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M40 19.9995C40 21.9781 36.4551 23.3721 35.8755 25.1575C35.2742 27.0081 37.302 30.2156 36.1807 31.7553C35.0489 33.3111 31.3644 32.3681 29.8086 33.5005C28.2683 34.6212 28.0331 38.4223 26.1832 39.0212C24.3978 39.6009 21.9809 36.6826 20 36.6826C18.019 36.6826 15.6043 39.6009 13.8167 39.0212C11.9662 38.4199 11.731 34.619 10.1907 33.5005C8.63549 32.3687 4.95097 33.3117 3.81862 31.7559C2.69793 30.2156 4.72512 27.0081 4.12444 25.1581C3.54488 23.3727 0 21.9787 0 20.0001C0 18.0215 3.54488 16.6275 4.12456 14.8421C4.72524 12.9921 2.69804 9.78451 3.81874 8.24432C4.95109 6.68855 8.6356 7.63152 10.1914 6.49975C11.7311 5.37905 11.9663 1.5774 13.8168 0.979024C15.6022 0.399347 18.0191 3.31758 20.0001 3.31758C21.9809 3.31758 24.3957 0.399347 26.1833 0.979024C28.0334 1.5797 28.269 5.38125 29.8088 6.49975C31.3645 7.63209 35.049 6.68913 36.1814 8.24432C37.3021 9.78462 35.2743 12.9922 35.8756 14.8421C36.4551 16.6268 40 18.0208 40 19.9995Z" fill="#278C4C"/>
                                <path d="M18.2533 26.7995C17.6676 26.8003 17.1057 26.5677 16.692 26.153L11.7686 21.2296C10.906 20.3673 10.9058 18.9691 11.768 18.1066C12.6303 17.244 14.0285 17.2438 14.8911 18.106L18.2532 21.4676L25.1127 14.6086C25.9751 13.7461 27.3735 13.746 28.236 14.6084C29.0985 15.4708 29.0987 16.8692 28.2362 17.7317L19.8149 26.153C19.4012 26.5678 18.8391 26.8004 18.2533 26.7995Z" fill="white"/>
                            </svg>
                        </div>
                        <div className='callback-head'>Callback Requested</div>
                        <div className='callback-info'>We will call you shortly on 9825577834 in next 5 minutes</div>
                    </div>                    
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default ReqCallback;
