import React, { useState } from 'react';
import CdTimerComp from '../../Components/Countdown/CdTimerComp';
import ProductGallery from '../../Components/Product/ProductGallery';
import ProductColors from '../../Components/Product/ProductColors';
import ProductModels from '../../Components/Product/ProductModels';
import NotifyMe from '../../Components/PreBooking/NotifyMe';
import WhyButFromSangeetha from '../../Components/Product/WhyButFromSangeetha';
import ProductBox from '../../Components/Product/ProductBox';
import ProductHighlights from '../../Components/Product/ProductHighlights';
import ProductRecommend from '../../Components/Product/ProductRecommend';
import ProductAccessories from '../../Components/Product/ProductAccessories';
import ProductRelatedCategory from '../../Components/Product/ProductRelatedCategory';
import ProductKeyFeatures from '../../Components/Product/ProductKeyFeatures';
import ProductWishlist from '../../Components/Product/ProductWishlist';
import ProductFooter from '../../Components/Footer/ProductFooter';
import 'react-multi-carousel/lib/styles.css';

const PreBooking = () => {
    const [ launchStatus ] = useState(true)
    const [ wishlistActive, setWishlistState ] = useState(false);
    const [isWishlist, setIsWishlist] = useState(false); 

    const handleToggle = () => {
        setWishlistState(!wishlistActive);
    };
    
    const handleClose = () => setIsWishlist(false);
    const handleChangeWishlist = () => {
        setIsWishlist((prevWishlist) => {
            return !prevWishlist;
        });
        setIsWishlist(true);
    };

    // postbooking
    const [ postbooking ] = useState(1);
    return (
        <>
            <div className='product-details-wrapper'>
                <div className='product-details-container product-bg'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='product-gallery-section'>
                                {postbooking===1?
                                    <div className='product-Out-of-stock'>Coming Soon</div>:postbooking===2?
                                        <div className='product-Out-of-stock'>booking open</div>:
                                        <div className="product-label-tag post_lunch-tag">Newly Launched</div>}
                                <div className='product-info'>
                                    <div className='brand-name'>Samsung</div>
                                    <h2 className='product-name'>Galaxy Z Fold2 5G</h2>
                                    {postbooking === 1 ?
                                        <>
                                            {launchStatus ?
                                                <CdTimerComp /> :
                                                <div className='pre-launch-1 pt-1'>
                                                    <span>March 31, 7:30pm IST</span>
                                                </div>
                                            }
                                        </>                                      
                                        :postbooking===2?
                                            <div className="bookingnow">
                                                <span className='bookingnowtxt'>Book now at </span> <span className='bookingnowPrice'>₹500</span>
                                            </div>:
                                            <div className="newlylucnhed">
                                                <div className="price-offer"><div className="new-price">₹43,659</div><div className="old-price">₹45,659</div></div>
                                                <div className="price-info-text">(Price Includes GST)</div>
                                                <div className="booked_confirmed-txt">
                                                    <span className='d-flex'>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 6C0 9.3138 2.6862 12 6 12C9.3138 12 12 9.3138 12 6C12 2.6862 9.3138 0 6 0C2.6862 0 0 2.6862 0 6Z" fill="#278C4C"/><path d="M8.56825 3.92716L8.49971 3.86269L8.43119 3.92718L5.24995 6.92165L3.56845 5.33904L3.49991 5.27454L3.43137 5.33904L2.93146 5.80955L2.85409 5.88237L2.93146 5.95519L5.18142 8.07282L5.24996 8.13733L5.31849 8.07282L9.06854 4.54333L9.14593 4.47048L9.06851 4.39767L8.56825 3.92716Z" fill="white" stroke="white"/>
                                                        </svg>
                                                    </span>
                                                    <span className='booked-successtxt'> Booked on 5th Jan </span>
                                                </div>
                                            </div>
                                    }
                                   
                                </div>
                              
                                <div className='mar-spacer'></div>
                                <div className='product-gallery'>
                                    <ProductGallery />                                    
                                </div>
                                {/* Wishlist and Share Icon */}
                                <div className='product-btns'>
                                    {['top'].map((placement, idx) => (
                                        <ProductWishlist 
                                            isWishlist={isWishlist} 
                                            handleChangeWishlist={handleChangeWishlist}
                                            handleClose={handleClose}
                                            key={idx} 
                                            placement={placement} 
                                            name={placement}
                                        />
                                    ))}
                                        
                                    <button type='button' className='product-share-btn' onClick={() => handleChangeWishlist()}>
                                        <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.04982 11.5157L5.64316 9.6577C5.31615 9.9823 4.90037 10.2028 4.44822 10.2915C3.99607 10.3801 3.5278 10.3329 3.10244 10.1558C2.67708 9.97866 2.31368 9.67958 2.05806 9.29624C1.80243 8.9129 1.66602 8.46246 1.66602 8.0017C1.66602 7.54095 1.80243 7.0905 2.05806 6.70716C2.31368 6.32382 2.67708 6.02474 3.10244 5.84764C3.5278 5.67053 3.99607 5.62331 4.44822 5.71195C4.90037 5.80059 5.31615 6.0211 5.64316 6.3457L9.04982 4.4877C8.93299 3.93953 9.01737 3.36761 9.28755 2.87654C9.55773 2.38547 9.99565 2.00806 10.5212 1.81332C11.0468 1.61859 11.6249 1.61955 12.1498 1.81603C12.6748 2.0125 13.1114 2.39136 13.38 2.88333C13.6485 3.37529 13.731 3.94749 13.6123 4.49527C13.4937 5.04306 13.1818 5.52983 12.7338 5.86657C12.2857 6.20331 11.7314 6.36752 11.1722 6.32917C10.6131 6.29081 10.0864 6.05246 9.68849 5.6577L6.28182 7.5157C6.34978 7.83614 6.34978 8.16727 6.28182 8.4877L9.68849 10.3457C10.0864 9.95094 10.6131 9.71259 11.1722 9.67424C11.7314 9.63588 12.2857 9.80009 12.7338 10.1368C13.1818 10.4736 13.4937 10.9603 13.6123 11.5081C13.731 12.0559 13.6485 12.6281 13.38 13.1201C13.1114 13.612 12.6748 13.9909 12.1498 14.1874C11.6249 14.3839 11.0468 14.3848 10.5212 14.1901C9.99565 13.9953 9.55773 13.6179 9.28755 13.1269C9.01737 12.6358 8.93299 12.0639 9.04982 11.5157Z" fill="#121415"/>
                                        </svg>
                                    </button>
                                </div>
                                {/* Key Features */}
                                <div className='show-features'>
                                    {['bottom'].map((placement, idx) => (
                                        <ProductKeyFeatures key={idx} placement={placement} name={placement} backdrop={true} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mar-spacer h-3'></div>
                <div className='product-specifications'>
                    {/* Colors */}
                    <div className='product-colors'>
                        <h4 className='specification-heading'>Color</h4>
                        <ProductColors />
                    </div>
                    <div className='border-divider'></div>
                    {/* Models */}
                    <div className='product-models'>
                        <h4 className='specification-heading'>Model</h4>
                        <ProductModels />
                    </div>
                    <div className='border-divider'></div>
                    <div className='mar-spacer h-1'></div>
                    {/* Buttons */}
                    {postbooking===1?

                        <div className='cart-btns'>
                            {['bottom'].map((placement, idx) => (
                                <NotifyMe key={idx} placement={placement} name={placement} />
                            ))}	
                        </div>
                        :postbooking===2?

                            <div className="product_openbtn_container">
                                <div className="preboook_lunch">
                                    <span className='preboook_lunch-txt'>
                                    Pre-Book now at <span className='preboook_lunch-price'>₹500</span>  and pay the remaining amount after launch
                                    </span>
                                </div>
                                <div className='notifybottommargin'></div>
                                <div className='cart-btns'>
                                    <button type='button' className='btn btn-block btn-dark-custom'>Book Now</button>
	
                                </div>
                                <div className='notifybottommargin'></div>
                                <div className='cart-btns'>
                                    <button type='button' className='btn btn-block btn-dark-outline'>Notify Me</button>
	
                                </div>
                                <div className='notifybottommargin'></div>
                                <div className="viewtermcondibokiing">
                                    <a href="/" className='specification-info__head text-decoration-underline my-0'>View Terms & Conditions</a>
                                </div>


                            </div>:postbooking===3?
                                <div className="product_openbtn_container">                                    
                                    <div className="newlybooked_buttonarea">
                                        <div className="booked_confirmed-txt">
                                            <span className='d-flex'>
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 6C0 9.3138 2.6862 12 6 12C9.3138 12 12 9.3138 12 6C12 2.6862 9.3138 0 6 0C2.6862 0 0 2.6862 0 6Z" fill="#278C4C"/><path d="M8.56825 3.92716L8.49971 3.86269L8.43119 3.92718L5.24995 6.92165L3.56845 5.33904L3.49991 5.27454L3.43137 5.33904L2.93146 5.80955L2.85409 5.88237L2.93146 5.95519L5.18142 8.07282L5.24996 8.13733L5.31849 8.07282L9.06854 4.54333L9.14593 4.47048L9.06851 4.39767L8.56825 3.92716Z" fill="white" stroke="white"/>
                                                </svg>
                                            </span>
                                            <span className='booked-successtxt'> Booked on 5th Jan </span>
                                        </div>
                                        <div className="margin-btm-b"></div>

                                        <div className="bookedtxtbtnarea">
                                        Complete your order now by paying the remaining amount of ₹43,159
                                        </div>
                                        <div className="margin-btm-b"></div>
                                        <div className="viewtermcondibokiing text-start">
                                            <a href="/" className='specification-info__head text-decoration-underline my-0'>View Terms & Conditions</a>
                                        </div>

                                    </div>
                                    <div className='notifybottommargin'></div>
                                    <div className='cart-btns'>
                                        <button type='button' className='btn btn-block btn-dark-outline'>Buy Now</button>

                                    </div>
                                    <div className='margin-btm-b'></div>
                                    <div className='cart-btns'>
                                        <button type='button' className='btn btn-block btn-dark-custom'>Add To Cart</button>

                                    </div>
                                   
    
    
                                </div>:""

                    }
                    <div className='mar-spacer h-3'></div>
                    {/* Removed Policies */}
                </div>
                {/* Removed Bank Offers */}
                {/* Removed Payment Offers */}
                {/* Removed Buy Together */}
                {/* What's in the box */}
                <div className='product-inbox specification-head px-0'>
                    <h4 className='specification-heading p-x-16'>What’s In The Box?</h4>
                    <h6 className='inbox-info p-x-16'>Samsung Galaxy Fold, Type C Cable, Pin</h6>
                    <ProductBox />
                </div>

                {/* Product Highlights */}
                <div className='product-specifications'>
                    <div className='mar-spacer'></div>
                    <div className='product-highlights'> 
                        <ProductHighlights />
                    </div>
                    <div className='mar-spacer'></div>
                </div>

                {/* Why Buy From Sangeetha */}
                <div className='product-inbox specification-head px-0'>
                    <div className='mar-spacer h-1'></div>
                    <h4 className='specification-heading p-x-16'>Why Buy From Sangeetha?</h4>
                    <div className='mar-spacer h-1'></div>
                    <WhyButFromSangeetha />
                    <div className='mar-spacer h-1'></div>
                </div>
                <div className='product-specifications specification-head px-0'>
                    <div className='mar-spacer h-3'></div>

                    {/* Sangeetha Recommends */}
                    <h4 className='specification-heading p-x-16'>Sangeetha Recommends</h4>
                    <div className=''>
                        <ProductRecommend deviceType="mobile" wishlistActive={wishlistActive} handleToggle={handleToggle} />
                    </div>
                    <div className='mar-spacer h-3'></div>

                    {/* Accessories for you */}
                    <h4 className='specification-heading p-x-16'>Accessories for you</h4>
                    <div className=''>
                        <ProductAccessories wishlistActive={wishlistActive} handleToggle={handleToggle} />
                    </div>
                    <div className='mar-spacer h-3'></div>

                    {/* Related Categories */}
                    <h4 className='specification-heading p-x-16'>Related Categories</h4>
                    <div className='category-carousel ml--16'>
                        <ProductRelatedCategory />
                    </div>
                    <div className='mar-spacer h-3'></div>
                </div>
            </div>
            <ProductFooter />
        </>
    )
}

export default PreBooking