import React from 'react'
import { Link } from '@reach/router';
import AddressList from '../Address/AddressList';

const Address = () => {
    return (
        <>
            <div className='mar-spacer h-2' />
            <div className='p-x-16'>
                <Link to='/AddAddressDetails' className='btn btn-cart-outline w-100'>+ Add New Address</Link>
            </div>
            <div className='mar-spacer h-3_5' />
            <AddressList />
        </>
    )
}

export default Address