import React from 'react'

const RemoveStep1 = () => {
    return (
        <>
            <div className="cartremove-txt">
                The previous items from your cart have been moved to Save for Later as you have selected an exchange offer for the current product.
            </div>
            <div className="mar-spacer h-1"></div>
 
            <div className='notify__btn'>
                <button className='btn-dark-custom btn-place-order w-100 border-0'>Continue</button>
            </div>
        </>
    )
}

export default RemoveStep1