import { Link } from "@reach/router";
import React, { useState } from "react";
import Step1 from "../Components/DIYLaptop/Step1";
import Step2 from "../Components/DIYLaptop/Step2";
import Step3 from "../Components/DIYLaptop/Step3";

const DIYLaptop = () => {
    const [page, setPage] = useState(0);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        username: "",
        nationality: "",
        other: "",
    });

    const stepsTitle = ["1/3", "2/3", "3/3"];

    const PageDisplay = () => {
        if (page === 0) {
            return <Step1 formData={formData} setFormData={setFormData} />;
        } else if (page === 1) {
            return <Step2 formData={formData} setFormData={setFormData} />;
        } else {
            return <Step3 formData={formData} setFormData={setFormData} />;
        }
    };
    
    return (
        <>
            <div className="wizard-steps">
                <div className="form">
                    <div className="progress-bar">
                        <div
                            className="progress-bar__line"
                            style={{ width: page === 0 ? "33.3%" : page === 1 ? "66.6%" : "100%" }}
                        />
                    </div>
                    <div className="form-container">
                        <div className="header">
                            <div className="steps-bar">Step <span>{stepsTitle[page]}</span></div>
                        </div>
                        <div className="body">{PageDisplay()}</div>
                        <div className="steps-footer">
                            <Link
                                className={page === 1 ? 'btn btn-place-order btn-dark-custom w-100' : 'btn btn-place-order btn-dark-custom not-selected- w-100'}
                                to={page === stepsTitle.length - 1 ? '/ResultList' : '' }
                                onClick={() => {
                                    if (page === stepsTitle.length - 1) {
                                        console.log(formData);
                                    } else {
                                        setPage((currPage) => currPage + 1);
                                    }
                                }}
                            >
                                {/* {page === stepsTitle.length - 1 ? "Submit" : "Continue"} */}
                                Continue
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DIYLaptop