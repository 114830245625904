import React from 'react'
import Form from 'react-bootstrap/Form'


const LunchYearFilter = () => {
    return (
        <>
            <div className='filter-tab__lists'>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <label htmlFor="label" className='filter_price'>Latest</label>
                    </div>
                    <span className='filter-tab__count'>20</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <label htmlFor="label" className='filter_price'>2021</label>
                    </div>
                    <span className='filter-tab__count'>20</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />                    
                        <label htmlFor="label" className='filter_price'>2020</label>
                    </div>
                    <span className='filter-tab__count'>14</span>
                </div>
                <div className='filter-tab__list'>  
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <label htmlFor="label" className='filter_price'>2019</label>                
                    </div>
                    <span className='filter-tab__count'>12</span>
                </div>
                <div className='filter-tab__list'>  
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <label htmlFor="label" className='filter_price'>Older</label>                
                    </div>
                    <span className='filter-tab__count'>10</span>
                </div>
            </div>
        </>
    )
}

export default LunchYearFilter