import React from 'react';
import { productsData } from '../../Data';
import similarItem from '../../Images/product/similarItem.png';

const SimilarProducts = () => {
    return (
        <>
            {productsData.slice(0, 3).map(product => (
                <div className='similar-product__item' key={product._id}>
                    <div className='product-list px-0' to='/ProductDetails'>
                        <div className='product-thumb'>
                            <img src={similarItem} className='img-fluid img w-100' alt={product.productName} />
                        </div>
                        <div className='product-details'>
                            <h2>{product.productName}</h2>
                            <p className='product-muted'>{product.productInfo}</p>
                            <div className='product-price'>
                                <div className='new-price'>₹{product.productNewPrice}</div>
                                <div className='old-price'>₹{product.productOldPrice}</div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <div className='flexible'></div>
            <button className='btn btn-block btn-dark-button w-100 mt-2 mb-1'>View All</button>
        </>
    )
}

export default SimilarProducts