import React from 'react';
import BrandCarousel from '../Components/Home/BrandCarousel';
import BrandList from '../Components/Home/BrandList';

const Brands = () => {
    return (
        <>
            <div className='all-category__wrap brands-wrapper exclusive-section'>
                <div className='all-category__head top-head__wrap'>
                    <h1 className='brand-heading'>Popular Brands</h1>
                </div>
                <div className='all-category__content ml--16'>
                    <BrandCarousel />
                </div>
            </div>
            <div className='exclusive-section'>
                <div className='all-category__head top-head__wrap'>
                    <h1 className='brand-heading'>All Brands</h1>
                </div>
                <div className='all-brands'>
                    <BrandList />
                </div>
            </div>
        </>
    )
}

export default Brands