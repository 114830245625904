import React,{useState} from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import img1 from '../../Images/Orders/img1.png';
import img2 from '../../Images/Orders/img2.png';

const CancellationNotPossible = ({...props}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <button type="button" className="btn btn-block btn-dark-outline support" variant="primary" onClick={handleShow}>
                <span className="ml-1 contactbtn">Contact Support</span>
            </button>

            <Offcanvas show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Cancel Order?</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="cancellation_offcanvas">
                        <div className='order-track__wrap px-0'>
                            <div className='orders_wrap'>
                                <div className='orders-list_wrap'>
                                    <div className='orders-media__wrap'>
                                        <img src={img1} className='img-fluid' alt='' />
                                        <div className='orders__tag'>
                                            <div className='orders_content'>
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='orders-media__wrap'>
                                        <img src={img2} className='img-fluid' alt='' />
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                        <div className="selectcancelorder-head">Oops! Cancellation is not possible on this item.</div>

                        <div className="selectcancelorder-txt">As this item is already out for delivery, it is not possible to cancel the order now.</div>
                        <div className="proceedcancellation-padding"></div>
                        {/* {['bottom'].map((idx) => (
                            <ChooseCancel key={idx} />
                        ))} */}
                        <div className="d-flex"><button className="btn btn-block btn-dark-custom w-100" >Proceed with Cancellation</button></div>
                        <div className="viewcancelpolicybtn">
                            <button type="button" className="text-dark btn m-0 p-0 btn-link">View Cancellation policy</button>
                        </div>
                    </div>
                
                </Offcanvas.Body>
            </Offcanvas>

        </>
    )
}

export default CancellationNotPossible