import React from 'react';
import { FaStar } from 'react-icons/fa';
import ReviewComments from './ReviewComments';
import ProductReviewWrite from '../../Components/Product/ProductReviewWrite'

const ProductReviewRating = () => {
    return (
        <>
            <div className='ratings-count'>3799 Ratings</div>
            <div className='rating-stars'>
                <div className='rating-counts'>4.2</div>
                <div className='review-stars'>
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                </div>
            </div>
            <div className='mar-spacer h-1_2'></div>
            {['bottom'].map((placement, idx) => (
                <ProductReviewWrite key={idx} placement={placement} name={placement} />
            ))}   

            <div className='cart-horizontal-line'></div>
            <ReviewComments />        
        </>
    )
}

export default ProductReviewRating