import React from 'react';
import { Link } from '@reach/router';
import cartState from '../../Images/product/cartState.png'; 

const CartState = () => {
    return (
        <>
            <div className='home-cart-state'>
                <div className='home-cart__wrap'>
                    <div className='cart__product d-flex align-items-center'>
                        <div className='cart-media__product'>
                            <img src={cartState} className='img-fluid' alt='' />
                        </div>
                        <div className='cart-info__product'>
                            <div className='cart-total__product'>Total 3 Items</div>
                            <div className='cart-price__product'>₹41,559</div>
                        </div> 
                    </div>
                    <div className='home-cart__btn'>
                        <Link to='/Cart' className='btn btn-dark-custom btn-space-22'>View Cart</Link>
                        <span className='ml-4'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0001 8.82227L14.1251 4.69727L15.3034 5.8756L11.1784 10.0006L15.3034 14.1256L14.1251 15.3039L10.0001 11.1789L5.87511 15.3039L4.69678 14.1256L8.82178 10.0006L4.69678 5.8756L5.87511 4.69727L10.0001 8.82227Z" fill="#121415"/>
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CartState