import { Link } from '@reach/router';
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

import Category1 from '../../Images/Home/exclusive/Img.png';

const OffersCarousel = () => {
    return (
        <>
            <div className='carousel__slider'>
                <Carousel controls={false} fade={false}>
                    <Carousel.Item interval={100000}>
                        <img
                            className="d-block w-100"
                            src={Category1}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <div className='exclusive-content-wrapper dark-text__wrap'>
                                <div className="exclusive-text">
                                    <h6 className="exclusive-head">Oneplus Nord 2 5G</h6>
                                    <div className='exclusive-label'>Setup your Game</div>
                                    <div className='exclusive-price'>₹70,559</div>
                                    <Link to='/' className='btn btn-dark-custom'>Explore Now</Link>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={500}>
                        <img
                            className="d-block w-100"
                            src={Category1}
                            alt="Second slide"
                        />
                        <Carousel.Caption>
                            <div className='exclusive-content-wrapper dark-text__wrap-'>
                                <div className="exclusive-text">
                                    <h6 className="exclusive-head">Oneplus Nord 2 5G</h6>
                                    <div className='exclusive-label'>Setup your Game</div>
                                    <div className='exclusive-price'>₹70,559</div>
                                    <Link to='/' className='btn btn-dark-custom'>Explore Now</Link>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={Category1}
                            alt="Third slide"
                        />
                        <Carousel.Caption>
                            <div className='exclusive-content-wrapper dark-text__wrap'>
                                <div className="exclusive-text">
                                    <h6 className="exclusive-head">Oneplus Nord 2 5G</h6>
                                    <div className='exclusive-label'>Setup your Game</div>
                                    <div className='exclusive-price'>₹70,559</div>
                                    <Link to='/' className='btn btn-dark-custom'>Explore Now</Link>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        </>
    )
}

export default OffersCarousel