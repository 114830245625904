import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import GPAY from '../../Images/product/emi/GPAY.png';
import Paytm from '../../Images/product/emi/Paytm.png';
import upiLogo from '../../Images/product/emi/upiLogo.png';
import PhonePe from '../../Images/product/emi/PhonePe.png';

const UPIPayment = () => {
    const [ istoggle, setToggle ] = useState(false);
    useEffect(() => {}, [istoggle])
    return (
        <>
            <div className='upi-payment__list'>
                <div className='upi-payment__wrap'>
                    <div className='upi-payment__btn'>
                        <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                    </div>
                    <div className='upi-payment__media'>
                        <img src={GPAY} className='img img-fluid' alt='' />
                    </div>
                    <span className='upi-payment__text'>GooglePay</span>
                </div>
                <div className='cart-payment__offer mt-2'>Get 5% off on your first payment through Google Pay</div>
            </div>
            <div className='upi-payment__list'>
                <div className='upi-payment__wrap'>
                    <div className='upi-payment__btn'>
                        <Form.Check type="radio" name='upiCoupon' aria-label="radio 4" />
                    </div>
                    <div className='upi-payment__media'>
                        <img src={Paytm} className='img img-fluid' alt='' />
                    </div>
                    <span className='upi-payment__text'>Paytm</span>
                </div>
            </div>
            <div className='upi-payment__list'>
                <div className='upi-payment__wrap'>
                    <div className='upi-payment__btn'>
                        <Form.Check type="radio" name='upiCoupon' aria-label="radio 3" />
                    </div>
                    <div className='upi-payment__media'>
                        <img src={PhonePe} className='img img-fluid phonepay' alt='' />
                    </div>
                    <span className='upi-payment__text'>PhonePe</span>
                </div>
            </div>
            <div className='upi-payment__list'>
                <div className='upi-payment__wrap'>
                    <div className='upi-payment__btn'>
                        <Form.Check type="radio" name='upiCoupon' aria-label="radio 2" onChange={()=>setToggle({toggle: !istoggle})}/>
                    </div>
                    <div className='upi-payment__media'>
                        <img src={upiLogo} className='img img-fluid' alt='' />
                    </div>
                    <span className='upi-payment__text'>UPI ID</span>
                </div>
                {istoggle ? (
                    <div className='upi-payment__add'>
                        <div className="cart-address__wrap pl-0 error-state">{/* class::valid-state || error-state */}
                            <input type="number" className="form-control form-control-cart" placeholder="Enter UPI ID" />
                            <button type="button" className="btn btn-dark-button absolute-cart-input btn-space">Verify</button>
                            <span>Please enter a valid UPI ID</span>
                        </div>                    
                        <div className='filter-tab__list border-0 p-0'>
                            <div className='filter-tab__flex'>
                                <Form.Check className='filter__checkbox' aria-label="option 1" defaultChecked />
                                <label htmlFor="label" className='filter_price'>Save this UPI ID for faster payments</label>
                            </div>
                        </div>
                    </div>) : null
                }
            </div>
        </>
    )
}

export default UPIPayment