import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import '../../Styles/productspecifications.css';
import loginlogo1 from '../../Images/CategoryDetails/Login_logo/logintop.png';
import loginlogo2 from '../../Images/CategoryDetails/Login_logo/logindown.png';

const LoginOTP = ({...props}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <button  onClick={handleShow} className=' btn btn-block btn-dark-custom w-100'> Continue</button>
            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='address-loggedIn-section'>
                        <div className="Loginlogo">
                            <img src={loginlogo1} alt="" className='first_logo' />
                            <img src={loginlogo2} alt="" className='first_logo1'/>
                        </div>
                        <h5 className='cart-heading offcanvas-title h5 text-center mt-4'>Login with OTP</h5>
                        <p className=' Login-info text-center mt-1'>Please enter OTP sent to 78*****389. <a href="/" className='Linkpolicy'>Change</a> </p>
                        <div className='loggedIn__wrap'>
                            <div className='loggedIn__lists'>
                                <div className='input-group-address'>
                                    <div className='cart-address__wrap '>
                                        <form action="" className='otp_set'>
                                            < input type='number' className='form-control otp_input'/>
                                            < input type='number' className='form-control otp_input'/>
                                            < input type='number' className='form-control otp_input'/>
                                            < input type='number' className='form-control otp_input '/>
                                        </form>
                                    </div>
                                    <div className="resendotp text-center">
                                        <a href="/" className='Resend_OtpLink'>Resend OTP</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="loginfooter">
                            <div className="footer-links mb-0 login-footerlink"><span className='fontcolor-light'>By continuing,</span>&nbsp;<span className='fontcolor-light'>I agree to the &nbsp;<a href="/" className='Linkpolicy'>Privacy Policy</a>  </span></div>
                            <div className="footer-links login-footerlink"> &nbsp;<span className='fontcolor-light'>and &nbsp;<a href="/" className='Linkpolicy'>Terms & Conditions</a> </span></div>
                        </div>
                        <div className="continuelogin_btn">
                            <button className='btn btn-place-order btn-dark-custom w-100'>Verify</button>
                        </div>                           
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default LoginOTP
