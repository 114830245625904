import React from 'react';
import Form from 'react-bootstrap/Form';

import Photography from '../../Images/DIY/ScreenSize1.svg'
import Networking from '../../Images/DIY/ScreenSize2.svg'
import Gaming from '../../Images/DIY/ScreenSize3.svg'

const Step2 = ({ formData, setFormData }) => {
    const availableCategories = [
        {
            __id : 1,
            name: 'Small',
            status: 'unchecked',
            Image: Photography
        }, {
            __id : 2,
            name: 'Medium',
            status: 'checked',
            Image: Networking
        }, {
            __id : 3,
            name: 'Large',
            status: 'unchecked',
            Image: Gaming
        }
    ];
    return (
        <>
            <div className="sign-up-container">
                <div className="exchange-data">
                    <h3 className='steps__head'>Which Screen size do you prefer?</h3>
                    <p className='steps__info'>Select upto 2 options from below :  </p>

                    <div className='filter-tab__lists'>     
                        {availableCategories.map((Data) => (
                            <div className={Data.status === 'checked' ? 'filter-tab__flex changeexchange-div active' : 'filter-tab__flex changeexchange-div'} key={Data.__id}>
                                <div className='steps__media' style={{ width: '36px', height: '46px'}}>
                                    <img src={Data.Image} className='img-fluid' alt={Data.name} />
                                </div>
                                <div className='steps__content'>
                                    <label htmlFor="label" className='filter_exchange'>{Data.name}</label>
                                    <Form.Check 
                                        className='filter__checkbox sort__checkbox' 
                                        aria-label="option 1" 
                                        value={Data}
                                        checked={Data.status === 'checked' ? 'checked' : null}
                                        onChange={(event) =>
                                            setFormData({ ...formData, password: event.target.value })
                                        }
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>                
            </div>
        </>
    )
}

export default Step2
