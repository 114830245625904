import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Offcanvas from 'react-bootstrap/Offcanvas';

const GiftCardModal = ({ ...props }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <button type='button' className='text-dark btn m-0 p-0 btn-link' onClick={handleShow}>Apply</button>  

            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Apply Giftcard</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='gift-card__wrap'>
                        <div className='address-form__details p-0'>
                            <div className='form-group-custom'>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Enter 16 digits gift card number"
                                    className='error-state'
                                >
                                    <Form.Control type="text" className='form-control form-control-custom' placeholder='Enter 16 digits gift card number' value='12940578965345678'/>
                                    <span>Gift Card Number is invalid</span>
                                </FloatingLabel>
                            </div>
                            <div className='form-group-custom'>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Enter 6 digits gift card PIN"
                                >
                                    <Form.Control type="text" className='form-control form-control-custom' placeholder='Enter 6 digits gift card PIN' />
                                </FloatingLabel>
                            </div>
                        </div>
                        <p className='gift-card__info'>Bank walltes and offers may not be applicable when you use a gift card</p>
                        <button type='button' className='btn btn-place-order btn-dark-custom w-100'>Apply</button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default GiftCardModal