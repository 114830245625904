
import React from 'react'

const SmartwatchManufaturer = () => {
    return (
        <>
            <div className='specifications-tab'>
                <h4 className='specifications-tab__head'>General</h4>
                <div className='specification-info__table'>
                    <table className='table specification-striped__table'>
                        <tbody>
                            <tr>
                                <th>Generic Name</th>
                                <td>Smartwatches</td>
                            </tr>
                            <tr>
                                <th>Country of Origin</th>
                                <td> China  </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='specific-horizontal-line'></div>
                <h4 className='specifications-tab__head'>Manufacturer’s Details</h4>
                <ul className='specifications-tab__manufacture'>
                    <li>Apple Inc, 1 infinite Loop, Cupertino, CA 95014 USA .</li>
                </ul>
                <h4 className='specifications-tab__head'>Importer’s Details</h4>
                <ul className='specifications-tab__manufacture'>
                    <li>Apple India Pvt. Ltd. No.24, 19th Floor, Concorde Tower C, UB City, Vittal Mallya Road, Bangalore - 560001 .</li>

                </ul>
                <h4 className='specifications-tab__head'>Packer’s Details</h4>
                <ul className='specifications-tab__manufacture'>
                    <li>Apple Inc, 1 infinite Loop, Cupertino, CA 95014 USA .</li>
                </ul>
            </div>
        </>
    )
}

export default SmartwatchManufaturer;
