import React from 'react';

import img1 from '../../Images/Compare/1.png';
import img2 from '../../Images/Compare/2.png';
import img3 from '../../Images/Compare/3.png';
import img4 from '../../Images/Compare/4.png';

const Overview = () => {
    return (
        <>
            <div className='compare__overview'>
                <div className='compare__head'>
                    <div className='compare__text'>Overview</div>
                </div>
                <div className='overview__content'>
                    <div className='col-half'>
                        <div className='overview__items'>
                            <div className='overview__media'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.99996 15H15V5H4.99996V15ZM11.6666 16.6667H8.33329V18.3333H6.66663V16.6667H4.16663C3.94561 16.6667 3.73365 16.5789 3.57737 16.4226C3.42109 16.2663 3.33329 16.0543 3.33329 15.8333V13.3333H1.66663V11.6667H3.33329V8.33333H1.66663V6.66667H3.33329V4.16667C3.33329 3.94565 3.42109 3.73369 3.57737 3.57741C3.73365 3.42113 3.94561 3.33333 4.16663 3.33333H6.66663V1.66667H8.33329V3.33333H11.6666V1.66667H13.3333V3.33333H15.8333C16.0543 3.33333 16.2663 3.42113 16.4225 3.57741C16.5788 3.73369 16.6666 3.94565 16.6666 4.16667V6.66667H18.3333V8.33333H16.6666V11.6667H18.3333V13.3333H16.6666V15.8333C16.6666 16.0543 16.5788 16.2663 16.4225 16.4226C16.2663 16.5789 16.0543 16.6667 15.8333 16.6667H13.3333V18.3333H11.6666V16.6667ZM6.66663 6.66667H13.3333V13.3333H6.66663V6.66667Z" fill="#C4CAD0"/>
                                </svg>
                            </div>
                            <div className='overview__details'>
                                <span>RAM</span>
                                <span>8GB</span>
                            </div>
                        </div>
                        <div className='overview__items'>
                            <div className='overview__media'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.66664 5C1.66664 4.54 2.04581 4.16667 2.49331 4.16667H17.5066C17.9633 4.16667 18.3333 4.5375 18.3333 5V16.6667C18.3333 17.1267 17.9541 17.5 17.5066 17.5H2.49331C2.38425 17.4998 2.2763 17.478 2.17567 17.436C2.07504 17.394 1.9837 17.3325 1.90689 17.255C1.83008 17.1776 1.76932 17.0858 1.7281 16.9848C1.68687 16.8838 1.66598 16.7757 1.66664 16.6667V5ZM11.6666 15C12.7717 15 13.8315 14.561 14.6129 13.7796C15.3943 12.9982 15.8333 11.9384 15.8333 10.8333C15.8333 9.72826 15.3943 8.66846 14.6129 7.88706C13.8315 7.10565 12.7717 6.66667 11.6666 6.66667C10.5616 6.66667 9.50177 7.10565 8.72036 7.88706C7.93896 8.66846 7.49998 9.72826 7.49998 10.8333C7.49998 11.9384 7.93896 12.9982 8.72036 13.7796C9.50177 14.561 10.5616 15 11.6666 15ZM3.33331 5.83333V7.5H5.83331V5.83333H3.33331ZM3.33331 1.66667H8.33331V3.33333H3.33331V1.66667Z" fill="#C4CAD0"/>
                                </svg>
                            </div>
                            <div className='overview__details'>
                                <span>Camera</span>
                                <span>48 MP + 8MP + 2MP</span>
                            </div>
                        </div>
                        <div className='overview__items'>
                            <div className='overview__media'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.6666 16.6667H8.33329V18.3333H6.66663V16.6667H4.16663C3.94561 16.6667 3.73365 16.5789 3.57737 16.4226C3.42109 16.2663 3.33329 16.0543 3.33329 15.8333V13.3333H1.66663V11.6667H3.33329V8.33333H1.66663V6.66667H3.33329V4.16667C3.33329 3.94565 3.42109 3.73369 3.57737 3.57741C3.73365 3.42113 3.94561 3.33333 4.16663 3.33333H6.66663V1.66667H8.33329V3.33333H11.6666V1.66667H13.3333V3.33333H15.8333C16.0543 3.33333 16.2663 3.42113 16.4225 3.57741C16.5788 3.73369 16.6666 3.94565 16.6666 4.16667V6.66667H18.3333V8.33333H16.6666V11.6667H18.3333V13.3333H16.6666V15.8333C16.6666 16.0543 16.5788 16.2663 16.4225 16.4226C16.2663 16.5789 16.0543 16.6667 15.8333 16.6667H13.3333V18.3333H11.6666V16.6667ZM5.83329 5.83333V9.16667H9.16663V5.83333H5.83329Z" fill="#C4CAD0"/>
                                </svg>
                            </div>
                            <div className='overview__details'>
                                <span>CPU</span>
                                <span>Octa Core 2.4 GHz</span>
                            </div>
                        </div>
                        <div className='overview__items'>
                            <div className='overview__media'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.57754 5.58916L7.50004 1.66666H15.8334C16.0544 1.66666 16.2663 1.75446 16.4226 1.91074C16.5789 2.06702 16.6667 2.27898 16.6667 2.5V17.5C16.6667 17.721 16.5789 17.933 16.4226 18.0893C16.2663 18.2455 16.0544 18.3333 15.8334 18.3333H4.16671C3.94569 18.3333 3.73373 18.2455 3.57745 18.0893C3.42117 17.933 3.33337 17.721 3.33337 17.5V6.17833C3.33342 5.95734 3.42125 5.74541 3.57754 5.58916ZM12.5 4.16666V7.5H14.1667V4.16666H12.5ZM10 4.16666V7.5H11.6667V4.16666H10ZM7.50004 4.16666V7.5H9.16671V4.16666H7.50004Z" fill="#C4CAD0"/>
                                </svg>
                            </div>
                            <div className='overview__details'>
                                <span>Storage</span>
                                <span>256GB</span>
                            </div>
                        </div>
                        <div className='overview__items'>
                            <div className='overview__media'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.99996 1.66667H15C15.221 1.66667 15.4329 1.75447 15.5892 1.91075C15.7455 2.06703 15.8333 2.27899 15.8333 2.50001V17.5C15.8333 17.721 15.7455 17.933 15.5892 18.0893C15.4329 18.2455 15.221 18.3333 15 18.3333H4.99996C4.77895 18.3333 4.56698 18.2455 4.4107 18.0893C4.25442 17.933 4.16663 17.721 4.16663 17.5V2.50001C4.16663 2.27899 4.25442 2.06703 4.4107 1.91075C4.56698 1.75447 4.77895 1.66667 4.99996 1.66667ZM9.99996 14.1667C9.77895 14.1667 9.56698 14.2545 9.4107 14.4107C9.25442 14.567 9.16663 14.779 9.16663 15C9.16663 15.221 9.25442 15.433 9.4107 15.5893C9.56698 15.7455 9.77895 15.8333 9.99996 15.8333C10.221 15.8333 10.4329 15.7455 10.5892 15.5893C10.7455 15.433 10.8333 15.221 10.8333 15C10.8333 14.779 10.7455 14.567 10.5892 14.4107C10.4329 14.2545 10.221 14.1667 9.99996 14.1667Z" fill="#C4CAD0"/>
                                </svg>
                            </div>
                            <div className='overview__details'>
                                <span>Display</span>
                                <span>6.43” Full HD + AMOLED DISPLAY</span>
                            </div>
                        </div>
                        <div className='overview__items'>
                            <div className='overview__media'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.49996 3.33334V2.50001C7.49996 2.27899 7.58776 2.06703 7.74404 1.91075C7.90032 1.75447 8.11228 1.66667 8.33329 1.66667H11.6666C11.8876 1.66667 12.0996 1.75447 12.2559 1.91075C12.4122 2.06703 12.5 2.27899 12.5 2.50001V3.33334H15C15.221 3.33334 15.4329 3.42114 15.5892 3.57742C15.7455 3.7337 15.8333 3.94566 15.8333 4.16667V17.5C15.8333 17.721 15.7455 17.933 15.5892 18.0893C15.4329 18.2455 15.221 18.3333 15 18.3333H4.99996C4.77895 18.3333 4.56698 18.2455 4.4107 18.0893C4.25442 17.933 4.16663 17.721 4.16663 17.5V4.16667C4.16663 3.94566 4.25442 3.7337 4.4107 3.57742C4.56698 3.42114 4.77895 3.33334 4.99996 3.33334H7.49996ZM10.8333 10V5.83334L6.66663 11.6667H9.16663V15.8333L13.3333 10H10.8333Z" fill="#C4CAD0"/>
                                </svg>
                            </div>
                            <div className='overview__details'>
                                <span>Battery</span>
                                <span>4300 mAh</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-half'>
                        <div className='overview__items'>
                            <div className='overview__media'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.99996 15H15V5H4.99996V15ZM11.6666 16.6667H8.33329V18.3333H6.66663V16.6667H4.16663C3.94561 16.6667 3.73365 16.5789 3.57737 16.4226C3.42109 16.2663 3.33329 16.0543 3.33329 15.8333V13.3333H1.66663V11.6667H3.33329V8.33333H1.66663V6.66667H3.33329V4.16667C3.33329 3.94565 3.42109 3.73369 3.57737 3.57741C3.73365 3.42113 3.94561 3.33333 4.16663 3.33333H6.66663V1.66667H8.33329V3.33333H11.6666V1.66667H13.3333V3.33333H15.8333C16.0543 3.33333 16.2663 3.42113 16.4225 3.57741C16.5788 3.73369 16.6666 3.94565 16.6666 4.16667V6.66667H18.3333V8.33333H16.6666V11.6667H18.3333V13.3333H16.6666V15.8333C16.6666 16.0543 16.5788 16.2663 16.4225 16.4226C16.2663 16.5789 16.0543 16.6667 15.8333 16.6667H13.3333V18.3333H11.6666V16.6667ZM6.66663 6.66667H13.3333V13.3333H6.66663V6.66667Z" fill="#C4CAD0"/>
                                </svg>
                            </div>
                            <div className='overview__details'>
                                <span>RAM</span>
                                <span>8GB</span>
                            </div>
                        </div>
                        <div className='overview__items'>
                            <div className='overview__media'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.66664 5C1.66664 4.54 2.04581 4.16667 2.49331 4.16667H17.5066C17.9633 4.16667 18.3333 4.5375 18.3333 5V16.6667C18.3333 17.1267 17.9541 17.5 17.5066 17.5H2.49331C2.38425 17.4998 2.2763 17.478 2.17567 17.436C2.07504 17.394 1.9837 17.3325 1.90689 17.255C1.83008 17.1776 1.76932 17.0858 1.7281 16.9848C1.68687 16.8838 1.66598 16.7757 1.66664 16.6667V5ZM11.6666 15C12.7717 15 13.8315 14.561 14.6129 13.7796C15.3943 12.9982 15.8333 11.9384 15.8333 10.8333C15.8333 9.72826 15.3943 8.66846 14.6129 7.88706C13.8315 7.10565 12.7717 6.66667 11.6666 6.66667C10.5616 6.66667 9.50177 7.10565 8.72036 7.88706C7.93896 8.66846 7.49998 9.72826 7.49998 10.8333C7.49998 11.9384 7.93896 12.9982 8.72036 13.7796C9.50177 14.561 10.5616 15 11.6666 15ZM3.33331 5.83333V7.5H5.83331V5.83333H3.33331ZM3.33331 1.66667H8.33331V3.33333H3.33331V1.66667Z" fill="#C4CAD0"/>
                                </svg>
                            </div>
                            <div className='overview__details'>
                                <span>Camera</span>
                                <span>48 MP + 8MP + 2MP</span>
                            </div>
                        </div>
                        <div className='overview__items'>
                            <div className='overview__media'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.6666 16.6667H8.33329V18.3333H6.66663V16.6667H4.16663C3.94561 16.6667 3.73365 16.5789 3.57737 16.4226C3.42109 16.2663 3.33329 16.0543 3.33329 15.8333V13.3333H1.66663V11.6667H3.33329V8.33333H1.66663V6.66667H3.33329V4.16667C3.33329 3.94565 3.42109 3.73369 3.57737 3.57741C3.73365 3.42113 3.94561 3.33333 4.16663 3.33333H6.66663V1.66667H8.33329V3.33333H11.6666V1.66667H13.3333V3.33333H15.8333C16.0543 3.33333 16.2663 3.42113 16.4225 3.57741C16.5788 3.73369 16.6666 3.94565 16.6666 4.16667V6.66667H18.3333V8.33333H16.6666V11.6667H18.3333V13.3333H16.6666V15.8333C16.6666 16.0543 16.5788 16.2663 16.4225 16.4226C16.2663 16.5789 16.0543 16.6667 15.8333 16.6667H13.3333V18.3333H11.6666V16.6667ZM5.83329 5.83333V9.16667H9.16663V5.83333H5.83329Z" fill="#C4CAD0"/>
                                </svg>
                            </div>
                            <div className='overview__details'>
                                <span>CPU</span>
                                <span>Octa Core 2.4 GHz</span>
                            </div>
                        </div>
                        <div className='overview__items'>
                            <div className='overview__media'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.57754 5.58916L7.50004 1.66666H15.8334C16.0544 1.66666 16.2663 1.75446 16.4226 1.91074C16.5789 2.06702 16.6667 2.27898 16.6667 2.5V17.5C16.6667 17.721 16.5789 17.933 16.4226 18.0893C16.2663 18.2455 16.0544 18.3333 15.8334 18.3333H4.16671C3.94569 18.3333 3.73373 18.2455 3.57745 18.0893C3.42117 17.933 3.33337 17.721 3.33337 17.5V6.17833C3.33342 5.95734 3.42125 5.74541 3.57754 5.58916ZM12.5 4.16666V7.5H14.1667V4.16666H12.5ZM10 4.16666V7.5H11.6667V4.16666H10ZM7.50004 4.16666V7.5H9.16671V4.16666H7.50004Z" fill="#C4CAD0"/>
                                </svg>
                            </div>
                            <div className='overview__details'>
                                <span>Storage</span>
                                <span>256GB</span>
                            </div>
                        </div>
                        <div className='overview__items'>
                            <div className='overview__media'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.99996 1.66667H15C15.221 1.66667 15.4329 1.75447 15.5892 1.91075C15.7455 2.06703 15.8333 2.27899 15.8333 2.50001V17.5C15.8333 17.721 15.7455 17.933 15.5892 18.0893C15.4329 18.2455 15.221 18.3333 15 18.3333H4.99996C4.77895 18.3333 4.56698 18.2455 4.4107 18.0893C4.25442 17.933 4.16663 17.721 4.16663 17.5V2.50001C4.16663 2.27899 4.25442 2.06703 4.4107 1.91075C4.56698 1.75447 4.77895 1.66667 4.99996 1.66667ZM9.99996 14.1667C9.77895 14.1667 9.56698 14.2545 9.4107 14.4107C9.25442 14.567 9.16663 14.779 9.16663 15C9.16663 15.221 9.25442 15.433 9.4107 15.5893C9.56698 15.7455 9.77895 15.8333 9.99996 15.8333C10.221 15.8333 10.4329 15.7455 10.5892 15.5893C10.7455 15.433 10.8333 15.221 10.8333 15C10.8333 14.779 10.7455 14.567 10.5892 14.4107C10.4329 14.2545 10.221 14.1667 9.99996 14.1667Z" fill="#C4CAD0"/>
                                </svg>
                            </div>
                            <div className='overview__details'>
                                <span>Display</span>
                                <span>6.43” Full HD + AMOLED DISPLAY</span>
                            </div>
                        </div>                        
                        <div className='overview__items'>
                            <div className='overview__media'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.49996 3.33334V2.50001C7.49996 2.27899 7.58776 2.06703 7.74404 1.91075C7.90032 1.75447 8.11228 1.66667 8.33329 1.66667H11.6666C11.8876 1.66667 12.0996 1.75447 12.2559 1.91075C12.4122 2.06703 12.5 2.27899 12.5 2.50001V3.33334H15C15.221 3.33334 15.4329 3.42114 15.5892 3.57742C15.7455 3.7337 15.8333 3.94566 15.8333 4.16667V17.5C15.8333 17.721 15.7455 17.933 15.5892 18.0893C15.4329 18.2455 15.221 18.3333 15 18.3333H4.99996C4.77895 18.3333 4.56698 18.2455 4.4107 18.0893C4.25442 17.933 4.16663 17.721 4.16663 17.5V4.16667C4.16663 3.94566 4.25442 3.7337 4.4107 3.57742C4.56698 3.42114 4.77895 3.33334 4.99996 3.33334H7.49996ZM10.8333 10V5.83334L6.66663 11.6667H9.16663V15.8333L13.3333 10H10.8333Z" fill="#C4CAD0"/>
                                </svg>
                            </div>
                            <div className='overview__details'>
                                <span>Battery</span>
                                <span>4300 mAh</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='compare__head'>
                    <div className='compare__text'>Offers and Deals</div>
                </div>
                <div className='overview__content'>
                    <div className='col-half'>
                        <div className='overview__items'>
                            <div className='overview__media'>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.44958 4.58579L10.9498 4.58579L10.9498 8.08597L9.94991 8.08596L9.94955 6.29239L5.2929 10.9497L4.58579 10.2426L9.2428 5.58563L7.44958 5.58563V4.58579ZM1.05026 3.91403L2.05011 3.91403L2.05011 5.70726L6.70711 1.05025L7.41422 1.75736L2.75722 6.41436H4.55044L4.55044 7.41421H1.05026V3.91403Z" fill="#121415"/>
                                </svg>
                            </div>
                            <div className='overview__details'>
                                <span>Upto ₹11,680 Off on Exchange</span>
                            </div>
                        </div>
                        <div className='overview__items'>
                            <div className='overview__media'>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.49963 0.5H5.08563L-0.000366211 5.586L4.41363 10L9.49963 4.914V0.5ZM7.74963 1.5C8.16363 1.5 8.49963 1.836 8.49963 2.25C8.49963 2.664 8.16363 3 7.74963 3C7.33563 3 6.99963 2.664 6.99963 2.25C6.99963 1.836 7.33563 1.5 7.74963 1.5Z" fill="#121415"/>
                                </svg>
                            </div>
                            <div className='overview__details'>
                                <span>10% Off on Axis Bank Credit Cards</span>
                            </div>
                        </div>
                        <div className='view-more-details'>
                            <span>3 more offers available</span>
                        </div>
                    </div>
                    <div className='col-half'>
                        <div className='overview__items'>
                            <div className='overview__media'>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.44958 4.58579L10.9498 4.58579L10.9498 8.08597L9.94991 8.08596L9.94955 6.29239L5.2929 10.9497L4.58579 10.2426L9.2428 5.58563L7.44958 5.58563V4.58579ZM1.05026 3.91403L2.05011 3.91403L2.05011 5.70726L6.70711 1.05025L7.41422 1.75736L2.75722 6.41436H4.55044L4.55044 7.41421H1.05026V3.91403Z" fill="#121415"/>
                                </svg>
                            </div>
                            <div className='overview__details'>
                                <span>Upto ₹11,680 Off on Exchange</span>
                            </div>
                        </div>
                        <div className='overview__items'>
                            <div className='overview__media'>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.49963 0.5H5.08563L-0.000366211 5.586L4.41363 10L9.49963 4.914V0.5ZM7.74963 1.5C8.16363 1.5 8.49963 1.836 8.49963 2.25C8.49963 2.664 8.16363 3 7.74963 3C7.33563 3 6.99963 2.664 6.99963 2.25C6.99963 1.836 7.33563 1.5 7.74963 1.5Z" fill="#121415"/>
                                </svg>
                            </div>
                            <div className='overview__details'>
                                <span>10% Off on Axis Bank Credit Cards</span>
                            </div>
                        </div>
                        <div className='view-more-details'>
                            <span> 6more offers available</span>
                        </div>
                    </div>
                </div>
                <div className='compare__head'>
                    <div className='compare__text'>Features</div>
                </div>
                <div className='overview__content'>
                    <div className='col-half'>
                        <div className='overview__items'>
                            <div className='overview__media'>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.44958 4.58579L10.9498 4.58579L10.9498 8.08597L9.94991 8.08596L9.94955 6.29239L5.2929 10.9497L4.58579 10.2426L9.2428 5.58563L7.44958 5.58563V4.58579ZM1.05026 3.91403L2.05011 3.91403L2.05011 5.70726L6.70711 1.05025L7.41422 1.75736L2.75722 6.41436H4.55044L4.55044 7.41421H1.05026V3.91403Z" fill="#121415"/>
                                </svg>
                            </div>
                            <div className='overview__details'>
                                <span>Upto ₹11,680 Off on Exchange</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-half'>
                        <div className='overview__items'>
                            <div className='overview__media'>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.44958 4.58579L10.9498 4.58579L10.9498 8.08597L9.94991 8.08596L9.94955 6.29239L5.2929 10.9497L4.58579 10.2426L9.2428 5.58563L7.44958 5.58563V4.58579ZM1.05026 3.91403L2.05011 3.91403L2.05011 5.70726L6.70711 1.05025L7.41422 1.75736L2.75722 6.41436H4.55044L4.55044 7.41421H1.05026V3.91403Z" fill="#121415"/>
                                </svg>
                            </div>
                            <div className='overview__details'>
                                <span>Upto ₹11,680 Off on Exchange</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='compare__head'>
                    <div className='compare__text'>Display</div>
                </div>
                <div className='overview__content'>
                    <div className='col-half'>
                        <div className='overview__items'>
                            <div className='overview__details'>
                                <span>6.1 inch (15.5 cm diagonal) Super XDR Retina with ProMotion</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-half'>
                        <div className='overview__items'>
                            <div className='overview__details'>
                                <span>6.7 inch (18 cm diagonal) Super XDR Retina with ProMotion</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='compare__head'>
                    <div className='compare__text'>Rear Camera</div>
                </div>
                <div className='overview__content'>
                    <div className='col-half'>
                        <div className='overview__items'>
                            <div className='overview__details'>
                                <span>108.0 MP + 10.0 MP + 12.0 MP + 10.0 MP</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-half'>
                        <div className='overview__items'>
                            <div className='overview__details'>
                                <span>108.0 MP + 10.0 MP + 12.0 MP + 10.0 MP</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='compare__head'>
                    <div className='compare__text'>Front Camera</div>
                </div>
                <div className='overview__content'>
                    <div className='col-half'>
                        <div className='overview__items'>
                            <div className='overview__details'>
                                <span>48MP True Depth Camera</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-half'>
                        <div className='overview__items'>
                            <div className='overview__details'>
                                <span>12MP True Depth Camera</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='compare__head'>
                    <div className='compare__text'>Face/ Touch ID</div>
                </div>
                <div className='overview__content'>
                    <div className='col-half'>
                        <div className='overview__items'>
                            <div className='overview__details'>
                                <span>Face ID</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-half'>
                        <div className='overview__items'>
                            <div className='overview__details'>
                                <span>Face ID</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='compare__head'>
                    <div className='compare__text'>Water Resistant</div>
                </div>
                <div className='overview__content'>
                    <div className='col-half'>
                        <div className='overview__items'>
                            <div className='overview__details'>
                                <span>Rated IP68 (maximum depth of 6 meters upto 30 minutes) under IEC standard 60529</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-half'>
                        <div className='overview__items'>
                            <div className='overview__details'>
                                <span>Rated IP68 (maximum depth of 6 meters upto 30 minutes) under IEC standard 60529</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='compare__head'>
                    <div className='compare__text'>Chip</div>
                </div>
                <div className='overview__content'>
                    <div className='col-half'>
                        <div className='overview__items'>
                            <div className='overview__details'>
                                <span>A15 Bionic chip with 6-core CPU (2 performance and 4 efficiency cores), 5 - core GPU and 16 core Neural engine</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-half'>
                        <div className='overview__items'>
                            <div className='overview__details'>
                                <span>Octa-Core</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mar-spacer bg-theme' />
                <div className='popular-comparison'>
                    <div className='mar-spacer h-1' />
                    <h6 className='popular-comparison__title'>Popular Comparisons</h6>
                    <div className='h-1_1 mar-spacer' />
                    <div className='popular-comparison__row p-x-16'>
                        <div className='popular-comparison__col'>
                            <div className='popular-comparison_media'>
                                <img src={img1} className='img-fluid' alt='' />
                            </div>
                            <div className='popular-comparison_details'>
                                <div>
                                    <h6>OnePlus Nord 2 5G</h6>
                                    <div className='compare_title m-0'>Olive gre..., 128GB + 8GB</div>
                                </div>
                                <div className='flexible' />
                                <div className='compare_price'>
                                    <div className='compare-new_price'>₹89,999</div>
                                </div>
                            </div>
                        </div>
                        <div className='popular-comparison__col ml-4'>
                            <div className='popular-comparison_media'>
                                <img src={img2} className='img-fluid' alt='' />
                            </div>
                            <div className='popular-comparison_details'>
                                <div>
                                    <h6>Xiaomi 11T Pro 5G Hyperphone</h6>
                                    <div className='compare_title m-0'>Purple, 1TB + 8GB</div>
                                </div>
                                <div className='flexible' />
                                <div className='compare_price'>
                                    <div className='compare-new_price'>₹81,999</div>
                                    <div className='compare-old_price'>₹83,559</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-1_1 mar-spacer' />
                    <div className='p-x-16'>
                        <button type='button' className='btn btn-dark-outline space-padding w-100'>View Comparison</button>
                    </div>
                    <div className='mar-spacer h-1' />
                </div>
                <div className='mar-spacer bg-theme' />
                <div className='popular-comparison'>
                    <div className='mar-spacer h-1' />
                    <div className='popular-comparison__row p-x-16'>
                        <div className='popular-comparison__col'>
                            <div className='popular-comparison_media'>
                                <img src={img3} className='img-fluid' alt='' />
                            </div>
                            <div className='popular-comparison_details'>
                                <div>
                                    <h6>OnePlus Nord 2 5G</h6>
                                    <div className='compare_title m-0'>Olive gre..., 128GB + 8GB</div>
                                </div>
                                <div className='flexible' />
                                <div className='compare_price'>
                                    <div className='compare-new_price'>₹89,999</div>
                                </div>
                            </div>
                        </div>
                        <div className='popular-comparison__col ml-4'>
                            <div className='popular-comparison_media'>
                                <img src={img4} className='img-fluid' alt='' />
                            </div>
                            <div className='popular-comparison_details'>
                                <div>
                                    <h6>Xiaomi 11T Pro 5G Hyperphone</h6>
                                    <div className='compare_title m-0'>Purple, 1TB + 8GB</div>
                                </div>
                                <div className='flexible' />
                                <div className='compare_price'>
                                    <div className='compare-new_price'>₹81,999</div>
                                    <div className='compare-old_price'>₹83,559</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-1_1 mar-spacer' />
                    <div className='p-x-16'>
                        <button type='button' className='btn btn-dark-outline space-padding w-100'>View Comparison</button>
                    </div>
                    <div className='h-3 mar-spacer' />
                </div>
            </div>
        </>
    )
}

export default Overview
