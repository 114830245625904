import React, { useState, useEffect } from 'react';

const ScrollToTop = () => {
    const [ isScrollTop, setIsScrollTop] = useState(false);

    const handleScroll = (e) => {
        setIsScrollTop(e.target.scrollTop > 400);
    }
    const scrollToTop = () => {
        document.body.scrollTo(0, 0);
    }	
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);      
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {isScrollTop ?
                (<div className={isScrollTop ? 'scroll-top-top fixed' : 'scroll-top-top'}>
                    <button type='button' className='scroll-top-btn' onClick={scrollToTop} id='scroller'>
                        <span className='d-flex'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.6667 5.21863V13.3333H7.33336V5.21863L3.75736 8.79463L2.8147 7.85196L8.00003 2.66663L13.1854 7.85196L12.2427 8.79463L8.6667 5.21863Z" fill="#121415"/>
                            </svg>
                        </span>
                    </button>
                </div>) : ''
            }
        </>
    )
}

export default ScrollToTop