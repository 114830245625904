import React,{ useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';

const OrderReplaceMentTrackStrap = ({...props}) => {
    const [show, setShow] = useState(false);
    // const [nonsangeethatrackStrap ]=useState(2);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [exceptdeliverystrap]=useState(true)



    return (
        <>
            <button type='button' className='btn btn-dark-button btn-space bnt-h' onClick={handleShow}>Track</button>

            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Track Shipment</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {exceptdeliverystrap?    
                        <div className="trackshipment_canva-Model expected">
                            <div className="ordersuccesful_message">
                                <p className='trackshipment_canva-para expected-para'>Expected delivery by</p>
                                <h4 className='trackshipment_canva-msg expected-para'>Tomorrow  </h4>
                            </div>
                        </div>:
                       
                        <div className="trackshipment_canva-Model">
                            <div className="ordersuccesful_message">
                                <p className='trackshipment_canva-para '>Replacement Delivered on   </p>
                                <h4 className='trackshipment_canva-msg'>Wed, 6th Jan</h4>
                            </div>
                        </div> 
                    }

                    <div className="mar-spacer h-1"></div>                    
                    <div className="order_tracking-shipiment-strapper">                 
                        <div className="stepper trackingstrappper">
                            <div className="d-flex mb-1">
                                <div className="starpper_round-linemain">
                                    <div className="circleroundedstraper circle-check">
                                    </div>
                                    <div className="line line-dark"></div>
                                </div>
                                <div className='stapper_leftpadding '>
                                    <strong className='strapperhead'>Replacement requested</strong>
                                    <span className='tracking_timing'>At 3:45pm, Today</span>
                                </div>
                            </div>
                            <div className="d-flex mb-1">
                                <div className="starpper_round-linemain">
                                    <div className="circleroundedstraper circle-check"></div>
                                    <div className="line line-dark"></div>

                                </div>
                                <div className='stapper_leftpadding '>
                                    <strong className="strapperhead">Pickup Scheduled</strong>

                                    <span className='tracking_timing'>Pick up expected by Today</span>
                                </div>
                            </div>
                            <div className="d-flex mb-1">
                                <div className="starpper_round-linemain">
                                    <div className="circleroundedstraper circle-check"></div>
                                    <div className="line line-dark"></div>

                                </div>
                                <div className='stapper_leftpadding '>
                                    <strong className='strapperhead'>Item is on the way to the store</strong>

                                </div>
                            </div>
                            <div className="d-flex mb-1">
                                <div className="starpper_round-linemain">
                                    <div className="circleroundedstraper circle-check"></div>
                                    <div className="line line-dark"></div>

                                </div>
                                <div className='stapper_leftpadding '>
                                    <strong className='strapperhead'>Received at Sangeetha store</strong>
                                    <span className='tracking_timing'>Expected Delivery by Today</span>


                                </div>
                            </div>
                            <div className="d-flex mb-1">
                                <div className="starpper_round-linemain">
                                    <div className="circleroundedstraper circle-check"></div>
                                    <div className="line line-dark"></div>

                                </div>
                                <div className='stapper_leftpadding '>
                                    <strong className='strapperhead'>Verification Pending</strong>
                                    <span className='tracking_timing'>Expected Verification by Tomorrow</span>


                                </div>
                            </div>
                            <div className="d-flex mb-1">
                                <div className="starpper_round-linemain">
                                    <div className="circleroundedstraper circle-check"></div>
                                    <div className="line"></div>

                                </div>
                                <div className='stapper_leftpadding '>
                                    <strong className='strapperhead'>Verification Successful</strong>
                                    <span className='tracking_timing'>11pm Today</span>


                                </div>
                            </div>
                            <div className="d-flex mb-1">
                                <div className="starpper_round-linemain">
                                    <div className="circleroundedstraper "></div>
                                </div>
                                <div className='stapper_leftpadding '>
                                    <strong className='strapperhead'>Verification Successful</strong>
                                    <span className='tracking_timing'>11pm Today</span>


                                </div>
                            </div>
   
                        </div>

                        <div className="mar-spacer h-1_2"></div>

                    </div>                                     
                     

                </Offcanvas.Body>
            </Offcanvas>

        </>
    )
}

export default OrderReplaceMentTrackStrap