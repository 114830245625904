import React from 'react';
import '../../Styles/productspecifications.css';


const SpecificationTab = () => {

    return (
        <>
            <div className='specifications-tab' >
                <div className='specifications-tab_box'>
                    <div className='specifications-tab_heading'> <h6>Whats in the box? </h6></div>
                    <p className='specifications-tab_para'>Handset, Non-Removable battery included, Data Cable (C-to-C), Ejection pin, Protective Vinyl, Protective Films (Pre-installed on main and cover display), Quick start guide</p>
                </div>
                <hr className='specification_hr_para'/>
                <div className='specifications-tab_box'>
                    <div className='specifications-tab_heading'> <h6>Key Features </h6></div>
                    <ul className="specification_listingdata">
                        <li className='specifications-tab_para'>12 GB RAM | 256 GB ROM </li>
                        <li className='specifications-tab_para'>19.3 cm (7.6 inch) QXGA+ Display</li>
                        <li className='specifications-tab_para'>12MP + 12MP + 12MP | 10MP Front Camera</li>
                        <li className='specifications-tab_para'>4400 mAh Lithium-ion Battery</li>
                        <li className='specifications-tab_para'>Qualcomm Snapdragon 888 Octa-Core Processor  </li>
                    </ul>
                </div>
                <hr className='specification_hr_para'/>
                <div className='specifications-tab_heading'> <h6>General </h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>In the Box </th>
                            <td>Handset, Non-Removable battery included, Data Cable (C-to-C), Ejection pin, Protective Vinyl, Protective Films (Pre-installed on main and cover display), Quick start guide</td>
                        </tr>
                        <tr>
                            <th>Model Number</th>
                            <td>SM - F926BZGDINU</td>
                        </tr>
                        <tr>
                            <th>Model Name</th>
                            <td>Galaxy Z Fold 3 5G</td>
                        </tr>
                        <tr>
                            <th>Color</th>
                            <td>Mystic Purple</td>
                        </tr>
                        <tr>
                            <th>Browse Type </th>
                            <td>Browse Type </td>
                        </tr>
                        <tr>
                            <th>SIM Type</th>
                            <td>Dual Sim</td>
                        </tr>
                        <tr>
                            <th>Hybrid Slot </th>
                            <td>No</td>
                        </tr>
                        <tr>
                            <th>Touchscreen</th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>OTG Compatible</th>
                            <td>Yes</td>
                        </tr>

                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Display Features</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Display Size </th>
                            <td>2208 x 1768 pixels</td>
                        </tr>
                        <tr>
                            <th>Resolution</th>
                            <td>SM - F926BZGDINU</td>
                        </tr>
                        <tr>
                            <th>Resolution Type</th>
                            <td>QXGA +</td>
                        </tr>
                        <tr>
                            <th>Display Type</th>
                            <td>Dynamic AMOLED 2X Main Display & AMOLED 2X Cover Display</td>
                        </tr>
                        <tr>
                            <th>Display Colors </th>
                            <td>16M </td>
                        </tr>
                        <tr>
                            <th>Other Display Features</th>
                            <td>Main Display with Adaptive 120Hz Refresh Rate</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>OS & Processor Features </h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Operating System </th>
                            <td>Android 11</td>
                        </tr>
                        <tr>
                            <th>Processor Type</th>
                            <td>Qualcomm Snapdragon 888 Octa-Core</td>
                        </tr>
                        <tr>
                            <th>Processor Core</th>
                            <td>Octa Core</td>
                        </tr>
                        <tr>
                            <th>Primary Clock Speed </th>
                            <td>2.84 GHz</td>
                        </tr>
                        <tr>
                            <th>Secondary Clock Speed </th>
                            <td>2.4 GHz </td>
                        </tr>
                        <tr>
                            <th>2.4 GHz</th>
                            <td>1.8 GHz</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Memory and Storage Features </h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Internal Storage  </th>
                            <td>256GB</td>
                        </tr>
                        <tr>
                            <th>RAM </th>
                            <td>12GB</td>
                        </tr>
                        <tr>
                            <th>Call Log Memory</th>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Camera Features</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Primary Camera available  </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Primary Camera </th>
                            <td>12MP + 12MP + 12MP</td>
                        </tr>
                        <tr>
                            <th>Primary Camera Features</th>
                            <td>Triple Rear Camera with Director’s View, Auto Framing, Single Take & Night Mode, OIS, Ultra Wide, Depth & Macro</td>
                        </tr>
                        <tr>
                            <th>Secondary Camera Available </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Secondary Camera </th>
                            <td>10MP Front Camera </td>
                        </tr>
                        <tr>
                            <th>Secondary Camera Features</th>
                            <td>10MP (f/2.2) Front Camera on Cover Screen + 4MP (f/1.8) Under Display Camera on Main Screen, Features : Optical Image Stabilisation</td>
                        </tr>
                        <tr>
                            <th>Flash </th>
                            <td>Rear Flash</td>
                        </tr>
                        <tr>
                            <th>HD Recording </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Full HD Recording </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Video Recording  </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Image Editor </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Dual Camera Lens </th>
                            <td>Primary Camera</td>
                        </tr>

                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Call Features</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Hands Free  </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Video Call Support </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Call Divert</th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Phone Book </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Call Timer </th>
                            <td>Yes </td>
                        </tr>
                        <tr>
                            <th>Speaker Phone</th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Call Records </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Logs </th>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Connectivity Features</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Network Type </th>
                            <td>5G, 4G, 3G, 2G </td>
                        </tr>
                        <tr>
                            <th>Supported Networks </th>
                            <td>5G, 4G LTE, WCDMA, GSM </td>
                        </tr>
                        <tr>
                            <th>3G</th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>GPRS </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Pre-Installed Browser </th>
                            <td>Google Chrome | Samsung S-Browser 14.0 </td>
                        </tr>
                        <tr>
                            <th>Micro USB Port</th>
                            <td>No</td>
                        </tr>
                        <tr>
                            <th>Mini USB Port </th>
                            <td>No</td>
                        </tr>
                        <tr>
                            <th>Bluetooth Support </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Bluetooth Version </th>
                            <td>v5.0</td>
                        </tr>
                        <tr>
                            <th>Wi-Fi  </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Wi-Fi Hotspot </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Mini HDMI Port </th>
                            <td>No</td>
                        </tr>
                        <tr>
                            <th>TV Out </th>
                            <td>No</td>
                        </tr>
                        <tr>
                            <th>Infrared </th>
                            <td>No</td>
                        </tr>
                        <tr>
                            <th>Wi-Fi Hotspot </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>USB Connectivity </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Map Support </th>
                            <td>Google Maps</td>
                        </tr>
                        <tr>
                            <th>Google Maps </th>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Other Details</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Smartphone  </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>SIM Size </th>
                            <td>Nano Sim & E-Sim</td>
                        </tr>
                        <tr>
                            <th>Social Networking Phone</th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Instant Message </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>MMS </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Keypad</th>
                            <td>No</td>
                        </tr>
                        <tr>
                            <th>Voice Input </th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Graphics PPI </th>
                            <td>374 PPI</td>
                        </tr>
                        <tr>
                            <th>Predictive Text</th>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th>Sensors  </th>
                            <td>Accelerometer, Barometer, Fingerprint Sensor, Gyro Senso, Geomagnetic Sensor, Hall Sensor, Light Sensor, Proximity Sensor </td>
                        </tr>
                        <tr>
                            <th>Series </th>
                            <td>Z Series</td>
                        </tr>
                        <tr>
                            <th>Browser </th>
                            <td>Google Chrome</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Battery & Power Features </h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Battery Capacity  </th>
                            <td>4400 mAh</td>
                        </tr>
                        <tr>
                            <th>Dual Battery </th>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Dimensions</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Heights  </th>
                            <td>158.2 mm</td>
                        </tr>
                        <tr>
                            <th>Weight </th>
                            <td>271 g</td>
                        </tr>
                    </tbody>
                </table>
                <div className='specification_height'></div>
                <hr className='specification_hr'/>
                <div className='specifications-tab_heading'> <h6>Warranty</h6></div>
                <table className="table specification-striped__table">
                    <tbody>
                        <tr>
                            <th>Warranty Summary  </th>
                            <td>1 Year Manufacturer Warranty for Device and 6 months Manufacturer Warranty for In-Box Accessories</td>
                        </tr>
                        <tr>
                            <th>Domestic Warranty  </th>
                            <td>1 year</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default SpecificationTab;