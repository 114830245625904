import React from 'react';
import Form from 'react-bootstrap/Form'
import Wired from '../../Images/CategoryDetails/filter/Wired.png';
import Wireless from '../../Images/CategoryDetails/filter/Wireless.png';
import TrulyWireless from '../../Images/CategoryDetails/filter/TrulyWireless.png';



const Connectivity = () => {
    return (
        <>
            <div className='filter-tab__lists'>
                <div className='filter-tab__list filter-tab__list_screen filter-margin'>
                    <div className='filter-tab__flex'>
                        <div className='filter-screen-size'>
                            <img src={Wired} alt="" />
                        </div>

                        <div className='filter-screen-size-text'>
                            <span className='Screensize-small'>Wired</span>
                        </div>
                    </div>
                    <Form.Check className='filter__checkbox screensize_checkbox' aria-label="option 1" />
                </div>

                <div className='filter-tab__list filter-tab__list_screen_off filter-margin'>
                    <div className='filter-tab__flex'>
                        <div className='filter-screen-size'>
                            <img src={Wireless} alt="" />
                        </div>

                        <div className='filter-screen-size-text'>
                            <span className='Screensize-small'>Wireless</span>
                        </div>
                    </div>
                    <Form.Check className='filter__checkbox screensize_checkbox' aria-label="option 1" />
                </div>

                <div className='filter-tab__list filter-tab__list_screen_off'>
                    <div className='filter-tab__flex'>
                        <div className='filter-screen-size'>
                            <img src={TrulyWireless} alt="" />
                        </div>
                        <div className='filter-screen-size-text'>
                            <span className='Screensize-small'>Truly Wireless</span>
                        </div>
                    </div>
                    <Form.Check className='filter__checkbox screensize_checkbox' aria-label="option 1" />
                </div>


             
            </div>
        </>
    )
}

export default Connectivity