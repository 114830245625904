import React from 'react'

const RenewedDetails = () => {
    return (
        <>
            <div className='renewed-info'>
                <div className='renewed_line'>
                    <div className='product-label-tag tag-flex-light'>
                        <span className='tag-flex_light'>
                            <span>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.04929 1.02043C7.94694 1.05953 7.88441 1.14005 7.88441 1.22976V1.76578C4.61529 1.8877 2 4.07772 2 6.75085C2 7.11433 2.04835 7.47549 2.14498 7.82056C2.17341 7.92409 2.28997 8 2.42075 8H2.42358C2.55718 7.99769 2.6709 7.92409 2.69932 7.81826C3.23375 5.82837 5.38568 4.36298 7.88441 4.25946V4.78397C7.88441 4.87368 7.94694 4.95651 8.04929 4.9933C8.15162 5.03012 8.26818 5.01631 8.35346 4.9588L10.9005 3.17825C10.9631 3.13455 11 3.07243 11 3.00341C11 2.93671 10.9631 2.87459 10.9005 2.83089L8.35346 1.05493C8.26818 0.997419 8.14876 0.983611 8.04929 1.02043Z" fill="white"/>
                                    <path d="M5.9488 9.00527C5.84928 8.96845 5.72985 8.98224 5.64454 9.04208L3.09666 10.8209C3.03411 10.8646 3 10.929 3 10.9957C3 11.0625 3.03411 11.1269 3.09666 11.1706L5.6474 12.9448C5.69858 12.9816 5.76397 13 5.83221 13C5.87202 13 5.91183 12.9931 5.9488 12.9793C6.05119 12.9425 6.11658 12.8596 6.11658 12.7699V12.2337C9.38672 12.1141 12 9.92341 12 7.24721C12 6.88363 11.9517 6.52466 11.8578 6.1795C11.8265 6.07365 11.7128 6 11.5791 6C11.4455 6 11.3289 6.07595 11.3005 6.18179C10.7687 8.17226 8.61327 9.63577 6.11658 9.73932V9.21467C6.11658 9.12493 6.05119 9.04437 5.9488 9.00527Z" fill="white"/>
                                </svg>
                            </span>
                            <span className='ml-1'>Refurbished</span>
                        </span>
                        <span className='tag-flex_dark'>Superb</span>
                    </div>
                    <div className='renewed_details'>Fully Functional | 1 year Warranty | Upto 5 minor scratches | No dents | All accessories included with the box</div>
                </div>
            </div>
        </>
    )
}

export default RenewedDetails