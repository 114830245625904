import React from 'react'
import ProductFilters from '../Filter/ProductFilters'
import SortFilter from '../Filter/SortFilter'

const StickyFilter = () => {
    return (
        <>
            <section className="sticky-menus filter-sticky-menus">
                <div className="sticky-toolbar d-table w-100">
                    {['bottom'].map((placement, idx) => (
                        <ProductFilters key={idx} placement={placement} name={placement} />
                    ))}
                    {/* <SortFilter/> */}
                   
                    {['bottom'].map((placement, idx) => (
                        <SortFilter key={idx} placement={placement} name={placement} />
                    ))}
                </div>
            </section>
        </>
    )
}

export default StickyFilter