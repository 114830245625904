import React,{useState} from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

import shoppingCart2 from '../../Images/CategoryDetails/Cart/iphone13-pro.png'
import iphone13 from '../../Images/CategoryDetails/Cart/iphone13.png'

const ReplaceCartItem = ({...props}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <button  className='cart-view' onClick={handleShow}>view</button>
            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Replace cart item?</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='p-0'>
                    <div className='p-x-16'>
                        <div className="replaceitems-model">
                            Item in Cart
                        </div>
                        <div className='product-wrapper p-x-'>
                            <div className='product-list px-0'>                    
                                <div className='product-label-tag m-h-1'>Sponcer</div>               
                                <div className='shopping-cart__list p-0'>
                                    <div className='shopping-cart___media'>
                                        <img src={shoppingCart2} className='img img-fluid' alt='' />
                                    </div>
                                    <div className='shopping-cart__info replace-model_info'>
                                        <h4>IPhone 13 Pro</h4>
                                        <p>
                                            <span>Pink </span>,
                                            <span>256GB </span> + 
                                            <span> 8GB</span>
                                        </p>                                   
                                        <div className='product-price addToCart-offcanvas'>
                                            <div className='new-price'>₹79,999</div>
                                            <div className='old-price'>₹85,559</div>
                                        </div>
                                        <div className="deliverTo__info mt-2">
                                            <span>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.3337 5.33337H13.3337L15.3337 8.03737V12H13.977C13.8977 12.5562 13.6205 13.0652 13.1961 13.4333C12.7718 13.8015 12.2288 14.0042 11.667 14.0042C11.1052 14.0042 10.5622 13.8015 10.1379 13.4333C9.71351 13.0652 9.43624 12.5562 9.35699 12H5.97699C5.89774 12.5562 5.62047 13.0652 5.19611 13.4333C4.77175 13.8015 4.2288 14.0042 3.66699 14.0042C3.10518 14.0042 2.56223 13.8015 2.13787 13.4333C1.71352 13.0652 1.43625 12.5562 1.35699 12H0.666992V4.00004C0.666992 3.82323 0.73723 3.65366 0.862254 3.52864C0.987279 3.40361 1.15685 3.33337 1.33366 3.33337H10.667C10.8438 3.33337 11.0134 3.40361 11.1384 3.52864C11.2634 3.65366 11.3337 3.82323 11.3337 4.00004V5.33337ZM11.3337 6.66671V8.66671H14.0003V8.47671L12.6617 6.66671H11.3337Z" fill="#C4CAD0"/>
                                                </svg>
                                            </span>
                                            <span className="info__similarProd ml-1">Delivery by &nbsp;</span>
                                            <span className="label__similarProd">Monday, 24 Jan.</span>
                                            <span className="deliver-type text-uppercase">Free</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="changingbtn">
                            <button>
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="30" height="30" rx="15" fill="#121415"/><path d="M14.9662 12.3L14.0235 13.2427L12.3329 11.552V20.3333H10.9995V11.552L9.30954 13.2427L8.36621 12.3L11.6662 9L14.9662 12.3ZM21.6329 17.7L18.3329 21L15.0329 17.7L15.9755 16.7573L17.6669 18.448L17.6662 9.66667H18.9995V18.448L20.6902 16.7573L21.6329 17.7Z" fill="white"/></svg>
                            </button>
                        </div>
                        <div className="replaceitems-model">Replacing With</div>
                        <div className='product-wrapper'>
                            <div className='product-list px-0'>                    
                                <div className='shopping-cart__list p-0'>
                                    <div className='shopping-cart___media'>
                                        <img src={iphone13} className='img img-fluid' alt='' />
                                    </div>
                                    <div className='shopping-cart__info replace-model_info'>
                                        <h4>Apple iPhone 13 Pro</h4>
                                        <p>
                                            <span>Purple </span>,
                                            <span>256GB </span> + 
                                            <span> 8GB</span>
                                        </p>                                   
                                        <div className='product-price addToCart-offcanvas'>
                                            <div className='new-price'>₹79,999</div>
                                            <div className='old-price'>₹85,559</div>
                                        </div>
                                        <div className="deliverTo__info mt-2">
                                            <span>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.3337 5.33337H13.3337L15.3337 8.03737V12H13.977C13.8977 12.5562 13.6205 13.0652 13.1961 13.4333C12.7718 13.8015 12.2288 14.0042 11.667 14.0042C11.1052 14.0042 10.5622 13.8015 10.1379 13.4333C9.71351 13.0652 9.43624 12.5562 9.35699 12H5.97699C5.89774 12.5562 5.62047 13.0652 5.19611 13.4333C4.77175 13.8015 4.2288 14.0042 3.66699 14.0042C3.10518 14.0042 2.56223 13.8015 2.13787 13.4333C1.71352 13.0652 1.43625 12.5562 1.35699 12H0.666992V4.00004C0.666992 3.82323 0.73723 3.65366 0.862254 3.52864C0.987279 3.40361 1.15685 3.33337 1.33366 3.33337H10.667C10.8438 3.33337 11.0134 3.40361 11.1384 3.52864C11.2634 3.65366 11.3337 3.82323 11.3337 4.00004V5.33337ZM11.3337 6.66671V8.66671H14.0003V8.47671L12.6617 6.66671H11.3337Z" fill="#C4CAD0"/>
                                                </svg>
                                            </span>
                                            <span className="info__similarProd ml-1">Delivery by &nbsp;</span>
                                            <span className="label__similarProd">Monday, 24 Jan.</span>
                                        </div>
                                    </div>
                                </div>                      
                            </div>                
                        </div>
                    </div>
                    <div className="exchange-doesnot-change_msg">Note: Exchange value of your old phone does not change</div>
                    <div className="d-flex align-items-center justify-content-center p-x-16 pb-3">
                        <button type="button" className="btn btn-dark-outline space-padding w-100">No</button>
                        <button type="button" className="btn btn-dark-custom space-padding text-nowrap w-100 ml-2">Yes, Replace</button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default ReplaceCartItem