import React from 'react';
import Form from 'react-bootstrap/Form';

const Step6 = () => {
    return (
        <>
            <div className='px-3'>
                <h3 className="Exchangehead">Tell us more about your phone&apos;s condition</h3>
                <div className="exchange-data">
                    <h3 className="Exchangehead Exchange-head">1. Is there any Body Damage?</h3>
                    <div className="filter-tab__lists">
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon' aria-label="radio 1"/>
                            <label htmlFor="label" className="filter_exchange ml-2">Yes</label>
                        </div>
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" />	
                            <label htmlFor="label" className="filter_exchange ml-2">No</label>
                        </div>
                    </div>
                </div>
                <div className="deviderclass-border"> </div>
                <div className="exchange-data">
                    <h3 className="Exchangehead Exchange-head">2. Is there any Screen Damage?</h3>
                    <div className="filter-tab__lists">
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon1' aria-label="radio 1" />
                            <label htmlFor="label" className="filter_exchange ml-2">Yes</label>
                        </div>
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon1' aria-label="radio 1" />
                            <label htmlFor="label" className="filter_exchange ml-2">No</label>
                        </div>
                    </div>
                </div>
                <div className="deviderclass-border"> </div>
                <div className="exchange-data">
                    <h3 className="Exchangehead Exchange-head">3. Do you have original charger?</h3>
                    <div className="filter-tab__lists">
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon1' aria-label="radio 1" />
                            <label htmlFor="label" className="filter_exchange ml-2">Yes</label>
                        </div>
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon1' aria-label="radio 1" />
                            <label htmlFor="label" className="filter_exchange ml-2">No</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer h-3 mt-5' />
        </>
    )
}

export default Step6