import React from 'react';
import Order_check from '../../Images/CategoryDetails/Order/order_check.png';
import Location from '../../Images/CategoryDetails/Order/location.png';
import ProductRelatedCategory from '../../Components/Product/ProductRelatedCategory';
import ProductAccessories from '../../Components/Product/ProductAccessories';
import ProductFooter from '../../Components/Footer/ProductFooter';
import ArrivingToday from '../../Components/Order/ArrivingToday';
import trackimg from '../../Images/CategoryDetails/logo/track.png';
import Lookoutdelivery from '../../Components/Order/Lookoutdelivery';
import ArrivingDate from '../../Components/Order/ArrivingDate';
import TrackShipment from '../../Components/Order/TrackShipment';
import Gamification from '../../Components/Gamification/Gamification';
import { Link } from '@reach/router';

const OrdersuccessfullDetails = () => {
    return (
        <>
            <div className="Order_successfull">
                <div className="order_successfull-Model">
                    <div className="order_successfullmsg">
                        <img src={Order_check} alt="" className='img w-100' />
                    </div>
                    <div className="ordersuccesful_message">
                        <h4 className='Order_Successful-msg'>Order Successful</h4>
                    </div>
                </div>
                <div className="address-Location-Model">
                    <div className="address-Location-msg">
                        <img src={Location} alt="" className='img w-100' />
                    </div>
                    <div className="address-Location_message">
                        <h6 className='address-Location-Heading'>Rohan Nanda, 560103</h6>
                        <p className='address-Location-para'>103, Udgam Belvedere, Kadubeesanahalli, Bella...
                        </p>
                    </div>
                </div>
                <div className="order_successfull-Model">
                    <div className="ordersuccesful_message">
                        <h4 className='Order_Successful-msg'>Arriving Today by 4pm</h4>
                        <p className='address-Location-para'>ORDER ID : 983729203491
                        </p>
                    </div>
                </div>
                <div className='product-inbox specification-head arriving-inbox'>
                    <ArrivingToday />
                </div>
                <div className='shopping-cart__vas protecttion_vas'>
                    <div className='shopping-vas__info'>
                        <div className='shopping-vas__media'>
                            <span>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="48" height="48" fill="white" />
                                    <path d="M15.783 14.826L24 13L32.217 14.826C32.4391 14.8754 32.6377 14.999 32.78 15.1764C32.9224 15.3538 33 15.5745 33 15.802V25.789C32.9999 26.7767 32.756 27.7492 32.2899 28.62C31.8238 29.4908 31.1499 30.2331 30.328 30.781L24 35L17.672 30.781C16.8503 30.2332 16.1765 29.4911 15.7103 28.6205C15.2442 27.7498 15.0002 26.7776 15 25.79V15.802C15 15.5745 15.0776 15.3538 15.22 15.1764C15.3623 14.999 15.5609 14.8754 15.783 14.826ZM25 22V17L20 24H23V29L28 22H25Z" fill="#121415" />
                                </svg>
                            </span>
                        </div>
                        <div className='d-flex flex-column'>
                            <div className='shopping-vas__head'>Money and Damage Protection (MAD) </div>
                            <div className='shopping-vas__label'>1 Year Protection</div>
                        </div>
                    </div>
                </div>
                <div className='mar-spacer h-1_2' />
                <div className='shopping-cart__btn'>
                    {/* <button type='button' className='btn btn-remove btn-cart-h'>
                        <span>
                            <img src={trackimg} alt="" />                                       
                        </span>
                        <span className='ml-1'>Track Order</span>
                    </button> */}
                    {['bottom'].map((placement, idx) => (
                        <TrackShipment key={idx} placement={placement} name={placement} />
                    ))}
                    <Link type='button' className='btn btn-save-for-later btn-cart-h' to="/OrderDetails">
                        <span className='ml-1'>Order Details</span>
                    </Link>
                </div>
                <div className='mar-spacer h-1_2' />
                <Lookoutdelivery />
                {/* Arriving tomorow */}
                <div className='mar-spacer h-1_2 blue_bg' />
                <div className="order_successfull-Model">
                    <div className="ordersuccesful_message">
                        <h4 className='Order_Pending-msg'>Arriving tomorrow, 22nd Jan</h4>
                        <p className='address-Location-para'>ORDER ID : 983729203491
                        </p>
                    </div>
                </div>
                <div className='product-inbox specification-head arriving-inbox'>
                    <ArrivingDate />
                </div>
                <div className='shopping-cart__vas protecttion_vas'>
                    <div className='shopping-vas__info'>
                        <div className='shopping-vas__media'>
                            <span>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="48" height="48" fill="white" />
                                    <path d="M15.783 14.826L24 13L32.217 14.826C32.4391 14.8754 32.6377 14.999 32.78 15.1764C32.9224 15.3538 33 15.5745 33 15.802V25.789C32.9999 26.7767 32.756 27.7492 32.2899 28.62C31.8238 29.4908 31.1499 30.2331 30.328 30.781L24 35L17.672 30.781C16.8503 30.2332 16.1765 29.4911 15.7103 28.6205C15.2442 27.7498 15.0002 26.7776 15 25.79V15.802C15 15.5745 15.0776 15.3538 15.22 15.1764C15.3623 14.999 15.5609 14.8754 15.783 14.826ZM25 22V17L20 24H23V29L28 22H25Z" fill="#121415" />
                                </svg>
                            </span>
                        </div>
                        <div className='d-flex flex-column'>
                            <div className='shopping-vas__head'>Money and Damage Protection (MAD) </div>
                            <div className='shopping-vas__label'>1 Year Protection</div>
                        </div>
                    </div>
                </div>
                <div className='mar-spacer h-1_2' />
                <div className='shopping-cart__btn'>
                    <button type='button' className='btn btn-remove btn-cart-h'>
                        <span>
                            <img src={trackimg} alt="" />
                        </span>
                        <span className='ml-1'>Track Order</span>
                    </button>
                    <button type='button' className='btn btn-save-for-later btn-cart-h'>
                        <span className='ml-1'>Order Details</span>
                    </button>
                </div>
                <div className='mar-spacer h-1_2 blue_bg' />
                <div className="order_successfull-Model">
                    <div className="ordersuccesful_message">
                        <h4 className='Order_Pending-msg'>Arriving on 25th Jan</h4>
                        <p className='address-Location-para'>ORDER ID : 983729203491
                        </p>
                    </div>
                </div>
                <div className='product-inbox specification-head arriving-inbox'>
                    <ArrivingDate />
                </div>
                <div className='shopping-cart__btn'>
                    <button type='button' className='btn btn-remove btn-cart-h'>
                        <span>
                            <img src={trackimg} alt="" />
                        </span>
                        <span className='ml-1'>Track Order</span>
                    </button>
                    <button type='button' className='btn btn-save-for-later btn-cart-h'>
                        <span className='ml-1'>Order Details</span>
                    </button>
                </div>
                <div className='h-1_2 mar-spacer' />
                <div className='play-earn p-x-16'>
                    <Gamification />
                </div>
                <div className='h_blue' />
                <div className='h-1_2 mar-spacer' />
                <div className='cart__shopping'>
                    <h2 className='shopping-cart__heading'>Accessories for you</h2>
                    <div className='btn-category__wrap'>
                        <button type='button' className='btn btn-category selected'>All</button>
                        <button type='button' className='btn btn-category'>Accessories</button>
                        <button type='button' className='btn btn-category'>Earphones</button>
                        <button type='button' className='btn btn-category'>Charger</button>
                        <button type='button' className='btn btn-category'>Headphone</button>
                    </div>
                    <div className='product-specifications p-0'>
                        <ProductAccessories />
                    </div>
                    <div className='h-2 mar-spacer' />
                </div> 
                <div className="order_details_categoary p-0">
                    <h4 className='specification-heading p-x-16'>Categories For You</h4>
                    <div className='category-carousel ml--16'>
                        <ProductRelatedCategory />
                    </div>
                </div>
                <div className='h-1_2 mar-spacer' />
                <div className='d-flex p-x-16'>
                    <Link to='/' className='btn btn-block btn-dark-custom w-100'>Back to Home</Link>
                </div>
                <div className='h-3 mar-spacer' />
                <ProductFooter />
            </div>
        </>
    )
}

export default OrdersuccessfullDetails
