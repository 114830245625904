import React, { useState } from 'react';
import { Link } from '@reach/router';

import Iphone13 from '../../Images/Compare/Iphone13.png';

const CompareSearch = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const handleChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleFocus = (e) => {
        e.target.classList.add("focus");
    };
    const handleBlur = (e) => {
        e.target.classList.remove("focus");
    };   
    const SearchedQuery = [
        {
            "_id": "1",
            "Image": Iphone13,
            "productName": "Apple iPhone 13 Pro",
            "prductBrand": "Apple",
            "productColor": "Awesome Violet",
            "productStorage": {
                "ram": "169GB",
                "Storage": "128GB"
            },
            "productNewPrice": 89999,
            "productOldPrice": 93559,
            "productOffer": "15% Off",
            "label": "Sponsored"
        }, {
            "_id": "2",
            "Image": Iphone13,
            "productName": "Galaxy Fold 5G",
            "productInfo": 'Black, 128TB + 8GB',
            "prductBrand": "Apple",
            "productColor": "Awesome Violet",
            "productStorage": {
                "ram": "8GB",
                "Storage": "128GB"
            },
            "productNewPrice": 89999,
            "productOldPrice": 93559,
            "productOffer": "15% Off",
            "label": ''
        }, {
            "_id": "3",
            "Image": Iphone13,
            "productName": "OnePlus Nord 2 5G",
            "productInfo": 'Black, 128TB + 8GB',
            "prductBrand": "Apple",
            "productColor": "Awesome Violet",
            "productStorage": {
                "ram": "8GB",
                "Storage": "128GB"
            },
            "productNewPrice": 89999,
            "productOldPrice": 93559,
            "productOffer": "15% Off",
            "label": ''
        }
    ];
    const results = !searchTerm
        ? SearchedQuery
        : SearchedQuery.filter(person =>
            person.productName.toLowerCase().includes(searchTerm.toLocaleLowerCase()) || searchTerm.includes(person.productStorage.Storage)
        );
    return (
        <>
            <div className='compare__search px-3 mt-2'>
                <div className='header-home__search'>
                    <form className="form">
                        <div className="form-group">
                            <div className="input-icon">
                                <div className='d-flex align-items-center col'>
                                    <input  type='text'
                                        className="bannerInput-search"
                                        placeholder="Search for mobiles and brands"
                                        value={searchTerm}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        onChange={handleChange} />
                                    {searchTerm ? 
                                        (<button  onClick={() => setSearchTerm(() => "")} className="searcharrow_img compare-search-close">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.99999 7.05732L11.3 3.75732L12.2427 4.69999L8.94266 7.99999L12.2427 11.3L11.3 12.2427L7.99999 8.94266L4.69999 12.2427L3.75732 11.3L7.05732 7.99999L3.75732 4.69999L4.69999 3.75732L7.99999 7.05732Z" fill="#121415"/>
                                            </svg>
                                        </button>) : ''
                                    }
                                </div>
                                <span className="bannerSearch-icon">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.0262 12.8473L17.5953 16.4157L16.4162 17.5948L12.8478 14.0257C11.5201 15.09 9.86866 15.6689 8.16699 15.6665C4.02699 15.6665 0.666992 12.3065 0.666992 8.1665C0.666992 4.0265 4.02699 0.666504 8.16699 0.666504C12.307 0.666504 15.667 4.0265 15.667 8.1665C15.6694 9.86817 15.0905 11.5196 14.0262 12.8473ZM12.3545 12.229C13.4121 11.1414 14.0027 9.68353 14.0003 8.1665C14.0003 4.94317 11.3895 2.33317 8.16699 2.33317C4.94366 2.33317 2.33366 4.94317 2.33366 8.1665C2.33366 11.389 4.94366 13.9998 8.16699 13.9998C9.68402 14.0022 11.1419 13.4116 12.2295 12.354L12.3545 12.229Z" fill="#999999"/>
                                    </svg>                                                        
                                </span>
                            </div>                                            
                        </div>
                    </form>
                </div>
            </div>
            <div className='product-wrapper px-3'>
                {results.map(product => (
                    <div className='product-list py-0' key={product._id}>
                        {product.label ?
                            <div className='product-label-tag m-h-1' style={{left: '-16px'}}>{product.label}</div> : ''
                        }
                        <div className='product-wrapper-flex w-100'>
                            <div className='product-thumb'>
                                <img src={Iphone13} className='img-fluid w-auto' alt={product.productName} />
                            </div>
                            <div className='product-details d-flex align-items-start justify-content-center flex-column'>
                                <h2>
                                    <Link to='/ProductDetails'>{product.productName}</Link>
                                </h2>
                                <p className='product-muted'>
                                    {product.productColor},
                                    {product.productStorage.Storage} +
                                    {product.productStorage.Storage}
                                </p>
                                <div className='product-price'>
                                    <div className='new-price'>₹{product.productNewPrice}</div>
                                    <div className='old-price'>₹{product.productOldPrice}</div>
                                    <div className='price-discount'>{product.productOffer}</div>
                                </div>                                        
                            </div>
                        </div>

                        <div className='product-tags'>
                            <div className='tag-label'>PhotoGraphy</div>
                            <div className='tag-label'>Wireless Charge</div>
                            <div className='tag-label'>Retina Display</div>
                            
                        </div>
                    </div>
                ))}
                {results.length === 0 ? 
                    <>
                        <div className='text-center mt-5 heading-group-banks'>
                            No Data Found
                        </div>
                        <div className={results.length === 0 ? 'mar-spacer h-13' : null} />
                    </> : 
                    null 
                }
            </div>
        </>
    )
}

export default CompareSearch