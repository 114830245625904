import React from 'react';
import { Link } from '@reach/router';

import callbackBanner from '../../Images/storeLocator/callback-banner.png';
import arrowVictor from '../../Images/storeLocator/Vector.png';


const ReqCallbackBanner = () => {
    return (
        <>
            <section className="store_callback-banner mb-2">
                <div 
                    className='phome-banner__wrap position-relative'
                    style={{ 
                        backgroundColor: '#FFF5ED',
                        padding: '24px 16px',
                        zIndex: 1,
                    }}    
                >
                    <img src={callbackBanner} className='img-fluid store-victor' alt='' />
                    <img src={arrowVictor} className='img-fluid arrow-victor' alt='' />
                    <div className='phome-banner__info'>
                        <h5>Need any help?</h5>
                        <p>We are here to assist you always</p>
                        <Link to='/' className='btn btn-dark-custom space-padding p-x-16'>Request Callback</Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ReqCallbackBanner