import React from 'react';
import { Link } from '@reach/router';
import AddressSearch from './AddressSearch';
import AddressList from './AddressList';

const index = () => {
    return (
        <>
            <div className='cart-address__head'>
                <AddressSearch />
                <Link className='cart-address__location' to='/AddAddressDetails'>
                    <span>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.33342 0.666626V2.03902C4.53723 2.3432 2.34332 4.53711 2.03914 7.33329H0.666748V8.66663H2.03914C2.34332 11.4628 4.53723 13.6567 7.33342 13.9609V15.3333H8.66675V13.9609C11.4629 13.6567 13.6568 11.4628 13.961 8.66663H15.3334V7.33329H13.961C13.6568 4.53711 11.4629 2.3432 8.66675 2.03902V0.666626H7.33342ZM8.00008 3.33329C10.6001 3.33329 12.6668 5.39996 12.6668 7.99996C12.6668 10.6 10.6001 12.6666 8.00008 12.6666C5.40008 12.6666 3.33342 10.6 3.33342 7.99996C3.33342 5.39996 5.40008 3.33329 8.00008 3.33329ZM8.00008 5.99996C7.46965 5.99996 6.96094 6.21067 6.58587 6.58575C6.2108 6.96082 6.00008 7.46953 6.00008 7.99996C6.00008 8.53039 6.2108 9.0391 6.58587 9.41418C6.96094 9.78925 7.46965 9.99996 8.00008 9.99996C8.53052 9.99996 9.03922 9.78925 9.4143 9.41418C9.78937 9.0391 10.0001 8.53039 10.0001 7.99996C10.0001 7.46953 9.78937 6.96082 9.4143 6.58575C9.03922 6.21067 8.53052 5.99996 8.00008 5.99996Z" fill="#00AA88"/>
                        </svg>
                    </span>
                    <label htmlFor="infoText">Use your current location</label>
                </Link>
            </div>
            <div className='mar-spacer bg-theme' />
            <div className='cart-address__head'>
                <h6>Saved Address</h6>
            </div>
            <AddressList />
            
            <div className='mar-spacer h-3' />
            <section className="sticky-menus filter-sticky-menus">
                <div className="sticky-price__wrap px-3 w-100">
                    <button type='button' className='btn btn-place-order btn-dark-custom w-100'>Confirm</button>                    
                </div>
            </section>
        </>
    )
}

export default index