import React from 'react'

const WatchModels = () => {
    return (
        <>
            <div className='product-models-box'>
                <div className='product-modelsFrame active'>
                    <span>44mm</span>
                </div>
                <div className='product-modelsFrame'>
                    <span>40mm</span>
                </div>
            </div>
        </>
    )
}

export default WatchModels;
