import React from 'react';
import Carousel from "react-multi-carousel";
import prodHighlight from '../../Images/product/prodHighlight.png'

const ProductHighlightCarousel = () => {

    const highlightData = [
        {   
            _id: 1,
            Image: prodHighlight,
            Title: 'Powerful Health Tool',
            Description: 'Keeps tabs on your heart rate when you’re walking, resting or working out. The watch also packs a Breathe App which guides you when you’re stressed. There are also third party health ap..'
        }, {   
            _id: 2,
            Image: prodHighlight,
            Title: 'Aura 360 Design',
            Description: 'Keeps tabs on your heart rate when you’re walking, resting or working out. The watch also packs a Breathe App which guides you when you’re stressed. There are also third party health ap..'
        }, {   
            _id: 3,
            Image: prodHighlight,
            Title: 'Powerful Health Tool',
            Description: 'Keeps tabs on your heart rate when you’re walking, resting or working out. The watch also packs a Breathe App which guides you when you’re stressed. There are also third party health ap..'
        }
    ];
    const responsive = {
        tablet: {
            breakpoint: { max: 768, min: 567 },
            items: 2,
            partialVisibilityGutter: 10
        },
        mobile: {
            breakpoint: { max: 567, min: 0 },
            items: 1,
            partialVisibilityGutter: 80
        }
    };

    return (
        <>
            <Carousel                
                partialVisible={true}
                arrows={false}
                itemClass="image-item"
                responsive={responsive}
            >   
                {highlightData && highlightData.map(Item => {
                    return (
                        <div className='highlight-info__carousel' key={Item._id}>                    
                            <div className='highlight-carousel-media'>
                                <img
                                    draggable={true}
                                    style={{ width: "100%", height: "100%" }}
                                    src={Item.Image}
                                    alt={Item.productName}
                                    className='img'
                                />
                            </div>
                            <div className='carousel-highlight-content'>
                                <div className='carousel-highlight-title'>{Item.Title}</div> 
                                <div className='flexible'></div>
                                <div className='carousel-highlight-desc'>{Item.Description}</div>
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </>
    )
}

export default ProductHighlightCarousel;
