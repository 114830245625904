import React from 'react'

const RemoveStep2 = () => {
    return (
        <>
            <div className="cartremove-txt">
            As you have selected an exchange offer for the previous item in your cart, a new product cannot be added
            </div>
            <div className="mar-spacer h-1"></div>
 
            <div className='notify__btn'>
                <button className='btn-dark-custom btn-place-order w-100 border-0'>Continue</button>
            </div>
        </>
    )
}

export default RemoveStep2;