import React from 'react';
import { Link } from '@reach/router';
import MasterLogo from '../../Images/product/MasterLogo.png';
import hdfc from '../../Images/product/emi/hdfc.png';
import SBI from '../../Images/product/emi/SBI.png';
import VisaLogo from '../../Images/product/emi/VisaLogo.png';
import GPAY from '../../Images/product/emi/GPAY.png';

const Payments = () => {
    return (
        <>
            <div className='mar-spacer h-2' />
            <div className='p-x-16'>
                <Link to='/AddAddressDetails' className='btn btn-cart-outline w-100'>+ Add New Card</Link>
                <div className='account-payment-list'>
                    <h6>Saved Cards</h6>
                    <div className='payment-card--lists'>
                        <div className='d-flex'>
                            <div className='payment-card--list upi-payment__wrap'>
                                <div className='payment-card--media'>
                                    <div className='upi-payment__media'>
                                        <img src={hdfc} className='img img-fluid' alt='' />
                                    </div>
                                </div>
                                <div className='payment-card--content'>
                                    <div className='payment-card--title'>HDFC Debit Card</div>
                                    <div className='payment-card--label'>
                                        <span>Master Card </span>
                                        <span>****2386</span>
                                        <span className='ml-2'>
                                            <img src={MasterLogo} className='img img-fluid' alt='' />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mar-spacer h-1_2' />
                        <button type='button' className='btn btn-dark-outline'>Remove</button>
                        <div className='mar-spacer h-2' />
                    </div>
                    <div className='payment-card--lists'>
                        <div className='d-flex'>
                            <div className='payment-card--list upi-payment__wrap'>
                                <div className='payment-card--media'>
                                    <div className='upi-payment__media'>
                                        <img src={SBI} className='img img-fluid' alt='' />
                                    </div>
                                </div>
                                <div className='payment-card--content'>
                                    <div className='payment-card--title'>SBI Debit Card</div>
                                    <div className='payment-card--label'>
                                        <span>Master Card </span>
                                        <span>****2386</span>
                                        <span className='ml-2'>
                                            <img src={VisaLogo} className='img img-fluid' alt='' />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mar-spacer h-1_2' />
                        <button type='button' className='btn btn-dark-outline'>Remove</button>
                        <div className='mar-spacer h-2' />
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme' />
            <div className='mar-spacer h-2' />
            <div className='p-x-16'>
                <Link to='/AddAddressDetails' className='btn btn-cart-outline w-100'>+ Add New Wallet</Link>
                <div className='account-payment-list'>
                    <h6>Saved Wallets</h6>
                    <div className='payment-card--lists'>
                        <div className='d-flex'>
                            <div className='payment-card--list upi-payment__wrap'>
                                <div className='payment-card--media'>
                                    <div className='upi-payment__media'>
                                        <img src={GPAY} className='img img-fluid' alt='' />
                                    </div>
                                </div>
                                <div className='payment-card--content'>
                                    <div className='payment-card--title'>Google Pay</div>
                                    <div className='payment-card--label'>
                                        <span>nanda.rohan@okicici</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mar-spacer h-1_2' />
                        <button type='button' className='btn btn-dark-outline'>Remove</button>
                        <div className='mar-spacer h-3' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Payments
