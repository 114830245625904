import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const GSTCart = ({...props }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className='shopping-cart__wrapper w-100'>
                <div className='d-flex align-items-start flex-column w-100'>
                    <Form.Check 
                        type='checkbox'
                        id='default-checkbox'
                        role="button" onClick={handleShow} aria-hidden="true"
                        label='Use GST Invoice'
                        className='d-flex align-items-start'
                    />
                    <div className='d-flex align-items-center justify-content-between gst__wrap w-100'>
                        <div className='d-flex flex-column gst__info'>
                            <span>PARALLEL</span>
                            <span>24UDBBFJD326HDC</span>
                        </div>
                        <button type='button' className='btn btn-dark-outline space-padding'>change</button>
                    </div>
                </div>
                
            </div>

            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Add GST Details</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='cart-gift__wrap'>
                        <div className='form-group-custom'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Company Name"
                            >
                                <Form.Control type="text" className='form-control form-control-custom' placeholder='Company Name' value="Parallel Private Limited" />
                            </FloatingLabel>
                        </div>
                        <div className='form-group-custom'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="GST Number"
                            >
                                <Form.Control type="text" className='form-control form-control-custom' placeholder='GST Number' value="24UDBBFJD326HDC" />
                                <span className='gst-label'>15/15</span>
                            </FloatingLabel>
                        </div>
                        <button type='button' className='btn btn-dark-custom w-100'>Confirm & Save</button>                          
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default GSTCart