import React from 'react';
import StoreListAccordion from './StoreListAccordion';
import ReqCallbackBanner from './ReqCallbackBanner';

const StoreList = () => {
    return (
        <>
            <div className='brands-wrapper exclusive-section border-0 pb-0'>
                <div className='top-head__wrap'>
                    <h4 className='brand-heading'>240 stores in India</h4>
                </div>                
                <div className='store_accordion'>
                    <StoreListAccordion />
                </div>
            </div>
            <ReqCallbackBanner />
        </>
    )
}

export default StoreList