import React from 'react';
import NotifyMeBtn from './NotifyMeBtn';
//import NFMModal from './NFMModal';


const OutOfStockBtn = () => {
    return (
        <>
            <button type='button' className='btn btn-block btn-dark-custom btn-out-of-stock mb-2'>Out Of Stock</button>
            {['bottom'].map((placement, idx) => (
                <NotifyMeBtn key={idx} placement={placement} name={placement} />
            ))}
        </>
    )
}

export default OutOfStockBtn
