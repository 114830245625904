import React from 'react'
import { Link } from '@reach/router';

import img1 from '../../../Images/Orders/img1.png';
import img3 from '../../../Images/Orders/img3.svg';
import img2 from '../../../Images/Orders/img2.png';


const OrderStatus = () => {
    return (

        <>
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info order-cancelled__text '>
                        <div className='order-placed__text'> 1. Order Placed for 2 hr delivery</div>
                    </div>
                </div>
        
            </div>
            <div className='mar-spacer bg-theme h-4' />
            {/* Order placed */}
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info'>
                        <div className='order-placed__text'>Order placed</div>
                        <div className='order-placed__info'>Arriving by 4:20 pm, Today</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='orders-media__wrap'>
                                <img src={img2} className='img-fluid' alt='' />
                            </div>
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />

            {/* Order is on the way */}
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info'>
                        <div className='order-placed__text'>Order is on the way</div>
                        <div className='order-placed__info'>Arriving in 30 mins</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='orders-media__wrap'>
                                <img src={img2} className='img-fluid' alt='' />
                            </div>
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />

            {/* Delivery Failed */}
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info'>
                        <div className='order-placed__text deliveryfailed-info'>Delivery Failed</div>
                        <div className='order-placed__info'>We will reattempt the delivery tomorrow</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='orders-media__wrap'>
                                <img src={img2} className='img-fluid' alt='' />
                            </div>
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />

            {/* Order Delivered */}

            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info'>
                        <div className='order-placed__text'>Order Delivered</div>
                        <div className='order-placed__info'>Delivered on Wed, 6th Jan, 2022 - 3:45pm</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='orders-media__wrap'>
                                <img src={img2} className='img-fluid' alt='' />
                            </div>
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mar-spacer bg-theme h-4' />

            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info order-cancelled__text '>
                        <div className='order-placed__text'>2. Order Placed for Non Sangeetha Location</div>
                    </div>
                </div>
        
            </div>
            <div className='mar-spacer bg-theme h-4' />


            {/* Order placed */}
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info'>
                        <div className='order-placed__text'>Order placed</div>
                        <div className='order-placed__info'>Expected delivery by Wed, 6th Jan</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />

            {/* Order is on the way */}
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info'>
                        <div className='order-placed__text'>Order is on the way</div>
                        <div className='order-placed__info'>Expected delivery by Wed, 6th Jan</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                         
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />
            {/* Order is on the way */}
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info'>
                        <div className='order-placed__text'>Order is on the way</div>
                        <div className='order-placed__info'>Expected delivery by Tomorrow</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                         
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />
            {/* Order is on the way */}

            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info'>
                        <div className="ordertrack_ontime_delayed delayordermb-2">
                            <p className='order_ontime_txt_delayed'>Delayed</p>
                        </div>
                        <div className='order-placed__text'>Order is on the way</div>
                        <div className='order-placed__info'>Expected delivery by Wed, 6th Jan</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='orders-media__wrap'>
                                <img src={img2} className='img-fluid' alt='' />
                            </div>
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />
            {/* Order is on the way */}

            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info order-cancelled__text '>                        
                        <div className='order-placed__text'>Order Delivered</div>
                        <div className='order-placed__info'>Delivered on Wed, 6th Jan, 2022 - 3:45pm</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>                           
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info order-cancelled__text '>
                        <div className='order-placed__text'> 3. Replacement requested for Non Sangeetha Location</div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />
            {/* Replacement Requested */}
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info'>
                        <div className='order-placed__text'>Replacement Requested</div>
                        <div className='order-placed__info'>Usually takes 7-8 days for replacement</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />

            {/* Pickup Scheduled */}
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info'>
                        <div className='order-placed__text'>Pickup Scheduled</div>
                        <div className='order-placed__info'>Pick up expected by Thu, 7th Jan</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />
            {/* Item picked up for verification */}
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info'>
                        <div className='order-placed__text'>Item picked up for verification</div>
                        <div className='order-placed__info'>Expected shipment to the store by fri, 8th Jan</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />
            {/* Item is on the way to the store */}

            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info'>
                       
                        <div className='order-placed__text'>Item is on the way to the store</div>
                        <div className='order-placed__info'>Expected verification by Thu, 10th Jan</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='orders-media__wrap'>
                                <img src={img2} className='img-fluid' alt='' />
                            </div>
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />
            {/* Received at Sangeetha Store */}

            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info  '>                        
                        <div className='order-placed__text'>Received at Sangeetha Store</div>
                        <div className='order-placed__info'>Expected verification by Thu, 10th Jan</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>                           
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* Verification Successful */}

            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info  '>                        
                        <div className='order-placed__text'>Verification Successful</div>
                        <div className='order-placed__info'>Replaced item expected to be shipped by Sat, 12th Jan</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>                           
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* Verification Failed */}

            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info '>                        
                        <div className='order-placed__text deliveryfailed-info'>Verification Failed</div>
                        <div className='order-placed__info'>Original item expected to be shipped by Sat, 12th Jan</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>                           
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* Replaced Item Shipped */}

            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info  '>                        
                        <div className='order-placed__text'>Replaced Item Shipped</div>
                        <div className='order-placed__info'>Expected delivery by Sat, 12th Jan</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>                           
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* Original Item Shipped */}

            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info '>                        
                        <div className='order-placed__text'>Original Item Shipped</div>
                        <div className='order-placed__info'>Expected delivery by Sat, 12th Jan</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>                           
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Original Item Shipped */}

            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info order-cancelled__text '>                        
                        <div className='order-placed__text'>Replaced Item Delivered</div>
                        <div className='order-placed__info'>Delivered on Sat, 12th Jan, 2022 - 3:45pm</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>                           
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* Delivered */}

            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info order-cancelled__text order-replacement__text '>                        
                        <div className='order-placed__text'>Delivered</div>
                        <div className='order-placed__info'>1 Item requested for replacement</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="replace-refresh-tag">
                                    <span>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.7315 2.2165C3.63878 1.43034 4.79949 0.99833 6 1C8.7615 1 11 3.2385 11 6C11 7.068 10.665 8.058 10.095 8.87L8.5 6H10C10.0001 5.21582 9.76962 4.44891 9.33733 3.79463C8.90504 3.14036 8.28997 2.62758 7.56859 2.32007C6.84721 2.01255 6.05136 1.92386 5.27998 2.06501C4.5086 2.20617 3.79573 2.57095 3.23 3.114L2.7315 2.2165ZM9.2685 9.7835C8.36122 10.5697 7.20051 11.0017 6 11C3.2385 11 1 8.7615 1 6C1 4.932 1.335 3.942 1.905 3.13L3.5 6H2C1.99994 6.78419 2.23038 7.5511 2.66267 8.20538C3.09496 8.85965 3.71003 9.37243 4.43141 9.67994C5.15279 9.98746 5.94864 10.0762 6.72002 9.935C7.4914 9.79384 8.20427 9.42905 8.77 8.886L9.2685 9.7835Z" fill="#E25100"/></svg>
                                    </span>
                                </div>
                            </div>
                            <div className='orders-media__wrap'>
                                <img src={img2} className='img-fluid' alt='' />
                            </div>                           
                        </div>
                       
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info order-cancelled__text '>
                        <div className='order-placed__text'> 4. Replacement requested for Sangeetha Location</div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />
            {/* Replacement Requested */}
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info'>
                        <div className='order-placed__text'>Replacement Requested</div>
                        <div className='order-placed__info'>Usually taked 2-3 days for replacement</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='orders-media__wrap'>
                                <img src={img3} className='img-fluid' alt='' />
                            </div>
                        
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />

            {/* Pickup Scheduled */}
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info'>
                        <div className='order-placed__text'>Pickup Scheduled</div>
                        <div className='order-placed__info'>Pick up expected by Today, 8pm</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='orders-media__wrap'>
                                <img src={img3} className='img-fluid' alt='' />
                            </div>
                        
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />
            {/* Item picked up for verification */}
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info'>
                        <div className='order-placed__text'>Item picked up for verification</div>
                        <div className='order-placed__info'>Expected verification by Tomorrow</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>           
            <div className='mar-spacer bg-theme h-4' />
            {/* Received at Sangeetha Store */}

            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info  '>                        
                        <div className='order-placed__text'>Received at Sangeetha store</div>
                        <div className='order-placed__info'>Expected verification by Tomorrow</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>                           
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* Verification Successful */}

            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info  '>                        
                        <div className='order-placed__text'>Verification Successful</div>
                        <div className='order-placed__info'>Expected delivery by Today</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>                           
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
         
            {/* Replacement Delivered */}

            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info order-cancelled__text '>                        
                        <div className='order-placed__text'>Replacement Delivered</div>
                        <div className='order-placed__info'>Delivered on 4th Jan, 2022 - 3:45pm</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>                           
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info order-cancelled__text '>
                        <div className='order-placed__text'> 5. Order cancelled</div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />
            {/* Cancelled */}
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info order-cancelled__text'>
                        <div className='order-placed__text'>Cancelled</div>
                        <div className='order-placed__info'>Refund of ₹19,999 has been initiated</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='orders-media__wrap'>
                                <img src={img3} className='img-fluid' alt='' />
                            </div>      
                        
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer bg-theme h-4' />

            {/* Order is on the way */}
            <div className='d-flex flex-column align-items-start w-100'>
                <div className='order-track__list'>
                    <div className='order-track_info order-cancelled__text'>
                        <div className='order-placed__text'>Cancelled</div>
                        <div className='order-placed__info'>Refund of ₹19,999 has been processed successfully on 25th Aug, 2022</div>
                    </div>
                    <Link to='/' className='order-track_link'>Track Order</Link>
                </div>
                <div className='order-track__wrap'>
                    <div className='orders_wrap'>
                        <div className='orders-list_wrap'>
                            <div className='orders-media__wrap'>
                                <img src={img1} className='img-fluid' alt='' />
                                <div className='orders__tag'>
                                    <div className='orders_content'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='orders-media__wrap'>
                                <img src={img3} className='img-fluid' alt='' />
                            </div>
                        
                        </div>
                        <div className='orders-link_wrap'>
                            <Link to='/OrderDetails'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#98A0AE"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>



        </>
   
    )
}

export default OrderStatus