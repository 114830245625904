import React,{useState} from 'react';
import Ontheway from '../../../Images/CategoryDetails/Order/orderdetails/Ontheway.png';


const ArrivingTomorrow = () => {
    const[arrivingtomorrow]=useState(false)

    return (
        <>
            { arrivingtomorrow?
                <div className="ordersuccesful_message order_tracking_model arriving-imges">  
                    <div>                                                                    
                        <div className="order_arriving_track">
                            
                            <div className="arrivingheading">
                                <div className="ordertrack_ontime_failed">
                                    <p className='order_ontime_txt_delayed'>Delivery failed</p>
                                </div>
                                <h4 className='Order_Successful-msg'>Arriving tomorrow</h4 >
                            </div>
                            
                        </div>                                                     
                        <p className='order-Location-para'>Rohan Home 103, Udgam Belvedere, Kadubeesana...
                        </p>
                    </div>
                    <div className='d-flex'>
                        <img src={Ontheway} className='img img-fluid my-2' alt=''/>
                    </div>
                   
                </div>:""
            }
        </>
    )
}

export default ArrivingTomorrow