import React from 'react';

const FilteredResult = () => {
    return (
        <>
            <div className='product-filter__apply_wrap px-0 ml--16'>
                <div className='product-filter__lists'>
                    <div className='product-filter__item'> 
                        <span>₹80,000 - ₹90,000</span>  
                        <button className='product_filter-cross_btn'>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.99932 4.41113L7.06182 2.34863L7.65099 2.9378L5.58849 5.0003L7.65099 7.0628L7.06182 7.65197L4.99932 5.58947L2.93682 7.65197L2.34766 7.0628L4.41016 5.0003L2.34766 2.9378L2.93682 2.34863L4.99932 4.41113Z" fill="#121415"/>
                            </svg>
                        </button>
                    </div>
                    <div className='product-filter__item'> 
                        <span> ₹Over 1,00,000</span> 
                        <button className='product_filter-cross_btn'>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.99932 4.41113L7.06182 2.34863L7.65099 2.9378L5.58849 5.0003L7.65099 7.0628L7.06182 7.65197L4.99932 5.58947L2.93682 7.65197L2.34766 7.0628L4.41016 5.0003L2.34766 2.9378L2.93682 2.34863L4.99932 4.41113Z" fill="#121415"/>
                            </svg>
                        </button>
                    </div>
                    <div className='product-filter__item'>  
                        <span>Apple</span>  
                        <button className='product_filter-cross_btn'>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.99932 4.41113L7.06182 2.34863L7.65099 2.9378L5.58849 5.0003L7.65099 7.0628L7.06182 7.65197L4.99932 5.58947L2.93682 7.65197L2.34766 7.0628L4.41016 5.0003L2.34766 2.9378L2.93682 2.34863L4.99932 4.41113Z" fill="#121415"/>
                            </svg>
                        </button>
                    </div>
                    <div className='product-filter__item'>
                        <span>Samsung</span> 
                        <button className='product_filter-cross_btn'>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.99932 4.41113L7.06182 2.34863L7.65099 2.9378L5.58849 5.0003L7.65099 7.0628L7.06182 7.65197L4.99932 5.58947L2.93682 7.65197L2.34766 7.0628L4.41016 5.0003L2.34766 2.9378L2.93682 2.34863L4.99932 4.41113Z" fill="#121415"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FilteredResult