import React, { useState } from 'react';
import ProductGallery from '../../Components/Product/ProductGallery';
import LoggedAddress from '../../Components/Address/LoggedAddress';
import AddToCart from '../../Components/Cart/AddToCart';
import WhyButFromSangeetha from '../../Components/Product/WhyButFromSangeetha';
import BankOffers from '../../Components/Product/BankOffers';
import PaymentOffers from '../../Components/Product/PaymentOffers';
import ProductBuyTogether from '../../Components/Product/ProductBuyTogether';
import ProductBox from '../../Components/Product/ProductBox';
import ProductHighlights from '../../Components/Product/ProductHighlights';
import ProductRecommend from '../../Components/Product/ProductRecommend';
import ProductAccessories from '../../Components/Product/ProductAccessories';
import ProductRelatedCategory from '../../Components/Product/ProductRelatedCategory';
import ProductKeyFeatures from '../../Components/Product/ProductKeyFeatures';
import ProductWishlist from '../../Components/Product/ProductWishlist';
import ProductFooter from '../../Components/Footer/ProductFooter';
import RenewedDetails from '../../Components/ProductRenewed/RenewedDetails';
import WorkStructure from '../../Components/ProductRenewed/WorkStructure';
import 'react-multi-carousel/lib/styles.css';

import VideoThumbnail from '../../Images/product/VideoThumbnail.png';
import PlayButton from '../../Images/product/PlayButton.png';
import img1 from '../../Images/product/img1.png';

const ProductRenewed = () => {
    const [ user ] = useState(false);
    const [ wishlistActive, setWishlistState ] = useState(false);
    const [isWishlist, setIsWishlist] = useState(false);

    const handleToggle = () => {
        setWishlistState(!wishlistActive);
    };
    const handleClose = () => setIsWishlist(false);
    const handleChangeWishlist = () => {
        setIsWishlist((prevWishlist) => {
            return !prevWishlist;
        });
        setIsWishlist(true);
    };
    return (
        <>
            <div className='product-details-wrapper renewed-pre-launch'>
                <div className='product-details-container product-bg'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='product-gallery-section'>
                                <div className='product-label-tag tag-flex-light'>
                                    <span className='tag-flex_light'>
                                        <span>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.04929 1.02043C7.94694 1.05953 7.88441 1.14005 7.88441 1.22976V1.76578C4.61529 1.8877 2 4.07772 2 6.75085C2 7.11433 2.04835 7.47549 2.14498 7.82056C2.17341 7.92409 2.28997 8 2.42075 8H2.42358C2.55718 7.99769 2.6709 7.92409 2.69932 7.81826C3.23375 5.82837 5.38568 4.36298 7.88441 4.25946V4.78397C7.88441 4.87368 7.94694 4.95651 8.04929 4.9933C8.15162 5.03012 8.26818 5.01631 8.35346 4.9588L10.9005 3.17825C10.9631 3.13455 11 3.07243 11 3.00341C11 2.93671 10.9631 2.87459 10.9005 2.83089L8.35346 1.05493C8.26818 0.997419 8.14876 0.983611 8.04929 1.02043Z" fill="white"/>
                                                <path d="M5.9488 9.00527C5.84928 8.96845 5.72985 8.98224 5.64454 9.04208L3.09666 10.8209C3.03411 10.8646 3 10.929 3 10.9957C3 11.0625 3.03411 11.1269 3.09666 11.1706L5.6474 12.9448C5.69858 12.9816 5.76397 13 5.83221 13C5.87202 13 5.91183 12.9931 5.9488 12.9793C6.05119 12.9425 6.11658 12.8596 6.11658 12.7699V12.2337C9.38672 12.1141 12 9.92341 12 7.24721C12 6.88363 11.9517 6.52466 11.8578 6.1795C11.8265 6.07365 11.7128 6 11.5791 6C11.4455 6 11.3289 6.07595 11.3005 6.18179C10.7687 8.17226 8.61327 9.63577 6.11658 9.73932V9.21467C6.11658 9.12493 6.05119 9.04437 5.9488 9.00527Z" fill="white"/>
                                            </svg>
                                        </span>
                                        <span className='ml-1'>Refurbished</span>
                                    </span>
                                    <span className='tag-flex_dark'>Superb</span>
                                </div>
                                <div className='product-info'>
                                    {/* <div className='brand-name'>{productDetails.brand_name}</div> */}
                                    <div className='brand-name'>Samsung</div>
                                    <h2 className='product-name'>Galaxy Z Fold2 5G</h2>
                                    {/* <h2 className='product-name'>Galaxy Z Fold2 5G{productDetails.title}</h2> */}
                                    <div className="price-offer">
                                        <div className="new-price">₹43,659</div>
                                        <div className="old-price">₹45,659</div>
                                    </div>
                                    <div className='price-info-text'>(Price Includes GST)</div>
                                </div>
                                <div className='mar-spacer'></div>
                                <div className='product-gallery'>
                                    <ProductGallery />
                                </div>
                                {/* Wishlist and Share Icon */}
                                <div className='product-btns'>
                                    {['top'].map((placement, idx) => (
                                        <ProductWishlist
                                            isWishlist={isWishlist}
                                            handleChangeWishlist={handleChangeWishlist}
                                            handleClose={handleClose}
                                            key={idx}
                                            placement={placement}
                                            name={placement}
                                        />
                                    ))}

                                    <button type='button' className='product-share-btn' onClick={() => handleChangeWishlist()}>
                                        <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.04982 11.5157L5.64316 9.6577C5.31615 9.9823 4.90037 10.2028 4.44822 10.2915C3.99607 10.3801 3.5278 10.3329 3.10244 10.1558C2.67708 9.97866 2.31368 9.67958 2.05806 9.29624C1.80243 8.9129 1.66602 8.46246 1.66602 8.0017C1.66602 7.54095 1.80243 7.0905 2.05806 6.70716C2.31368 6.32382 2.67708 6.02474 3.10244 5.84764C3.5278 5.67053 3.99607 5.62331 4.44822 5.71195C4.90037 5.80059 5.31615 6.0211 5.64316 6.3457L9.04982 4.4877C8.93299 3.93953 9.01737 3.36761 9.28755 2.87654C9.55773 2.38547 9.99565 2.00806 10.5212 1.81332C11.0468 1.61859 11.6249 1.61955 12.1498 1.81603C12.6748 2.0125 13.1114 2.39136 13.38 2.88333C13.6485 3.37529 13.731 3.94749 13.6123 4.49527C13.4937 5.04306 13.1818 5.52983 12.7338 5.86657C12.2857 6.20331 11.7314 6.36752 11.1722 6.32917C10.6131 6.29081 10.0864 6.05246 9.68849 5.6577L6.28182 7.5157C6.34978 7.83614 6.34978 8.16727 6.28182 8.4877L9.68849 10.3457C10.0864 9.95094 10.6131 9.71259 11.1722 9.67424C11.7314 9.63588 12.2857 9.80009 12.7338 10.1368C13.1818 10.4736 13.4937 10.9603 13.6123 11.5081C13.731 12.0559 13.6485 12.6281 13.38 13.1201C13.1114 13.612 12.6748 13.9909 12.1498 14.1874C11.6249 14.3839 11.0468 14.3848 10.5212 14.1901C9.99565 13.9953 9.55773 13.6179 9.28755 13.1269C9.01737 12.6358 8.93299 12.0639 9.04982 11.5157Z" fill="#121415"/>
                                        </svg>
                                    </button>
                                </div>
                                {/* Key Features */}
                                <div className='show-features'>
                                    {['bottom'].map((placement, idx) => (
                                        <ProductKeyFeatures key={idx} placement={placement} name={placement} backdrop={true} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mar-spacer h-3'></div>
                <div className='product-specifications'>
                    {/* Colors */}
                    <div className='product-colors'>
                        <h4 className='specification-heading'>Color</h4>
                        <div className='product-colors-box'>
                            <div className='product-colorFrame active'>
                                <div className='product-colorFrame-media'>
                                    <img src={img1} className='img-fluid img d-block m-auto' alt='' />
                                </div>
                                <div className='colorFrame-name'>Awesome Violet</div>
                            </div> 
                        </div>
                    </div>
                    <div className='border-divider'></div>
                    {/* Models */}
                    <div className='product-models'>
                        <h4 className='specification-heading'>Model</h4>
                        <div className='product-models-box'>
                            <div className='product-modelsFrame active'>
                                <span>6GB + 128GB</span>
                            </div>
                        </div>
                    </div>
                    <div className='border-divider'></div>
                    {/* PIN Code */}
                    <div className='product-deliverTo mt-1'>
                        <h4 className='specification-heading'>Deliver To</h4>
                        <div className='deliver-location'>
                            <div className='location-pin'>
                                <span className='d-flex'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.2427 11.5746L8 15.8173L3.75734 11.5746C2.91823 10.7355 2.34679 9.66643 2.11529 8.50255C1.88378 7.33866 2.0026 6.13227 2.45673 5.03591C2.91086 3.93956 3.6799 3.00249 4.66659 2.34321C5.65328 1.68392 6.81332 1.33203 8 1.33203C9.18669 1.33203 10.3467 1.68392 11.3334 2.34321C12.3201 3.00249 13.0891 3.93956 13.5433 5.03591C13.9974 6.13227 14.1162 7.33866 13.8847 8.50255C13.6532 9.66643 13.0818 10.7355 12.2427 11.5746ZM8 8.66531C8.35362 8.66531 8.69276 8.52484 8.94281 8.27479C9.19286 8.02474 9.33334 7.6856 9.33334 7.33198C9.33334 6.97836 9.19286 6.63922 8.94281 6.38917C8.69276 6.13912 8.35362 5.99865 8 5.99865C7.64638 5.99865 7.30724 6.13912 7.05719 6.38917C6.80715 6.63922 6.66667 6.97836 6.66667 7.33198C6.66667 7.6856 6.80715 8.02474 7.05719 8.27479C7.30724 8.52484 7.64638 8.66531 8 8.66531Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span>462026</span>
                            </div>
                            {['bottom'].map((placement, idx) => (
                                <LoggedAddress key={idx} placement={placement} name={placement} user={user} />
                            ))}
                        </div>
                        <p className='mb-0 deliver-info'>Get this product by <span className='deliver-time'>Tomorrow</span>. <span className='deliver-type'>FREE</span></p>
                    </div>
                    <div className='mar-spacer h-3'></div>
                    <RenewedDetails />
                    <div className='mar-spacer h-3'></div>
                    {/* Buttons */}
                    <div className='cart-btns'>
                        <button type='button' className='btn btn-block btn-dark-custom mb-2'>Buy Now</button>
                        {['bottom'].map((placement, idx) => (
                            <AddToCart key={idx} placement={placement} name={placement} />
                        ))}
                    </div>
                    <div className='mar-spacer h-3'></div>
                    {/* Policies */}
                    <div className='product-policies'>
                        <div className='days-policies border-r1'>
                            <span className='policies-icon'>
                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.50065 14.6654C4.81865 14.6654 1.83398 11.6807 1.83398 7.9987C1.83398 4.3167 4.81865 1.33203 8.50065 1.33203C12.1827 1.33203 15.1673 4.3167 15.1673 7.9987C15.1673 11.6807 12.1827 14.6654 8.50065 14.6654ZM8.50065 13.332C9.91514 13.332 11.2717 12.7701 12.2719 11.7699C13.2721 10.7697 13.834 9.41319 13.834 7.9987C13.834 6.58421 13.2721 5.22766 12.2719 4.22746C11.2717 3.22727 9.91514 2.66536 8.50065 2.66536C7.08616 2.66536 5.72961 3.22727 4.72941 4.22746C3.72922 5.22766 3.16732 6.58421 3.16732 7.9987C3.16732 9.41319 3.72922 10.7697 4.72941 11.7699C5.72961 12.7701 7.08616 13.332 8.50065 13.332ZM5.16732 8.66536H11.1673V9.9987H8.50065V11.9987L5.16732 8.66536ZM8.50065 5.9987V3.9987L11.834 7.33203H5.83398V5.9987H8.50065Z" fill="white"/>
                                </svg>
                            </span>
                            <span className='policies-label'>7 days Return</span>
                        </div>
                        <div className='days-policies'>
                            <span className='policies-icon'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.00065 2H14.0007C14.1775 2 14.347 2.07024 14.4721 2.19526C14.5971 2.32029 14.6673 2.48986 14.6673 2.66667V13.3333C14.6673 13.5101 14.5971 13.6797 14.4721 13.8047C14.347 13.9298 14.1775 14 14.0007 14H2.00065C1.82384 14 1.65427 13.9298 1.52925 13.8047C1.40422 13.6797 1.33398 13.5101 1.33398 13.3333V2.66667C1.33398 2.48986 1.40422 2.32029 1.52925 2.19526C1.65427 2.07024 1.82384 2 2.00065 2ZM13.334 7.33333H2.66732V12.6667H13.334V7.33333ZM13.334 6V3.33333H2.66732V6H13.334ZM9.33398 10H12.0007V11.3333H9.33398V10Z" fill="white"/>
                                </svg>
                            </span>
                            <span className='policies-label'>Doorstep EMI</span>
                        </div>
                    </div>
                </div>
                <div className='mar-spacer h-1_5'></div>
                <WorkStructure />
                {/* Bank Offers */}
                <div className='product-detail-offers specification-head px-0'>
                    <h4 className='specification-heading p-x-16'>Offers</h4>
                    <BankOffers />
                </div>
                <div className='product-specifications'>
                    <div className='mar-spacer h-3'></div>
                    {/* Payment Offers */}
                    <PaymentOffers />
                    <div className='mar-spacer h-3'></div>
                    {/* Buy Together */}
                    <div className='product-buy-together specification-head'>
                        <h4 className='specification-heading'>Buy together, save more</h4>
                        <ProductBuyTogether />
                    </div>
                    <div className='mar-spacer h-3'></div>
                </div>

                {/* What's in the box */}
                <div className='product-inbox specification-head px-0'>
                    <h4 className='specification-heading p-x-16'>What’s In The Box?</h4>
                    <h6 className='inbox-info p-x-16'>Samsung Galaxy Fold, Type C Cable, Pin</h6>
                    <ProductBox />
                </div>

                {/* Product Highlights */}
                <div className='product-specifications'>
                    <div className='mar-spacer'></div>
                    <div className='product-highlights'>
                        <ProductHighlights />
                    </div>
                    <div className='mar-spacer'></div>
                </div>

                {/* Why Buy From Sangeetha */}
                <div className='product-inbox specification-head px-0'>
                    <div className='mar-spacer h-1'></div>
                    <h4 className='specification-heading p-x-16'>Why Buy From Sangeetha?</h4>
                    <div className='mar-spacer h-1'></div>
                    <WhyButFromSangeetha />
                    <div className='mar-spacer h-1'></div>
                </div>

                {/* Watch Review Video */}
                <div className='product-specifications'>
                    <div className='mar-spacer h-3'></div>
                    <div className='specification-head'>
                        <h4 className='specification-heading'>Watch Galaxy Fold’s Expert Review</h4>
                        <div className='product-review-wrap'>
                            <div className='review-section'>
                                <img src={VideoThumbnail} className='img img-fluid w-100' alt='' />
                                <div className='review-play-btn'>
                                    <img src={PlayButton} className='img img-fluid' alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mar-spacer h-3'></div>
                </div>

                {/* free demo */}
                <div className='product-free-demo'>
                    <h4 className='specification-heading'>Get a free demo at <br/>your home</h4>
                    <div className='product-offer-wrapper'>
                        <button type='button' className='btn btn-dark-outline'>Book an appointment
                            <span className='ml-2'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.724 5.33333L3.986 11.0713L4.92867 12.014L10.6667 6.276V11.3333H12V4H4.66667V5.33333H9.724Z" fill="#121415"/>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <div className='bg-img-vector'>
                        <svg width="107" height="51" viewBox="0 0 107 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 8L39 43L74 8L109 43L144 8" stroke="white" strokeOpacity="0.2" strokeWidth="10"/>
                        </svg>
                        <svg width="107" height="51" viewBox="0 0 107 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 8L39 43L74 8L109 43L144 8" stroke="white" strokeOpacity="0.2" strokeWidth="10"/>
                        </svg>
                    </div>
                </div>
                <div className='product-specifications specification-head px-0'>
                    <div className='mar-spacer h-3'></div>

                    {/* Sangeetha Recommends */}
                    <h4 className='specification-heading p-x-16'>Sangeetha Recommends</h4>
                    <div className=''>
                        <ProductRecommend deviceType="mobile" wishlistActive={wishlistActive} handleToggle={handleToggle} />
                    </div>
                    <div className='mar-spacer h-3'></div>

                    {/* Accessories for you */}
                    <h4 className='specification-heading p-x-16'>Accessories for you</h4>
                    <div className=''>
                        <ProductAccessories wishlistActive={wishlistActive} handleToggle={handleToggle} />
                    </div>
                    <div className='mar-spacer h-3'></div>

                    {/* Related Categories */}
                    <h4 className='specification-heading p-x-16'>Related Categories</h4>
                    <div className='category-carousel ml--16'>
                        <ProductRelatedCategory />
                    </div>
                    <div className='mar-spacer h-3'></div>
                </div>
            </div>
            <ProductFooter />
        </>
    )
}

export default ProductRenewed