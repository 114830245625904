import React from 'react'
//import LoginAddress from '../Cart/LoginAddress'
import Login from '../Login'

const StickyCart = () => {
    return (
        <>
            <section className="sticky-menus filter-sticky-menus">
                <div className="sticky-price__wrap w-100">
                    <div className='sticky-price'>
                        <div className='sticky-price__total'>₹21,998</div>
                        <div className='sticky-price__label'>Total</div>
                    </div>
                    {['bottom'].map((placement, idx) => (
                        <Login key={idx} placement={placement} name={placement} />
                    ))}
                    
                </div>
            </section>
        </>
    )
}

export default StickyCart
