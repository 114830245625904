import React from 'react';
import PlayEarn from './PlayEarn';

import WheelImg from '../../Images/playEarn/wheel.svg';

const Gamification = () => {
    return (
        <>
            <div className='pay-earn__bg'>
                <div className='pay-earn__flex'>
                    <div className='pay-earn__col d-flex align-items-start flex-column'>
                        <h4 className='pay-earn__heading mb-1'>Play & Earn</h4>
                        <div className='pay-earn__info mb-2' >Win bumper prize upto ₹60,000 with just one tap!</div>
                        {['bottom'].map((placement, idx) => (
                            <PlayEarn key={idx} placement={placement} name={placement} />
                        ))}
                    </div>
                    <div className='pay-earn__media'>
                        <img src={WheelImg} className='img-fluid' style={{ height: '100%'}} alt='' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Gamification