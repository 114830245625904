import {
    GET_PRODUCT_INFO, 
    ERROR_PRODUCT_INFO,
    GET_PRODUCT_COLOR,
    ERROR_PRODUCT_COLOR
} from '../types'

const initialState = {
    error: '',
    errProductColor: '',
    productDetails:{},
    productColors:{},
    productDetailsLoading: true,
    productColorsLoading: true
}

export default function reducer(state = initialState, action) {

    switch(action.type){

    case GET_PRODUCT_INFO:
        return {
            ...state,
            productDetails:action.payload,
            productDetailsLoading:false

        }
    case ERROR_PRODUCT_INFO:
        return{
            productLoading: false, 
            error: action.payload 
        }
    case GET_PRODUCT_COLOR:
        return {
            ...state,
            productColors:action.payload,
            productColorsLoading:false

        }
    case ERROR_PRODUCT_COLOR:
        return{
            productColorsLoading: false, 
            errProductColor: action.payload 
        }
    default: return state
    }

}