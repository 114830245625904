
import Offcanvas from 'react-bootstrap/Offcanvas';
// import img1 from '../../Images/Orders/img1.png';
// import img2 from '../../Images/Orders/img2.png';
// import ChooseCancel from '../../Components/Ordercancellation/ChooseCancel'


import { Link } from "@reach/router";
import React, { useState } from "react";
import OrderCancelStep1 from "../Ordercancellation/OrderCancelStep1";
import OrderCancelStep2 from "../Ordercancellation/OrderCancelStep2";
import OrderCancelStep3 from "../Ordercancellation/OrderCancelStep3";
const OrderCancel1 = ({...props}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [page, setPage] = useState(0);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        username: "",
        nationality: "",
        other: "",
    });
    const stepsTitle = ["Cancel Order?", "Why do you want to cancel?", "Refund Summary"];

    const stepBtn =['Proceed with Cancellation','Confirm Cancellation','Confirm Cancellation']


    const PageDisplay = () => {
        if (page === 0) {
            return <OrderCancelStep1 formData={formData} setFormData={setFormData} />;
        } else if (page === 1) {
            return <OrderCancelStep2 formData={formData} setFormData={setFormData} />;
        } else {
            return <OrderCancelStep3 formData={formData} setFormData={setFormData} />;
        }
    };

    return (
        <>
            <button type="button" className="btn btn-block btn-dark-outline support" variant="primary" onClick={handleShow}>
                <span className="ml-1 contactbtn">Contact Support</span>
            </button>

            <Offcanvas show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>                     
                        <div className="header">
                            <div className="steps-bar"> <span>{stepsTitle[page]}</span></div>
                        </div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="form-container">                              
                        <div className="body">{PageDisplay()}</div>
                    </div>
                    <div className="">
                        <Link className={page === 1 ? 'btn btn-place-order btn-dark-custom w-100' : 'btn btn-place-order btn-dark-custom not-selected- w-100'}
                            to={page === stepsTitle.length - 1 ? '/OrderCancelled' : '' } 
                            onClick={() => {
                                if (page === stepsTitle.length - 1) {
                                    console.log(formData);
                                } else {
                                    setPage((currPage) => currPage + 1);
                                }
                            }}
                        >
                            {stepBtn[page]}
                        </Link>
                    </div>                     
                    <div className="viewcancelpolicybtn"><button type="button" className="text-dark btn m-0 p-0 btn-link">View Cancellation policy</button></div>                 
                </Offcanvas.Body>
            </Offcanvas>

        </>
    )
}

export default OrderCancel1