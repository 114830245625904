import React, { useState } from 'react';
import AddressList from './AddressList';

const Location = () => {
    const [ user ] = useState(true);
    return (
        <>
            <div className='location-wrapper'>
                <div className='location_row'>
                    <div className='location_col'>
                        <div className='location_media d-flex align-items-center justify-content-center'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.2427 11.5761L8 15.8188L3.75734 11.5761C2.91823 10.737 2.34679 9.66789 2.11529 8.50401C1.88378 7.34013 2.0026 6.13373 2.45673 5.03738C2.91086 3.94103 3.6799 3.00396 4.66659 2.34467C5.65328 1.68539 6.81332 1.3335 8 1.3335C9.18669 1.3335 10.3467 1.68539 11.3334 2.34467C12.3201 3.00396 13.0891 3.94103 13.5433 5.03738C13.9974 6.13373 14.1162 7.34013 13.8847 8.50401C13.6532 9.66789 13.0818 10.737 12.2427 11.5761ZM8 8.66678C8.35362 8.66678 8.69276 8.5263 8.94281 8.27625C9.19286 8.0262 9.33334 7.68707 9.33334 7.33344C9.33334 6.97982 9.19286 6.64068 8.94281 6.39064C8.69276 6.14059 8.35362 6.00011 8 6.00011C7.64638 6.00011 7.30724 6.14059 7.05719 6.39064C6.80715 6.64068 6.66667 6.97982 6.66667 7.33344C6.66667 7.68707 6.80715 8.0262 7.05719 8.27625C7.30724 8.5263 7.64638 8.66678 8 8.66678Z" fill="#121415"/>
                            </svg>
                        </div>
                        <div className='location_content'>
                            <span className='location_label'>Deliver to </span>
                            <span className='location_pin'>560017</span>
                        </div>
                    </div>
                    {['bottom'].map((placement, idx) => (
                        <AddressList key={idx} placement={placement} name={placement} user={user} />
                    ))}
                </div>
            </div>
        </>
    )
}

export default Location