import React from 'react';
import { Link } from '@reach/router';
import addressCart from '../Images/product/addressCart.png';
import Headphones from '../Images/product/Headphones.png';
import PopSocket from '../Images/product/PopSocket.png';

const Address = () => {
    return (
        <>
            <div className='cart__wrap'>
                <div className='cart-steps'>
                    {/* Cart Steps */}
                    <ul className='form-steps'>
                        <li className='steps valid'>
                            <span>Cart</span>
                        </li>
                        <li className='steps valid'>
                            <span>Address</span>
                        </li>
                        <li className='steps'>
                            <span>Payment</span>
                        </li>
                    </ul>
                </div>
                <div className='mar-spacer bg-theme' />
                <div className='cart-address__head'>
                    <h6>Delivery Address</h6>
                </div>
                <div className='cart-address__wrap'>
                    <div className='cart-address__default'>
                        <div className='loggedIn__list border-0 mb-0'>
                            <div className='loggedIn__label'>Rohan Nanda <span className='default__address'>(Default)</span></div>
                            <div className="btn btn-login btn-tag">Home</div>
                            <div className='cart-address__section'>
                                <div className='loggedIn-address__label'>
                                    103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103
                                </div>
                                <div className='loggedIn-address__contact'>
                                    <label htmlFor="label">Mobile : </label>
                                    <span>7382901648</span>
                                </div>
                            </div>
                        </div>
                        <div className='cart-address-btn__wrap'>
                            <Link to='/AddAddress' className='btn btn-category w-100'>Change or add address</Link>
                        </div>
                    </div>
                </div>
                <div className='mar-spacer bg-theme' />
                <div className='cart-address__head'>
                    <h6>Delivery Estimates</h6>
                </div>
                <div className='cart-address__delivery'>
                    <div className='address-este__delivery'>
                        <div className='address-este____media'>
                            <img src={addressCart} className='img img-fluid my-2' alt=''/>
                        </div>
                        <div className='address-este__details'>
                            <h5>Galaxy Fold 5g</h5>
                            <p>Awesome White, 128Gb, 8Gb</p>
                            <div className="deliverTo__info">
                                <span>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.91658 4.66667H11.6666L13.4166 7.03267V10.5H12.2295C12.1602 10.9867 11.9175 11.432 11.5462 11.7541C11.1749 12.0763 10.6998 12.2537 10.2083 12.2537C9.71667 12.2537 9.24158 12.0763 8.87027 11.7541C8.49896 11.432 8.25635 10.9867 8.187 10.5H5.2295C5.16016 10.9867 4.91754 11.432 4.54623 11.7541C4.17492 12.0763 3.69984 12.2537 3.20825 12.2537C2.71667 12.2537 2.24159 12.0763 1.87027 11.7541C1.49896 11.432 1.25635 10.9867 1.187 10.5H0.583252V3.50001C0.583252 3.3453 0.64471 3.19692 0.754106 3.08753C0.863503 2.97813 1.01188 2.91667 1.16659 2.91667H9.33325C9.48796 2.91667 9.63633 2.97813 9.74573 3.08753C9.85513 3.19692 9.91658 3.3453 9.91658 3.50001V4.66667ZM9.91658 5.83334V7.58334H12.2499V7.41709L11.0786 5.83334H9.91658Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span className="info__similarProd ml-1">Delivery by &nbsp;</span>
                                <span className="label__similarProd">14 Jan, &nbsp;</span>
                                <span className="deliver-type text-uppercase">Free</span>
                            </div>
                        </div>
                        {/* For Mobile Phones */}
                        <div className='cart-delivery__service'>
                            <h6>You are eligible for our Free Delivery Services</h6>
                            <ul className='delivery-lists__service'>
                                <li className='delivery-list-service'>
                                    <span>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 7V8C5.20435 8 4.44129 8.31607 3.87868 8.87868C3.31607 9.44129 3 10.2044 3 11H2C2 9.93913 2.42143 8.92172 3.17157 8.17157C3.92172 7.42143 4.93913 7 6 7ZM6 6.5C4.3425 6.5 3 5.1575 3 3.5C3 1.8425 4.3425 0.5 6 0.5C7.6575 0.5 9 1.8425 9 3.5C9 5.1575 7.6575 6.5 6 6.5ZM6 5.5C7.105 5.5 8 4.605 8 3.5C8 2.395 7.105 1.5 6 1.5C4.895 1.5 4 2.395 4 3.5C4 4.605 4.895 5.5 6 5.5ZM9 10.75L7.5305 11.5225L7.811 9.8865L6.6225 8.7275L8.2655 8.4885L9 7L9.735 8.4885L11.3775 8.7275L10.189 9.8865L10.469 11.5225L9 10.75Z" fill="white"/>
                                        </svg>
                                    </span>
                                    <label htmlFor="label">Delivery by expert.</label>
                                </li>
                                <li className='delivery-list-service'>
                                    <span>
                                        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.2735 1.94311L5.67974 0.035833C5.56466 -0.0119326 5.43531 -0.0119561 5.32026 0.035833L0.726508 1.94311C0.551547 2.01576 0.4375 2.18658 0.4375 2.37602V4.69458C0.4375 7.88707 2.36709 10.7588 5.32286 11.9652C5.43639 12.0116 5.56359 12.0116 5.67714 11.9652C8.63287 10.7588 10.5625 7.88709 10.5625 4.69458V2.37602C10.5625 2.18658 10.4485 2.01576 10.2735 1.94311ZM9.625 4.69458C9.625 7.40852 8.03125 9.90689 5.5 11.0221C3.03639 9.93668 1.375 7.48207 1.375 4.69458V2.68896L5.5 0.976286L9.625 2.68896V4.69458Z" fill="white"/>
                                            <path d="M5.00498 6.34001L3.87998 5.34L3.25 5.89999L5.005 7.5L7.75 5.05999L7.12002 4.5L5.00498 6.34001Z" fill="white"/>
                                        </svg>
                                    </span>
                                    <label htmlFor="label">Tempered Glass Installation at home</label>
                                </li>
                                <li className='delivery-list-service'>
                                    <span>
                                        <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.3745 3.41124L9.37449 0.411184C9.20709 0.243838 8.93573 0.243888 8.76838 0.411285C8.68805 0.491643 8.64292 0.600579 8.6429 0.714211V1.99995H5.64284C5.40614 1.99995 5.21426 2.19183 5.21426 2.42853C5.21426 2.66524 5.40614 2.85712 5.64284 2.85712H9.07148C9.30819 2.85712 9.50007 2.66524 9.50007 2.42853V1.74878L11.4655 3.71424L9.50007 5.67971V4.99998C9.50007 4.76327 9.30819 4.57139 9.07148 4.57139H4.35711V3.28566C4.35706 3.04895 4.16513 2.85712 3.92845 2.85717C3.81484 2.8572 3.70588 2.90232 3.62552 2.98266L0.625491 5.98271C0.45817 6.15009 0.45817 6.42137 0.625491 6.58872L3.62555 9.58878C3.7059 9.66916 3.81489 9.71434 3.92855 9.71436C3.98487 9.71454 4.0407 9.70346 4.09268 9.68179C4.25279 9.61539 4.35716 9.45912 4.35711 9.28578V8.00004H7.35716C7.59387 8.00004 7.78575 7.80816 7.78575 7.57145C7.78575 7.33475 7.59387 7.14286 7.35716 7.14286H3.92855C3.69184 7.14286 3.49996 7.33475 3.49996 7.57145V8.25118L1.5345 6.28572L3.49996 4.32025V4.99998C3.49996 5.23669 3.69184 5.42857 3.92855 5.42857H8.6429V6.7143C8.64295 6.95101 8.83488 7.14284 9.07156 7.14279C9.18517 7.14276 9.29413 7.09764 9.37449 7.0173L12.3745 4.01725C12.5418 3.84987 12.5418 3.57859 12.3745 3.41124Z" fill="white"/>
                                        </svg>
                                    </span>
                                    <label htmlFor="label">Data Transfer at home</label>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='address-este__delivery'>
                        <div className='address-este____media'>
                            <img src={Headphones} className='img img-fluid my-2' alt='' />
                        </div>
                        <div className='address-este__details'>
                            <h5>boAt Airdopes 131 Bluetooth Headset</h5>
                            <div className="deliverTo__info">
                                <span>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.91658 4.66667H11.6666L13.4166 7.03267V10.5H12.2295C12.1602 10.9867 11.9175 11.432 11.5462 11.7541C11.1749 12.0763 10.6998 12.2537 10.2083 12.2537C9.71667 12.2537 9.24158 12.0763 8.87027 11.7541C8.49896 11.432 8.25635 10.9867 8.187 10.5H5.2295C5.16016 10.9867 4.91754 11.432 4.54623 11.7541C4.17492 12.0763 3.69984 12.2537 3.20825 12.2537C2.71667 12.2537 2.24159 12.0763 1.87027 11.7541C1.49896 11.432 1.25635 10.9867 1.187 10.5H0.583252V3.50001C0.583252 3.3453 0.64471 3.19692 0.754106 3.08753C0.863503 2.97813 1.01188 2.91667 1.16659 2.91667H9.33325C9.48796 2.91667 9.63633 2.97813 9.74573 3.08753C9.85513 3.19692 9.91658 3.3453 9.91658 3.50001V4.66667ZM9.91658 5.83334V7.58334H12.2499V7.41709L11.0786 5.83334H9.91658Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span className="info__similarProd ml-1">Delivery by &nbsp;</span>
                                <span className="label__similarProd">14 Jan, &nbsp;</span>
                                <span className="deliver-type text-uppercase">Free</span>
                            </div>
                        </div>
                    </div>

                    <div className='address-este__delivery'>
                        <div className='address-este____media'>
                            <img src={PopSocket} className='img img-fluid my-2' alt='' />
                        </div>
                        <div className='address-este__details'>
                            <h5>Zapvi Watercolor gradient Phone grip holder and stand</h5>
                            <div className="deliverTo__info">
                                <span>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.91658 4.66667H11.6666L13.4166 7.03267V10.5H12.2295C12.1602 10.9867 11.9175 11.432 11.5462 11.7541C11.1749 12.0763 10.6998 12.2537 10.2083 12.2537C9.71667 12.2537 9.24158 12.0763 8.87027 11.7541C8.49896 11.432 8.25635 10.9867 8.187 10.5H5.2295C5.16016 10.9867 4.91754 11.432 4.54623 11.7541C4.17492 12.0763 3.69984 12.2537 3.20825 12.2537C2.71667 12.2537 2.24159 12.0763 1.87027 11.7541C1.49896 11.432 1.25635 10.9867 1.187 10.5H0.583252V3.50001C0.583252 3.3453 0.64471 3.19692 0.754106 3.08753C0.863503 2.97813 1.01188 2.91667 1.16659 2.91667H9.33325C9.48796 2.91667 9.63633 2.97813 9.74573 3.08753C9.85513 3.19692 9.91658 3.3453 9.91658 3.50001V4.66667ZM9.91658 5.83334V7.58334H12.2499V7.41709L11.0786 5.83334H9.91658Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span className="info__similarProd ml-1">Delivery by &nbsp;</span>
                                <span className="label__similarProd">22 Jan, &nbsp;</span>
                                <span className="deliver-type text-uppercase">Free</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer h-3' />
            <section className="sticky-menus filter-sticky-menus">
                <div className="sticky-price__wrap w-100">
                    <div className='sticky-price'>
                        <div className='sticky-price__total'>₹21,998</div>
                        <div className='sticky-price__label'>View Detailed Bill</div>
                    </div>
                    <button type='button' className='btn btn-place-order btn-dark-custom'>Continue</button>                    
                </div>
            </section>
        </>
    )
}

export default Address