import React, { useState, useEffect } from "react";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TabContent from 'react-bootstrap/TabContent';
import ProductHighlightTab from './ProductHighlightTab';
import ProductManufacturer from './ProductManufacturer';
import SpecificationTab from "./SpecificationTab";
// import StickyTab from './StickyTab';


const ProductSpecificationTab = () => {
    const [key, setKey] = useState('profile');
    const [ stickyTab, setStickyTab] = useState(0);

    const handleScroll = (e) => {
        setStickyTab(e.target.scrollTop > 87);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);      
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>             
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className={stickyTab ? "d-flex justify-content-center tab-sticky-top" : "d-flex justify-content-center"}
            >
                <Tab eventKey="home" title="Highlights">
                    <TabContent>
                        <ProductHighlightTab />
                    </TabContent>
                </Tab>
                <Tab eventKey="profile" title="Specifications">
                    <TabContent>
                        <SpecificationTab />
                    </TabContent >
                </Tab>
                <Tab eventKey="contact" title="Manufacturer">
                    <TabContent>
                        <ProductManufacturer />
                    </TabContent>
                </Tab>
            </Tabs>
        </>
    )
}

export default ProductSpecificationTab