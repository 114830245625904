import React from 'react';
import couponbanner from '../../Images/coupon2.png'


const OrderapologiseDelay = () => {
    return (
        <>
            <div className="order_details-Model apologise">
                <h4 className='Order_Pending-msg'>We apologise for the delay</h4>
            </div>
            <div className="couponapply-para">
            To make it up to you, here’s a ₹1000 coupon on us! Apply it in your cart on your next order.
            </div>
            <div className="couponapply-para-hb"></div>
            <div className="couponbanner">
                <img src={couponbanner} alt="coupon-banner" className='img-fluid'/>
            </div>
        </>
    )
}

export default OrderapologiseDelay;