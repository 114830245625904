import React, { useState } from 'react';
import ProductResult from '../Components/Product/ProductResult'
import ProductNoResult from '../Components/Product/ProductNoResult';

const SearchResult = () => {
    const [isWishlist] = useState(true);
    const [ isResultFound ] = useState(false);

    return (
        <>  
            {isResultFound ?
                <ProductResult isWishlist={isWishlist} /> : <ProductNoResult />
            }
        </>
    )
}

export default SearchResult