import React from 'react';
import Carousel from "react-multi-carousel";
import CustomDot from '../../CustomDot';

import ProductDetail from '../../../../Images/CategoryDetails/Tv_details1.png';

const SmartTvGallery = () => {
    return (
        <>
            <Carousel
                partialVisible={false}
                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="carouser-container"
                customDot={<CustomDot />}
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 1
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 1
                    }
                }}
                showDots
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                <img src={ProductDetail} className='img img-fluid w-100' alt='' />
                <img src='https://images.unsplash.com/photo-1550133730-695473e544be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60' className='img img-fluid w-100' alt='' />
                <img src={ProductDetail} className='img img-fluid w-100' alt='' />
                <img src={ProductDetail} className='img img-fluid w-100' alt='' />
                <img src={ProductDetail} className='img img-fluid w-100' alt='' />
            </Carousel>
        </>
    )
}

export default SmartTvGallery
