import React from 'react';
import Carousel from "react-multi-carousel";
import Category1 from '../../Images/brands/Catg1.png';
import Category2 from '../../Images/brands/Catg2.png';
import Category3 from '../../Images/brands/Catg3.png';
import Category4 from '../../Images/brands/Catg4.png';
import Category5 from '../../Images/brands/Catg5.png';

const PopularBrands = () => {
    const ProductCategory = [
        {   
            _id: 1,
            Image: Category1
        }, {   
            _id: 2,
            Image: Category2
        }, {   
            _id: 3,
            Image: Category3
        }, {   
            _id: 4,
            Image: Category4
        }, {   
            _id: 5,
            Image: Category5
        }
    ];

    const responsive = {
        tablet: {
            breakpoint: { max: 768, min: 567 },
            items: 7,
            partialVisibilityGutter: 0
        },
        mobile: {
            breakpoint: { max: 567, min: 0 },
            items: 5,
            partialVisibilityGutter: 0
        }
    };

    return (
        <>
            <Carousel                
                partialVisible={true}
                arrows={false}
                itemClass="image-item"
                responsive={responsive}
            >   
                {ProductCategory.map(ProductCategory => {
                    return (
                        <div className='product-carousel-wrapper__category' key={ProductCategory._id}>
                            <div className='carousel-media-wrapper__category'>
                                <img
                                    draggable={true}
                                    src={ProductCategory.Image}
                                    alt={ProductCategory.productName}
                                    className='img'
                                />
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </>
    )
}

export default PopularBrands