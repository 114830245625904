import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AxisEmi from '../DebitEmiBanks/AxisEmi';
import HdfcEmi from '../DebitEmiBanks/HdfcEmi';
import SbiEmi from '../DebitEmiBanks/SbiEmi';
import IcIciEmi from '../DebitEmiBanks/IcIciEmi';

import Axis from '../../Images/CategoryDetails/DebitEmi/Axis.png'
import Hdfc from '../../Images/CategoryDetails/DebitEmi/Hdfc.png'
import SBI from '../../Images/CategoryDetails/DebitEmi/Sbi.png'
import ICICI from '../../Images/CategoryDetails/DebitEmi/IcIcI.png'

const DebitEmi = () => {
    const [activeId, setActiveId] = useState('5');

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }
    return (
        <>
            <Accordion className='cart-payment__accordion emi' defaultActiveKey={activeId}>
                <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap' : ' panel-wrap active-panel'}>
                    <Accordion.Header onClick={() => toggleActive('0')}>
                        <span>
                            <img src={Axis} alt="Axis" />                          
                        </span>
                        <div className='payment-card__head debit-cardemi'>
                            <span>Axis</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <AxisEmi />
                    </Accordion.Body>
                </Accordion.Item> 
                <Accordion.Item eventKey="1" className={activeId === '1' ? 'panel-wrap' : ' panel-wrap active-panel'}>
                    <Accordion.Header onClick={() => toggleActive('1')}>
                        <span>
                            <img src={Hdfc} alt="Hdfc" />                          
                        </span>
                        <div className='payment-card__head debit-cardemi'>
                            <span>HDFC</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <HdfcEmi />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className={activeId === '2' ? 'panel-wrap' : ' panel-wrap active-panel'}>
                    <Accordion.Header onClick={() => toggleActive('2')}>
                        <span>
                            <img src={SBI} alt="SBI" />                          
                        </span>
                        <div className='payment-card__head debit-cardemi'>
                            <span>SBI</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <SbiEmi />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className={activeId === '3' ? 'panel-wrap' : ' panel-wrap active-panel'}>
                    <Accordion.Header onClick={() => toggleActive('3')}>
                        <span>
                            <img src={ICICI} alt="ICICI" />                          
                        </span>
                        <div className='payment-card__head debit-cardemi'>
                            <span>ICICI</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <IcIciEmi />
                    </Accordion.Body>
                </Accordion.Item>                                   
            </Accordion>
        
        </>
    )
}

export default DebitEmi