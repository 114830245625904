import React from 'react';

export const NavAddToCart = () => {
    return (
        <>
            <a href="/Cart" className="navbar-tool">
                <div className="navbar-tool-icon">
                    <svg width="23" height="23" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 12V3H1.5V1.5H3.75C3.94891 1.5 4.13968 1.57902 4.28033 1.71967C4.42098 1.86032 4.5 2.05109 4.5 2.25V11.25H13.8285L15.3285 5.25H6V3.75H16.29C16.404 3.75 16.5165 3.776 16.619 3.826C16.7214 3.87601 16.8111 3.94871 16.8813 4.03859C16.9514 4.12847 17.0001 4.23315 17.0237 4.34468C17.0473 4.45622 17.0452 4.57166 17.0175 4.68225L15.1425 12.1823C15.1019 12.3444 15.0082 12.4884 14.8764 12.5913C14.7446 12.6941 14.5822 12.75 14.415 12.75H3.75C3.55109 12.75 3.36032 12.671 3.21967 12.5303C3.07902 12.3897 3 12.1989 3 12ZM4.5 17.25C4.10217 17.25 3.72064 17.092 3.43934 16.8107C3.15804 16.5294 3 16.1478 3 15.75C3 15.3522 3.15804 14.9706 3.43934 14.6893C3.72064 14.408 4.10217 14.25 4.5 14.25C4.89782 14.25 5.27936 14.408 5.56066 14.6893C5.84196 14.9706 6 15.3522 6 15.75C6 16.1478 5.84196 16.5294 5.56066 16.8107C5.27936 17.092 4.89782 17.25 4.5 17.25ZM13.5 17.25C13.1022 17.25 12.7206 17.092 12.4393 16.8107C12.158 16.5294 12 16.1478 12 15.75C12 15.3522 12.158 14.9706 12.4393 14.6893C12.7206 14.408 13.1022 14.25 13.5 14.25C13.8978 14.25 14.2794 14.408 14.5607 14.6893C14.842 14.9706 15 15.3522 15 15.75C15 16.1478 14.842 16.5294 14.5607 16.8107C14.2794 17.092 13.8978 17.25 13.5 17.25Z" fill="white"/>
                    </svg>
                    <div className='cart-qty'>1</div>
                </div>
            </a>
        </>
    );
};

export default NavAddToCart