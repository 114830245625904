import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { OffersList } from '../../Data';

const CartBankOfferList = ({ ...props }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div  role="button" className='offer-link' onClick={handleShow} aria-hidden="true">6 More Offers</div>
            <Offcanvas show={show} onHide={handleClose} {...props} className='notify-me__offCanvas-height'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Bank Offers</Offcanvas.Title>
                </Offcanvas.Header>
                <div className='cart-horizontal-line m-0'></div>
                <Offcanvas.Body>
                    <div className='product-specifications- bank-offer-wrapper'>
                        <div className='product-payment-offers'>  
                            {OffersList.map((OffersItems, idx) => {                      
                                return(
                                    <div to='/BankOffersDetails' className='payment-offer-options' key={idx}>
                                        <div className='payment-offer-content'>
                                            <div className='payment-offer-icon'>
                                                <span>
                                                    <img src={OffersItems.Image} className='img im-fluid' alt='' />
                                                </span>
                                            </div>
                                            <div className='payment-offer-info'>
                                                <p className='payment-offer-details'>{OffersItems.Text}</p>
                                            </div>
                                        </div>
                                        <div className='highlight-body__link ml-2'>Apply</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default CartBankOfferList