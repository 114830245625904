import { Link } from '@reach/router'; 
import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';

import address from '../../Images/address.png';

const AddressList = ({ user, ...props }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <button type='button' className='btn location-link' onClick={handleShow}>Edit</button>

            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Select Delivery Address</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='input-group-address'>
                        <div className='cart-address__wrap'>
                            <input type='number' className='form-control form-control-cart' placeholder='' />
                            <button type='button' className='btn btn-dark-button absolute-cart-input btn-space'>Check</button> 
                        </div>
                    </div>
                    <div className='cart-horizontal-line'></div>
                    {user ? 
                        <div className='address-loggedIn-section'>
                            <h5 className='address-loggedIn__head'>Saved Address</h5>
                            <div className='loggedIn__wrap'>
                                <div className='loggedIn__lists'>
                                    <div className='loggedIn__list'>
                                        <div className='loggedIn__checkbox'>
                                            <div className='coupon-list__input mt-1'>
                                                <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" />
                                            </div>
                                        </div>
                                        <div className='loggedIn__addr'>
                                            <h6 className='loggedIn__label'>Rohan Nanda</h6>
                                            <p className='loggedIn__info'>103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103</p>
                                        </div>
                                        <div className='loggedIn__tag'>
                                            <div className='btn btn-login btn-tag'>Home</div>
                                        </div>
                                    </div>
                                    <div className='loggedIn__list'>
                                        <div className='loggedIn__checkbox'>
                                            <div className='coupon-list__input mt-1'>
                                                <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" />
                                            </div>
                                        </div>
                                        <div className='loggedIn__addr'>
                                            <h6 className='loggedIn__label'>Rohan Nanda</h6>
                                            <p className='loggedIn__info'>103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103</p>
                                        </div>
                                        <div className='loggedIn__tag'>
                                            <div className='btn btn-login btn-tag'>Home</div>
                                        </div>
                                    </div>
                                    <div className='loggedIn__list'>
                                        <div className='loggedIn__checkbox'>
                                            <div className='coupon-list__input mt-1'>
                                                <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" />
                                            </div>
                                        </div>
                                        <div className='loggedIn__addr'>
                                            <h6 className='loggedIn__label'>Rohan Nanda</h6>
                                            <p className='loggedIn__info'>103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103</p>
                                        </div>
                                        <div className='loggedIn__tag'>
                                            <div className='btn btn-login btn-tag'>Home</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> :
                        <div className='address-loggedOut-section'>
                            <div className='address-icon'>
                                {/* <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="60" cy="60" r="60" fill="#F5F7FB"/>
									<g clipPath="url(#clip0_817_38482)">
										<path d="M84.7134 46.8637C88.802 49.2243 91.7855 53.1124 93.0074 57.6727C94.2293 62.2329 93.5896 67.0918 91.2291 71.1805L85.2954 81.4579C82.9348 85.5466 79.0467 88.53 74.4864 89.7519C69.9261 90.9739 65.0672 90.3342 60.9786 87.9736L35.2849 73.1393C31.1963 70.7788 28.2128 66.8907 26.9909 62.3304C25.769 57.7701 26.4087 52.9112 28.7693 48.8226L34.703 38.5451C37.0635 34.4565 40.9516 31.473 45.5119 30.2511C50.0722 29.0292 54.9311 29.6689 59.0197 32.0295L84.7134 46.8637ZM59.3107 49.3266L54.172 46.3597L51.2051 51.4985L46.0664 48.5316L43.0995 53.6703L48.2357 56.6357L45.2714 61.7759L50.4101 64.7428L53.3744 59.6026L58.5157 62.5709L61.4826 57.4322L56.3438 54.4653L59.3107 49.3266ZM73.9319 71.4714L68.7932 68.5046L65.8264 73.6433L70.9651 76.6102L73.9319 71.4714ZM74.7269 58.2271L69.5882 55.2603L66.6213 60.399L71.7601 63.3659L74.7269 58.2271Z" fill="#C4CAD0"/>
									</g>
									<defs>
										<clipPath id="clip0_817_38482">
											<rect width="71.2044" height="71.2044" fill="white" transform="translate(46.9688 11.3672) rotate(30)"/>
										</clipPath>
									</defs>
								</svg> */}
                                <img src={address} className='img-fluid' alt='' />
                            </div>
                            {/* <div className='cart-info__text'>Login to view saved addresses</div> */}
                            <div className='cart-info__text'>You haven’t added any address yet</div>
                            <Link to='/' className='btn btn-login'>Add Address</Link>
                        </div>                        
                    }
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default AddressList