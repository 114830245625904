import { Link } from '@reach/router';
import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

const CategoryListAccordion = () => {
    const [activeId, setActiveId] = useState('0');

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }
    return (
        <>
            <Accordion className='cart-payment__accordion categories__accordion' defaultActiveKey={activeId}>
                <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('0')} className='px-0'>
                        <div className='payment-card__head m-0'>
                            <span>Air Quality Managers</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='item-item__category'>
                            <Link to='/' className='item-links__category'>Air Quality Managers</Link>
                            <Link to='/' className='item-links__category'>Air Quality Managers</Link>
                            <Link to='/' className='item-links__category'>Air Quality Managers</Link>
                            <Link to='/' className='item-links__category'>Air Quality Managers</Link>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className={activeId === '1' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('1')} className='px-0'>
                        <span>
                            <img src='' className='img img-fluid' alt='' />
                        </span>
                        <div className='payment-card__head m-0'>
                            <span>Bike Accessories</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='item-item__category'>
                            <Link to='/' className='item-links__category'>Bike Accessories</Link>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className={activeId === '2' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('2')} className='px-0'>
                        <span>
                            <img src='' className='img img-fluid' alt='' />
                        </span>
                        <div className='payment-card__head m-0'>
                            <span>Camera Accessories</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='item-item__category'>
                            <Link to='/' className='item-links__category'>Camera Accessories</Link>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className={activeId === '3' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('3')} className='px-0'>
                        <span>
                            <img src='' className='img img-fluid' alt='' />
                        </span>
                        <div className='payment-card__head m-0'>
                            <span>Car Accessories</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='item-item__category'>
                            <Link to='/' className='item-links__category'>Car Accessories</Link>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className={activeId === '4' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('4')} className='px-0'>
                        <span>
                            <img src='' className='img img-fluid' alt='' />
                        </span>
                        <div className='payment-card__head m-0'>
                            <span>Data Storage</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='item-item__category'>
                            <Link to='/' className='item-links__category'>Data Storage</Link>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className={activeId === '5' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('5')} className='px-0'>
                        <span>
                            <img src='' className='img img-fluid' alt='' />
                        </span>
                        <div className='payment-card__head m-0'>
                            <span>Desktops</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='item-item__category'>
                            <Link to='/' className='item-links__category'>Desktops</Link>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default CategoryListAccordion