import { Link } from '@reach/router';
import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import HighlistImage1 from '../../../Images/CategoryDetails/heighlight/earphone-highlight1.png';
import HighlistImage2 from '../../../Images/CategoryDetails/heighlight/earphone-highlight2.png';
import HighlistImage3 from '../../../Images/CategoryDetails/heighlight/earphone-highlight3.png';
import HighlistImage4 from '../../../Images/CategoryDetails/heighlight/earphone-highlight4.png';
import HighlistImage5 from '../../../Images/CategoryDetails/heighlight/earphone-highlight5.png';

const EarphoneHighlightTab = () => {
    const [activeId, setActiveId] = useState('0');

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    const ProductHighlightsTab = [
        {   
            _id: 0,
            Image: HighlistImage1,
            TabName: '650 mAh Carrying Case ',
            info: 'Interruptions during entertainment can be dissapointing and that is why these earbuds from boAt come with a carrying cum charging case that offers upto 12 hours of playback time wit.'
        }, {   
            _id: 1,
            Image: HighlistImage2,
            TabName: 'Bluetooth v5.0',
            info: 'Enjoy a stable wireless connection with the Bluetooth v5.0 technology featured on the boAt Airdopes 441 that delivers true wireless real time sound. '
        }, {   
            _id: 2,
            Image: HighlistImage3,
            TabName: 'Instant voice assistant ',
            info: 'Accessing the voice assistant function is easy as all it takes is a single press of access multifunction buttons featured on the Airdopes 441. '
        }, {
            _id: 3,
            Image: HighlistImage4,
            TabName: 'IWP Technology ',
            info: 'The Insta Wake N’ Pair technology featired in the Airdopes 441 automatically powers on the earbuds once the carry case is opened.'
        }, {
            _id: 4,
            Image: HighlistImage5,
            TabName: 'USB Type-C',
            info: 'You can use the Type-C port to charge the carrying case that also acts as a portable charging unit with an inbuilt battery. '
        },
    ];

    return (
        <>  
            <div className='highlight-tab__wrap specifications-tab'>
                {ProductHighlightsTab && ProductHighlightsTab.map((HighlightsTab) => {
                    return (
                        <Accordion defaultActiveKey={HighlightsTab._id}  key={HighlightsTab._id}>
                            <div className="mar-spacer h-1"></div>
                            <Accordion.Item eventKey={HighlightsTab._id} className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                                <Accordion.Header onClick={() => toggleActive('0')}>{HighlightsTab.TabName}</Accordion.Header>
                                <Accordion.Body>
                                    <div className='highlight-body__wrap'>
                                        <p className='highlight-body__info'>This Samsung mobile phone features an Infinity Flex Display with an Under Display Camera, allowing the device to function both as a smartphone and tablet, As a result, you can experienc a...</p>
                                        <Link to='/' className='highlight-body__link'>Read More</Link>
                                        <div className='mar-spacer h-1_2'></div>
                                        <img src={HighlightsTab.Image} className='img img-fluid' alt='' />
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    );
                })}
            </div>
        </>
    )
}

export default EarphoneHighlightTab;