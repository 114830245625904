import React, { useState } from 'react';
import icon1 from '../../Images/Home/whySangeetha/InTransit.svg';
import icon2 from '../../Images/Home/whySangeetha/Breakable.svg';
import icon3 from '../../Images/Home/whySangeetha/Valet.svg';
import icon4 from '../../Images/Home/whySangeetha/Shop.svg';
import icon5 from '../../Images/Home/whySangeetha/HelpingHand.svg';
import CheckeleigiblityModel from './CheckeleigiblityModel';

const WhySangeetha = () => {
    const [ offerEligible ] = useState(true);
    return (
        <>
            <section className='why-sangeetha__home'>
                <div className="top-head__wrap px-0">
                    <h4 className="brand-heading">Why buy from Sangeetha?</h4>
                    <p className='sangeetha-home__text m-0'>A unique delivery experience with Sangeetha</p>
                </div>
                <div className='sangeetha__flex'>
                    <div className='sangeetha-lists__flex'>
                        <div className='sangeetha-media__icon'>
                            <img src={icon1} className='img-fluid' alt='' />
                        </div>
                        <span className='ml-3'>2 Hour Delivery</span>
                    </div>
                    <div className='sangeetha-lists__flex'>
                        <div className='sangeetha-media__icon'>
                            <img src={icon2} className='img-fluid' alt='' />
                        </div>
                        <span className='ml-3'>Services at your doorstep</span>
                    </div>
                    <div className='sangeetha-lists__flex'>
                        <div className='sangeetha-media__icon'>
                            <img src={icon3} className='img-fluid' alt='' />
                        </div>
                        <span className='ml-3'>Expert Guidance</span>
                    </div>
                    <div className='sangeetha-lists__flex'>
                        <div className='sangeetha-media__icon'>
                            <img src={icon4} className='img-fluid' alt='' />
                        </div>
                        <span className='ml-3'>700+ Stores</span>
                    </div>
                    <div className='sangeetha-lists__flex'>
                        <div className='sangeetha-media__icon'>
                            <img src={icon5} className='img-fluid' alt='' />
                        </div>
                        <span className='ml-3'>Post Sales Support</span>
                    </div>
                </div>
                <div className='mar-spacer h-1_2' />
                {offerEligible ? 
                    <div className='sangeetha-eligible__offer'>
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.2427 12.0756L8 16.3183L3.75734 12.0756C2.91823 11.2365 2.34679 10.1674 2.11529 9.00352C1.88378 7.83964 2.0026 6.63324 2.45673 5.53689C2.91086 4.44054 3.6799 3.50347 4.66659 2.84418C5.65328 2.1849 6.81332 1.83301 8 1.83301C9.18669 1.83301 10.3467 2.1849 11.3334 2.84418C12.3201 3.50347 13.0891 4.44054 13.5433 5.53689C13.9974 6.63324 14.1162 7.83964 13.8847 9.00352C13.6532 10.1674 13.0818 11.2365 12.2427 12.0756ZM8 9.16629C8.35362 9.16629 8.69276 9.02581 8.94281 8.77576C9.19286 8.52572 9.33334 8.18658 9.33334 7.83296C9.33334 7.47933 9.19286 7.14019 8.94281 6.89015C8.69276 6.6401 8.35362 6.49962 8 6.49962C7.64638 6.49962 7.30724 6.6401 7.05719 6.89015C6.80715 7.14019 6.66667 7.47933 6.66667 7.83296C6.66667 8.18658 6.80715 8.52572 7.05719 8.77576C7.30724 9.02581 7.64638 9.16629 8 9.16629Z" fill="#121415"/>
                        </svg>
                        <div className='sangeetha-eligible__pin ml-2'>
                            <span>Delivery</span> 560017
                        </div>
                        <div className='sangeetha-eligible__link ml-2'>Edit</div>
                    </div> : (
                        <div>
                            {['bottom'].map((placement, idx) => ( <CheckeleigiblityModel key={idx} placement={placement} name={placement}/> ))}

                        </div>)
                                 
                }
            </section>

           
        </>
    )
}

export default WhySangeetha