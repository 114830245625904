import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import screensize2 from '../../Images/CategoryDetails/filter/screensize2.png';

const ScreenSizeGuide = () => {
   
    const [activeId, setActiveId] = useState('1');

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    };

   
    
    return (
        <>
            <Accordion defaultActiveKey={activeId}>
                <div className='accordion-border'>
                    <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                        <Accordion.Header onClick={() => toggleActive('0')} className="filteraccordian_heading">Buying guide to screen size</Accordion.Header>
                        <Accordion.Body>
                            <div className="filter-screen-guide_size-body">
                                <p className='filter-screen_guide-para'>Faster clock speeds mean that you’ll see tasks ordered from your CPU completed quicker, making your experience seamless and reducing the time you wait to interface with your favorite applications and programs.</p>

                                <div className="screen_card_main">
                                    <div className="filter_screen_card1">
                                        <div className="filter_screen-img">
                                            <img src={screensize2} alt="" />
                                        </div>
                                        <div className="filter_screenaccordian-head">
                               Low
                                        </div>
                                        <div className="filter_screeen_description">
                               Web browsing, email and some social networking.
                                        </div>
                                    </div>   
                                    <div className="filter_screen_card2">
                                        <div className="filter_screen-img">
                                            <img src={screensize2} alt="" />
                                        </div>
                                        <div className="filter_screenaccordian-head">
                               Medium
                                        </div>
                                        <div className="filter_screeen_description">
                               Web browsing, email and some social networking.
                                        </div>
                                    </div>                            
                                </div>
                            </div>
                     
                        </Accordion.Body>
                    </Accordion.Item>
                </div>  
            </Accordion>
        </>
    )
}

export default ScreenSizeGuide;