import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccessoriesHighlightCarousel from '../../CategoryDetails/Accessories/AccessoriesHighlightCarousel';
import ProductPolicy from './../../ProductPolicy';
import { Link } from '@reach/router';


const AccessoriesHighlight = () => {

    const [activeId, setActiveId] = useState('1');

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    return (
        <>             
            <Accordion defaultActiveKey={activeId}>
                <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('0')}>Specifications</Accordion.Header>
                    <Accordion.Body>
                        <div className='product-specification__otherInfo'>
                            <h6 className='specification-info__head mt-0'> Details</h6>
                            <div className='specification-info__table'>
                                <table className='table specification-striped__table'>
                                    <tbody>
                                        <tr>
                                            <th>Size</th>
                                            <td>6.5 x 5 x 6.5 cm</td>
                                        </tr>
                                        <tr>
                                            <th>Weight </th>
                                            <td>318 grams</td>
                                        </tr>
                                        <tr>
                                            <th>Compatibility</th>
                                            <td>Headphones</td>
                                        </tr>
                                        <tr>
                                            <th>Number of Items </th>
                                            <td>1</td>
                                        </tr>
                                        <tr>
                                            <th>Speacial Feature</th>
                                            <td>Lightweight</td>
                                        </tr>
                                        <tr>
                                            <th>Warranty</th>
                                            <td>1</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Link to='/CategoryDetails/ProductSpecifications' className='specification-info__head text-decoration-underline'>View All Details</Link>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className={activeId === '1' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('1')}>Highlights</Accordion.Header>
                    <Accordion.Body>
                        <div className='product-highlight__otherInfo'>
                            <AccessoriesHighlightCarousel />
                            <Link to='/EarphoneFullSpecification' className='specification-info__head text-decoration-underline'>View More Highlights</Link>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className={activeId === '2' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('2')}>Cancellation & Return Policy</Accordion.Header>
                    <Accordion.Body>
                        <ProductPolicy />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default AccessoriesHighlight;