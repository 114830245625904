import React from 'react'

const RatingProduct = () => {
    return (
        <>
            <div className="order_detail-cart__vas ">
                <div className="shopping-vas__info">
                    <div className="rating-vas__media">
                        <span className='rate_txt'>
                        Rate Product
                        </span>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="shopping-vas__head reating_start"> 
                            <span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.99911 15.2165L4.12161 18.5065L5.43411 11.8998L0.488281 7.3265L7.17745 6.53317L9.99911 0.416504L12.8208 6.53317L19.5099 7.3265L14.5641 11.8998L15.8766 18.5065L9.99911 15.2165ZM9.99911 13.3065L13.5383 15.2873L12.7474 11.3098L15.7249 8.55567L11.6974 8.07817L9.99911 4.39567L8.30078 8.079L4.27328 8.55567L7.25078 11.3098L6.45995 15.2873L9.99911 13.3065Z" fill="#C4CAD0"/></svg>
                            </span>
                            <span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.99911 15.2165L4.12161 18.5065L5.43411 11.8998L0.488281 7.3265L7.17745 6.53317L9.99911 0.416504L12.8208 6.53317L19.5099 7.3265L14.5641 11.8998L15.8766 18.5065L9.99911 15.2165ZM9.99911 13.3065L13.5383 15.2873L12.7474 11.3098L15.7249 8.55567L11.6974 8.07817L9.99911 4.39567L8.30078 8.079L4.27328 8.55567L7.25078 11.3098L6.45995 15.2873L9.99911 13.3065Z" fill="#C4CAD0"/></svg>
                            </span>
                            <span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.99911 15.2165L4.12161 18.5065L5.43411 11.8998L0.488281 7.3265L7.17745 6.53317L9.99911 0.416504L12.8208 6.53317L19.5099 7.3265L14.5641 11.8998L15.8766 18.5065L9.99911 15.2165ZM9.99911 13.3065L13.5383 15.2873L12.7474 11.3098L15.7249 8.55567L11.6974 8.07817L9.99911 4.39567L8.30078 8.079L4.27328 8.55567L7.25078 11.3098L6.45995 15.2873L9.99911 13.3065Z" fill="#C4CAD0"/></svg>
                            </span>
                            <span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.99911 15.2165L4.12161 18.5065L5.43411 11.8998L0.488281 7.3265L7.17745 6.53317L9.99911 0.416504L12.8208 6.53317L19.5099 7.3265L14.5641 11.8998L15.8766 18.5065L9.99911 15.2165ZM9.99911 13.3065L13.5383 15.2873L12.7474 11.3098L15.7249 8.55567L11.6974 8.07817L9.99911 4.39567L8.30078 8.079L4.27328 8.55567L7.25078 11.3098L6.45995 15.2873L9.99911 13.3065Z" fill="#C4CAD0"/></svg>
                            </span>
                            <span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.99911 15.2165L4.12161 18.5065L5.43411 11.8998L0.488281 7.3265L7.17745 6.53317L9.99911 0.416504L12.8208 6.53317L19.5099 7.3265L14.5641 11.8998L15.8766 18.5065L9.99911 15.2165ZM9.99911 13.3065L13.5383 15.2873L12.7474 11.3098L15.7249 8.55567L11.6974 8.07817L9.99911 4.39567L8.30078 8.079L4.27328 8.55567L7.25078 11.3098L6.45995 15.2873L9.99911 13.3065Z" fill="#C4CAD0"/></svg>
                            </span>
                        </div>
                    </div>
                </div>    
                        
                            
            </div>
        </>
    )
}

export default RatingProduct