import React, { useState } from "react";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TabContent from 'react-bootstrap/TabContent';
import OffersOffcanvas from './OffersOffcanvas';

import { OffersList } from '../../Data';

const BankOffersList = () => {   
    const [key, setKey] = useState('1');
    return (
        <>  
            <div className='product-specifications-section'>
                <div className="product-details__tabs">
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="d-flex justify-content-center"
                    >
                        <Tab eventKey="1" title="Bank Offers">
                            <TabContent>
                                <div className='product-specifications bank-offer-wrapper'>
                                    <div className='product-payment-offers'>  
                                        {OffersList.map((OffersItems, idx) => {                      
                                            return(
                                                <div className='payment-offer-options' key={idx}>
                                                    <div className='payment-offer-content'>
                                                        <div className='payment-offer-icon'>
                                                            <span>
                                                                <img src={OffersItems.Image} className='img img-fluid' alt='' />
                                                            </span>
                                                        </div>
                                                        <div className='payment-offer-info'>
                                                            <p className='payment-offer-details'>{OffersItems.Text}</p>
                                                        </div>
                                                    </div>
                                                    <div className='payment-offer-link'>
                                                        {['bottom'].map((placement, idx) => (
                                                            <OffersOffcanvas key={idx} placement={placement} name={placement} />
                                                        ))}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </TabContent>
                        </Tab>
                        <Tab eventKey="2" title="Brand Offers">
                            <TabContent>
                                <div className="tab-specific debitcard">
                                    2
                                </div>
                            </TabContent >
                        </Tab>
                        <Tab eventKey="3" title="EMI Offers">
                            <TabContent>
                                <div className="tab-specific ">
                                    3
                                </div>
                            </TabContent>
                        </Tab>
                    </Tabs>  
                </div> 
            </div>   
        </>
    )
}

export default BankOffersList;