import React from 'react'

const CustomDot = ({ onClick, active }) => {
    return (
        <li
            className={active ? "active" : "inactive"}
            onClick={() => onClick()}
            aria-hidden="true"
        >
            <span></span>
        </li>        
    )
}

export default CustomDot
