import React from 'react';
import { Link } from '@reach/router';
import HowExchangeWorks from './HowExchangeWorks';

import coupon from '../../../Images/coupon/coupon.svg';
import MyCouponDetails from '../../Account/MyCouponDetails';

import Banner2 from '../../../Images/coupon/banner2.png';
import coupon1 from '../../../Images/coupon/coupon1.png';

const Step7 = () => {
    return (
        <>
            <div className='px-3'>
                <div className='exchange__result'>
                    <div className='exchange__content'>
                        <div className='exchange__greet'>Hurray! </div>
                        <div className='exchange__text'>Your phone’s total exchange value is</div>

                        <div className='exchange__price'>₹21,000</div>
                    </div>
                    <div className='exchange__media'>
                        <img src={coupon} className='img-fluid' alt='' />
                    </div>
                </div>
            </div>
            <div className='exchange__val px-3'>
                <div className='exchange-label__val'>
                    <span>Device Value</span>
                    <span>₹20,000</span>
                </div>
                <div className='exchange-label__val'>
                    <span>Exchange Bonus</span>
                    <span className='exchange-valid'>₹1000</span>
                </div>
            </div>
            <div className='px-3'>
                <div className='deviderclass-border' />
                <div className='coupon_checkout'>Use this coupon during checkout</div>
                <div className='coupon-card__wrapper'>
                    <img src={Banner2} className='img-fluid w-100 coupon-img' alt='' />
                    <div className='coupon-card__bg'>
                        <div className='coupon-card__content'>
                            <h5>Yay! ₹3000 off </h5>
                            <p>Sangeetha Exchange Bonus</p>
                            <hr className='hr-coupon' />
                            <div className='coupon-card__info'>VALIDITY: only on current purchase</div>
                            {['bottom'].map((placement, idx) => (
                                <MyCouponDetails key={idx} placement={placement} name={placement} />
                            ))} 
                        </div>
                        <div className='coupon-card__media'>
                            <img src={coupon1} className='img img-fluid' alt='' />
                        </div>
                    </div>
                </div>
                <div className='deviderclass-border mt-4' />
                <div className='d-flex align-items-start justify-content-between'>
                    <div className='coupon_exchange'>
                        <span>Your Device for Exchange</span>
                        <div className='info-variant'>OnePlus | OnePlus Nord 6GB + 128GB</div>
                    </div>
                    <Link to='/' className='exchange_link'>Edit</Link>
                </div>
                <div className='deviderclass-border mt-4' />
                <HowExchangeWorks />
                <div className='deviderclass-border mt-4' />
                <div className='work_structure p-0'>
                    <h6 className='work-head_structure'>Old phone will be accepted only if</h6>
                    <ul className='work-list_wrap'>
                        <li>Phone must be in a working condition</li>
                        <li>During pick up, IMEI number must match the one submitted</li>
                        <li>iCloud or screen lock needs to be disabled</li>
                        <li>Phone must be free from physical defects like cracks, dents, or scratches on the body</li>
                        <li>Phone’s screen must be working, and free from damages</li>
                    </ul>
                </div>
                <div className='exchange__tip'>
                    <h6 className='work-head_structure mb-2'>Exchange Tip</h6>
                    <div className='exchange-info__tip'>If your exchange is rejected, you may still pay the pending discount amount at the time of delivery and receive your new phone</div>
                </div>
                <div className='mar-spacer h-1_5' />
            </div>
            <div className='mar-spacer h-3 mt-3' />
        </>
    )
}

export default Step7