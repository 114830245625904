import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import VasUser1 from '../../Images/vas/users/1.png';
import VasUser2 from '../../Images/vas/users/2.png';
import VasUser3 from '../../Images/vas/users/3.png';

const WhySangeethaExplore = () => {
    const [activeId, setActiveId] = useState('0');

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }
    return (
        <>
            <div className='sangeetha-explore__wrap mt-2'>
                <Accordion className='cart-payment__accordion categories__accordion' defaultActiveKey={activeId}>
                    <Accordion.Item eventKey="0" className={`panel-wrap font-500 ${activeId === '0' ? 'active-panel' : ''}`}>
                        <Accordion.Header onClick={() => toggleActive('0')}>
                            <div className='payment-card__head m-0 d-flex align-items-center flex-row'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.783 2.826L12 1L20.217 2.826C20.4391 2.87536 20.6377 2.99897 20.78 3.1764C20.9224 3.35384 21 3.57452 21 3.802V13.789C20.9999 14.7767 20.756 15.7492 20.2899 16.62C19.8238 17.4908 19.1499 18.2331 18.328 18.781L12 23L5.672 18.781C4.85027 18.2332 4.17646 17.4911 3.71035 16.6205C3.24424 15.7498 3.00024 14.7776 3 13.79V3.802C3.00004 3.57452 3.07764 3.35384 3.21999 3.1764C3.36234 2.99897 3.56094 2.87536 3.783 2.826ZM13 10V5L8 12H11V17L16 10H13Z" fill="#121415"/>
                                </svg>
                                <span className='ml-2'>Money and Damage Protection</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className='p-0'>
                            <div className='sangeetha-vas__wrap p-x-16'>
                                <div className='sangeetha_vasWrap'>
                                    <div className='sangeetha-vas__lists'>
                                        <div className='sangeetha-vas__list'>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66649 10.115L12.7945 3.98633L13.7378 4.92899L6.66649 12.0003L2.42383 7.75766L3.36649 6.81499L6.66649 10.115Z" fill="#121415"/>
                                            </svg>
                                            <span className='ml-2'>Free repairs in case of liquid, physical and fire damage </span>
                                        </div>
                                        <div className='sangeetha-vas__list'>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66649 10.115L12.7945 3.98633L13.7378 4.92899L6.66649 12.0003L2.42383 7.75766L3.36649 6.81499L6.66649 10.115Z" fill="#121415"/>
                                            </svg>
                                            <span className='ml-2'>Incase the phone is irrepaireable, we will replace it for you at 50% the cost</span>
                                        </div>
                                        <div className='sangeetha-vas__list'>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66649 10.115L12.7945 3.98633L13.7378 4.92899L6.66649 12.0003L2.42383 7.75766L3.36649 6.81499L6.66649 10.115Z" fill="#121415"/>
                                            </svg>
                                            <span className='ml-2'>Refunds against reciepts in case the phone cannot be repaired at the Sangeetha Store</span>
                                        </div>
                                        <div className='sangeetha-vas__list'>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66649 10.115L12.7945 3.98633L13.7378 4.92899L6.66649 12.0003L2.42383 7.75766L3.36649 6.81499L6.66649 10.115Z" fill="#121415"/>
                                            </svg>
                                            <span className='ml-2'>Refund your Protection Plan at Sangeetha if you don’t claim it!</span>
                                        </div>
                                    </div>
                                    <div className='vas-list__users'>
                                        <div className='vas__user'>
                                            <img src={VasUser1} className='img-fluid' alt='' />
                                        </div>
                                        <div className='vas__user'>
                                            <img src={VasUser2} className='img-fluid' alt='' />
                                        </div>
                                        <div className='vas__user'>
                                            <img src={VasUser3} className='img-fluid' alt='' />
                                        </div>
                                        <div className='vas__user'>
                                            <span>+30K</span>
                                        </div>
                                        <div className='var-user__stars'>Rated this 4.5 Stars</div>
                                    </div>
                                </div>
                                <div className='vas-amount__details'>
                                    <div className='vas-amount__price'>Starts at ₹999</div>
                                    <div className='vas-amount__text'>
                                        <span>Validity</span> 12 Months
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className={`panel-wrap font-500 ${activeId === '1' ? 'active-panel' : ''}`}>
                        <Accordion.Header onClick={() => toggleActive('1')} >
                            <div className='payment-card__head m-0 d-flex align-items-center flex-row'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.4995 5.67025V3.75C20.4995 2.92275 19.8268 2.25 18.9995 2.25H11.7065C11.4395 2.25 11.1885 2.354 10.9995 2.543L1.49953 12.043C1.10953 12.433 1.10953 13.0672 1.49953 13.457L9.29254 21.25C9.48729 21.445 9.74354 21.5425 9.99954 21.5425C10.2555 21.5425 10.5118 21.445 10.7065 21.25L15.5518 16.4047C16.342 17.3972 17.3725 18 18.4995 18C20.9808 18 22.9995 15.0842 22.9995 11.5C22.9995 9.00075 22.026 6.7545 20.4995 5.67025ZM18.4995 17C16.5695 17 14.9995 14.5327 14.9995 11.5C14.9995 9.9175 15.4418 8.40675 16.2128 7.3555C16.3758 7.13275 16.3278 6.81975 16.105 6.6565C15.882 6.4935 15.5695 6.54175 15.406 6.76425C15.0285 7.279 14.724 7.8875 14.4925 8.54975C14.0405 8.1835 13.7495 7.627 13.7495 7C13.7495 5.8955 14.645 5 15.7495 5C16.854 5 17.7495 5.8955 17.7495 7C17.7495 7.83275 17.2405 8.546 16.5168 8.847C16.185 9.6175 15.9995 10.5375 15.9995 11.5C15.9995 12.97 16.405 14.191 16.9673 14.9892L20.2065 11.75C20.3955 11.561 20.4995 11.31 20.4995 11.043V7.00625C21.42 8.0275 21.9995 9.703 21.9995 11.5C21.9995 14.5327 20.4295 17 18.4995 17Z" fill="black"/>
                                </svg>
                                <span className='ml-2'>Assured Buy Back</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className='p-0'>
                            <div className='sangeetha-vas__wrap p-x-16'>
                                <div className='sangeetha_vasWrap'>
                                    <div className='sangeetha-vas__lists'>
                                        <div className='sangeetha-vas__list'>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66649 10.115L12.7945 3.98633L13.7378 4.92899L6.66649 12.0003L2.42383 7.75766L3.36649 6.81499L6.66649 10.115Z" fill="#121415"/>
                                            </svg>
                                            <span className='ml-2'>Pay a One Time fee to sell your phone back to Sangeetha at a price based on the purchase.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='vas-amount__details'>
                                    <div className='vas-amount__price'>Starts at ₹349</div>
                                    <div className='vas-amount__text'>
                                        <span>Validity</span> 12 -24 Months
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className={`panel-wrap font-500 ${activeId === '2' ? 'active-panel' : ''}`}>
                        <Accordion.Header onClick={() => toggleActive('2')} >
                            <div className='payment-card__head m-0 d-flex align-items-center flex-row'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_4478_107062)">
                                        <path d="M11.8609 9.93H11.8709L16.7709 8.26C16.7909 8.17 16.7909 8.08 16.7909 8C16.7909 7.91 16.7809 7.83 16.7509 7.75C16.6709 7.52 16.5209 7.27 16.3109 7.09V4.9C16.3109 3.28 15.7309 2.64 15.1309 2.27C14.8209 1.33 13.5309 0 11.0009 0C8.00087 0 5.74087 2.97 5.74087 4.9C5.74087 5.7 5.71087 6.33 5.68087 6.81C5.68087 6.91 5.67087 7 5.67087 7.08C5.45087 7.28 5.30087 7.55 5.23087 7.81C5.22087 7.87 5.21087 7.93 5.21087 8C5.21087 8.78 5.65087 9.91 5.71087 10.04C5.77087 10.21 5.90087 10.35 6.07087 10.43C6.08087 10.47 6.09087 10.53 6.09087 10.65C6.09087 11.71 7.00087 12.71 7.50087 13.19C7.45087 14.29 7.14087 15.05 6.70087 15.24L2.78086 16.54C1.67086 16.91 0.840863 17.81 0.550863 18.95L0.0208624 21.07C-0.0291376 21.29 0.0208624 21.53 0.160862 21.71C0.300863 21.89 0.520863 22 0.750863 22H11.9609C11.6609 21.62 11.3809 21.2 11.1209 20.75C10.4609 19.58 10.0009 18.16 10.0009 16.55V12.54C10.0009 11.36 10.7509 10.32 11.8609 9.93Z" fill="black"/>
                                        <path d="M23.491 11.8265L18.241 10.0405C18.085 9.9865 17.915 9.9865 17.759 10.0405L12.509 11.8265C12.204 11.9295 12 12.2145 12 12.5365V16.5545C12 21.4585 17.474 23.8425 17.707 23.9415C17.895 24.0205 18.105 24.0205 18.293 23.9415C18.526 23.8415 24 21.4585 24 16.5545V12.5365C24 12.2145 23.796 11.9295 23.491 11.8265ZM21.286 15.6185L18.536 19.1185C18.186 19.5655 17.53 19.6305 17.099 19.2605L15.349 17.7605C14.929 17.4015 14.88 16.7695 15.241 16.3505C15.6 15.9315 16.232 15.8835 16.65 16.2425L17.608 17.0635L19.713 14.3845C20.054 13.9505 20.682 13.8735 21.117 14.2165C21.552 14.5555 21.628 15.1845 21.286 15.6185Z" fill="black"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4478_107062">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <span className='ml-2'>Theft Protection</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className='p-0'>
                            <div className='sangeetha-vas__wrap p-x-16'>
                                <div className='sangeetha_vasWrap'>
                                    <div className='sangeetha-vas__lists'>
                                        <div className='sangeetha-vas__list'>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66649 10.115L12.7945 3.98633L13.7378 4.92899L6.66649 12.0003L2.42383 7.75766L3.36649 6.81499L6.66649 10.115Z" fill="#121415"/>
                                            </svg>
                                            <span className='ml-2'>Pay a One Time fee, provide an FIR and install the Sangeetha App on your phone to get a brand new phone upon losing your older phone.  </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='vas-amount__details'>
                                    <div className='vas-amount__price'>Starts at ₹349</div>
                                    <div className='vas-amount__text'>
                                        <span>Validity</span> 1 -12 Months
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" className={`panel-wrap font-500 ${activeId === '3' ? 'active-panel' : ''}`}>
                        <Accordion.Header onClick={() => toggleActive('3')} >
                            <div className='payment-card__head m-0 d-flex align-items-center flex-row'>
                                <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.153 1.89075L10.153 0.01575C10.0521 -0.00525 9.94792 -0.00525 9.84705 0.01575L0.847047 1.89075C0.499234 1.96322 0.25 2.26973 0.25 2.625V12.9485C0.25 14.5028 0.64825 16.0429 1.40172 17.4023C2.15519 18.7618 3.25009 19.9158 4.56812 20.7395L9.6025 23.886C9.72409 23.962 9.86205 24 10 24C10.138 24 10.2759 23.962 10.3975 23.886L15.4319 20.7395C16.7499 19.9158 17.8448 18.7618 18.5983 17.4023C19.3517 16.0429 19.75 14.5028 19.75 12.9485V2.625C19.75 2.26973 19.5007 1.96322 19.153 1.89075ZM10.75 16.5808V16.8538C10.75 17.2576 10.4396 17.6051 10.0363 17.6242C9.60555 17.6444 9.25 17.3012 9.25 16.875V16.5819C9.25 16.479 9.1825 16.3892 9.08439 16.3581C8.04864 16.0291 7.2498 15.1542 7.04828 14.0368C6.97431 13.6267 7.22917 13.218 7.63834 13.1388C8.05412 13.0583 8.45167 13.3363 8.52109 13.7512C8.64798 14.509 9.32416 15.0493 10.1051 14.9965C10.843 14.9466 11.4418 14.35 11.4959 13.6125C11.5602 12.7347 10.8643 12 10 12C8.34578 12 7 10.6542 7 9C7 7.66458 7.87708 6.53016 9.08547 6.14255C9.18306 6.11123 9.25 6.02166 9.25 5.91919V5.64619C9.25 5.24236 9.56036 4.89488 9.96372 4.87584C10.3944 4.85555 10.75 5.19877 10.75 5.625V5.91811C10.75 6.021 10.8175 6.11072 10.9156 6.14189C11.963 6.47461 12.7682 7.36561 12.9583 8.5012C13.0267 8.90972 12.751 9.29634 12.3424 9.36473C11.9337 9.43327 11.5473 9.15741 11.4789 8.74884C11.352 7.99102 10.6758 7.45078 9.89486 7.50356C9.157 7.55344 8.55817 8.15002 8.50412 8.88759C8.43981 9.76523 9.13572 10.5 10 10.5C11.6542 10.5 13 11.8458 13 13.5C13 14.8354 12.1229 15.9698 10.9145 16.3575C10.8169 16.3888 10.75 16.4783 10.75 16.5808Z" fill="black"/>
                                </svg>
                                <span className='ml-2'>Price Protection</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className='p-0'>
                            <div className='sangeetha-vas__wrap p-x-16'>
                                <div className='sangeetha_vasWrap'>
                                    <div className='sangeetha-vas__lists'>
                                        <div className='sangeetha-vas__list'>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66649 10.115L12.7945 3.98633L13.7378 4.92899L6.66649 12.0003L2.42383 7.75766L3.36649 6.81499L6.66649 10.115Z" fill="#121415"/>
                                            </svg>
                                            <span className='ml-2'>Pay a One Time fee to accomodate for price drop for 90 days after purchase.</span>
                                        </div>
                                        <div className='sangeetha-vas__list'>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66649 10.115L12.7945 3.98633L13.7378 4.92899L6.66649 12.0003L2.42383 7.75766L3.36649 6.81499L6.66649 10.115Z" fill="#121415"/>
                                            </svg>
                                            <span className='ml-2'>Sangeetha refunds you the difference between the price drop and the price at which you bought your phone.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='vas-amount__details'>
                                    <div className='vas-amount__price'>Starts at ₹99</div>
                                    <div className='vas-amount__text'>
                                        <span>Validity</span> 6 Months
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    )
}

export default WhySangeethaExplore