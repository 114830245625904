import React from 'react'

const NavWishlist = () => {
    return (
        <>
            <a href="/Wishlist" className="navbar-tool">
                <div className="navbar-tool-icon">
                    <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.8847 9.59549L15.8944 9.60599L9.0004 16.5L2.1064 9.60599L2.11615 9.59549C1.31558 8.66215 0.897285 7.46075 0.945096 6.23203C0.992907 5.0033 1.50329 3.83804 2.37397 2.96973C3.24465 2.10142 4.4113 1.59422 5.64015 1.54976C6.869 1.5053 8.06925 1.92687 9.0004 2.72999C9.93155 1.92687 11.1318 1.5053 12.3607 1.54976C13.5895 1.59422 14.7562 2.10142 15.6268 2.96973C16.4975 3.83804 17.0079 5.0033 17.0557 6.23203C17.1035 7.46075 16.6852 8.66215 15.8847 9.59549ZM3.43165 4.03724C3.11825 4.35063 2.86965 4.72269 2.70003 5.13217C2.53042 5.54165 2.44312 5.98052 2.44312 6.42374C2.44312 6.86695 2.53042 7.30583 2.70003 7.7153C2.86965 8.12478 3.11825 8.49684 3.43165 8.81024L9.0004 14.379L14.5692 8.81024C15.2021 8.1773 15.5577 7.31885 15.5577 6.42374C15.5577 5.52862 15.2021 4.67018 14.5692 4.03724C13.9362 3.4043 13.0778 3.04872 12.1827 3.04872C11.2875 3.04872 10.4291 3.4043 9.79615 4.03724L6.6139 7.21949L5.5534 6.15749L7.9249 3.78599C7.27632 3.26799 6.45959 3.00717 5.63082 3.05338C4.80205 3.09959 4.01938 3.44959 3.4324 4.03649L3.43165 4.03724Z" fill="white"/>
                    </svg>
                </div>
            </a>
        </>
    )
}

export default NavWishlist
