import React from 'react';
import CartCouponList from './CartCouponList';
import CartCouponApplied from './CartCouponApplied';

const CartCoupon = ({ isCouponApplied }) => {
    return (
        <>
            {isCouponApplied ?
                <CartCouponApplied /> :
                <div className='cart-coupon'>
                    <div className='cart-coupon__head'>
                        <div className='coupon-tag'>
                            <span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.26624 1.3999L13.8656 2.34324L14.8082 8.94324L8.68024 15.0712C8.55522 15.1962 8.38568 15.2664 8.20891 15.2664C8.03213 15.2664 7.86259 15.1962 7.73757 15.0712L1.13757 8.47124C1.01259 8.34622 0.942383 8.17668 0.942383 7.9999C0.942383 7.82313 1.01259 7.65359 1.13757 7.52857L7.26624 1.3999ZM9.15157 7.05724C9.27541 7.18103 9.42242 7.27922 9.5842 7.3462C9.74598 7.41318 9.91938 7.44764 10.0945 7.4476C10.2696 7.44757 10.443 7.41305 10.6047 7.34602C10.7665 7.27898 10.9134 7.18074 11.0372 7.0569C11.161 6.93307 11.2592 6.78606 11.3262 6.62428C11.3932 6.46249 11.4276 6.2891 11.4276 6.114C11.4276 5.9389 11.3931 5.76552 11.326 5.60376C11.259 5.442 11.1607 5.29503 11.0369 5.17124C10.9131 5.04744 10.7661 4.94925 10.6043 4.88227C10.4425 4.8153 10.2691 4.78084 10.094 4.78087C9.74037 4.78093 9.40125 4.92147 9.15124 5.17157C8.90123 5.42167 8.76081 5.76084 8.76087 6.11447C8.76094 6.4681 8.90147 6.80723 9.15157 7.05724Z" fill="#121415"/>
                                </svg>
                            </span>
                            <span>Coupons</span>
                        </div>
                        <span className='coupon-link'>
                            {['bottom'].map((placement, idx) => (
                                <CartCouponList key={idx} placement={placement} name={placement} />
                            ))}
                        </span>
                    </div>
                    <div className='input-group-address'>
                        <div className='cart-address__wrap'>
                            <input type="number" className='form-control form-control-cart' placeholder='Enter coupon code here'/>
                            <button type='button' className='btn btn-dark-button absolute-cart-input btn-space'>Apply</button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default CartCoupon