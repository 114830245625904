//Images
import HDFCIcon from './Images/product/emi/bankIcon.svg';
import AxisIcon from './Images/product/emi/AxisIcon.svg';
import SBIIcon from './Images/product/emi/SBIIcon.svg';
import paytmIcon from './Images/product/emi/paytmIcon.svg';
import SimpleIcon from './Images/product/emi/SimpleIcon.svg';
//CardlessEMI
import Zestpay from './Images/product/emi/zest.png';
import loantap from './Images/product/emi/loantap.png'
import Simpl from './Images/product/emi/Simpl.png'
import Zebpay from './Images/product/emi/Zebpay.png'

import Discount from './Images/product/emi/Discount.png'
import Group from './Images/product/emi/Group.png'
import hdfc from './Images/product/emi/hdfc.png';

import  productImg from './Images/product/productImg.png'
import TypeCConnector from './Images/product/TypeCConnector.png';
import SponsoredBanner from './Images/product/SponsoredBanner.png';
import  ProductReno from './Images/CategoryDetails/filter/Product_filterImg.png'
import AppleIphonePro from './Images/product/product-1.png';
import Samsung from './Images/CategoryDetails/filter/ProductSamsungFilter.png';

// watchimgs
import watch1 from './Images/CategoryDetails/ProductList/Watch/RedmiWatch.png';
import watch2 from './Images/CategoryDetails/ProductList/Watch/AppleWatch.png';
import watch3 from './Images/CategoryDetails/ProductList/Watch/FireBolt.png';
import watch4 from './Images/CategoryDetails/ProductList/Watch/Fitbit.png';

// mobilecovers
import Cover1 from './Images/CategoryDetails/ProductList/Mobilecover/cover1.png';
import Cover2 from './Images/CategoryDetails/ProductList/Mobilecover/cover2.png';
import Cover3 from './Images/CategoryDetails/ProductList/Mobilecover/cover3.png';
import Cover4 from './Images/CategoryDetails/ProductList/Mobilecover/cover4.png';

// HeadphoneList
import Earphones1 from './Images/CategoryDetails/ProductList/headphone/Earphones1.png';
import Earphones2 from './Images/CategoryDetails/ProductList/headphone/Earphone2.png';

// header Search
import searchProduct from './Images/CategoryDetails/search/iphone_recomended.png';
import searchProduct2 from './Images/CategoryDetails/search/laptop_search.png';
import searchProduct3 from './Images/CategoryDetails/search/Earphone_Search.png';

export const user = [
    {
        "_id": 1,
        "Name": "Test User"		
    }, {
        "_id": 2,
        "Name": "Test2"		
    }, {
        "_id": 3,
        "Name": "Test3"		
    }
]

export const productsData = [
    {
        "_id": "1",
        "Image": productImg,
        "productName": "Apple iPhone 13 Pro",
        "prductBrand": "Apple",
        "productColor": "Awesome Violet",
        "productStorage": {
            "ram": "169GB",
            "Storage": "128GB"
        },
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": "Sponsored"
    }, {
        "_id": "2",
        "Image": productImg,
        "productName": "Galaxy Fold 5G",
        "productInfo": 'Black, 128TB + 8GB',
        "prductBrand": "Apple",
        "productColor": "Awesome Violet",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        },
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ''
    }, {
        "_id": "3",
        "Image": productImg,
        "productName": "Apple iPhone 13 Pro",
        "productInfo": 'Black, 128TB + 8GB',
        "prductBrand": "Apple",
        "productColor": "Awesome Violet",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        },
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": "Sponsored"
    }, {
        "_id": "4",
        "Image": productImg,
        "productName": "Xiaomi 11T Pro 5G Hyperphone",
        "productInfo": 'Meteorite Black 256GB + 8GB',
        "prductBrand": "Apple",
        "productColor": "Awesome Violet",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        },
        "productNewPrice": 119999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ''        
    }
]
export const BrandproductsData = [
    {
        "_id": "1",
        "Image": productImg,
        "productName": "Mi 11X Pro 5G",
        "prductBrand": "Apple",
        "productColor": "Purple",
        "productStorage": {
            "ram": "8GB",
            "Storage": "1GB"
        },
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": "Sponsored"
    }, {
        "_id": "2",
        "Image": productImg,
        "productName": "Mi Earphones Basic (with in-built mic) Black.",
        "productInfo": 'Black, 128TB + 8GB',
        "prductBrand": "Apple",
        "productColor": "Awesome Violet",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        },
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ''
    }, {
        "_id": "3",
        "Image": productImg,
        "productName": "Redmi Note 11T 5G",
        "productInfo": 'Black, 128TB + 8GB',
        "prductBrand": "Apple",
        "productColor": "Awesome Violet",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        },
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": "Sponsored"
    }, {
        "_id": "4",
        "Image": productImg,
        "productName": "Xiaomi 11T Pro 5G Hyperphone",
        "productInfo": 'Meteorite Black 256GB + 8GB',
        "prductBrand": "Apple",
        "productColor": "Awesome Violet",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        },
        "productNewPrice": 119999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ''        
    }, {
        "_id": "5",
        "Image": productImg,
        "productName": "MI WATCH REVOLVE ACTIVE",
        "productInfo": 'Meteorite Black 256GB + 8GB',
        "prductBrand": "Apple",
        "productColor": "Green Strap, Free Size",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "productNewPrice": 119999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ''        
    }
]
export const SearchData = [
    {
        "_id": "1",
        "Image": searchProduct,
        "productName": "Apple iPhone 13 Pro",
        "prductBrand": "Apple",
        "productColor": "Graphite",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        }
    }, {
        "_id": "2",
        "Image": searchProduct2,
        "productName": "ASUS TUF Gaming Laptop F15",
        "productInfo": 'Core i5, 512GB SSD, 10th Gen',
        "prductBrand": "ASUS",
        "productColor": "Core i5, 512GB SSD, 10th Gen",
        "productStorage": {
            "ram": "8GB",
            "genaration":"Core i5",
            "Storage": "512GB SSD"
        }
    }, {
        "_id": "3",
        "Image": searchProduct3,
        "productName": "boAt Airdopes Bluetooth Eardbuds",
        "productInfo": 'Black, 128TB + 8GB',
        "prductBrand": "Apple",
        "productColor": "Midnight Blue, True Wireless",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        }
    }
]
export const productsFillerData = [
    {
        "_id": "1",
        "Image": AppleIphonePro,
        "productName": "Apple iPhone 13 Pro",
        "prductBrand": "Apple",
        "productColor": "Awesome Violet",
        "productStorage": {
            "ram": "169GB",
            "Storage": "128GB"
        },
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": "Sponsored"
    }, {
        "_id": "2",
        "Image": ProductReno,
        "productName": "Oppo Reno 6",
        "productInfo": 'Black, 128TB + 8GB',
        "prductBrand": "Oppo",
        "productColor": "Black",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        },
        "productNewPrice": 119999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ''
    }, {
        "_id": "3",
        "Image": Samsung,
        "productName": "Samsung Galaxy FoldZ 5G",
        "productInfo": 'Black, 128TB + 8GB',
        "prductBrand": "Apple",
        "productColor": "Black",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        },
        "productNewPrice": 119999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": "Sponsored"
    }, {
        "_id": "4",
        "Image": productImg,
        "productName": "Xiaomi 11T Pro 5G Hyperphone",
        "productInfo": 'Meteorite Black 256GB + 8GB',
        "prductBrand": "Apple",
        "productColor": "Awesome Violet",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        },
        "productNewPrice": 119999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ''        
    }
]
export const SmartWatchData = [
    {
        "_id": "1",
        "Image": watch1,
        "productName": "Fire-Boltt Talk Bluetooth C...",
        "prductBrand": "Fire-Boltt",
        "productColor": "Teal Strap, 46",
        "productStorage": {
            "ram": "169GB",
            "Storage": "128GB"
        },
        "productNewPrice": 2999,
        "productOldPrice": 5559,
        "productOffer": "52% Off",
        "label": "Top Seller"
    }, {
        "_id": "2",
        "Image": watch2,
        "productName": "APPLE Watch SE GPS",
        "productInfo": 'White, Regular',
        "prductBrand": "APPLE",
        "productColor": "Black",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        },
        "productNewPrice": 2999,
        "productOldPrice": 5559,
        "productOffer": "52% Off",
        "label": ''
    }, {
        "_id": "3",
        "Image": watch3,
        "productName": "Noise ColorFit Brio",
        "productInfo": 'Green',
        "prductBrand": "Noise",
        "productColor": "Black",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        },
        "productNewPrice": 2999,
        "productOldPrice": 5559,
        "productOffer": "52% Off",
        "label": "Sponsored"
    }, {
        "_id": "4",
        "Image": watch4,
        "productName": "DIZO Watch 2 by Realme",
        "productInfo": 'Grey Strap, Free Size',
        "prductBrand": "DIZO",
        "productColor": "Awesome Violet",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        },
        "productNewPrice": 2999,
        "productOldPrice": 5559,
        "productOffer": "52% Off",
        "label": ''        
    }
]
export const MobileAccessoriesData = [
    {
        "_id": "1",
        "Image": Cover1,
        "productName": "Wellpoint Back Cover for Apple iPhone 12 Pro, 6.1 INCH ONLY  (Transparent, Grip Case)",
        "prductBrand": "Apple iPhone 12 Pro",
        "productColor": "",
        "productStorage": {
            "ram": "169GB",
            "Storage": "128GB"
        },
        "productNewPrice":199,
        "productOldPrice":559,
        "productOffer": "71% Off",
        "label": "Sponsored"
    }, {
        "_id": "2",
        "Image": Cover2,
        "productName": "Ultra Hybrid Case Cover For OnePlus 7 Pro",
        "productInfo": 'White, Regular',
        "prductBrand": "",
        "productColor": "",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        },
        "productNewPrice": 199,
        "productOldPrice": 559,
        "productOffer": "52% Off",
        "label": ''
    }, {
        "_id": "3",
        "Image": Cover3,
        "productName": "HUPSHY Back Cover for Samsung Galaxy M12, Samsung Galaxy F12, Samsung Galaxy",
        "productInfo": 'Green',
        "prductBrand": "",
        "productColor": "",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        },
        "productNewPrice": 199,
        "productOldPrice": 559,
        "productOffer": "52% Off",
        "label": "Sponsored"
    }, {
        "_id": "4",
        "Image": Cover4,
        "productName": "WEBKREATURE Back Cover for Realme C12, Realme Narzo 20, Realme Narzo 30A, Realme",
        "productInfo": '',
        "prductBrand": "",
        "productColor": "",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        },
        "productNewPrice": 199,
        "productOldPrice": 559,
        "productOffer": "52% Off",
        "label": ''        
    }
]
export const HeadPhoneData = [
    {
        "_id": "1",
        "Image": Earphones2,
        "productName": "boAt Bassheads 900 Wired On Ear Headphones with Mic (Carbon Black)",
        "prductBrand": "Apple iPhone 12 Pro",
        "productColor": "",
        "productStorage": {
            "ram": "169GB",
            "Storage": "128GB"
        },
        "productNewPrice":1999,
        "productOldPrice":3559,
        "productOffer": "50% Off",
        "label": "Sponsored"
    }, {
        "_id": "2",
        "Image": Earphones1,
        "productName": "Oneplus Bullets Wireless Z Bass Edition Bluetooth, Reverb Red, In the Ear",
        "productInfo": 'White, Regular',
        "prductBrand": "",
        "productColor": "",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        },
        "productNewPrice": 1999,
        "productOldPrice": 3559,
        "productOffer": "52% Off",
        "label": 'Top Seller'
    }, {
        "_id": "3",
        "Image": Earphones2,
        "productName": "Oneplus Bullets Wireless Z Bass Edition Bluetooth, Reverb Red, In the Ear",
        "productInfo": 'Green',
        "prductBrand": "",
        "productColor": "",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        },
        "productNewPrice": 1999,
        "productOldPrice": 3559,
        "productOffer": "52% Off",
        "label": "Sponsored"
    }, {
        "_id": "4",
        "Image": Earphones1,
        "productName": "boAt Bassheads 900 Wired On Ear Headphones with Mic (Carbon Black)",
        "productInfo": '',
        "prductBrand": "",
        "productColor": "",
        "productStorage": {
            "ram": "8GB",
            "Storage": "128GB"
        },
        "productNewPrice": 199,
        "productOldPrice": 559,
        "productOffer": "52% Off",
        "label": ''        
    }
]

export const ProductAccessoriesData = [
    {   
        Image: TypeCConnector,
        productName: 'Logitech Type C to USB 3.5mm convertor',
        productNewPrice: 99,
        productOldPrice: 120,
        label: '15% Off'
    }, {   
        Image: TypeCConnector,
        productName: 'Zapvi Watercolor gradient Phone grip holder and stand',
        productNewPrice: 200,
        productOldPrice: 559,
        label: ''
    }, {   
        Image: TypeCConnector,
        productName: 'Apple iPhone 13 Pro',
        productNewPrice: 89999,
        productOldPrice: 93559,
        label: '15% Off'
    }
];


export const BankOfferData = [
    {   
        _id: 1,
        Title: 'Bank Offers',
        OfferDetails: '5% Cashback on SANGEETHA AXIS Bank Credit Card on orders above ₹1800 '
    }, {   
        _id: 2,
        Title: 'No Cost EMI',
        OfferDetails: 'Upto ₹5,599 EMI interest savings on select Credit cards'
    }
];

export const CartBankOfferData = [
    {   
        _id: 1,
        Icon: Discount,
        Title: 'Bank Offers',
        Image: hdfc,
        OfferDetails: '10% Instant Savings on Citi Credit and Debit Cards on a min of ₹2,999 TCA'
    }, {   
        _id: 2,
        Icon: Group,
        Title: 'Cardless EMI',
        Image: Zestpay,
        OfferDetails: 'Buy your new phone @ ₹2515/month and Save ₹2,999 on interest'
    }
];

export const OffersList = [
    {   
        Image: HDFCIcon,
        Text: '10% Instant Savings on Citi Credit and Debit Cards on a min of ₹2,999 TCA'
    }, {   
        Image: AxisIcon,
        Text: 'Use code TRYNEW & get 30% off on orders above ₹480. Maximum discount: ₹48'
    }, {   
        Image: SBIIcon,
        Text: 'Use code TRYNEW & get 30% off on orders above ₹480. Maximum discount: ₹48'
    }, {   
        Image: paytmIcon,
        Text: 'Use code TRYNEW & get 30% off on orders above ₹480. Maximum discount: ₹48'
    }, {   
        Image: SimpleIcon,
        Text: 'Use code TRYNEW & get 30% off on orders above ₹480. Maximum discount: ₹48'
    }
];

export const CardlessEMIData = [
    {   
        _id: 1,
        Img: Zestpay,
        Title: 'Zestpay',
        EMIInfo: 'Save upto ₹2,450 on interest'
    }, {   
        _id: 2,
        Img: loantap,
        Title: 'Loantap',
        EMIInfo: 'Save upto ₹2,450 on interest'
    }, {   
        _id: 3,
        Img: Simpl,
        Title: 'Simpl',
        EMIInfo: 'Save upto ₹2,450 on interest'
    }, {   
        _id: 4,
        Img: Zebpay,
        Title: 'Zebpay',
        EMIInfo: 'Save upto ₹1,450 on interest'
    }
];

export const SponsoredPhones = [
    {   
        _id: 1,
        Image: SponsoredBanner,
        Name: 'OnePlus 9 5G',
        Price: 42000 
    }, {   
        _id: 2,
        Image: SponsoredBanner,
        Name: 'Samsung A2',
        Price: 32000 
    }, {   
        _id: 3,
        Image: SponsoredBanner,
        Name: 'OnePlus 9 5G',
        Price: 42000 
    }
];
