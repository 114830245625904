import React from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import COD from '../../Images/product/COD.png';

const CODPayment = () => {
    return (
        <>
            <div className='cod__wrap d-flex flex-column align-items-start'>
                <img src={COD} className='img img-fluid' alt='' />
                <button type="button" className="text-dark btn mt-1 p-0 btn-link">Change Image</button>
            </div>
            <div className='mar-spacer h-1' />
            <div className='address-form__details p-0'>
                <div className='form-group-custom'>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Enter code shown in above image*"
                    >
                        <Form.Control type="text" className='form-control form-control-custom' placeholder='Enter code shown in above image*' defaultValue='Rohan' />
                    </FloatingLabel>
                </div>
                <p className='cod-info__wrap'>You can pay via Card/Cash or UPI enabled app at the time of delivery. Ask your delivery executive for these options.</p>
            </div>
        </>
    )
}

export default CODPayment