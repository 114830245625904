import React,{useState} from 'react';
import Form from 'react-bootstrap/Form';

// import Photography from '../../Images/DIY/1.svg'
// import Networking from '../../Images/DIY/2.svg'
// import Gaming from '../../Images/DIY/3.svg'
// import Music from '../../Images/DIY/4.svg'
// import Travelling from '../../Images/DIY/5.svg'
// import Office from '../../Images/DIY/6.svg'


const OrderCancelStep2 = ({formData, setFormData}) => {
    // const availableCategories = [
    //     {
    //         __id : 1,
    //         name: 'Photography',
    //         status: 'unchecked',
    //         Image: Photography
    //     }, {
    //         __id : 2,
    //         name: 'Social Networking',
    //         status: 'checked',
    //         Image: Networking
    //     }, {
    //         __id : 3,
    //         name: 'Gaming',
    //         status: 'unchecked',
    //         Image: Gaming
    //     }, {
    //         __id : 4,
    //         name: 'Music',
    //         status: 'checked',
    //         Image: Music
    //     }, {
    //         __id : 5,
    //         name: 'Travelling',
    //         status: 'unchecked',
    //         Image: Travelling
    //     }, {
    //         __id : 6,
    //         name: 'Office',
    //         status: 'unchecked',
    //         Image: Office
    //     }
    // ];
    const [chartbox, setChartbox]=useState(false);
    const Chartboxclick =()=>setChartbox(true)
    return (
        <>
            <div className='filter-tab__lists sort__by'>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox sort__checkbox ' aria-label="option 1" type='radio' name='radio'/>
                        <label htmlFor="label" className='filter_sort cancelsort'>Wrong Product specifications </label>
                    </div>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox sort__checkbox' aria-label="option 1" type='radio' name='radio'/>
                        <label htmlFor="label" className='filter_sort cancelsort'>Change address or phone number</label>
                    </div>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox sort__checkbox' aria-label="option 1"type='radio' name='radio'/>                    
                        <label htmlFor="label" className='filter_sort cancelsort'>Changed My Mind</label>
                    </div>
                </div>
                <div className='filter-tab__list'>  
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox sort__checkbox' aria-label="option 1" type='radio' name='radio'/>
                        <label htmlFor="label" className='filter_sort cancelsort'>Changed My Mind</label>                
                    </div>
                </div>
                <div className='filter-tab__list'>  
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox sort__checkbox' aria-label="option 1" type='radio' name='radio'/>
                        <label htmlFor="label" className='filter_sort cancelsort'>Bought it from somewhere else</label>                
                    </div>
                </div>
                <div className='filter-tab__list'>  
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox sort__checkbox' aria-label="option 1" type='radio' name='radio'/>
                        <label htmlFor="label" className='filter_sort cancelsort'>Needed an earlier Delivery time</label>                
                    </div>
                </div>
                <div className='filter-tab__list'>  
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox sort__checkbox' aria-label="option 1" type='radio' name='radio' onClick={Chartboxclick} />
                        <label htmlFor="label" className='filter_sort cancelsort'>Other</label>                             
                    </div>
                           
                </div>
                { chartbox ?(
                    <div className="textarea" id='textarea'>
                        <textarea className='textarea-form-control-review' cols={3} rows={4} placeholder='Add description'></textarea>
                    </div>
                ):""
                }
                <div className="proceedcancellation-padding"></div>

                <div className="product-cancelselectFilter">
                You will be charged ₹150 as cancellation fees. ₹78,549 will be refunded to your respective payment method within the next 3 - 7 days.
                </div>
                <div className="proceedcancellation-padding"></div>
                                  
         
            </div>
        </>
    )
}

export default OrderCancelStep2