import React,{useState} from 'react';
import { Link } from '@reach/router';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form'

const SortFilter = ({...props}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Link to={show ? '/' : '#'} className="toolbar-menu-item d-table-cell" onClick={handleShow} aria-hidden="true">

                <div className='d-flex align-items-center justify-content-center'>
                    <span className="toolbar-menu-icon">
                        <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.00065 5.33333H5.33465L5.33398 13.3333H4.00065V5.33333H1.33398L4.66732 2L8.00065 5.33333ZM14.6673 10.6667L11.334 14L8.00065 10.6667H10.6673V2.66667H12.0007V10.6667H14.6673Z" fill="#121415"/>
                        </svg>
                    </span>
                    <span className="toolbar-menu-label ml-2">Sort By</span>
                </div>
            </Link>
            <Offcanvas className='address-offcanvas' show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Sort By</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='pt-0'>
                    <div className='filter-tab__lists sort__by'>
                        <div className='filter-tab__list'>
                            <div className='filter-tab__flex'>
                                <Form.Check className='filter__checkbox sort__checkbox' aria-label="option 1" type='radio' name='radio'/>
                                <label htmlFor="label" className='filter_sort'>Relevance</label>
                            </div>
                        </div>
                        <div className='filter-tab__list'>
                            <div className='filter-tab__flex'>
                                <Form.Check className='filter__checkbox sort__checkbox' aria-label="option 1" type='radio' name='radio'/>
                                <label htmlFor="label" className='filter_sort'>Price - High to Low</label>
                            </div>
                        </div>
                        <div className='filter-tab__list'>
                            <div className='filter-tab__flex'>
                                <Form.Check className='filter__checkbox sort__checkbox' aria-label="option 1"type='radio' name='radio'/>                    
                                <label htmlFor="label" className='filter_sort'>Price - Low to High</label>
                            </div>
                        </div>
                        <div className='filter-tab__list'>  
                            <div className='filter-tab__flex'>
                                <Form.Check className='filter__checkbox sort__checkbox' aria-label="option 1" type='radio' name='radio'/>
                                <label htmlFor="label" className='filter_sort'>Popularity</label>                
                            </div>
                        </div>
                        <div className='filter-tab__list'>  
                            <div className='filter-tab__flex'>
                                <Form.Check className='filter__checkbox sort__checkbox' aria-label="option 1" type='radio' name='radio'/>
                                <label htmlFor="label" className='filter_sort'>Newest First</label>                
                            </div>
                        </div>
              
         
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default SortFilter