import React, { useState } from 'react';
import { Link } from '@reach/router';
// import SponsoredPhonesCarousel from '../Product/SponsoredPhonesCarousel';
// import ProductRelatedCategory from '../Product/ProductRelatedCategory';
import { SearchData } from '../../Data';

const IphoneSearchTrending = () => {
    const [trandingoffer]=useState(true)
    return (
        <>


           

            <div className="recentsearch_div"> 

                <div className='product-wrapper product__0 mt-0'>    
                    {SearchData.slice(0, 3).map(data => (
                        <div className='searchtrandingrow' key={data._id}>
                            <div className='mt-0 search_product product-wrapper-flex'>
                                <div className='product-thumb'>
                                    <img src={data.Image} className='img-fluid img w-100' alt={data.productName} />
                                </div>
                                <div className='product-details productsearch_reco'>
                                    {trandingoffer ?
                                        (
                                            <p className='product-muted navtrending-tag'>TRENDING</p>                                        
                                        ):(
                                            <p className='product-muted navtrending-tag'>Upto 18% Off</p> 
                                        ) 
                                    }
                                    <h2>
                                        <Link to='/ProductDetails '>{data.productName}</Link>
                                    </h2>
                                    <p className='product-muted'>
                                        {data.productColor}                                
                                    </p>                                         
                                </div>                     
                            </div>
                        </div>
                    ))}
                    {/* <div className='product-sponsored__wrap searchsponser_container'>
                        <h4 className='product-sponsored__head p-x-16'>Sponsored Phones</h4>
                        <div className='ml--16'>
                            <SponsoredPhonesCarousel /> 
                        </div>
                    </div>
                    <div className=''>
                        <h4 className='specification-heading p-x-16'>Related Categories</h4>
                        <div className='category-carousel ml--16'>
                            <ProductRelatedCategory />
                        </div>
                    </div> */}
                </div>  
            </div>       
        </>
    )
}

export default IphoneSearchTrending