import React from 'react';
import { Link } from '@reach/router';
import Form from 'react-bootstrap/Form';
import RemoveAddress from './RemoveAddress';

const AddressList = () => {
    return (
        <>
            <div className='cart-address__wrap'>
                <div className='cart-address__normal cart-address__default'>
                    <div className='coupon-list__input mt-1'>
                        <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" />
                    </div>
                    <div className='loggedIn__list'>
                        <div className='loggedIn__label'>Rohan Nanda <span className='default__address'>(Default)</span></div>
                        <div className="btn btn-login btn-tag">Home</div>
                        <div className='cart-address__section'>
                            <div className='loggedIn-address__label'>
                                103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103
                            </div>
                            <div className='cart-address-btn__wrap'>
                                <Link to='/AddAddressDetails' className='btn btn-category'>Edit</Link>
                                {['bottom'].map((placement, idx) => (
                                    <RemoveAddress key={idx} placement={placement} name={placement} />
                                ))} 
                            </div>
                        </div>
                    </div>
                </div>
                <div className='cart-address__normal'>
                    <div className='coupon-list__input mt-1'>
                        <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" />
                    </div>
                    <div className='loggedIn__list'>
                        <div className='loggedIn__label'>Avani Soni</div>
                        <div className="btn btn-login btn-tag">Work</div>
                        <div className='cart-address__section'>
                            <div className='loggedIn-address__label'>
                                205, Khalsa Building, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560017
                            </div>
                            <div className='cart-address-btn__wrap'>
                                <Link to='/AddAddressDetails' className='btn btn-category'>Edit</Link>
                                {['bottom'].map((placement, idx) => (
                                    <RemoveAddress key={idx} placement={placement} name={placement} />
                                ))} 
                            </div>
                        </div>
                    </div>
                </div>
                <div className='cart-address__normal'>
                    <div className='coupon-list__input mt-1'>
                        <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" />
                    </div>
                    <div className='loggedIn__list'>
                        <div className='loggedIn__label'>Simran Param Singh</div>
                        
                        <div className='cart-address__section'>
                            <div className='loggedIn-address__label'>
                                205, Khalsa Building, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103
                            </div>
                            <div className='cart-address-btn__wrap'>
                                <Link to='/AddAddressDetails' className='btn btn-category'>Edit</Link>
                                {['bottom'].map((placement, idx) => (
                                    <RemoveAddress key={idx} placement={placement} name={placement} />
                                ))} 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddressList