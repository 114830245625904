import React from 'react';
import Form from 'react-bootstrap/Form'


const Discount = () => {
    return (
        <>
            <div className='filter-tab__lists'>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox screensize_checkbox' aria-label="option 1" id='label' />
                        <label htmlFor="label" className='filter_price'>30% and above</label>
                    </div>
                    <span className='filter-tab__count'>20</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox screensize_checkbox' aria-label="option 1" id='label' />
                        <label htmlFor="label" className='filter_price'>40% and above</label>
                    </div>
                    <span className='filter-tab__count'>20</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox screensize_checkbox' aria-label="option 1" id='label' />                    
                        <label htmlFor="label" className='filter_price'>50% and above</label>
                    </div>
                    <span className='filter-tab__count'>10</span>
                </div>
                <div className='filter-tab__list'>  
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox screensize_checkbox' aria-label="option 1" id='label' />
                        <label htmlFor="label" className='filter_price'>60% and above</label>                
                    </div>
                    <span className='filter-tab__count'>12</span>
                </div>
            </div>
        </>
    )
}

export default Discount